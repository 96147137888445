<template >
<div @mouseover="isHovering = true" @mouseleave="isHovering = false">
    
    <a class="nav-link dropdown-toggle dropdown-arrow expanded" href="" id="library" role="button" data-toggle="dropdown" aria-expanded="false">
        <img class="lang-icon" :src="getLanguageIcon(currentLanguage)">
    </a>

    <!--new desigin language -->
    <div v-if="isHovering"
        style="left: -161px " class="dropdown-locator drop-lang-menu-selector show">
        <ul class="dropdown-menu dropdown-menu-end dropdown-lang-menu" aria-labelledby="dropdownMenuButton1">
 
            <div v-if="supportedLanguage()" class="current-country-wrapper">
                <li>
                    <a class="dropdown-item current-country font-msn-semibold" href="#">{{t("languageDropdown.header")}}</a>
                </li>
                <li v-for="language in languages" v-bind:key="language.code">
                    <a v-if="currentLanguage == language.code">
                        <p class="dropdown-item current-country-name font-msn-body">{{ language.name }}</p>
                    </a>
                </li>
                <li>
                    <hr class="dropdown-divider">
                </li>
            </div>

            <div v-if="!loading">
                <div class="filter-accordion-item dropdown-region">

                    <p class="current-font dropdown-region-header font-msn-semibold">{{t("languageDropdown.subHeader")}}</p>

                    <div class="filter-accordion-item__headingWrapper">
                        <p class="filter-accordion-item__label"></p>
                        <button
                            style="max-height:12px !important; font-weight: bold; font-size:12px"
                            @click="shouldExpand1 = !shouldExpand1"
                            :class="['accordion-button', { collapsed: !shouldExpand1 }]"
                            type="button">
                            {{t('languageDropdown.content.europe')}}
                        </button>

                    </div>
                    <div v-if="shouldExpand1" class="filter-accordion-item__content">
                        <div v-for="language in languages" v-bind:key="language.code">
                            <a v-if="!northAmerica(language.name) && language.name"
                               class="header-language-name-selector font-msn-body"
                               style="padding:10px; padding-left:30px"
                               :class="currentLanguage === language.code ? 'active' : ''"
                               @click="onClick(language)"
                            >
                                <span style="font-size:90%;  font-weight: normal;">{{ language.name }}</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="!loading">
                <div style="border-radius: 8px !important;" class="filter-accordion-item dropdown-region">
                    <div class="filter-accordion-item__headingWrapper">
                        <p class="filter-accordion-item__label"></p>
                        <button
                            style="max-height:20px !important; font-weight: bold;font-size:12px"
                            @click="shouldExpand = !shouldExpand"
                            :class="['accordion-button', { collapsed: !shouldExpand }]"
                            type="button"
                        >
                            {{t('languageDropdown.content.northAmerica')}}
                        </button>
                    </div>
                    <div v-if="shouldExpand" class="filter-accordion-item__content">
                        <div v-for="language in languages" v-bind:key="language.code">
                            <a v-if="northAmerica(language.name) && language.name"
                               class="header-language-name-selector font-msn-body"
                               style="padding:10px;padding-left:30px"
                               :class="currentLanguage === language.code ? 'active' : ''"
                               @click="onClick(language)"
                            >
                                <span style="font-size:90%;  font-weight: normal;">{{ language.name }}</span>
                            </a>
                        </div>
                    </div>
                </div>
                <!--<a style="font-size:0.7em !important;position:relative !important ; left:50px ;" href="https://staging.mysewnet.com/en/country-list/"> View All Countries</a> -->
                <div class="countries-list ">
                    <a class="font-msn-body" href="https://staging.mysewnet.com/en/country-list/">
                    <!-- this is where the view all counteries would be -->
                    </a>
                </div>
            </div>
            <div v-if="loading">
                <div style="position:relative; left:100px ;max-height:10px;max-width:10px; " class="spinner-grow" role="status">
                    <span class="sr-only">{{ t("search.loading") }}</span>
                </div>
            </div>

        </ul>

    </div>
</div>
</template>


<style lang="scss" scoped>
.current-font {
    --bs-blue: #0d6efd;
    --bs-indigo: #6610f2;
    --bs-purple: #6f42c1;
    --bs-pink: #d63384;
    --bs-red: #dc3545;
    --bs-orange: #fd7e14;
    --bs-yellow: #ffc107;
    --bs-green: #198754;
    --bs-teal: #20c997;
    --bs-cyan: #0dcaf0;
    --bs-white: #fff;
    --bs-gray: #6c757d;
    --bs-gray-dark: #343a40;
    --bs-primary: #0d6efd;
    --bs-secondary: #6c757d;
    --bs-success: #198754;
    --bs-info: #0dcaf0;
    --bs-warning: #ffc107;
    --bs-danger: #dc3545;
    --bs-light: #f8f9fa;
    --bs-dark: #212529;
    --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    word-wrap: break-word;
    -webkit-font-smoothing: antialiased;
    visibility: visible;
    list-style: none;
    --bs-position: end;
    box-sizing: border-box;
    display: block;
    width: 100%;
    padding: .25rem 1rem;
    clear: both;
    font-weight: 400;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    font-family: soleil-semibold, "Helvetica Neue", Arial, sans-serif;
    text-decoration: none;
    color: #5D3078;
    font-size: 9px;
    letter-spacing: 0;
    line-height: 12px;
    text-transform: uppercase;
}

.current-contry {
    border-radius: 8px;
    --bs-blue: #0d6efd;
    --bs-indigo: #6610f2;
    --bs-purple: #6f42c1;
    --bs-pink: #d63384;
    --bs-red: #dc3545;
    --bs-orange: #fd7e14;
    --bs-yellow: #ffc107;
    --bs-green: #198754;
    --bs-teal: #20c997;
    --bs-cyan: #0dcaf0;
    --bs-white: #fff;
    --bs-gray: #6c757d;
    --bs-gray-dark: #343a40;
    --bs-primary: #0d6efd;
    --bs-secondary: #6c757d;
    --bs-success: #198754;
    --bs-info: #0dcaf0;
    --bs-warning: #ffc107;
    --bs-danger: #dc3545;
    --bs-light: #f8f9fa;
    --bs-dark: #212529;
    --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    word-wrap: break-word;
    -webkit-font-smoothing: antialiased;
    background: linear-gradient(#FFF, #EDEAEF);
    visibility: visible;
    list-style: none;
    --bs-position: end;
    box-sizing: border-box;
    margin: 0;
    margin-bottom: 1em;
    display: block;
    width: 100%;
    padding: .25rem 1rem;
    clear: both;
    font-weight: 400;
    text-align: inherit;
    text-decoration: none;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    font-family: soleil-light, "Helvetica Neue", Arial, sans-serif;
    color: #000000;
    font-size: 11px;
    letter-spacing: 0;
    line-height: 14px;
}

.line {
    border-bottom: 2px solid purple;
}

.language-menu {
    width: 150%;
    position: absolute;
    z-index: 999;
    right: 0;
    top: -2px;
    background-color: white;
}

.lang-icon {
    max-height:20px !important;
    max-width:20px !important;
    display:inline-block;
}
</style>

<script>
import {
    translate,
    getCurrentLanguageFromCookie,
    getLanguageIcon
} from "../utils/utils";

export default {
    name: "LanguageList",
    data: () => ({
        isHovering:false,
        languages: [],
        loading: false,
        currentLanguage: '',
        shouldExpand: false,
        shouldExpand1: false

    }),
    props: {
        languagedata: String
    },

    methods: {
        getLanguageIcon,
        supportedLanguage(){
            for( let i=0; i<this.languages.length;i++){
                if (this.languages[i].code === this.currentLanguage){
                    return true;
                }
            }
            return false;
        },
        northAmerica(lang) {
            return lang === "Canada (English)" || lang === "Canada (French)" || lang === "United States (English)";

        },
        onClick: function (languageObject) {
            const url = window.location.origin + '/api/setculture' + languageObject.href;
            const currentUrl = window.location.href;
            this.currentLanguage = languageObject.code;

            $.ajax({
                url: url,
                method: "POST",
                data: {
                    currentUrl
                },
                dataType: "json",
                beforeSend: xhr => {
                    this.loading = true;
                },
                success: data => {
                    if (data.error) {
                        this.loading = false;
                        return;
                    }

                    let newUrl = '';
                    let urlArray = data.currentUrl.split('/');

                    let matches = this.languages.filter(function (language) {
                        return language.href === ('/' + urlArray[3]);
                    });

                    // Check if the URL has language setting already and if it exists, update to new else insert the language
                    if (data.newCulture == null) {
                        if (urlArray.length >= 4) {
                            if (matches.length >= 1) {
                                urlArray.splice(3, 1);
                            }
                        }
                    } else if (matches.length >= 1) {
                        urlArray[3] = data.newCulture;
                    } else {
                        urlArray.splice(3, 0, data.newCulture);
                    }

                    newUrl = urlArray.join("/");
                    this.$store.commit("clearSearchResult");
                    window.location.href = newUrl;
                },
                error: (request) => {
                    this.loading = false;
                }
            });
        },
        t(key) {
            return translate(key)
        }
    },
    created: function () {
        // Sort the list according to name
        this.languages = JSON.parse(this.languagedata);
        this.currentLanguage = getCurrentLanguageFromCookie();
    },
}
</script>
