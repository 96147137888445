<template>
    <div>
        <img :src="additionalImage.thumbnail" :class="{'active': currentIndex == additionalImage.index}" alt="">
    </div>

<!-- Alternate version, old / WIP -->
<!-- <div class="slider-wrapper" :data-image="additionalImage.image" data-target="#design-images-slider" :data-slide-to="additionalImage.index">
        <ul class="slider-thumb">
            <li class="type-image"><img v-bind:src="additionalImage.thumbnail" :class="{'active': currentIndex == additionalImage.index}"/></li>
        </ul>
    </div> -->
</template>

<script>
export default {
    name: "AdditionalImageThumbnail",
    props: {
        additionalImage: Object,
        currentIndex: Number
    }
}
</script>
