<template>
    <div class="remove-coupon" @click="removeCoupon">
        <slot></slot>
    </div>
</template>

<script>
import store from "../store";
import { translatedAlertModal } from "../components/alert-modal";

export default {
    name: "RemoveCoupon",
    props: {
        coupon: {
            type: Object,
            default: null,
        }
    },
    data() {
        return {
            url: "/api/order/coupon",
            isLoading: false,
        }
    },
    methods: {
        removeCoupon(e) {
            if (this.isLoading) {
                return;
            }

            this.isLoading = true;

            this.$emit("remove-coupon-click", this.coupon.couponId);

            store.commit("updateOrderLoading", {
                status: true
            });

            $.ajax({
                url: this.url,
                method: "DELETE",
                data: { couponId: this.coupon.couponId },
                success: data => {
                    store.commit("updateOrder", {
                        order: data
                    });
                },
                error: (event, textStatus, error) => {
                    let response = event.responseJSON;
                    this.$emit('remove-coupon-error', "Could not remove coupon \"" + this.coupon.code + "\"");

                    if (typeof Rollbar !== 'undefined') {
                        var eventObject = {
                            errorResponseObject: event,
                            textStatus: textStatus,
                            error: error,
                            eventType: 'placeOrderError'
                        }
                        Rollbar.captureEvent(eventObject, 'error');
                        Rollbar.error('API call POST order/coupon error');
                    }
                },
                complete: event => {
                    store.commit("updateOrderLoading", {
                        status: false
                    });
                    this.isLoading = false;
                }
            });
        }
    }
}
</script>

<style scoped>

</style>
