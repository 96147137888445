var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "unit-switch" }, [
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.displayUnit,
          expression: "displayUnit",
        },
      ],
      attrs: { type: "radio", id: "unit-mm", value: "millimeter" },
      domProps: { checked: _vm._q(_vm.displayUnit, "millimeter") },
      on: {
        change: [
          function ($event) {
            _vm.displayUnit = "millimeter"
          },
          _vm.setDisplayUnit,
        ],
      },
    }),
    _vm._v(" "),
    _c(
      "label",
      { class: { active: _vm.mmIsActive() }, attrs: { for: "unit-mm" } },
      [_vm._v(_vm._s(_vm.t("display-unit.millimeter")))]
    ),
    _vm._v(" "),
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.displayUnit,
          expression: "displayUnit",
        },
      ],
      attrs: { type: "radio", id: "unit-inch", value: "inch" },
      domProps: { checked: _vm._q(_vm.displayUnit, "inch") },
      on: {
        change: [
          function ($event) {
            _vm.displayUnit = "inch"
          },
          _vm.setDisplayUnit,
        ],
      },
    }),
    _vm._v(" "),
    _c(
      "label",
      { class: { active: !_vm.mmIsActive() }, attrs: { for: "unit-inch" } },
      [_vm._v(_vm._s(_vm.t("display-unit.inch")))]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }