var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.shouldShow && _vm.imageUrl,
          expression: "shouldShow && imageUrl",
        },
      ],
      staticClass: "featured-img",
      class: _vm.classes,
    },
    [_c("img", { attrs: { src: _vm.imageUrl, alt: "featured image" } })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }