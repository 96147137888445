var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        id: "download",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        width: "32",
        height: "32",
        viewBox: "0 0 32 32",
      },
    },
    [
      _c("path", {
        attrs: {
          id: "Path_136158",
          "data-name": "Path 136158",
          d: "M21,14l-5,5-5-5",
          fill: "none",
          stroke: "#fff",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
          "stroke-width": "2",
        },
      }),
      _vm._v(" "),
      _c("line", {
        attrs: {
          id: "Line_4159",
          "data-name": "Line 4159",
          y1: "13",
          transform: "translate(16 6)",
          fill: "none",
          stroke: "#fff",
          "stroke-linecap": "round",
          "stroke-width": "2",
        },
      }),
      _vm._v(" "),
      _c("g", { attrs: { id: "Group_186315", "data-name": "Group 186315" } }, [
        _c(
          "g",
          {
            attrs: {
              id: "Group_186314",
              "data-name": "Group 186314",
              "clip-path": "url(#clip-path)",
            },
          },
          [
            _c("path", {
              attrs: {
                id: "Path_136159",
                "data-name": "Path 136159",
                d: "M3.5,21l2,3.6A2.637,2.637,0,0,0,7.79,26H24.21a2.637,2.637,0,0,0,2.29-1.4l2-3.6",
                fill: "none",
                stroke: "#fff",
                "stroke-linecap": "round",
                "stroke-linejoin": "round",
                "stroke-width": "2",
              },
            }),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }