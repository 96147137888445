<template>
<div class="latest-designs-container" v-bind:class="{ 'is-loading': loading }">
    <div v-if="loading" class="loading-container d-flex align-items-center justify-content-center">
        <div class="spinner-grow" role="status">
            <span class="sr-only">{{ t("search.loading") }}</span>
        </div>
    </div>
    <div v-else>
        <div class="horizontal-list-container">
            <tiny-slider class="design-slide-container" :items="2" :slideBy="2" :loop="false" :responsive="slideOptions.responsive" :controlsContainer="controlsContainerSelector" :nav="true" :navContainer="navContainerSelector" :controls="false" :preventScrollOnTouch="slideOptions.preventScrollOnTouch">
                <div v-for="design in computedCollections" :key="design.sku" class="design-slide-item mt-3">
                    <ListEntity :entity="design" />
                </div>
            </tiny-slider>
            <div class="controls-container" :class="controlsContainerClass">
                <span class="left">
                    <i class="fas fa-chevron-left left"></i>
                </span>
                <span class="right">
                    <i class="fas fa-chevron-right right"></i>
                </span>
            </div>
            <div class="nav-container" :class="navContainerClass">
                <button v-for="i in computedCollections.length" :key="'key' + i" class="navigation-dot">
                </button>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import VueTinySlider from 'vue-tiny-slider';
import ListEntity from "./card/ListEntity.vue";
import {
    translate,
    generalErrorCallback,
    handleFetchErrors
} from "../utils/utils";
import {
    alertModal
} from '../components/alert-modal';
import HorizontalList from "./HorizontalList";

export default {
    name: "FeaturedCollections",
    data: () => ({
        nextButton: `<i class="fas fa-chevron-right right"></i>`,
        prevButton: `<i class="fas fa-chevron-left left"></i>`,
        slideOptions: {
            navContainer: 'tiny-slider-nav-container',
            controlsContainer: 'tiny-slider-controls-container',
            preventScrollOnTouch: 'force',
            responsive: {
                768: {
                    items: 3,
                    slideBy: 3,
                    controls: true
                },
                1024: {
                    items: 3,
                    slideBy: 3,
                    controls: true
                }
            }
        },
        loading: true,
        designs: []
    }),
    components: {
        HorizontalList,
    },
    components: {
        'tiny-slider': VueTinySlider,
        ListEntity,
    },
    computed: {
        controlsContainerClass: function () {
            return this.slideOptions.controlsContainer + '-' + this._uid;
        },
        controlsContainerSelector: function () {
            return '.' + this.controlsContainerClass;
        },
        navContainerClass: function () {
            return this.slideOptions.navContainer + '-' + this._uid;
        },
        navContainerSelector: function () {
            return '.' + this.navContainerClass;
        },
        computedCollections: function () {
            if (typeof this.designs === 'string') {
                return JSON.parse(this.designs);
            }

            return this.designs;
        },

    },
    mounted: function () {
        this.fetchCollections();
    },
    methods: {
        fetchCollections: function () {
            this.designs = [];
            const url = window.location.origin + "/api/collections?&orderKey=publishedDate&order=desc";
            //TODO  add this to the URL after finished testing "?myFavorites=true&offset=" + this.orderkey + "&offset=" + this.offset;
            this.errorState = false;
            this.loading = true;

            $.ajax({
                url: url,
                method: "GET",
                dataType: "json",
                beforeSend: xhr => {
                    xhr.setRequestHeader(
                        "Cache-Control",
                        "no-store, no-cache, must-revalidate, max-age=0"
                    );
                },
                success: data => {
                    if (!this.loadingMore) {
                        this.collectionfavorites = [];
                    }

                    this.designs = [
                        ...this.designs,
                        ...data.collections.map(collection => {
                            return {
                                url: collection.collectionPageUrl,
                                ...collection
                            };
                        })
                    ];

                    this.loading = false;
                },
                error: event => {
                    this.errorState = true;
                    generalErrorCallback(event, url);
                    this.loading = false;

                },
                complete: event => {
                    this.loading = false;
                }
            });
        },

        t(key) {
            return translate(key)
        }
    }
}
</script>
