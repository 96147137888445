var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "collection-designs" }, [
    _c("div", { staticClass: "mt-4" }, [
      _c(
        "h2",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: this.hideTitle === false,
              expression: "this.hideTitle === false",
            },
          ],
        },
        [_vm._v(_vm._s(_vm.t(this.title)))]
      ),
      _vm._v(" "),
      _c(
        "p",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: this.hideDescription === false,
              expression: "this.hideDescription === false",
            },
          ],
        },
        [
          _vm._v(
            " " +
              _vm._s(
                _vm.t("designsincollection", { count: this.designs.length })
              )
          ),
        ]
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "mt-4" }, [
      _vm.loading
        ? _c(
            "div",
            {
              staticClass:
                "loading-container d-flex align-items-center justify-content-center",
            },
            [
              _c(
                "div",
                { staticClass: "spinner-grow", attrs: { role: "status" } },
                [
                  _c("span", { staticClass: "sr-only" }, [
                    _vm._v(_vm._s(_vm.t("search.loading"))),
                  ]),
                ]
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.designs.length > 0
        ? _c(
            "div",
            { staticClass: "row" },
            _vm._l(_vm.designs, function (design) {
              return _c(
                "div",
                {
                  key: design.sku,
                  staticClass: "col-lg-3 col-md-4 col-sm-6 col-6 mt-4",
                },
                [
                  _c("ListEntity", {
                    attrs: { entity: design },
                    on: { favoriteClickEvent: _vm.handleFavoriteClickEvent },
                  }),
                ],
                1
              )
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.errorState
        ? _c("div", { staticClass: "vue-error-message" }, [
            _vm._v(
              "\n            " +
                _vm._s(_vm.t("search.fetcherror")) +
                "\n        "
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.loadingMore
        ? _c(
            "div",
            {
              staticClass:
                "loading-container d-flex align-items-center justify-content-center",
            },
            [
              _c("div", {
                staticClass: "spinner-grow",
                attrs: { role: "status" },
              }),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _vm.hits > _vm.pageSize + _vm.offset
            ? _c("LoadMore", { attrs: { onClick: _vm.loadMoreClick } })
            : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }