<script>
import {
    mapGetters,
    mapMutations,
    mapActions
} from 'vuex'
import store from "../store";
import {
    isLoggedIn
} from "../utils/utils";

export default {
    name: "PreferredCraftingFormat",
    props: {
        isCheckout: {
            type: Boolean,
            default: false
        },
        isStandAlone: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            options: [
                {
                    text: '.CCM (for Singer Momento)',
                    value: 'ccm'
                }
            ]
        }
    },
    computed: {
        selectedCraftingFormat() {
            return this.isCheckout && this.craftingFormat ? this.craftingFormat : this.preferredCraftingFormat;
        },
        ...mapGetters([
            'preferredCraftingFormat',
            'craftingFormat'
        ]),
        ...mapMutations([
            'updatePreferredCraftingFormat',
            'updateCraftingFormat',
        ]),
        ...mapActions([
            'fetchPreferredCraftingFormat'
        ]),
    },

    created() {
        if (!this.preferredCraftingFormat && isLoggedIn()) {
            store.dispatch('fetchPreferredCraftingFormat')
        }
    },
    methods: {
        changeFormat: function (event) {
            const value = event.target.value;
            if (this.isStandAlone) {
                this.$emit("getSelectedCraftingFormat", value);
                return;
            }
            if (!this.isCheckout) {
                store.dispatch('storePreferredCraftingFormat', {
                    value
                })
                store.commit('updatePreferredCraftingFormat', {
                    value
                });
            } else {
                store.dispatch('storePreferredCraftingFormat', {
                    value
                });
                store.commit('updateCraftingFormat', {
                    value
                });
            }
            console.log( store.state );
        },
    }
}
</script>

<template>
    <div class="preferred-crafting-format">
        <select @change="changeFormat" :value="selectedCraftingFormat">
            <option v-for="option in options" :value="option.value" :key="option.value">
                {{ option.text }}
            </option>
        </select>
        <i class="fa fa-caret-down" ></i>
    </div>
</template>

<style scoped lang="scss">
    .preferred-crafting-format {
        position: relative;
        display: inline-block;
        margin: 10px 0;

        select {
            border: 1px solid ;
            border-color: gray;
            padding: .6rem 1rem .6rem .6rem;
            border-radius: 10px;
            width: 350px;
            max-width: 100%;
            background-color: white;
            height: 45px;
            -webkit-appearance: none;
            -moz-appearance: none;
            padding-right: 20px;
            text-overflow: ellipsis;
        }

        .fa-caret-down,
        .fa-caret-up  {
            position: absolute;
            right: 10px;
            top: 12px;
            font-size: 1.28rem;
            color: #505050;
            pointer-events: none;
        }


        .fa-caret-up {
            margin-top: -1rem;
        }
    }

    .preferred-format-icon {
        font-size: 16px;
    }
</style>
