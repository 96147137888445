var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "g",
    { attrs: { id: "favorite", transform: "translate(-675 -624)" } },
    [
      _c("path", {
        attrs: {
          id: "favorite-2",
          "data-name": "favorite",
          d: "M33.95,11.23a6.6,6.6,0,0,0-9.539-.373q-.187.18-.361.373L22,13.461,19.95,11.23a6.6,6.6,0,0,0-9.539-.373q-.187.18-.361.373A8.1,8.1,0,0,0,10.05,22L22,35,33.95,22A8.1,8.1,0,0,0,33.95,11.23Z",
          transform: "translate(669 618)",
          fill: "#C61A5A",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
          "stroke-width": "0",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }