<template>
    <div ref="swiper" class="swiper" v-show="showList">
        <!-- Additional required wrapper -->
        <div class="swiper-wrapper">
            <!-- Slides -->
            <template v-for="item in toolsList">
                <div class="swiper-slide">
                    <ToolsSwiperItem :item="item"></ToolsSwiperItem>
                </div>
            </template>
        </div>
        <!-- If we need pagination -->
        <div class="swiper-pagination"></div>

        <!-- If we need navigation buttons -->
        <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div>

        <!-- If we need scrollbar -->
        <div class="swiper-scrollbar"></div>
    </div>
</template>

<script>
import Swiper from 'swiper';
import 'swiper/css'
import 'swiper/css/navigation'
import ToolsSwiperItem from './ToolsSwiperItem.vue';
import ListEntity from './card/ListEntity.vue';

export default {
    name: "ToolsSwiper",
    components: {ListEntity, ToolsSwiperItem },
    data() {
        return  {
            showList: false
        }
    },
    mounted() {
        new Swiper(this.$refs.swiper, {
            slidesPerView: 'auto',
            spaceBetween: 16,
            // slidesPerGroup: 2,
            mousewheel: true,
            // Navigation arrows
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            watchOverflow: true,
        })
        this.$nextTick(() => {
            this.showList = true
        })
    },
    props: {
        toolsList: {
            type: Array
        }
    }
}

</script>
<style lang="scss" scoped>
.swiper {
    margin: 0;
}
.swiper-button-next,
.swiper-button-prev {
    background-color: white;
    padding: 8px;
    border-radius: 100%;
    box-shadow: 0 10px 30px #0000003E;
    height: 44px;
    width: 44px;

    &::after {
        font-size: 16px;
        color: #161614;
    }

    &.swiper-button-disabled {
        display: none;
    }
}

.swiper-slide {
    width: 260px;
}

.hidden {
    display: none;
}

/* Tablet devices */
@media screen and (max-width: 1023px) {
    .swiper-slide {
        width: 224px;
    }
    .swiper {
        margin: 0 10px;
    }
}

/* Mobile devices */
@media screen and (max-width: 767px) {
    .swiper-slide {
        width: 174px;
    }
    .swiper {
        margin: 0 3px;
    }
}
</style>
