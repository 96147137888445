<script>
import CollectionIcon from "../icons/common/CollectionIcon.vue";
import EmbroideryIcon from "../icons/common/EmbroideryIcon.vue";
import FontsIcon from "../icons/common/FontsIcon.vue";
import CraftingIcon from "../icons/common/CraftingIcon.vue";
import IconsBase from "../icons/IconsBase.vue";
import { EventBus } from "../../utils/eventBus";
import Swiper from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import {translate} from '../../utils/utils';
export default {
    name: "LFilterButtons",
    components: {
        IconsBase,
        EventBus,
        EmbroideryIcon,
        FontsIcon,
        CraftingIcon,
        CollectionIcon,
    },
    /**
     * @typedef {Object} props
     * @property {string<all | emb | craft | fonts | collections >} activeFilter - button style types
     * */
    props: {
        activeFilter: {
            type: String,
            default: "all",
        },
        allnum: Number,
        collectionsnum: Number,
        fontsnum: Number,
        craftingnum: Number,
        embroiderynum: Number,
    },
    data() {
        return {
            localSelected: this.activeFilter,
        }
    },
    mounted() {
        new Swiper(this.$refs.swiper, {
            slidesPerView: 'auto',
            spaceBetween: 16,
        })
        this.$nextTick(() => {
            this.setFilter('all');
        });
    },
    methods: {
        t(key) {
            return translate(key);
        },
        setFilter(filterType) {
            this.localSelected = filterType;
            EventBus.$emit('filterChanged', filterType);
        },
    }
}
</script>

<template>
    <div class="l-filter">
        <div ref="swiper" class="swiper">
            <div class="swiper-wrapper">
                <div class="swiper-slide">
                    <div :class="['l-filter__item', { 'all-active': localSelected === 'all' }]" @click="setFilter('all')">
                        {{ t('search-results.type.all') }} <span>({{allnum}})</span>
                    </div>
                </div>
                <div class="swiper-slide">
                    <div :class="['l-filter__item', { 'emb-active': localSelected === 'emb' }]" @click="setFilter('emb')">
                        <IconsBase :iconColor="localSelected === 'emb' ? '#fff' : '#000'">
                            <embroidery-icon :iconName="t('search-results.type.embroideries')" />
                        </IconsBase>
                        {{ t('search-results.type.embroideries') }} <span>({{ embroiderynum }})</span>
                    </div>
                </div>
                <div class="swiper-slide">
                    <div :class="['l-filter__item', { 'craft-active': localSelected === 'crafting' }]" @click="setFilter('crafting')">
                        <IconsBase :iconName="t('search-results.type.crafting')" :iconColor="localSelected === 'crafting' ? '#fff' : '#000'">
                            <crafting-icon />
                        </IconsBase>
                        {{ t('search-results.type.crafting') }} <span>({{ craftingnum }})</span>
                    </div>
                </div>
                <div class="swiper-slide">
                    <div :class="['l-filter__item', { 'fonts-active': localSelected === 'font' }]" @click="setFilter('font')">
                        <IconsBase :iconName="t('search-results.type.fonts')" :iconColor="localSelected === 'font' ? '#fff' : '#000'">
                            <fonts-icon />
                        </IconsBase>
                        {{ t('search-results.type.fonts') }} <span>({{ fontsnum }})</span>
                    </div>
                </div>
                <div class="swiper-slide">
                    <div :class="['l-filter__item', { 'collections-active': localSelected === 'collection' }]"
                         @click="setFilter('collection')">
                        <IconsBase :iconName="t('search-results.type.collections')" :iconColor="localSelected === 'collection' ? '#fff' : '#000'">
                            <collection-icon />
                        </IconsBase>
                        {{ t('search-results.type.collections') }} <span>({{ collectionsnum }})</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.swiper-slide {
    width: auto;
}

.l-filter {
    font-family: 'soleil-regular', sans-serif;
    color: var(--white);
    font-size: 16px;
    line-height: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    .swiper {
        padding: 0 10px;
    }

    &__item {
        display: inline-block;
        cursor: pointer;
        padding: 11px 12px;
        border: 1px solid #D8D8D8;
        border-radius: 12px;
        height: 44px;
        background-color: var(--white);
        color: black;
        & span {
            font-family: 'soleil-semibold', sans-serif;
        }

        &.all-active {
            border: 1px solid #367498;
            background-color: var(--green);
            color: var(--white);
        }

        &.emb-active {
            border: 1px solid #4E2765;
            background-color: var(--violet);
            color: var(--white);
        }

        &.craft-active {
            border: 1px solid #9D272B;
            background-color: var(--singer-red);
            color: var(--white);
        }

        &.fonts-active {
            border: 1px solid #9D272B;
            background-color: var(--singer-red);
            color: var(--white);
        }

        &.collections-active {
            border: 1px solid #D8D8D8;
            background-color: var(--orange);
            color: var(--white);
        }
    }
}

/* Tablet devices */
@media screen and (max-width: 1023px) {
    .l-filter {
        gap: 12px;
        font-size: 14px;

        &__item {
            height: 40px;
        }
    }
}

/* Mobile devices */
@media screen and (max-width: 767px) {
    .l-filter {
        gap: 12px;
        font-size: 12px;

        &__item {
            height: 38px;
        }
    }
}</style>
