var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "l-popper",
        {
          attrs: {
            trigger: "clickToOpen",
            options: {
              placement: "auto",
              modifiers: { offset: { offset: "0,10px" } },
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "popper" },
            [
              _vm.isOpenTooltip ? _c("SubscriptionTooltip") : _vm._e(),
              _vm._v(" "),
              _vm.isOpenDownloadTooltip
                ? _c("DownloadDesign", {
                    attrs: {
                      sku: _vm.sku,
                      name: _vm.name,
                      isPurchased: _vm.isPurchased == true,
                      isCollection: _vm.isCollection == true,
                      designtype: _vm.designtype,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "l-button",
            {
              staticStyle: { "background-color": "rgba(0, 0, 0, 0.3)" },
              attrs: { slot: "reference", type: "small" },
              nativeOn: {
                click: function ($event) {
                  return _vm.openDownloadModal()
                },
              },
              slot: "reference",
            },
            [
              _c(
                "IconsBase",
                { attrs: { iconName: "Download" } },
                [_c("download-icon")],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }