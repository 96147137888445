<template>
<div class="favorites-listing-page item-listing-page">
    <div class="item-listing-page-header-container">
        <h1>{{ t("header.library.myFavorites") }}</h1>
        <div class="item-listing-page-header-extra">
            <div class="item-type-switch-container row">
                <div class="col-6" style="width:30%">
                    <button class="item-type-switch" :class="{ active: specifiedEmbroidery }" style="" value="embroidery" @click="specifyItemType('Embroidery')">
                        <img v-if="specifiedEmbroidery" src="/static/icons/check.png" />{{ t("categories.designs") }}
                    </button>
                </div>
                <div class="col-6" style="width:30%">
                    <button class="item-type-switch" :class="{ active: specifiedCollections }" value="collections" @click="specifyItemType('collections')">
                        <img v-if="specifiedCollections" src="/static/icons/check.png" />{{ t("Collections") }}
                    </button>
                </div>
                <div class="col-6" style="width:30%">
                    <button class="item-type-switch" :class="{ active: specifiedCrafting }" value="crafting" @click="specifyItemType('crafting')">
                        <img v-if="specifiedCrafting" src="/static/icons/check.png" />{{ t("Crafting") }}
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="page-margin mt-4">
        <KeepAlive>
            <EmbroideryFavorites v-if="specifiedEmbroidery || allTypesNotSelected()" />
        </KeepAlive>
        <div class="item-listing-page-section-divider"></div>
        <KeepAlive>
            <CollectionsFavorites v-if="specifiedCollections || allTypesNotSelected()" />
        </KeepAlive>
        <!-- <div class="item-listing-page-section-divider"></div> -->
        <!-- <KeepAlive>
            <QuiltFavorites v-if="specifiedQuilt || allTypesNotSelected()" />
        </KeepAlive> -->
        <div class="item-listing-page-section-divider"></div>
        <KeepAlive>
            <CraftingFavorites v-if="specifiedCrafting || allTypesNotSelected()" />
        </KeepAlive>
    </div>
</div>
</template>

<script>
import {
    translate,
} from "../utils/utils";

import EmbroideryFavorites from "./EmbroideryFavorites";
import QuiltFavorites from "./QuiltFavorites";
import CollectionsFavorites from './CollectionsFavorites';
import CraftingFavorites from './CraftingFavorites';

export default {
    name: "Favorites",
    data: () => ({
        specifiedEmbroidery: false,
        specifiedCollections:false,
        specifiedCrafting:false,
        specifiedQuilt:false,
    }),
    components: {
        CollectionsFavorites,
        EmbroideryFavorites,
        QuiltFavorites,
        CraftingFavorites,
    },
    created: function () {
    },
    props: {
        link: String,
    },
    methods: {
        allTypesNotSelected(){
            if(!this.specifiedCollections && !this.specifiedEmbroidery && !this.specifiedCrafting)
            return true
        },
        specifyItemType(type) {
            if (type === 'Embroidery' ){this.specifiedEmbroidery = !this.specifiedEmbroidery}
            if (type === 'collections' ){this.specifiedCollections = !this.specifiedCollections}
            if (type === 'crafting' ){this.specifiedCrafting = !this.specifiedCrafting}
           
            
        },

        t(key, variables) {
            return translate(key, variables)
        },
    },

}
</script>

<style lang="scss">

</style>
