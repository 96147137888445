// Returns value of given query parameter
export function getQueryParameterValue(variable) {

    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) {
            return decodeURIComponent(pair[1].replace(/\+/g, '%20'));
        }
    }
    return "";
}

// Returns new url with modified query parameter
export function composeUrlFromQueryVariables(queryData, baseUrl = window.location.href) {
    const url = new URL(baseUrl);

    const queryString = url.search;

    const searchParams = new URLSearchParams(queryString);

    for (const k in queryData) {
        if (queryData.hasOwnProperty(k) && (queryData[k].length || queryData[k] !== '')) {
            searchParams.set(k, queryData[k]);
        }
        // Remove empty search params
        if (searchParams.has(k) && queryData[k].length === 0) {
            searchParams.delete(k)
        }
    }

    // change the search property of the main url
    url.search = searchParams.toString();

    // output : http://demourl.com/path?id=101&topic=main
    return url.toString();
}

// Returns new url with modified query parameter
export function composeMainpageUrlFromQueryVariables(queryData, baseUrl) {
    if (baseUrl === undefined) {
        baseUrl = window.location.origin + window.location.pathname
    }

    var languageCode = window.location.pathname.split('/')[1];
    var url = new URL(baseUrl + "search");

    if(languageCode){
        url = new URL(baseUrl + "/search");
    }

    const queryString = url.search;

    const searchParams = new URLSearchParams(queryString);

    for (const k in queryData) {
        if (queryData.hasOwnProperty(k) && (queryData[k].length || queryData[k] !== '')) {
            searchParams.set(k, queryData[k]);
        }
        // Remove empty search params
        if (searchParams.has(k) && queryData[k].length === 0) {
            searchParams.delete(k)
        }
    }

    // change the search property of the main url
    url.search = searchParams.toString();

    // output : http://demourl.com/path?id=101&topic=main
    return url.toString();
}

export function generalErrorCallback(event, url) {
    console.error(`Server error: ${event.status} ${event.statusText}  |  URL: ${url}`);
}

export function switchFavorite(sku, isCurrentFavorite, designType, callback) {

    // Analytics
    try {
        if (!isCurrentFavorite) {
            gtag('event', 'Favorite marked', {
                'event_category': 'Designs',
                'event_label': sku
            });
        } else {
            gtag('event', 'Favorite unmarked', {
                'event_category': 'Designs',
                'event_label': sku
            });
        }

    } catch (error) {
        console.warn("Google Analytics code is missing.")
    }
    var url;

    // Fallback if designType is not defined.
    if (typeof designType === "undefined") {
        designType = 'embroidery';
    }

    switch (designType.toLowerCase()) {
        case "collection":
            url = `${ window.location.origin }/api/user/collectionfavorites/${ sku }`
            break;
        case "font":
            url = `${ window.location.origin }/api/user/fontfavorites/${ sku }`
            break;
        case "embroidery":
        case "quilt":
        case "crafting":
        default:
            url = `${ window.location.origin }/api/user/favorites/${ sku }`

    }

    var method = isCurrentFavorite ? "DELETE" : "PUT";

    return fetch(url, {
        method: method,
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    });
}

export function handleFetchErrors(response) {
    // Check success here, as the catch function 
    // is only executed when there is a network failure.

    // This checks for an OK on the response object.
    // This is set to false for eny HTTP status 
    // other than in the range 200-299
    if (response.ok === false) {
        // Aborts and runs catch function
        throw Error(response.statusText);
    }

    return response.json();
}

export function translate(key, variables) {
    let translation = window.app_translations[key] ? window.app_translations[key] : key;

    for (const variable in variables) {
        translation = translation.replace(`%${variable}%`, variables[variable]);
    }

    return translation;
}

export function getCurrentLanguageFromCookie() {
    let cookie = '; ' + document.cookie;
    let parts = cookie.split('; svpCulture=');

    if (parts.length === 2) {
        let code = parts.pop().split(';').shift().toLowerCase()

        if (code === 'zh-cn') {
            code = 'zh-hans-cn';
        }

        return code;
    } else {
        // Default language, if no language cookie exists
        return 'en-us';
    }
}

// Helper function to check if window.logged_in if set, and returns what it is set to
export function isLoggedIn() {
    if (typeof window.logged_in === 'undefined') {
        return false;
    }

    return window.logged_in;
}

export function isAllowedCountry() {
    if (typeof window.is_allowed_country === 'undefined') {
        return false;
    }

    return window.is_allowed_country;
}

export function userHasSubscription() {
    if (typeof window.user_has_subscription === 'undefined') {
        return false;
    }

    return window.user_has_subscription;
}
export function getLanguageIcon(lang) {
    const library = "https://staging.mysewnet.com/globalassets/mysewnet/country-flag-icons";
    const pc_library = "https://projectcreator.qa.mysewnet.com/assets/static/flags";
    switch (lang) {
        case 'en-us': {
            return `${library}/world-flag_241-usa--united-states-of-america.svg`
        }
        case 'en-ca':
        case 'fr-ca': {
            return `${library}/world-flag_040-can--canada.svg`
        }
        case 'da-dk': {
            return `${library}/world-flag_062-dnk--denmark.svg`
        }
        case 'en-gb': {
            return `${library}/world-flag_240-gbr--united-kingdom.svg`
        }
        case 'fr-fr': {
            return `${library}/world-flag_078-fra--france.svg`
        }
        case 'it-it': {
            return `${library}/world-flag_111-ita--italy.svg`
        }
        case 'fr-be':
        case 'nl-be': {
            return `${library}/world-flag_022-bel--belgium.svg`
        }
        case 'tr-tr': {
            return `${library}/world-flag_233-tur--turkey.svg`
        }
        case 'es-es': {
            return `${library}/world-flag_214-esp--spain.svg`
        }
        case 'nb-no':
        case 'no-no': {
            return `${library}/world-flag_170-nor--norway.svg`
        }
        case 'zh-hans-cn': {
            return `${library}/world-flag_047-chn--china.svg`
        }
        case 'ru-ru': {
            return `${library}/world-flag_187-rus--russian-federation.svg`
        }
        case 'sv-fi':
        case 'fi-fi': {
            return `${library}/world-flag_077-fin--finland.svg`
        }
        case 'nl-nl': {
            return `${pc_library}/world-flag_159-NLD--Netherlands.svg`
        }
        case 'sv-se': {
            return `${pc_library}/world-flag_220-SWE--Sweden.svg`
        }
        case 'he-il': {
            return `${pc_library}/world-flag_108-IRL--Ireland.svg`
        }
        case 'de-at': {
            return `${pc_library}/world-flag_015-AUT--Austria.svg`
        }
        case 'de-de': {
            return `${pc_library}/world-flag_085-DEU--Germany.svg`
        }
        case 'de-ch':
        case 'fr-ch':
        case 'it-ch': {
            return `${pc_library}/world-flag_221-CHE--Switzerland.svg`
        }
        default: {
            return "https://images.squarespace-cdn.com/content/v1/5fa6b76b045ef433ae7b252e/1606858578321-LQQRBVXYIQUH3S4ZYYQI/Flag_20x30.jpg"
        }
    }
}

export function SmoothScrollToTop() {
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    });
}

/**
 * Check if minutes have passed since time
 *
 * @param time
 * @param minutes
 * @returns {boolean}
 */
export function hasMinutesPassed(time, minutes) {
    var past = new Date(time).getTime();
    var minutesToCheck = 1000 * 60 * minutes;
    return (new Date().getTime() - past < minutesToCheck) ? false : true;
}

/**
 * Generic method for calling Library api
 *
 * @param endpoint
 * @param method
 * @param data
 * @param successCallback
 * @param errorCallback
 * @returns {*}
 */
export function apiCall(endpoint, method, data, successCallback, errorCallback) {
    const url = window.location.origin + '/api/' + endpoint;

    const ajaxOptions = {
        url: url,
        method: method,
        dataType: "json",
        beforeSend: xhr => {
            xhr.setRequestHeader(
                "Cache-Control",
                "no-store, no-cache, must-revalidate, max-age=0"
            );
        },
        success: response => {
            if (successCallback) {
                successCallback(response);
            }
        },
        error: (response, status, error) => {
            if (errorCallback) {
                errorCallback(response, status, error);
            } else {
                var eventObject = {
                    errorResponseObject: errorResponse,
                    textStatus: textStatus,
                    error: error,
                    eventType: 'genericApiCallError',
                    endpoint: endpoint,
                }
                Rollbar.captureEvent(eventObject, 'error');
                Rollbar.error('API call ' + endpoint + ' error');
            }
        }
    }

    if (data) {
        ajaxOptions.data = data;
    }

    return $.ajax(ajaxOptions);
}
