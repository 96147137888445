<script>
export default {
    name: "LButton",
    /**
     * @typedef {Object} props
     * @property {string<primary | secondary | tint>} type - button style types
     * @property {string<ordinary | tiny >} size - button style size
     * @property {string<violet | orange | pink | blue>} color - button style types
     * @property {string<default | dark | bordered>} rightIconType - right icon types
     * @property {string<default | dark | bordered>} leftIconType - left icon types
     * */
    props: {
        type: {
            type: String,
            default: 'primary',
        },
        size: {
            type: String,
            default: 'ordinary',
        },
        color: {
            type: String,
            default: 'violet',
        },
        block: {
            type: String,
            default: '',
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },
        rightIconType: {
            type: String,
            default: 'default',
        },
        leftIconType: {
            type: String,
            default: 'default',
        },
    },
    computed: {
        leftIconSlot() {
            return this.$slots.left_icon !== undefined && this.$slots.left_icon.length > 0;
        },
        rightIconSlot() {
            return this.$slots.right_icon !== undefined && this.$slots.right_icon.length > 0;
        },
    },
    methods: {
        getIconClass() {
            return {
                'left-icon-dark': this.leftIconType === 'dark',
                'left-icon-bordered': this.leftIconType === 'bordered',
                'right-icon-dark': this.rightIconType === 'dark',
                'right-icon-bordered': this.rightIconType === 'bordered',
            }
        }
    }
}
</script>

<template>
    <button
        class="l-btn"
        :class="[type, color, size, { block: block, loading: isLoading }, getIconClass()]"
        :disabled="isDisabled"
    >
        <span v-if="leftIconSlot" class="left-icon">
            <slot name="left_icon"></slot>
        </span>
        <span class="l-btn__text"
              :class="[{'with-left-icon': leftIconSlot, 'with-right-icon': rightIconSlot}]"
        >
            <slot></slot>
        </span>
        <span v-if="rightIconSlot" class="right-icon">
            <slot name="right_icon"></slot>
        </span>
    </button>
</template>

<style scoped lang="scss">
.l-btn {
    display: grid;
    grid-template-columns: auto 1fr auto;
    text-align: center;
    justify-content: space-between;
    align-items: center;
    border: none;
    background: none;
    cursor: pointer;
    outline: none;
    border-radius: 8px;
    text-transform: uppercase;
    transition: .3s;
    margin: 10px 0;
    box-sizing: border-box;
    font-family: 'soleil-semibold', sans-serif;
    font-size: 16px;
    padding: 0;
    &__text {
        padding: 12px 24px;
        &.with-left-icon {
            padding-left: 0;
        }
        &.with-right-icon {
            padding-right: 12px;
        }
    }
    &.block {
        width: 100%;
    }
    &.small {
        line-height: 1.2;
        background-color: #6C6C6C;
        color: var(--white);
        &:hover {
            background-color: lighten(#6C6C6C, 5%);
        }
        & .l-btn__text {
            padding: 9px;
        }
        &.pink {
            background-color: var(--pink);
            &:hover {
                background-color: var(--pink-light);
            }
        }
        &.light-gray {
            background-color: var(--black);
            opacity: 30%;
            &:hover {
                opacity: 40%;
            }
        }
    }
    &.primary {
        background-color: var(--violet);
        color: var(--white);
        &:hover {
            background-color: var(--violet-light);
        }
        &.violet {
            background-color: var(--violet);
            &.left-icon-dark {
                & .left-icon {
                    background-color: var(--dark-violet);
                    padding: 12px 19px;
                    margin-right: 7px;
                }
            }
            &.right-icon-dark {
                & .right-icon {
                    background-color: var(--dark-violet);
                }
            }
            &:hover {
                background-color: var(--violet-light);
            }
        }
        &.orange {
            background-color: var(--orange);
            &.left-icon-dark {
                & .left-icon {
                    background-color: black;
                    margin-right: 7px;
                    padding: 12px 19px;
                }
            }
            &.right-icon-dark {
                & .right-icon {
                    background-color: black;
                }
            }
            &:hover {
                background-color: var(--orange-light);
            }
        }
        &.pink {
            background-color: var(--pink);
            &.left-icon-dark {
                & .left-icon {
                    background-color: var(--dark-violet);
                    margin-right: 7px;
                    padding: 12px 19px;
                }
            }
            &.right-icon-dark {
                & .right-icon {
                    background-color: var(--dark-violet);
                }
            }
            &:hover {
                background-color: var(--pink-light);
            }
        }
        &.blue {
            background-color: var(--blue);
            &.left-icon-dark {
                & .left-icon {
                    background-color: black;
                    margin-right: 7px;
                    padding: 12px 19px;
                }
            }
            &.right-icon-dark {
                & .right-icon {
                    background-color: black;
                }
            }
            &:hover {
                background-color: var(--blue-light);
            }
        }
    }
    &.secondary {
        &.violet {
            color: var(--violet);
            border: 2px solid var(--violet);
            &:hover {
                background-color: var(--tint-violet);
            }
            &.left-icon-bordered {
                & .left-icon {
                    border-right: 2px solid var(--violet);
                    margin-right: 7px;
                    padding: 12px 19px;
                }
            }
            &.right-icon-bordered {
                & .right-icon {
                    border-left: 2px solid var(--violet);
                }
            }
        }
        &.orange {
            color: var(--orange);
            border: 2px solid var(--orange);
            &:hover {
                background-color: var(--tint-orange);
            }
            &.left-icon-bordered {
                & .left-icon {
                    border-right: 2px solid var(--orange);
                    margin-right: 7px;
                    padding: 12px 19px;
                }
            }
            &.right-icon-bordered {
                & .right-icon {
                    border-left: 2px solid var(--orange);
                }
            }
        }
        &.pink {
            color: var(--pink);
            border: 2px solid var(--pink);
            &:hover {
                background-color: var(--tint-pink);
            }
            &.left-icon-bordered {
                & .left-icon {
                    border-right: 2px solid var(--pink);
                    margin-right: 7px;
                    padding: 12px 19px;
                }
            }
            &.right-icon-bordered {
                & .right-icon {
                    border-left: 2px solid var(--pink);
                }
            }
        }
        &.blue {
            color: var(--blue);
            border: 2px solid var(--blue);
            &:hover {
                background-color: var(--tint-blue);
            }
            &.left-icon-bordered {
                & .left-icon {
                    border-right: 2px solid var(--blue);
                    margin-right: 7px;
                    padding: 12px 19px;
                }
            }
            &.right-icon-bordered {
                & .right-icon {
                    border-left: 2px solid var(--blue);
                }
            }
        }
    }
    &.tint {
        border: 2px solid var(--violet-light);
        &.violet {
            color: var(--violet);
            background-color: var(--tint-violet);
            &:hover {
                background-color: var(--violet-light);
                color: var(--white);
            }
        }
        &.orange {
            color: var(--orange);
            border-color: var(--orange-light);
            background-color: var(--tint-orange);
            &:hover {
                background-color: var(--orange-light);
                color: var(--white);
            }
        }
        &.pink {
            color: var(--pink);
            border-color: var(--pink-light);
            background-color: var(--tint-pink);
            &:hover {
                background-color: var(--pink-light);
                color: var(--white);
            }
        }
        &.blue {
            color: var(--blue);
            border-color: var(--blue-light);
            background-color: var(--tint-blue);
            &:hover {
                background-color: var(--blue-light);
                color: var(--white);
            }
        }
    }
    &.tiny {
      & .l-btn__text {
        padding: 7px 12px;
        font-size: 16px;
      }
    }
    & .left-icon {
      padding: 12px;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }
    & .right-icon {
      padding: 12px 19px;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
}
</style>
