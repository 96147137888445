var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "failed purchase" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-12 wrapper" }, [
        _c("div", { staticClass: "align-center" }, [
          _c("h3", [_vm._v(_vm._s(_vm.t("checkout.failed.top-headline")))]),
          _vm._v(" "),
          _c("i", { staticClass: "far fa-times-circle align-center" }),
        ]),
        _vm._v(" "),
        _c("div", {
          staticClass: "text",
          domProps: { innerHTML: _vm._s(_vm.t("checkoutFree.failed.text")) },
        }),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-block btn-lg black",
            on: {
              click: function ($event) {
                return _vm.changeStep("Billing")
              },
            },
          },
          [_vm._v(_vm._s(_vm.t("checkoutFree.failed.return-button-label")))]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }