<script>
import { generalErrorCallback, translate } from "../../utils/utils";
import { EventBus } from "../../utils/eventBus";

export default {
    name: "SendToMachineTooltip",
    props: {
        sku: String,
        isPurchased: Boolean,
        designname: String,
        designtype: String,
        sendButtonEventId: String
    },
    data() {
        return {
            clients: [],
            designSent: false,
            targetClient: {},
            loading: false,
        }
    },
    components: {
        EventBus
    },
    computed: {
        compatible() {
            return this.clients.filter(function (client) {
                return client.compatible;
            });
        },
        notcompatible() {
            return this.clients.filter(function (client) {
                return !client.compatible;
            });
        }
    },
    mounted() {
        EventBus.$on(this.sendButtonEventId, this.getClientList);
    },
    methods: {
        t(key, variables) {
            return translate(key, variables)
        },
        getClientList() {
            this.designSent= false,
            this.loading = true;
            const baseApiUrl = `${window.location.origin}/api/mysewnet/clients`;
            const designTypeParam = `designtype=${this.designtype}`;
            const supportsUnencryptedParam = this.isPurchased ? 'supportsUnencrypted=1' : '';

            const url = `${baseApiUrl}?${designTypeParam}&${supportsUnencryptedParam}`;

            $.getJSON(
                url,
                (data) => {
                    this.clients = data;
                },
            ).fail(event => {
                this.errorState = true;
                generalErrorCallback(event, url);
            }).always(event => {
                this.loading = false;
            });

        },
        onSendDesignClick(client) {
            const url = window.location.origin + "/api/mysewnet/send/" + this.sku;
            this.loading = true;
            this.designSent = false;

            $.ajax({
                type: "POST",
                url: url,
                context: this,
                data: client,
                success: (data) => {
                    if (!data || data.success === false) {
                        this.designSent = false;
                    } else {
                        this.designSent = true;
                        this.targetClient = client;
                    }
                },
            }).fail(event => {
                this.designSent = false;
                generalErrorCallback(event, url);
            }).always(event => {
                this.loading = false;

                // Send analytics event.
                const parameters = {
                    sku: this.sku,
                    success: this.designSent,
                    targetClientId: client.clientId
                };
                const url = window.location.origin + "/api/mysewnet/event/library-send-design";
                $.post(url, parameters);
            });
        },
    }
}
</script>
<template>
    <div class="send-box">
        <div class="modal-header">
      <img style="width: 40px; height: 40px; padding: 3px;" src="/static/icons/send-design-black.svg" alt>
     
        <p style="font-weight: bold; top:8px; position: relative;">{{ t("design-page.send-to") }}</p>
        </div>
    <div class="modal-body">
        <template v-if="loading">
            <div class="loading-container d-flex align-items-center justify-content-center">
                <div class="spinner-grow" role="status">
                    <span class="sr-only">{{ t("search.loading") }}</span>
                </div>
            </div>
        </template>
        <template v-if="!designSent && !loading && compatible.length > 0">
           <p> {{ t("send-to.heading-send-to") }} <span class="design-name">{{designname}}</span></p><br/>

            <div>
                <p> {{ t("detected-online-devices") }}</p>
                <div v-if="compatible.length">
                    <div v-for="client in compatible" :key="client.clientId">
                        <LButton color="pink" size="tiny" @click.native="onSendDesignClick(client)">
                            {{ client.name }}
                        </LButton>
                    </div>
                </div>
            </div>
        </template>

        <template v-else-if="compatible.length == 0 && !loading">
            <p class="bold">{{ t("send-to.no-clients") }}</p>
            <p>
                {{ t("sendDesign.not-loggedin", { designname: designname }) }}

            </p>
            <div style="display: grid; text-decoration: none;">
            <a style="padding-bottom: 10px; color:black" href="https://mysewnet.com/en/join-mysewnet/mysewnet-enabled-machines/">{{ t('mySewnet.Enabled-Machines') }}</a>
            <a style="color:black" href="https://mysewnet.com/knowledge-center/">{{ t('send_to.how_to_sign_dvice') }}</a>
            </div>
        </template>
        <template v-else>
            <p v-if="!loading"
                v-html="t('send-to.design-sent-success', { design: designname, machine: targetClient.name })">
            </p>
        </template>
    </div>
    </div>
</template>

<style scoped lang="scss">
.send-box {
    width: 100%;
    text-align: left;
    .modal-header{
        display: inline-flex;
        border: none;
    }
    .modal-body{
        padding-left: 20px;
    }
}
</style>
