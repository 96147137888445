var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "send-box" }, [
    _c("div", { staticClass: "modal-header" }, [
      _c("img", {
        staticStyle: { width: "40px", height: "40px", padding: "3px" },
        attrs: { src: "/static/icons/send-design-black.svg", alt: "" },
      }),
      _vm._v(" "),
      _c(
        "p",
        {
          staticStyle: {
            "font-weight": "bold",
            top: "8px",
            position: "relative",
          },
        },
        [_vm._v(_vm._s(_vm.t("design-page.send-to")))]
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "modal-body" },
      [
        _vm.loading
          ? [
              _c(
                "div",
                {
                  staticClass:
                    "loading-container d-flex align-items-center justify-content-center",
                },
                [
                  _c(
                    "div",
                    { staticClass: "spinner-grow", attrs: { role: "status" } },
                    [
                      _c("span", { staticClass: "sr-only" }, [
                        _vm._v(_vm._s(_vm.t("search.loading"))),
                      ]),
                    ]
                  ),
                ]
              ),
            ]
          : _vm._e(),
        _vm._v(" "),
        !_vm.designSent && !_vm.loading && _vm.compatible.length > 0
          ? [
              _c("p", [
                _vm._v(" " + _vm._s(_vm.t("send-to.heading-send-to")) + " "),
                _c("span", { staticClass: "design-name" }, [
                  _vm._v(_vm._s(_vm.designname)),
                ]),
              ]),
              _c("br"),
              _vm._v(" "),
              _c("div", [
                _c("p", [
                  _vm._v(" " + _vm._s(_vm.t("detected-online-devices"))),
                ]),
                _vm._v(" "),
                _vm.compatible.length
                  ? _c(
                      "div",
                      _vm._l(_vm.compatible, function (client) {
                        return _c(
                          "div",
                          { key: client.clientId },
                          [
                            _c(
                              "LButton",
                              {
                                attrs: { color: "pink", size: "tiny" },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.onSendDesignClick(client)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(client.name) +
                                    "\n                    "
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      }),
                      0
                    )
                  : _vm._e(),
              ]),
            ]
          : _vm.compatible.length == 0 && !_vm.loading
          ? [
              _c("p", { staticClass: "bold" }, [
                _vm._v(_vm._s(_vm.t("send-to.no-clients"))),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      _vm.t("sendDesign.not-loggedin", {
                        designname: _vm.designname,
                      })
                    ) +
                    "\n\n        "
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { display: "grid", "text-decoration": "none" } },
                [
                  _c(
                    "a",
                    {
                      staticStyle: { "padding-bottom": "10px", color: "black" },
                      attrs: {
                        href: "https://mysewnet.com/en/join-mysewnet/mysewnet-enabled-machines/",
                      },
                    },
                    [_vm._v(_vm._s(_vm.t("mySewnet.Enabled-Machines")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticStyle: { color: "black" },
                      attrs: { href: "https://mysewnet.com/knowledge-center/" },
                    },
                    [_vm._v(_vm._s(_vm.t("send_to.how_to_sign_dvice")))]
                  ),
                ]
              ),
            ]
          : [
              !_vm.loading
                ? _c("p", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.t("send-to.design-sent-success", {
                          design: _vm.designname,
                          machine: _vm.targetClient.name,
                        })
                      ),
                    },
                  })
                : _vm._e(),
            ],
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }