<template>
<div class="checkout-details payment">
    <div class="modal fade modal-library" id="currencyChangedModal" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="currencyChangedModalTitle" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 id="currencyChangedModalTitle" class="modal-title">
                        {{ t("checkout.payment.currency-changed-headline") }}
                    </h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <img src="/static/icons/close.png" alt>
                    </button>
                </div>
                <div class="modal-body">
                    <p>{{ t("checkout.payment.currency-changed-text") }}</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" data-dismiss="modal">{{ t('close') }}</button>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="shopping-cart">
            <div v-if="isLoading" style="margin-right: auto; margin-left: auto;" class="spinner-wrapper">
                <div class="spinner-grow" role="status"></div>
                <div class="processing-payment" v-if="processingPayment">{{ t("checkout.payment.processing-payment-text") }}</div>
            </div>

            <div v-if="!isLoading" class="checkout-wrapper row">
                <div class="row">
                    <div class="col-md-6 order-2 order-md-1 mt-3 mt-md-0">
                        <h3>{{ t("checkout.payment.headline") }}</h3>
                        <div v-html='t("checkout.payment.text")'></div>
                    </div>
                    <div class="col-md-6 col order-details-wrapper">
                        <div class="price-wrapper">
                            <Summary data-vue step="payment"></Summary>
                        </div>

                    </div>
                </div>
                <div class="row mt-2 mt-md-5">
                    <div class="col-md-12">
                        <iframe :src="iframe.src" id="paymentFrame" @load="load" v-show="iframe.loaded" frameborder="0" height="500px" width="100%"></iframe>
                    </div>
                </div>
            </div>
            <div class="btn-wrapper-profile">
                <a @click="changeStep('Billing')" class="blank-btn c-pointer ">{{ t('checkout.actions.back') }}</a>
            </div>
        </div>
    </div>

</div>
</template>

<style lang="scss">
@import "../../globals";

.checkout-details {
    .spinner-wrapper {
        align-content: center;
        flex-wrap: wrap;
    }

    &.payment {
        .processing-payment {
            margin-top: 1rem;
            display: block;
            text-align: center;
            width: 100%;
        }
    }
}

.checkout-step.payment {
    .form-group {
        position: relative;
        margin-bottom: 0;
        padding-bottom: 2.6rem;
    }
}

.form-group {

    &.card-number,
    &.cvc {
        position: relative;
    }

    &.card-number {
        .card-type-icon {
            position: absolute;
            top: 12px;
            right: 5px;
            width: 3.21rem;
            height: 3.21rem;

            &.visa {
                background: url('../../icons/card_visa.svg') no-repeat center center;
            }

            &.mastercard {
                background: url('../../icons/card_master.svg') no-repeat center center;
            }
        }
    }

    &.cvc {
        .cvc-icon {
            width: 3.21rem;
            height: 3.21rem;
            right: 5px;
            top: 10px;
            position: absolute;
            background: url('../../icons/card_cvc.svg') no-repeat center center;
        }
    }
}
</style>

<script>
import {
    mask
} from 'vue-the-mask';
import {
    translate,
    isLoggedIn,
    hasMinutesPassed,
    apiCall
} from "../../utils/utils";
import store from "../../store";
import {
    mapGetters
} from "vuex";
import Summary from "./Summary";
import {
    alertModal
} from "../../components/alert-modal";
import Modal from "../Modal";
import { SmoothScrollToTop } from "../../utils/utils";

export default {
    directives: {
        mask
    },
    name: "Payment",
    props: ["handleChange"],
    components: {
        Summary,
        Modal
    },
    data: () => ({
        currency: window.global_currency_code || '',
        GData: [],
        Temporder: null,
        processingPayment: false,
        showCurrencyChangedModal: false,
        errors: [],
        isLoading: false,
        valid: false, // Change to false after validation is implemented.
        iframe: {
            src: 'checkout/iframe',
            loaded: false
        }
    }),
    computed: {
        validate() {
            return true;
        },
        ...mapGetters([
            'payment',
            'order',
            'purchase',
            'designFormat',
            'preferredFormat',
        ]),
    },
    mounted() {
        window.payment = this;

        if (this.order && this.order.showCurrencyChangedModal) {
            this.showCurrencyChangedModal = true;

            this.openModal();

            // Close the modal after 3 seconds
            setTimeout(() => {
                this.closeModal();

            }, 10000);
        }
        if (this.order) {
            this.Temporder = this.order;
            this.onCheckout("add_payment_info");
        }
    },
    methods: {
        pushData(item) {
            try{
            if(item.purchasePrice.discount !== null){
                this.GData.push({
                    item_id: item.designcode,
                    item_name: item.nameofdesign.replace(/['"]+/g, ''),
                    currency:this.currency,
                    discount: item.purchasePrice.discount,
                    price: item.purchasePrice.amount.toString(),
                    item_category: item.extendedData.categories[0],
                    item_category1: item.extendedData.categories[1],
                    item_category2: item.extendedData.categories[2],
                    item_category3: item.extendedData.categories[3],
                    item_category4: item.extendedData.categories[4],
                    item_category5: item.extendedData.categories[5],
                    quantity: 1
                });
            }
            else{
                this.GData.push({
                    item_id: item.designcode,
                    item_name: item.nameofdesign.replace(/['"]+/g, ''),
                    currency:this.currency,
                    price: item.purchasePrice.amount.toString(),
                    item_category: item.extendedData.categories[0],
                    item_category1: item.extendedData.categories[1],
                    item_category2: item.extendedData.categories[2],
                    item_category3: item.extendedData.categories[3],
                    item_category4: item.extendedData.categories[4],
                    item_category5: item.extendedData.categories[5],
                    quantity: 1
                });
            }
            }catch(error){console.log('this item seems to be a collection')}
        },
        onCheckout(stepname) {
            this.GData = [];
            this.Temporder.lineitems.forEach(this.pushData);
            dataLayer.push({
                ecommerce: null
            }); // Clear the previous ecommerce object.
            dataLayer.push({
                event: stepname,
                ecommerce: {
                    currency: this.currency,
                    value: this.Temporder.cost[0].display,
                    tax:this.Temporder.taxTotal,
                    items: this.GData
                }
            });
            console.log(dataLayer);
        },
        // onCheckoutGtagBackub(stepname) {
        //     this.Temporder.lineitems.forEach(this.pushData);

        //     gtag("event", stepname, {
        //         currency: this.currency,
        //         value: this.Temporder.orderCost[0].amount.toString(),
        //         items: this.GData
        //     });
        // },
        load: function () {
            this.iframe.loaded = true;
            this.isloading = false;
        },
        closeModal() {
            this.showCurrencyChangedModal = false;

            // Remove from order object and commit changes.
            this.order.showCurrencyChangedModal = false;

            $(this.$el).find('#currencyChangedModal').modal('hide');

            store.commit("updateOrder", {
                order: this.order,
            });
        },
        openModal() {
            $(this.$el).find('#currencyChangedModal').modal('show');
        },
        hasError(field) {
            if (this.errors) {
                const foundError = this.errors.filter((error) => error.field === field);

                if (foundError.length) {
                    return foundError[0];
                }
            }

            return false;
        },
        translateError(field) {
            const error = this.hasError(field);

            if (error) {
                return this.t(error.msg)
            }

            return null;
        },
        addError(field, msg) {
            this.errors.push({
                field: field,
                msg: msg
            });
        },
        clearError(field) {
            if (this.errors) {
                this.errors.forEach((error, index) => {
                    if (error.field === field) {
                        this.errors.splice(index, 1);
                        return false;
                    }
                })
            }
        },
        onConfirmAndPay(token, nameOnCard) {
            this.isLoading = true;
            this.processingPayment = true;

            const setPaymentMethodData = {
                token: token,
                name: nameOnCard,
            }
            SmoothScrollToTop();
            apiCall("order/set-payment-method", "POST", setPaymentMethodData,
                (setPaymentMethodResponse) => {
                    const placeOrderData = {
                        format: this.designFormat ? this.designFormat : this.preferredFormat,
                    }
                    apiCall("order/place-order", "POST", placeOrderData,
                        (placeOrderResponse) => {
                            this.isLoading = false;
                            this.valid = true;
                            this.processingPayment = false;

                            // Remove token information from store
                            store.commit("updatePayment", {
                                payload: null
                            });
                            store.commit("updatePurchase", {
                                purchase: placeOrderResponse,
                            })
                            store.commit("updateOrder", {

                                order: null,
                            })
                            this.changeStep('Confirmation');
                        },
                        (errorResponse, textStatus, error) => {
                            if (typeof Rollbar !== 'undefined') {
                                var eventObject = {
                                    errorResponseObject: errorResponse,
                                    textStatus: textStatus,
                                    error: error,
                                    eventType: 'placeOrderError'
                                }
                                Rollbar.captureEvent(eventObject, 'error');
                                Rollbar.error('API call order/place-order error');
                            }
                            this.isLoading = false;
                            this.valid = false;
                            this.processingPayment = false;
                            this.changeStep('Failed');
                        }
                    );
                },
                (errorResponse, textStatus, error) => {
                    if (typeof Rollbar !== 'undefined') {
                        var eventObject = {
                            errorResponseObject: errorResponse,
                            textStatus: textStatus,
                            error: error,
                            eventType: 'setPaymentError'
                        }
                        Rollbar.captureEvent(eventObject, 'error');
                        Rollbar.error('API call order/set-payment-method error');
                    }
                    this.changeStep('Failed');
                }
            );
        },
        changeStep(step) {
            // if (step === "Confirmation") {
            //     try{  this.onCheckout("purchase");}
            //   catch(e){console.error(e)}
            // }
            this.$emit("handleChange", step);
        },
        t(key, variables) {
            return translate(key, variables);
        }
    },
}
</script>
