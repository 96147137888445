<template>
<div v-if="!entity.empty" class="list-entity" style="border:none !important">
    <div class="list-entity-inner">
        <div class="list-entity-body">
            <div class="list-entity-image-wrapper itemimg">
                <span v-if="entity.isPurchased" class="purchased-label">{{ t("design.purchased-label") }}</span>
                <a class="card-img-wrapper" :href="entity.isCollection ? entity.collectionPageUrl : entity.designPageUrl"><img v-lazy="entity.thumbnail || noImageUrl" /></a>

                <span @click="onFavoriteClick">
                    <img v-if="!entity.isFavorite" class="like-img" src="/static/icons/like.svg" alt="">
                    <img v-if="entity.isFavorite" class="like-img" src="/static/icons/selected-heart.svg" alt="">
                </span>
            
            </div>
            <div class="list-entity-buttons">
                <div class="list-entity-buttons-inner">
                    <div v-if="!entity.isCollection" class="card-design-share-btn">
                        <SendToMachineButton :sku="entity.sku" :is-purchased="entity.isPurchased" :designname="entity.label" :designimage="entity.thumbnail" :designtype="entity.designtype" />
                    </div>
                    <SaveDesignVaultButton :designtype="entity.designtype" :iscollection="entity.isCollection" :designimage="entity.thumbnail || noImageUrl" :designname="entity.label" :sku="entity.priceGroup" :is-purchased="entity.isPurchased" :price-display="entity.price ? entity.price.display : ''" :allowedCountry="showPrice" :designCode="entity.sku" :nameOfDesign="entity.name" />
                </div>

                <AddToCart v-if="entity.isAvailableAsSinglePurchase && !canDownload" :skuCode="entity.priceGroup" :designCode="entity.sku" :price="entity.price" :nameOfDesign="entity.name" :allowedCountry="entity.allowedCountry" :free="free" :itemtype="entity.designtype"/>

                <DownloadButton v-if="canDownload" :image="entity.thumbnail || noImageUrl" :sku="entity.sku" :name="entity.label" :designtype="entity.designtype" />
            </div>
        </div>
        <div class="list-entity-footer">
            <img :src="itemTypeImage" alt="">
            <span class="design-name">{{entity.label}}</span>
        </div>
    </div>
</div>
</template>

<script>
import {
    subscriptionModal
} from "../components/subscription-modal";
import {
    switchFavorite,
    translate,
    isLoggedIn,
    userHasSubscription
} from '../utils/utils';
import {
    EventBus
} from "../utils/eventBus";
import SendToMachineButton from './SendToMachineButton';
import SaveDesignVaultButton from './SaveDesignVaultButton'
import {
    loginModal
} from "../components/login-modal";

import AddToCart from "./AddToCart";
import DownloadButton from "./DownloadButton";

import Modal from "./Modal";
import DownloadDesign from "./DownloadDesign";
import {
    mapGetters,
    mapMutations
} from 'vuex'
import store from "../store";
import {
    translatedAlertModal
} from "../components/alert-modal";
export default {
    name: "SimpleListEntity",
    props: {
        entity: Object,
    },
    data() {
        return {

            noImageUrl: "https://via.placeholder.com/600",
            isUpdating: false,
        }
    },
    /*   data: () => ({
           noImageUrl: "https://via.placeholder.com/600",
           isUpdating: false,
       }),*/
    components: {
        SendToMachineButton,
        SaveDesignVaultButton,
        AddToCart,
        DownloadButton,

    },
    methods: {
        t(key) {
            return translate(key)
        },
        expandClick: function (event) {
            event.preventDefault();
        },
        onFavoriteClick: function name(event) {
          
            event.preventDefault();

            if (this.isUpdating) {
                return;
            }

            if (isLoggedIn() === false) {
                loginModal();
                return;
            }

            this.isUpdating = true;

            const designEntity = this;
            console.log(this.entity.isCollection)
            const favoritePromise = switchFavorite(this.entity.sku, this.entity.isFavorite, this.entity.designtype);

            this.entity.isFavorite = !this.entity.isFavorite;

            // Set as not loading when the fetch has finished.
            // We have to use finally .instead of .then as to not interfere
            // with the actual response of the fetch action.
            // As for now, we don't actually care about the result in this component.
            favoritePromise.finally(function () {
                designEntity.isUpdating = false;
            });

            // Inform parents that may be listening the the favoriteClick event
            this.$emit('favoriteClickEvent', favoritePromise, this.entity.sku);
        },

    },

    computed: {

        free: function () {
            return (this.singlePurchase && this.entity.price && this.entity.price.price === 0);
        },
        canDownload: function () {
            return this.entity.isPurchased;
        },
        singlePurchase: function () {
            return !!(this.entity.isAvailableAsSinglePurchase && this.entity.priceGroup && this.entity.price);
        },
        hasSub: function () {
            return userHasSubscription();
        },
        sku() {
            return this.downloadSku;
        },
        showPrice: function () {
            return this.entity.allowedCountry;

        },
        itemTypeImage: function () {
            if (this.entity.isCollection) {
                return "/static/icons/collection-1.svg";
            }

            if (this.entity.designtype == "embroidery") {
                return "/static/icons/emb-1.svg";
            }

            if (this.entity.designtype == "quilt") {
                return "/static/icons/quilt-1.svg";
            }
            if (this.entity.designtype == "crafting"){
                return "/static/icons/crafting-1.svg";
            }
        }
    },
}
</script>
