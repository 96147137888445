<template>
    <div class="tool-item">
        <img :src=item.image :alt=item.name />
        <p>{{ item.name }}</p>
        <l-button type="small" color="" block="block" @click.native="onClick(item.link)">
            {{ t('design-page.tools.visit-to-buy') }}
        </l-button>
    </div>
</template>

<script>
import {translate} from '../utils/utils';
import LButton from './buttons/LButton.vue';

export default {
    name: "ToolsSwiperItem",
    components: { LButton },
    props: {
        item: {
            type: Object,
        }
    },
    methods: {
        t: (key) => {
            return translate(key)
        },
        onClick: (link) => {
            if (typeof link !== 'undefined') {
                window.location.href = link;
            }

        }
    },
}

</script>

<style lang="scss" scoped>
.tool-item {
    text-align: center;
    padding: 5px 12px 12px 12px;
    border: 1px solid #D8D8D8;
    border-radius: 12px;

    button {
        grid-template-columns: 1fr;
        margin-bottom: 0;
    }
}
</style>
