<template>
    <div class="collection-help" v-on:click="openModal">
        <img src="/static/icons/quick_help.svg" alt="help" class="help-icon">
    </div>
</template>

<style lang="scss">
@import "../globals";

.collection-help {
    top: 6px;
    background-color: $btn-bg-color;
    border-radius: 8px;
    padding: 6px;
    width: 42px;
    height: 42px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    img.help-icon {
        width: 18px;
        height: 18px;
        filter: brightness(0);
        -webkit-filter: brightness(0);
    }
}

</style>

<script>
import {
    EventBus
} from "../utils/eventBus";
import Modal from "./Modal";

import IconsBase from "./icons/IconsBase.vue";
import {translate} from "../utils/utils";

export default {
    name: "CollectionHelpButton",
    components: {IconsBase, Modal},
    methods: {
        openModal() {
            EventBus.$emit('OpenCollectionHelp');
        },
        t(key, variables) {
            return translate(key, variables)
        }
    }
}
</script>
