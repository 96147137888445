var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "save-design-wrap" }, [
    _c("div", { staticClass: "modal-header" }, [
      _c("img", {
        staticStyle: { width: "40px", height: "40px", padding: "3px" },
        attrs: { src: "/static/icons/vault.svg", alt: "" },
      }),
      _vm._v(" "),
      _c(
        "p",
        {
          staticStyle: {
            "font-weight": "bold",
            top: "8px",
            position: "relative",
          },
        },
        [_vm._v(_vm._s(_vm.t("upload.tocloud.modal.header")))]
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "modal-body" }, [
      !_vm.hasError && !_vm.uploadSuccess
        ? _c("p", [
            _vm._v("\n      " + _vm._s(_vm.t(_vm.uploadText)) + " "),
            _c("span", { staticClass: "bold" }, [
              _vm._v(_vm._s(_vm.designname)),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.hasError && _vm.uploadSuccess
        ? _c("p", { staticClass: "bold" }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.t("save-to.heading-design-saved-success")) +
                "\n    "
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.hasError
        ? _c("div", [
            _vm._v("\n      " + _vm._s(_vm.t(_vm.errorText)) + "\n    "),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.uploadSuccess
        ? _c("p", [
            _vm._v(
              "\n      " +
                _vm._s(
                  _vm.t("save-to.design-saved-success-msg", {
                    filename: _vm.filename,
                  })
                ) +
                "\n    "
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.hasError &&
      !_vm.uploadSuccess &&
      _vm.isPurchased &&
      !_vm.isquilt &&
      !_vm.iscrafting
        ? _c(
            "div",
            [
              _c("p", [_vm._v(_vm._s(_vm.t("design.download-design-format")))]),
              _vm._v(" "),
              _c("PreferredFormat", {
                staticStyle: { "max-width": "-webkit-fill-available" },
                attrs: { isStandAlone: true },
                on: { getSelectedFormat: _vm.handleSelectedFormat },
              }),
            ],
            1
          )
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "save-design-wrap__actions" },
      [
        !_vm.uploadSuccess && !_vm.hasError
          ? _c(
              "LButton",
              {
                attrs: { color: "pink", size: "tiny" },
                nativeOn: {
                  click: function ($event) {
                    return _vm.uploadDesign()
                  },
                },
              },
              [
                _vm._v(
                  "\n        " + _vm._s(_vm.t(_vm.uploadText)) + "\n      "
                ),
              ]
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }