<script>
import IconsBase from "./icons/IconsBase.vue";
import ArrowDownIcon from "./icons/common/ArrowDownIcon.vue";

export default {
    name: "LAccordion",
    components: {ArrowDownIcon, IconsBase},
    props: {
        title: String,
        isOpen: {
            type: [Boolean, String],
            default: false
        }
    },
    data() {
        return {
            accordionIsOpen: this.isOpen
        };
    },
}
</script>

<template>
    <div class="l-accordion">
        <div class="l-accordion-item"  @click="accordionIsOpen = !accordionIsOpen">
            <p class="l-accordion-item__label">{{ title }}</p>
            <div :class="['l-accordion-item__arrow',
            accordionIsOpen ? 'collapsed' : null]">
                <IconsBase :iconColor="accordionIsOpen ? 'blue' : '#000000'">
                    <arrow-down-icon />
                </IconsBase>
            </div>
<!--            <i :class="['l-accordion-item__arrow', !accordionIsOpen ? 'collapsed' : null]"/>-->
        </div>
        <div class="l-accordion-item__content" v-show="accordionIsOpen">
            <slot />
        </div>
    </div>
</template>

<style scoped lang="scss">
.l-accordion {
    width: 100%;
    &-item {
        cursor: pointer;
        border-bottom: 1px solid #d7d7d4;
        margin-bottom: 17px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &__label {
            font-size: 24px;
        }
        &__arrow {
            transition: 0.3s;
            &.collapsed {
                rotate: 180deg;
            }
        }
    }
}
</style>
