var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "preferred-crafting-format" }, [
    _c(
      "select",
      {
        domProps: { value: _vm.selectedCraftingFormat },
        on: { change: _vm.changeFormat },
      },
      _vm._l(_vm.options, function (option) {
        return _c(
          "option",
          { key: option.value, domProps: { value: option.value } },
          [_vm._v("\n            " + _vm._s(option.text) + "\n        ")]
        )
      }),
      0
    ),
    _vm._v(" "),
    _c("i", { staticClass: "fa fa-caret-down" }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }