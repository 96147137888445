var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "card-image" },
      [
        _c("div", { staticClass: "card-image__current" }, [
          _c("img", {
            staticClass: "card-image__current-img",
            attrs: { src: _vm.currentImage.image },
          }),
        ]),
        _vm._v(" "),
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.computedDesignImages.length > 1,
                  expression: "computedDesignImages.length>1",
                },
              ],
              ref: "swiper",
              staticClass: "swiper",
              staticStyle: { padding: "20px" },
            },
            [
              _c(
                "div",
                { staticClass: "swiper-wrapper" },
                [
                  _vm._l(_vm.computedDesignImages, function (item, idx) {
                    return [
                      _c(
                        "div",
                        {
                          staticClass: "swiper-slide",
                          staticStyle: {
                            border: "1px solid #D8D8D8",
                            "border-radius": "16px",
                            "justify-content": "center",
                            display: "flex",
                          },
                          style: {
                            border:
                              _vm.currentIndex === idx
                                ? "5px solid #C3215B8B"
                                : "1px solid #D8D8D8",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.changeCurrentImage(idx)
                            },
                          },
                        },
                        [
                          _c("img", {
                            staticClass: "card-image__list-item__img",
                            staticStyle: { "max-height": "174", width: "260" },
                            attrs: { src: item.image },
                          }),
                        ]
                      ),
                    ]
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _c("div", { staticClass: "swiper-pagination" }),
              _vm._v(" "),
              _c("div", { staticClass: "swiper-button-prev" }),
              _vm._v(" "),
              _c("div", { staticClass: "swiper-button-next" }),
              _vm._v(" "),
              _c("div", { staticClass: "swiper-scrollbar" }),
            ]
          ),
        ],
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }