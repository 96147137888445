var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "favorites-listing-page item-listing-page" },
    [
      _c("div", { staticClass: "item-listing-page-header-container" }, [
        _c("h1", [_vm._v(_vm._s(_vm.t("header.library.myFavorites")))]),
        _vm._v(" "),
        _c("div", { staticClass: "item-listing-page-header-extra" }, [
          _c("div", { staticClass: "item-type-switch-container row" }, [
            _c("div", { staticClass: "col-6", staticStyle: { width: "30%" } }, [
              _c(
                "button",
                {
                  staticClass: "item-type-switch",
                  class: { active: _vm.specifiedEmbroidery },
                  attrs: { value: "embroidery" },
                  on: {
                    click: function ($event) {
                      return _vm.specifyItemType("Embroidery")
                    },
                  },
                },
                [
                  _vm.specifiedEmbroidery
                    ? _c("img", { attrs: { src: "/static/icons/check.png" } })
                    : _vm._e(),
                  _vm._v(
                    _vm._s(_vm.t("categories.designs")) +
                      "\n                    "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-6", staticStyle: { width: "30%" } }, [
              _c(
                "button",
                {
                  staticClass: "item-type-switch",
                  class: { active: _vm.specifiedCollections },
                  attrs: { value: "collections" },
                  on: {
                    click: function ($event) {
                      return _vm.specifyItemType("collections")
                    },
                  },
                },
                [
                  _vm.specifiedCollections
                    ? _c("img", { attrs: { src: "/static/icons/check.png" } })
                    : _vm._e(),
                  _vm._v(
                    _vm._s(_vm.t("Collections")) + "\n                    "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-6", staticStyle: { width: "30%" } }, [
              _c(
                "button",
                {
                  staticClass: "item-type-switch",
                  class: { active: _vm.specifiedCrafting },
                  attrs: { value: "crafting" },
                  on: {
                    click: function ($event) {
                      return _vm.specifyItemType("crafting")
                    },
                  },
                },
                [
                  _vm.specifiedCrafting
                    ? _c("img", { attrs: { src: "/static/icons/check.png" } })
                    : _vm._e(),
                  _vm._v(_vm._s(_vm.t("Crafting")) + "\n                    "),
                ]
              ),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "page-margin mt-4" },
        [
          _c(
            "KeepAlive",
            [
              _vm.specifiedEmbroidery || _vm.allTypesNotSelected()
                ? _c("EmbroideryFavorites")
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "item-listing-page-section-divider" }),
          _vm._v(" "),
          _c(
            "KeepAlive",
            [
              _vm.specifiedCollections || _vm.allTypesNotSelected()
                ? _c("CollectionsFavorites")
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "item-listing-page-section-divider" }),
          _vm._v(" "),
          _c(
            "KeepAlive",
            [
              _vm.specifiedCrafting || _vm.allTypesNotSelected()
                ? _c("CraftingFavorites")
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }