var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "accordion-item" }, [
    _c(
      "div",
      {
        class: [
          "accordion-header-wrapper",
          _vm.accordionIsOpen ? "active" : null,
        ],
      },
      [
        _vm.title.length > 0
          ? _c("p", { staticClass: "accordion-header" }, [
              _vm._v(_vm._s(_vm.title)),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("button", {
          class: [
            "accordion-button",
            !_vm.accordionIsOpen ? "collapsed" : null,
          ],
          attrs: { type: "button" },
          on: {
            click: function ($event) {
              _vm.accordionIsOpen = !_vm.accordionIsOpen
            },
          },
        }),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.accordionIsOpen,
            expression: "accordionIsOpen",
          },
        ],
        staticClass: "accordion-body",
      },
      [_vm._t("default")],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }