// Vue components
import BackButton from "./vue-components/BackButton";
import DownloadDesign from "./vue-components/DownloadDesign";
import CategoryDesigns from "./vue-components/CategoryDesigns";
import Favorites from "./vue-components/Favorites";
import FavoriteButton from "./vue-components/FavoriteButton";
import LanguageList from "./vue-components/LanguageList";
import LanguagePicker from "./vue-components/LanguagePicker";
import LoadMore from "./vue-components/LoadMore";
import RelatedDesigns from "./vue-components/RelatedDesigns";
import SearchFilter from "./vue-components/SearchFilter";
import SearchInput from "./vue-components/SearchInput";
import CollectionSearchInput from "./vue-components/CollectionSearchInput";
import DesignSearchInput from "./vue-components/DesignSearchInput";
import SearchResult from "./vue-components/SearchResult";
import SendDesign from "./vue-components/SendDesign";
import ScrollArrow from "./vue-components/ScrollArrow";
import ScrollToTop from "./vue-components/ScrollToTop";
import SortBy from "./vue-components/SortBy";
import SimpleSortBy from "./vue-components/SimpleSortBy";
import SaveCollectionVaultButtonItemDetails from "./vue-components/SaveCollectionVaultButtonItemDetails";
import RelatedCollections from "./vue-components/RelatedCollections";
import AddToCartCollectionDetails from "./vue-components/AddToCartCollectionDetails";
import SaveDesignVault from "./vue-components/SaveDesignVault";
import UnitSwitch from "./vue-components/UnitSwitch";
import DesignDimensions from "./vue-components/DesignDimensions";
import CraftingDesignDimensions from "./vue-components/CraftingDesignDimensions";
import SimpleListEntity from "./vue-components/SimpleListEntity";
import EmbroideryFavorites from "./vue-components/EmbroideryFavorites";
import QuiltFavorites from "./vue-components/QuiltFavorites";
import QuiltPurchases from "./vue-components/QuiltPurchases";
import CraftingFavorites from "./vue-components/CraftingFavorites";
import CraftingPurchases from "./vue-components/CraftingPurchases";
import Categories from "./vue-components/Categories";
import FeaturedCollections from "./vue-components/FeaturedCollections";
import CollectionListing from "./vue-components/CollectionListing";
import HorizontalList from "./vue-components/HorizontalList";
import SendToMachineButton from "./vue-components/SendToMachineButton";
import SendToMachineButtonItemDetails from "./vue-components/SendToMachineButtonItemDetails";
import PromotionDesigns from "./vue-components/PromotionDesigns";
import LatestDesigns from "./vue-components/LatestDesigns";
import EmbroideryPurchases from "./vue-components/EmbroideryPurchases";
import DesignImageGallery from "./vue-components/DesignImageGallery";
import AdditionalImages from "./vue-components/AdditionalImages";
import AdditionalImageThumbnail from "./vue-components/AdditionalImageThumbnail";
import CollectionsFavorites from "./vue-components/CollectionsFavorites";
import CollectionPurchases from "./vue-components/CollectionPurchases";
import SaveDesignVaultButton from "./vue-components/SaveDesignVaultButton";
import SaveDesignVaultButtonItemDetails from "./vue-components/SaveDesignVaultButtonItemDetails";
import PreferredFormat from "./vue-components/PreferredFormat";
import MiniCart from "./vue-components/MiniCart";
import AddToCart from "./vue-components/AddToCart";
import AddToCartItemDetails from "./vue-components/AddToCartItemDetails";
import Checkout from "./vue-components/checkout/Checkout";
import DesignUserInfo from "./vue-components/DesignUserInfo";
import Purchases from "./vue-components/Purchases";
import collection from "./vue-components/collection";
import AddToCartText from "./vue-components/AddToCartText";
import DownloadButton from "./vue-components/DownloadButton";
import DownloadButtonItemDetails from "./vue-components/DownloadButtonItemDetails";
import SearchResultBanner from "./vue-components/SearchResultBanner";
import CategoryBanner from "./vue-components/CategoryBanner";
import CollectionDesigns from "./vue-components/CollectionDesigns";
import FeaturedImage from "./vue-components/banners/FeaturedImage";
import CheckoutAccordion from "./vue-components/CheckoutAccordion.vue";

// New Vue 2 components

import LButton from "./vue-components/buttons/LButton";
import LTag from "./vue-components/LTag";
import LFilterButtons from "./vue-components/buttons/LFilterButtons.vue";
import ProductsSwiper from "./vue-components/slider/ProductsSwiper.vue";
import ActionCallBanner from "./vue-components/banners/ActionCallBanner.vue";
import MainPageBanner from "./vue-components/banners/MainPageBanner.vue";
import LSearchInput from "./vue-components/input/LSearchInput.vue";
import SubscriptionInfo from './vue-components/SubscriptionInfo.vue';
import ToolsSwiper from './vue-components/ToolsSwiper.vue';
import PurchasedTag from './vue-components/icons/common/PurchasedTag.vue';
import width from './vue-components/icons/common/width.vue';
import height from './vue-components/icons/common/height.vue';
import colors from './vue-components/icons/common/colors.vue';
import filetype from './vue-components/icons/common/filetype.vue';
import filename from './vue-components/icons/common/filename.vue';
import LAccordion from "./vue-components/LAccordion.vue";
import InstructionButton from "./vue-components/InstructionButton.vue";
import CollectionHelpButton from "./vue-components/CollectionHelpButton.vue";
import CollectionHelpDetails from "./vue-components/CollectionHelpDetails.vue";
import CraftingLevel from "./vue-components/icons/common/CraftingLevel.vue";
import TextWithTitle from "./vue-components/banners/TextWithTitle.vue";
import TitleWithLink from "./vue-components/banners/TitleWithLink.vue"

export default {
    craftinglevel:CraftingLevel,
    textwithtitle:TextWithTitle,
    titlewithlink: TitleWithLink,
    itemdetailedfilename:filename,
    itemdetailedfiletype:filetype,
    itemdetailedcolors:colors,
    itemdetailedheight:height,
    itemdetailedwidth:width,
    purchasedtag:PurchasedTag,
    featuredimage:FeaturedImage,
    BackButton,
    downloaddesign: DownloadDesign,
    CategoryDesigns,
    Favorites,
    favoritebutton: FavoriteButton,
    languagelist: LanguageList,
    languagepicker: LanguagePicker,
    LoadMore,
    relateddesigns: RelatedDesigns,
    searchfilter: SearchFilter,
    searchinput: SearchInput,
    CollectionSearchInput,
    DesignSearchInput,
    SearchResult,
    senddesign: SendDesign,
    ScrollArrow,
    ScrollToTop,
    SortBy,
    SimpleSortBy,
    savecollectionvaultbuttonitemdetails:SaveCollectionVaultButtonItemDetails,
    RelatedCollections,
    addtocartcollectiondetails:AddToCartCollectionDetails,
    savedesignvault: SaveDesignVault,
    unitswitch: UnitSwitch,
    designdimensions: DesignDimensions,
    CraftingDesignDimensions,
    SimpleListEntity,
    EmbroideryFavorites,
    QuiltFavorites,
    QuiltPurchases,
    CraftingFavorites,
    CraftingPurchases,
    Categories,
    FeaturedCollections,
    CollectionListing,
    HorizontalList,
    SendToMachineButton,
    sendtomachinebuttonitemdetails:SendToMachineButtonItemDetails,
    PromotionDesigns,
    LatestDesigns,
    EmbroideryPurchases,
    designimagegallery: DesignImageGallery,
    AdditionalImages,
    AdditionalImageThumbnail,
    CollectionsFavorites,
    CollectionPurchases,
    SaveDesignVaultButton,
    savedesignvaultbuttonitemdetails:SaveDesignVaultButtonItemDetails,
    PreferredFormat,
    minicart: MiniCart,
    AddToCart,
    addtocartitemdetails: AddToCartItemDetails,
    Checkout,
    DesignUserInfo,
    Purchases,
    collection,
    addtocarttext: AddToCartText,
    downloadbutton:DownloadButton,
    downloadbuttonitemdetails: DownloadButtonItemDetails,
    SearchResultBanner,
    CategoryBanner,
    collectiondesigns:CollectionDesigns,
    LButton,
    LTag,
    LFilterButtons,
    ProductsSwiper,
    ActionCallBanner,
    MainPageBanner,
    LSearchInput,
    subscriptioninfo: SubscriptionInfo,
    toolsswiper: ToolsSwiper,
    LAccordion,
    checkoutaccordion: CheckoutAccordion,
    instructionButton: InstructionButton,
    collectionhelpdetails: CollectionHelpDetails,
    collectionhelpbutton: CollectionHelpButton,
}
