var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "checkout-details payment" }, [
    _c(
      "div",
      {
        staticClass: "modal fade modal-library",
        attrs: {
          id: "currencyChangedModal",
          "data-backdrop": "static",
          "data-keyboard": "false",
          tabindex: "-1",
          "aria-labelledby": "currencyChangedModalTitle",
          "aria-hidden": "true",
        },
      },
      [
        _c("div", { staticClass: "modal-dialog" }, [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c(
                "h5",
                {
                  staticClass: "modal-title",
                  attrs: { id: "currencyChangedModalTitle" },
                },
                [
                  _vm._v(
                    "\n                        " +
                      _vm._s(
                        _vm.t("checkout.payment.currency-changed-headline")
                      ) +
                      "\n                    "
                  ),
                ]
              ),
              _vm._v(" "),
              _vm._m(0),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-body" }, [
              _c("p", [
                _vm._v(_vm._s(_vm.t("checkout.payment.currency-changed-text"))),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-footer" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { type: "button", "data-dismiss": "modal" },
                },
                [_vm._v(_vm._s(_vm.t("close")))]
              ),
            ]),
          ]),
        ]),
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "shopping-cart" }, [
        _vm.isLoading
          ? _c(
              "div",
              {
                staticClass: "spinner-wrapper",
                staticStyle: { "margin-right": "auto", "margin-left": "auto" },
              },
              [
                _c("div", {
                  staticClass: "spinner-grow",
                  attrs: { role: "status" },
                }),
                _vm._v(" "),
                _vm.processingPayment
                  ? _c("div", { staticClass: "processing-payment" }, [
                      _vm._v(
                        _vm._s(
                          _vm.t("checkout.payment.processing-payment-text")
                        )
                      ),
                    ])
                  : _vm._e(),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        !_vm.isLoading
          ? _c("div", { staticClass: "checkout-wrapper row" }, [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-6 order-2 order-md-1 mt-3 mt-md-0" },
                  [
                    _c("h3", [
                      _vm._v(_vm._s(_vm.t("checkout.payment.headline"))),
                    ]),
                    _vm._v(" "),
                    _c("div", {
                      domProps: {
                        innerHTML: _vm._s(_vm.t("checkout.payment.text")),
                      },
                    }),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-6 col order-details-wrapper" },
                  [
                    _c(
                      "div",
                      { staticClass: "price-wrapper" },
                      [
                        _c("Summary", {
                          attrs: { "data-vue": "", step: "payment" },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row mt-2 mt-md-5" }, [
                _c("div", { staticClass: "col-md-12" }, [
                  _c("iframe", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.iframe.loaded,
                        expression: "iframe.loaded",
                      },
                    ],
                    attrs: {
                      src: _vm.iframe.src,
                      id: "paymentFrame",
                      frameborder: "0",
                      height: "500px",
                      width: "100%",
                    },
                    on: { load: _vm.load },
                  }),
                ]),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "btn-wrapper-profile" }, [
          _c(
            "a",
            {
              staticClass: "blank-btn c-pointer",
              on: {
                click: function ($event) {
                  return _vm.changeStep("Billing")
                },
              },
            },
            [_vm._v(_vm._s(_vm.t("checkout.actions.back")))]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          type: "button",
          "data-dismiss": "modal",
          "aria-label": "Close",
        },
      },
      [_c("img", { attrs: { src: "/static/icons/close.png", alt: "" } })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }