<template>
    <div>
    <l-popper
        trigger="clickToOpen"
        :options="{
      placement: 'auto',
      modifiers: { offset: { offset: '0,10px' } }
    }">
      <div class="popper" >
        <SubscriptionTooltip v-show="isOpenTooltip"/>

            <SaveDesignVault  v-show="isOpenUploadTooltip" :sku="sku" :designCode="designCode"  :designtype="designtype" :designs="null" :nameOfDesign="nameOfDesign" :designname="designname" :isPurchased="isPurchased==true" :priceDisplay="priceDisplay" :allowedCountry="allowedCountry" iscollection="true" />

        </div>
      <l-button type="small"
                slot="reference"
                style="background-color:rgba(0, 0, 0, 0.3)"
                @click.native="onClick()"
                :class="{'to-cloud-faded': !hasSub }">
        <IconsBase :iconName='t("upload.tocloud.modal.header")'>
          <upload-icon/>
        </IconsBase>
      </l-button>
    </l-popper>
  </div>
</template>


<style lang="scss">
@import "../globals";

.save-to-vault {
    margin-left: 16px;
    top: 6px;
    background-color: $btn-bg-color;
    border-radius: 8px;
    padding: 6px;
    width: 42px;
    height: 42px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    img.vault-icon {
        width: 18px;
        height: 18px;
        filter: brightness(0);
        -webkit-filter: brightness(0);
    }
}

.design-info-wrapper {
    .cart-icon-wrapper {
        padding: 0 8px;
        cursor: pointer;

        .to-cloud {
            width: 42px;
            height: 42px;
            background: $btn-bg-color url(../icons/btn_download_light.svg) no-repeat center center;
            border-radius: 50%;
        }
    }
}
</style>

<script>
import Modal from "./Modal";
import SaveDesignVault from './SaveDesignVault';
import {
    userHasSubscription
} from "../utils/utils";
import SubscriptionTooltip from "./tooltip/SubscriptionTooltip.vue";
import Popper from 'vue-popperjs';
import LButton from "./buttons/LButton.vue";
import IconsBase from "./icons/IconsBase.vue";
import UploadIcon from "./icons/common/UploadIcon.vue";
import {
    EventBus
} from "../utils/eventBus";
import {
    subscriptionModal
} from "../components/subscription-modal";
import {
    translate
} from "../utils/utils";
export default {
    name: "SaveCollectionVaultButtonItemDetails",
    props: {
        sku: String,
        designs: String,
        isPurchased: Boolean,
        designname: String,
        designtype:String,
        priceDisplay: String,
        allowedCountry: Boolean,
        designCode: String,
        nameOfDesign: String,
    },
    components: {
        Modal,
        SaveDesignVault,
        LButton,
        IconsBase,
        UploadIcon,
        SubscriptionTooltip,
        'l-popper': Popper,
    },
    created: function () {

        this.currentImage = this.computedDesignImages;
    },
    computed: {
        shouldOfferSubscription() {
            return userHasSubscription() === false && !this.isPurchased;
        },
        computedDesignImages: function () {

            // console.log(this.designimage);

            return this.designimage;
        },
        computedDesigns: function () {

            if (typeof this.designs === 'string') {

                return JSON.parse(this.designs);
            }
            return this.designs;
        }
    },
    data() {
        return {
            showUploadModal: false,
            hasSub: !!userHasSubscription(),
            isOpenTooltip: false,
            isOpenUploadTooltip:false,
        }
    },
    methods: {
        t(key) {
            return translate(key)
        },
        onClick() {
            if (this.shouldOfferSubscription) {
                this.isOpenTooltip = true
            } else {
              this.isOpenUploadTooltip=true;            }
        },

        closeModal() {
            this.showUploadModal = false;
        },
    }
}
</script>
<style scoped lang="scss">
.subscription-wrap {
  width: 100%;
  text-align: left;
  & h5 {
    font-family: soleil-semibold, Helvetica, Arial, sans-serif;
    font-size: 16px;
  }
  & p {
    font-size: 14px;
  }
  &__actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 5px;
  }
}
</style>
