var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("g", { attrs: { id: "collection", transform: "translate(0 0)" } }, [
    _c(
      "g",
      {
        attrs: {
          id: "Group_190730",
          "data-name": "Group 190730",
          "clip-path": "url(#clip-path)",
        },
      },
      [
        _c("path", {
          attrs: {
            id: "Path_138936",
            "data-name": "Path 138936",
            d: "M11.333,14v7.524a1.658,1.658,0,0,0,2.57,1.393l1.559-1.028a1.658,1.658,0,0,1,1.825,0l1.476,1a1.658,1.658,0,0,0,2.57-1.377V14",
            fill: "none",
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
            "stroke-width": "2",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            id: "Path_138937",
            "data-name": "Path 138937",
            d: "M4.333,14V27.5a2.5,2.5,0,0,0,2.5,2.5h19a2.5,2.5,0,0,0,2.5-2.5V14",
            fill: "none",
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
            "stroke-width": "2",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            id: "Path_138938",
            "data-name": "Path 138938",
            d: "M8.211,6.413A1.378,1.378,0,0,1,8.255,4.5l2.093-2.092a1.412,1.412,0,0,1,1.986,0l4,4.005,4-4.005a1.412,1.412,0,0,1,1.986,0L24.411,4.5a1.378,1.378,0,0,1,.045,1.913",
            fill: "none",
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
            "stroke-width": "2",
          },
        }),
        _vm._v(" "),
        _c("rect", {
          attrs: {
            id: "Rectangle_85145",
            "data-name": "Rectangle 85145",
            width: "28",
            height: "7",
            rx: "2",
            transform: "translate(2.333 7)",
            fill: "none",
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
            "stroke-width": "2",
          },
        }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }