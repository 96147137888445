var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "l-filter" }, [
    _c("div", { ref: "swiper", staticClass: "swiper" }, [
      _c("div", { staticClass: "swiper-wrapper" }, [
        _c("div", { staticClass: "swiper-slide" }, [
          _c(
            "div",
            {
              class: [
                "l-filter__item no-icon",
                { "all-active": !_vm.activeType || _vm.activeType === "all" },
              ],
              on: {
                click: function ($event) {
                  return _vm.setFilter("")
                },
              },
            },
            [
              _vm._v(
                "\n                    " +
                  _vm._s(_vm.t("search-results.type.all")) +
                  " "
              ),
              _c("span", [_vm._v("(" + _vm._s(_vm.totals.all) + ")")]),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "swiper-slide" }, [
          _c(
            "div",
            {
              class: [
                "l-filter__item",
                { "emb-active": _vm.activeType === "embroidery" },
              ],
              on: {
                click: function ($event) {
                  return _vm.setFilter("embroidery")
                },
              },
            },
            [
              _c(
                "IconsBase",
                {
                  attrs: {
                    iconColor:
                      _vm.activeType === "embroidery" ? "#fff" : "#000",
                  },
                },
                [_c("embroidery-icon")],
                1
              ),
              _vm._v(
                "\n                    " +
                  _vm._s(_vm.t("search-results.type.embroideries")) +
                  " "
              ),
              _c("span", [_vm._v("(" + _vm._s(_vm.totals.embroidery) + ")")]),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "swiper-slide" }, [
          _c(
            "div",
            {
              class: [
                "l-filter__item",
                { "craft-active": _vm.activeType === "crafting" },
              ],
              on: {
                click: function ($event) {
                  return _vm.setFilter("crafting")
                },
              },
            },
            [
              _c(
                "IconsBase",
                {
                  attrs: {
                    iconColor: _vm.activeType === "crafting" ? "#fff" : "#000",
                  },
                },
                [_c("crafting-icon")],
                1
              ),
              _vm._v(
                "\n                    " +
                  _vm._s(_vm.t("search-results.type.crafting")) +
                  " "
              ),
              _c("span", [_vm._v("(" + _vm._s(_vm.totals.crafting) + ")")]),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "swiper-slide" }, [
          _c(
            "div",
            {
              class: [
                "l-filter__item",
                { "fonts-active": _vm.activeType === "font" },
              ],
              on: {
                click: function ($event) {
                  return _vm.setFilter("font")
                },
              },
            },
            [
              _c(
                "IconsBase",
                {
                  attrs: {
                    iconColor: _vm.activeType === "font" ? "#fff" : "#000",
                  },
                },
                [_c("fonts-icon")],
                1
              ),
              _vm._v(
                "\n                    " +
                  _vm._s(_vm.t("search-results.type.fonts")) +
                  " "
              ),
              _c("span", [_vm._v("(" + _vm._s(_vm.totals.font) + ")")]),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "swiper-slide" }, [
          _c(
            "div",
            {
              class: [
                "l-filter__item",
                { "collections-active": _vm.activeType === "collection" },
              ],
              on: {
                click: function ($event) {
                  return _vm.setFilter("collection")
                },
              },
            },
            [
              _c(
                "IconsBase",
                {
                  attrs: {
                    iconColor:
                      _vm.activeType === "collection" ? "#fff" : "#000",
                  },
                },
                [_c("collection-icon")],
                1
              ),
              _vm._v(
                "\n                    " +
                  _vm._s(_vm.t("search-results.type.collections")) +
                  " "
              ),
              _c("span", [_vm._v("(" + _vm._s(_vm.totals.collection) + ")")]),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }