<script>
export default {
    props: {
        title: String,
        isOpen: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            accordionIsOpen: this.isOpen
        };
    },
}
</script>

<template>
    <div class="filter-accordion-item">
        <div class="filter-accordion-item__headingWrapper">
            <p class="filter-accordion-item__label">{{ title }}</p>
            <button @click="accordionIsOpen = !accordionIsOpen"
                    :class="['accordion-button', !accordionIsOpen ? 'collapsed' : null]"
                    type="button"
            />
        </div>
        <div class="filter-accordion-item__content" v-show="accordionIsOpen">
            <slot />
        </div>
    </div>
</template>

<style scoped lang="scss">

</style>
