var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "cart-wrapper" }, [
    _c("a", { attrs: { href: "/checkout" } }, [
      _c("img", { attrs: { src: "/static/icons/cart-black.svg", alt: "" } }),
      _vm._v(" "),
      this.cartItems.totalQuantity > 0
        ? _c("span", { staticClass: "count" }, [
            _vm._v(_vm._s(_vm.cartItems.totalQuantity)),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }