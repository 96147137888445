<template>
    <div>
        <l-popper trigger="clickToOpen" :options="{
            placement: 'auto',
            modifiers: { offset: { offset: '0,10px' } }
        }">
            <div class="popper">
                <SubscriptionTooltip v-if="isOpenTooltip"  />
                <SendToMachineTooltip v-else :sku="sku" :isPurchased="isPurchased" :designname="designname"
                    :designtype="designtype" :sendButtonEventId="sendButtonEventId" />
            </div>
            <l-button type="small" slot="reference" @click.native="onClick()">
                <IconsBase :iconName='t("send-to.heading-send-to")'>
                    <send-icon />
                </IconsBase>
            </l-button>
        </l-popper>
    </div>
</template>

<script>
import { EventBus } from "../utils/eventBus";
import { userHasSubscription ,translate } from "../utils/utils";
import IconsBase from "./icons/IconsBase.vue";
import SendIcon from "./icons/common/SendIcon.vue";
import LButton from "./buttons/LButton.vue";
import SubscriptionTooltip from "./tooltip/SubscriptionTooltip.vue";
import SendToMachineTooltip from "./tooltip/SendToMachineTooltip.vue";
import Popper from 'vue-popperjs';
export default {
    name: "SendToMachineButton",
    components: { SubscriptionTooltip, IconsBase, SendIcon, LButton, 'l-popper': Popper, SendToMachineTooltip, EventBus },
    data() {
        return {
            showSendToMachineTooltip: false,
            isOpenTooltip: false,
            hasSub: userHasSubscription(),
            sendButtonEventId: 'sendButtonPressedEvent_' + this._uid
        }
    },
    computed: {
        shouldOfferSubscription() {
            return userHasSubscription() === false && !this.isPurchased;
        }
    },
    methods: {
        t(key) {
            return translate(key)
        },
        onClick() {
            EventBus.$emit(this.sendButtonEventId);
            if (this.shouldOfferSubscription) {
                this.isOpenTooltip = true
            }
        }
    },
    props: {
        sku: String,
        isPurchased: Boolean,
        designname: String,
        designtype: String,
    },
}
</script>
