var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "l-accordion",
        { attrs: { title: _vm.t("search.searchin"), isOpen: "" } },
        [
          _c("ul", { staticClass: "ms-checkbox-list" }, [
            _c("li", [
              _c("label", { staticClass: "ms-checkbox-pink m-0" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchParams.myFavorites,
                      expression: "searchParams.myFavorites",
                    },
                  ],
                  attrs: { type: "checkbox" },
                  domProps: {
                    value: String(_vm.params.myFavorites),
                    checked: Array.isArray(_vm.searchParams.myFavorites)
                      ? _vm._i(
                          _vm.searchParams.myFavorites,
                          String(_vm.params.myFavorites)
                        ) > -1
                      : _vm.searchParams.myFavorites,
                  },
                  on: {
                    change: [
                      function ($event) {
                        var $$a = _vm.searchParams.myFavorites,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = String(_vm.params.myFavorites),
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.searchParams,
                                "myFavorites",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.searchParams,
                                "myFavorites",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.searchParams, "myFavorites", $$c)
                        }
                      },
                      function ($event) {
                        return _vm.filterChanged()
                      },
                    ],
                  },
                }),
                _vm._v(" "),
                _c("span", { staticClass: "checkmark" }),
                _vm._v(" "),
                _c("span", { staticClass: "text" }, [
                  _vm._v(" " + _vm._s(_vm.t("header.library.myFavorites"))),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("ul", { staticClass: "ms-checkbox-list" }, [
            _c("li", [
              _c("label", { staticClass: "ms-checkbox-pink m-0" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchParams.myPurchases,
                      expression: "searchParams.myPurchases",
                    },
                  ],
                  attrs: { type: "checkbox" },
                  domProps: {
                    value: String(_vm.params.myPurchases),
                    checked: Array.isArray(_vm.searchParams.myPurchases)
                      ? _vm._i(
                          _vm.searchParams.myPurchases,
                          String(_vm.params.myPurchases)
                        ) > -1
                      : _vm.searchParams.myPurchases,
                  },
                  on: {
                    change: [
                      function ($event) {
                        var $$a = _vm.searchParams.myPurchases,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = String(_vm.params.myPurchases),
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.searchParams,
                                "myPurchases",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.searchParams,
                                "myPurchases",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.searchParams, "myPurchases", $$c)
                        }
                      },
                      function ($event) {
                        return _vm.filterChanged()
                      },
                    ],
                  },
                }),
                _vm._v(" "),
                _c("span", { staticClass: "checkmark" }),
                _vm._v(" "),
                _c("span", { staticClass: "text" }, [
                  _vm._v(" " + _vm._s(_vm.t("header.library.myPurchases"))),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm.searchParams?.type === "embroidery" ||
      _vm.searchParams?.type === "crafting"
        ? [
            _c(
              "l-accordion",
              {
                attrs: {
                  title: _vm.t("search.filter.categories.header"),
                  isOpen: "",
                },
              },
              [
                _vm._t("default", function () {
                  return [
                    _c("div", { staticClass: "select-wrap" }, [
                      _c(
                        "div",
                        {
                          staticClass: "select-wrap-all",
                          class: {
                            "opacity-for-select":
                              _vm.searchParams.categories.length ===
                              _vm.categoryList.length,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.selectAllCategories()
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.t("search.filter.selectAll")) +
                              "\n                    "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "select-wrap-clear",
                          class: {
                            "opacity-for-select":
                              !_vm.searchParams.categories.length,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.selectAllCategories(false)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.t("search.filter.clearAll")) +
                              "\n                    "
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.categoryList, function (category) {
                      return _vm.searchParams?.type === "embroidery"
                        ? _c(
                            "div",
                            { staticClass: "categorie-filter-type-wrapper" },
                            [
                              category
                                ? _c(
                                    "ul",
                                    { staticClass: "ms-checkbox-list" },
                                    [
                                      _c(
                                        "div",
                                        { staticStyle: { display: "flex" } },
                                        [
                                          _c("li", [
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "ms-checkbox-pink m-0",
                                              },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.searchParams
                                                          .categories,
                                                      expression:
                                                        "searchParams.categories",
                                                    },
                                                  ],
                                                  attrs: { type: "checkbox" },
                                                  domProps: {
                                                    value: String(category?.id),
                                                    checked: Array.isArray(
                                                      _vm.searchParams
                                                        .categories
                                                    )
                                                      ? _vm._i(
                                                          _vm.searchParams
                                                            .categories,
                                                          String(category?.id)
                                                        ) > -1
                                                      : _vm.searchParams
                                                          .categories,
                                                  },
                                                  on: {
                                                    change: [
                                                      function ($event) {
                                                        var $$a =
                                                            _vm.searchParams
                                                              .categories,
                                                          $$el = $event.target,
                                                          $$c = $$el.checked
                                                            ? true
                                                            : false
                                                        if (
                                                          Array.isArray($$a)
                                                        ) {
                                                          var $$v = String(
                                                              category?.id
                                                            ),
                                                            $$i = _vm._i(
                                                              $$a,
                                                              $$v
                                                            )
                                                          if ($$el.checked) {
                                                            $$i < 0 &&
                                                              _vm.$set(
                                                                _vm.searchParams,
                                                                "categories",
                                                                $$a.concat([
                                                                  $$v,
                                                                ])
                                                              )
                                                          } else {
                                                            $$i > -1 &&
                                                              _vm.$set(
                                                                _vm.searchParams,
                                                                "categories",
                                                                $$a
                                                                  .slice(0, $$i)
                                                                  .concat(
                                                                    $$a.slice(
                                                                      $$i + 1
                                                                    )
                                                                  )
                                                              )
                                                          }
                                                        } else {
                                                          _vm.$set(
                                                            _vm.searchParams,
                                                            "categories",
                                                            $$c
                                                          )
                                                        }
                                                      },
                                                      function ($event) {
                                                        return _vm.filterChanged()
                                                      },
                                                    ],
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("span", {
                                                  staticClass: "checkmark",
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  { staticClass: "text" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(category?.name)
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          category.categories.length &&
                                          !_vm.shouldExpandCategory(category.id)
                                            ? _c(
                                                "a",
                                                {
                                                  staticStyle: {
                                                    "padding-left": "50px",
                                                  },
                                                  attrs: { type: "button" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.toggleCategory(
                                                        category.id
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("+")]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          category.categories.length &&
                                          _vm.shouldExpandCategory(category.id)
                                            ? _c(
                                                "a",
                                                {
                                                  staticStyle: {
                                                    "padding-left": "50px",
                                                  },
                                                  attrs: { type: "button" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.toggleCategory(
                                                        category.id
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "-\n                        "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _vm.shouldExpandCategory(category.id)
                                        ? _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                position: "relative",
                                                display: "grid",
                                                left: "30px",
                                              },
                                            },
                                            _vm._l(
                                              category.categories,
                                              function (subCategory) {
                                                return _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      "ms-checkbox-pink m-0",
                                                  },
                                                  [
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm.searchParams
                                                              .categories,
                                                          expression:
                                                            "searchParams.categories",
                                                        },
                                                      ],
                                                      attrs: {
                                                        type: "checkbox",
                                                      },
                                                      domProps: {
                                                        value: String(
                                                          subCategory?.id
                                                        ),
                                                        checked: Array.isArray(
                                                          _vm.searchParams
                                                            .categories
                                                        )
                                                          ? _vm._i(
                                                              _vm.searchParams
                                                                .categories,
                                                              String(
                                                                subCategory?.id
                                                              )
                                                            ) > -1
                                                          : _vm.searchParams
                                                              .categories,
                                                      },
                                                      on: {
                                                        change: [
                                                          function ($event) {
                                                            var $$a =
                                                                _vm.searchParams
                                                                  .categories,
                                                              $$el =
                                                                $event.target,
                                                              $$c = $$el.checked
                                                                ? true
                                                                : false
                                                            if (
                                                              Array.isArray($$a)
                                                            ) {
                                                              var $$v = String(
                                                                  subCategory?.id
                                                                ),
                                                                $$i = _vm._i(
                                                                  $$a,
                                                                  $$v
                                                                )
                                                              if (
                                                                $$el.checked
                                                              ) {
                                                                $$i < 0 &&
                                                                  _vm.$set(
                                                                    _vm.searchParams,
                                                                    "categories",
                                                                    $$a.concat([
                                                                      $$v,
                                                                    ])
                                                                  )
                                                              } else {
                                                                $$i > -1 &&
                                                                  _vm.$set(
                                                                    _vm.searchParams,
                                                                    "categories",
                                                                    $$a
                                                                      .slice(
                                                                        0,
                                                                        $$i
                                                                      )
                                                                      .concat(
                                                                        $$a.slice(
                                                                          $$i +
                                                                            1
                                                                        )
                                                                      )
                                                                  )
                                                              }
                                                            } else {
                                                              _vm.$set(
                                                                _vm.searchParams,
                                                                "categories",
                                                                $$c
                                                              )
                                                            }
                                                          },
                                                          function ($event) {
                                                            return _vm.filterChanged()
                                                          },
                                                        ],
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("span", {
                                                      staticClass: "checkmark",
                                                    }),
                                                    _vm._v(" "),
                                                    _c(
                                                      "span",
                                                      { staticClass: "text" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            subCategory?.name
                                                          ) +
                                                            "\n                                "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          )
                                        : _vm._e(),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          )
                        : _vm._e()
                    }),
                    _vm._v(" "),
                    _vm.searchParams?.type === "crafting"
                      ? _c(
                          "div",
                          _vm._l(
                            _vm.categoriesByCraftingType,
                            function (value, key) {
                              return _c(
                                "div",
                                { staticClass: "category-filter-type-wrapper" },
                                [
                                  value.length > 0
                                    ? _c("h5", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.t(
                                              "search-filters." +
                                                key.replace("_", "-") +
                                                "s"
                                            )
                                          )
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm._l(value, function (category) {
                                    return _c(
                                      "ul",
                                      { staticClass: "ms-checkbox-list" },
                                      [
                                        _c(
                                          "div",
                                          { staticStyle: { display: "flex" } },
                                          [
                                            _c("li", [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "ms-checkbox-pink m-0",
                                                },
                                                [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.searchParams
                                                            .categories,
                                                        expression:
                                                          "searchParams.categories",
                                                      },
                                                    ],
                                                    attrs: { type: "checkbox" },
                                                    domProps: {
                                                      value: String(
                                                        category?.id
                                                      ),
                                                      checked: Array.isArray(
                                                        _vm.searchParams
                                                          .categories
                                                      )
                                                        ? _vm._i(
                                                            _vm.searchParams
                                                              .categories,
                                                            String(category?.id)
                                                          ) > -1
                                                        : _vm.searchParams
                                                            .categories,
                                                    },
                                                    on: {
                                                      change: [
                                                        function ($event) {
                                                          var $$a =
                                                              _vm.searchParams
                                                                .categories,
                                                            $$el =
                                                              $event.target,
                                                            $$c = $$el.checked
                                                              ? true
                                                              : false
                                                          if (
                                                            Array.isArray($$a)
                                                          ) {
                                                            var $$v = String(
                                                                category?.id
                                                              ),
                                                              $$i = _vm._i(
                                                                $$a,
                                                                $$v
                                                              )
                                                            if ($$el.checked) {
                                                              $$i < 0 &&
                                                                _vm.$set(
                                                                  _vm.searchParams,
                                                                  "categories",
                                                                  $$a.concat([
                                                                    $$v,
                                                                  ])
                                                                )
                                                            } else {
                                                              $$i > -1 &&
                                                                _vm.$set(
                                                                  _vm.searchParams,
                                                                  "categories",
                                                                  $$a
                                                                    .slice(
                                                                      0,
                                                                      $$i
                                                                    )
                                                                    .concat(
                                                                      $$a.slice(
                                                                        $$i + 1
                                                                      )
                                                                    )
                                                                )
                                                            }
                                                          } else {
                                                            _vm.$set(
                                                              _vm.searchParams,
                                                              "categories",
                                                              $$c
                                                            )
                                                          }
                                                        },
                                                        function ($event) {
                                                          return _vm.filterChanged()
                                                        },
                                                      ],
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c("span", {
                                                    staticClass: "checkmark",
                                                  }),
                                                  _vm._v(" "),
                                                  _c(
                                                    "span",
                                                    { staticClass: "text" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(category?.name)
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            category.categories.length &&
                                            !_vm.shouldExpandCategory(
                                              category.id
                                            )
                                              ? _c(
                                                  "a",
                                                  {
                                                    staticStyle: {
                                                      "padding-left": "50px",
                                                    },
                                                    attrs: { type: "button" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.toggleCategory(
                                                          category.id
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("+")]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            category.categories.length &&
                                            _vm.shouldExpandCategory(
                                              category.id
                                            )
                                              ? _c(
                                                  "a",
                                                  {
                                                    staticStyle: {
                                                      "padding-left": "50px",
                                                    },
                                                    attrs: { type: "button" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.toggleCategory(
                                                          category.id
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "-\n                       "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _vm.shouldExpandCategory(category.id)
                                          ? _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  position: "relative",
                                                  display: "grid",
                                                  left: "30px",
                                                },
                                              },
                                              _vm._l(
                                                category.categories,
                                                function (subCategory) {
                                                  return _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "ms-checkbox-pink m-0",
                                                    },
                                                    [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.searchParams
                                                                .categories,
                                                            expression:
                                                              "searchParams.categories",
                                                          },
                                                        ],
                                                        attrs: {
                                                          type: "checkbox",
                                                        },
                                                        domProps: {
                                                          value: String(
                                                            subCategory?.id
                                                          ),
                                                          checked:
                                                            Array.isArray(
                                                              _vm.searchParams
                                                                .categories
                                                            )
                                                              ? _vm._i(
                                                                  _vm
                                                                    .searchParams
                                                                    .categories,
                                                                  String(
                                                                    subCategory?.id
                                                                  )
                                                                ) > -1
                                                              : _vm.searchParams
                                                                  .categories,
                                                        },
                                                        on: {
                                                          change: [
                                                            function ($event) {
                                                              var $$a =
                                                                  _vm
                                                                    .searchParams
                                                                    .categories,
                                                                $$el =
                                                                  $event.target,
                                                                $$c =
                                                                  $$el.checked
                                                                    ? true
                                                                    : false
                                                              if (
                                                                Array.isArray(
                                                                  $$a
                                                                )
                                                              ) {
                                                                var $$v =
                                                                    String(
                                                                      subCategory?.id
                                                                    ),
                                                                  $$i = _vm._i(
                                                                    $$a,
                                                                    $$v
                                                                  )
                                                                if (
                                                                  $$el.checked
                                                                ) {
                                                                  $$i < 0 &&
                                                                    _vm.$set(
                                                                      _vm.searchParams,
                                                                      "categories",
                                                                      $$a.concat(
                                                                        [$$v]
                                                                      )
                                                                    )
                                                                } else {
                                                                  $$i > -1 &&
                                                                    _vm.$set(
                                                                      _vm.searchParams,
                                                                      "categories",
                                                                      $$a
                                                                        .slice(
                                                                          0,
                                                                          $$i
                                                                        )
                                                                        .concat(
                                                                          $$a.slice(
                                                                            $$i +
                                                                              1
                                                                          )
                                                                        )
                                                                    )
                                                                }
                                                              } else {
                                                                _vm.$set(
                                                                  _vm.searchParams,
                                                                  "categories",
                                                                  $$c
                                                                )
                                                              }
                                                            },
                                                            function ($event) {
                                                              return _vm.filterChanged()
                                                            },
                                                          ],
                                                        },
                                                      }),
                                                      _vm._v(" "),
                                                      _c("span", {
                                                        staticClass:
                                                          "checkmark",
                                                      }),
                                                      _vm._v(" "),
                                                      _c(
                                                        "span",
                                                        { staticClass: "text" },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              subCategory?.name
                                                            ) +
                                                              "\n                               "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            )
                                          : _vm._e(),
                                      ]
                                    )
                                  }),
                                ],
                                2
                              )
                            }
                          ),
                          0
                        )
                      : _vm._e(),
                  ]
                }),
              ],
              2
            ),
            _vm._v(" "),
            _vm.searchParams?.type === "crafting"
              ? _c(
                  "l-accordion",
                  {
                    attrs: {
                      title: _vm.t("search.filter.operationType.header"),
                      isOpen: "",
                    },
                  },
                  [
                    _vm._t("default", function () {
                      return _vm._l(
                        _vm.operationTypesList,
                        function (operationType) {
                          return _c(
                            "div",
                            { staticClass: "operation-filter-type-wrapper" },
                            [
                              _c("ul", [
                                _c("li", [
                                  _c(
                                    "label",
                                    { staticClass: "ms-checkbox-pink m-0" },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.searchParams.operationType,
                                            expression:
                                              "searchParams.operationType",
                                          },
                                        ],
                                        attrs: { type: "checkbox" },
                                        domProps: {
                                          value: String(operationType?.id),
                                          checked: Array.isArray(
                                            _vm.searchParams.operationType
                                          )
                                            ? _vm._i(
                                                _vm.searchParams.operationType,
                                                String(operationType?.id)
                                              ) > -1
                                            : _vm.searchParams.operationType,
                                        },
                                        on: {
                                          change: [
                                            function ($event) {
                                              var $$a =
                                                  _vm.searchParams
                                                    .operationType,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = String(
                                                    operationType?.id
                                                  ),
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    _vm.$set(
                                                      _vm.searchParams,
                                                      "operationType",
                                                      $$a.concat([$$v])
                                                    )
                                                } else {
                                                  $$i > -1 &&
                                                    _vm.$set(
                                                      _vm.searchParams,
                                                      "operationType",
                                                      $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        )
                                                    )
                                                }
                                              } else {
                                                _vm.$set(
                                                  _vm.searchParams,
                                                  "operationType",
                                                  $$c
                                                )
                                              }
                                            },
                                            function ($event) {
                                              return _vm.filterChanged()
                                            },
                                          ],
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("span", { staticClass: "checkmark" }),
                                      _vm._v(" "),
                                      _c("span", { staticClass: "text" }, [
                                        _vm._v(_vm._s(operationType?.name)),
                                      ]),
                                    ]
                                  ),
                                ]),
                              ]),
                            ]
                          )
                        }
                      )
                    }),
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.searchParams?.type === "embroidery"
              ? _c(
                  "l-accordion",
                  {
                    attrs: {
                      title: _vm.t("search.filter.techniques.header"),
                      isOpen: "",
                    },
                  },
                  [
                    _vm._t("default", function () {
                      return [
                        _c("div", { staticClass: "select-wrap" }, [
                          _c(
                            "div",
                            {
                              staticClass: "select-wrap-all",
                              class: {
                                "opacity-for-select":
                                  _vm.searchParams.techniques.length ===
                                  _vm.techniqueList.length,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.selectAllTechniques()
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.t("search.filter.selectAll")) +
                                  "\n                    "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "select-wrap-clear",
                              class: {
                                "opacity-for-select":
                                  !_vm.searchParams.techniques.length,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.selectAllTechniques(false)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.t("search.filter.clearAll")) +
                                  "\n                    "
                              ),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "scroll-content" }, [
                          _vm.techniqueList.length > 0
                            ? _c(
                                "div",
                                { staticClass: "inner-scroll-wrapper" },
                                [
                                  _c(
                                    "ul",
                                    { staticClass: "ms-checkbox-list" },
                                    _vm._l(
                                      _vm.techniqueList,
                                      function (technique) {
                                        return _c("li", [
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "ms-checkbox-pink m-0",
                                              staticStyle: {
                                                padding: "3px 3px 3px 20px",
                                              },
                                            },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.searchParams
                                                        .techniques,
                                                    expression:
                                                      "searchParams.techniques",
                                                  },
                                                ],
                                                attrs: { type: "checkbox" },
                                                domProps: {
                                                  value: String(technique?.id),
                                                  checked: Array.isArray(
                                                    _vm.searchParams.techniques
                                                  )
                                                    ? _vm._i(
                                                        _vm.searchParams
                                                          .techniques,
                                                        String(technique?.id)
                                                      ) > -1
                                                    : _vm.searchParams
                                                        .techniques,
                                                },
                                                on: {
                                                  change: [
                                                    function ($event) {
                                                      var $$a =
                                                          _vm.searchParams
                                                            .techniques,
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = String(
                                                            technique?.id
                                                          ),
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            _vm.$set(
                                                              _vm.searchParams,
                                                              "techniques",
                                                              $$a.concat([$$v])
                                                            )
                                                        } else {
                                                          $$i > -1 &&
                                                            _vm.$set(
                                                              _vm.searchParams,
                                                              "techniques",
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                )
                                                            )
                                                        }
                                                      } else {
                                                        _vm.$set(
                                                          _vm.searchParams,
                                                          "techniques",
                                                          $$c
                                                        )
                                                      }
                                                    },
                                                    function ($event) {
                                                      return _vm.filterChanged()
                                                    },
                                                  ],
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("span", {
                                                staticClass: "checkmark",
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                { staticClass: "text" },
                                                [
                                                  _vm._v(
                                                    _vm._s(technique?.name)
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ])
                                      }
                                    ),
                                    0
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]),
                      ]
                    }),
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.searchParams?.type === "embroidery"
              ? _c(
                  "l-accordion",
                  {
                    attrs: {
                      title: _vm.t("search.filter.designSize.header"),
                      isOpen: "",
                    },
                  },
                  [
                    _vm._t("default", function () {
                      return [
                        _c("div", { staticClass: "header-container" }, [
                          _c("h3", [
                            _vm._v(
                              _vm._s(_vm.t("search.filter.designSize.header")) +
                                " (" +
                                _vm._s(
                                  _vm.t("display-unit." + _vm.displayUnit)
                                ) +
                                ")"
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "section-content design-size" },
                          [
                            _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "col-6 position-relative title",
                                },
                                [
                                  _c("i", {
                                    staticClass: "fas fa-arrows-alt-v",
                                  }),
                                  _c("span", { staticClass: "heading" }, [
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.t(
                                            "search.filter.designSize.height"
                                          )
                                        )
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "p-2 position-relative m-1 rounded bg-grey measurement",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "form-group py-1" },
                                    [
                                      _c("label", [
                                        _c("span", {
                                          staticClass:
                                            "icon icon-arrow-left-right",
                                        }),
                                        _vm._v(
                                          _vm._s(
                                            _vm.t(
                                              "search.filter.designSize.min"
                                            )
                                          )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.searchParams.heightMin,
                                            expression:
                                              "searchParams.heightMin",
                                          },
                                        ],
                                        staticClass: "form-control input-sm",
                                        attrs: {
                                          type: "number",
                                          min: "1",
                                          value: "",
                                          max: "99999999",
                                          "data-limit": "99999999",
                                        },
                                        domProps: {
                                          value: _vm.searchParams.heightMin,
                                        },
                                        on: {
                                          change: _vm.filterChanged,
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.searchParams,
                                              "heightMin",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "form-group py-1" },
                                    [
                                      _c("label", [
                                        _c("span", {
                                          staticClass:
                                            "icon icon-arrow-left-right",
                                        }),
                                        _vm._v(
                                          _vm._s(
                                            _vm.t(
                                              "search.filter.designSize.max"
                                            )
                                          )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.searchParams.heightMax,
                                            expression:
                                              "searchParams.heightMax",
                                          },
                                        ],
                                        staticClass: "form-control input-sm",
                                        attrs: {
                                          type: "number",
                                          min: "1",
                                          value: "",
                                          max: "99999999",
                                          "data-limit": "99999999",
                                        },
                                        domProps: {
                                          value: _vm.searchParams.heightMax,
                                        },
                                        on: {
                                          change: _vm.filterChanged,
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.searchParams,
                                              "heightMax",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "col-6 position-relative title",
                                },
                                [
                                  _c("i", {
                                    staticClass: "fas fa-arrows-alt-h",
                                  }),
                                  _c("span", { staticClass: "heading" }, [
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.t(
                                            "search.filter.designSize.width"
                                          )
                                        )
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "p-2 position-relative m-1 rounded bg-grey measurement",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "form-group py-1" },
                                    [
                                      _c("label", [
                                        _c("span", {
                                          staticClass:
                                            "icon icon-arrow-left-right",
                                        }),
                                        _vm._v(
                                          _vm._s(
                                            _vm.t(
                                              "search.filter.designSize.min"
                                            )
                                          )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.searchParams.widthMin,
                                            expression: "searchParams.widthMin",
                                          },
                                        ],
                                        staticClass: "form-control input-sm",
                                        attrs: {
                                          type: "number",
                                          min: "1",
                                          value: "",
                                          max: "99999999",
                                          "data-limit": "99999999",
                                        },
                                        domProps: {
                                          value: _vm.searchParams.widthMin,
                                        },
                                        on: {
                                          change: _vm.filterChanged,
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.searchParams,
                                              "widthMin",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "form-group py-1" },
                                    [
                                      _c("label", [
                                        _c("span", {
                                          staticClass:
                                            "icon icon-arrow-left-right",
                                        }),
                                        _vm._v(
                                          _vm._s(
                                            _vm.t(
                                              "search.filter.designSize.max"
                                            )
                                          )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.searchParams.widthMax,
                                            expression: "searchParams.widthMax",
                                          },
                                        ],
                                        staticClass: "form-control input-sm",
                                        attrs: {
                                          type: "number",
                                          min: "1",
                                          value: "",
                                          max: "99999999",
                                          "data-limit": "99999999",
                                        },
                                        domProps: {
                                          value: _vm.searchParams.widthMax,
                                        },
                                        on: {
                                          change: _vm.filterChanged,
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.searchParams,
                                              "widthMax",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        ),
                      ]
                    }),
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.searchParams?.type === "embroidery"
              ? _c(
                  "l-accordion",
                  {
                    attrs: {
                      title: _vm.t("search.filter.colorBlockCount.header"),
                      isOpen: "",
                    },
                  },
                  [
                    _vm._t("default", function () {
                      return [
                        _c("div", { staticClass: "counter-wrapper" }, [
                          _c("label", [
                            _c("span", { staticClass: "icon icon-stitch-1" }),
                            _vm._v(
                              _vm._s(
                                _vm.t("search.filter.colorBlockCount.subHeader")
                              )
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "max-count-wrapper" }, [
                            _c("i", {
                              staticClass: "fas fa-minus-circle pointer",
                              staticStyle: { "font-size": "23px" },
                              on: { click: _vm.decrementColorCount },
                            }),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.searchParams.colorBlockCountMax,
                                  expression: "searchParams.colorBlockCountMax",
                                },
                              ],
                              staticClass: "form-control input-sm",
                              attrs: {
                                type: "number",
                                min: "1",
                                value: "",
                                max: "99",
                                "data-limit": "99",
                              },
                              domProps: {
                                value: _vm.searchParams.colorBlockCountMax,
                              },
                              on: {
                                change: _vm.filterChanged,
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.searchParams,
                                    "colorBlockCountMax",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("i", {
                              staticClass: "fas fa-plus-circle pointer",
                              staticStyle: { "font-size": "23px" },
                              on: { click: _vm.incrementColorCount },
                            }),
                          ]),
                        ]),
                      ]
                    }),
                  ],
                  2
                )
              : _vm._e(),
          ]
        : _vm.searchParams?.type === "font"
        ? [
            _c(
              "l-accordion",
              {
                attrs: {
                  title: _vm.t("search.filter.categories.header"),
                  isOpen: "",
                },
              },
              [
                _vm._t("default", function () {
                  return [
                    _c("div", { staticClass: "select-wrap" }, [
                      _c(
                        "div",
                        {
                          staticClass: "select-wrap-all",
                          class: {
                            "opacity-for-select":
                              _vm.searchParams.categories.length ===
                              _vm.categoryList.length,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.selectAllCategories()
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.t("search.filter.selectAll")) +
                              "\n                    "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "select-wrap-clear",
                          class: {
                            "opacity-for-select":
                              !_vm.searchParams.categories.length,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.selectAllCategories(false)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.t("search.filter.clearAll")) +
                              "\n                    "
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.categoryList, function (category) {
                      return _c(
                        "div",
                        { staticClass: "categorie-filter-type-wrapper" },
                        [
                          category
                            ? _c("ul", { staticClass: "ms-checkbox-list" }, [
                                _c(
                                  "div",
                                  { staticStyle: { display: "flex" } },
                                  [
                                    _c("li", [
                                      _c(
                                        "label",
                                        { staticClass: "ms-checkbox-pink m-0" },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.searchParams.categories,
                                                expression:
                                                  "searchParams.categories",
                                              },
                                            ],
                                            attrs: { type: "checkbox" },
                                            domProps: {
                                              value: String(category?.id),
                                              checked: Array.isArray(
                                                _vm.searchParams.categories
                                              )
                                                ? _vm._i(
                                                    _vm.searchParams.categories,
                                                    String(category?.id)
                                                  ) > -1
                                                : _vm.searchParams.categories,
                                            },
                                            on: {
                                              change: [
                                                function ($event) {
                                                  var $$a =
                                                      _vm.searchParams
                                                        .categories,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = String(
                                                        category?.id
                                                      ),
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        _vm.$set(
                                                          _vm.searchParams,
                                                          "categories",
                                                          $$a.concat([$$v])
                                                        )
                                                    } else {
                                                      $$i > -1 &&
                                                        _vm.$set(
                                                          _vm.searchParams,
                                                          "categories",
                                                          $$a
                                                            .slice(0, $$i)
                                                            .concat(
                                                              $$a.slice($$i + 1)
                                                            )
                                                        )
                                                    }
                                                  } else {
                                                    _vm.$set(
                                                      _vm.searchParams,
                                                      "categories",
                                                      $$c
                                                    )
                                                  }
                                                },
                                                function ($event) {
                                                  return _vm.filterChanged()
                                                },
                                              ],
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("span", {
                                            staticClass: "checkmark",
                                          }),
                                          _vm._v(" "),
                                          _c("span", { staticClass: "text" }, [
                                            _vm._v(_vm._s(category?.name)),
                                          ]),
                                        ]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    category.categories.length &&
                                    !_vm.shouldExpandCategory(category.id)
                                      ? _c(
                                          "a",
                                          {
                                            staticStyle: {
                                              "padding-left": "50px",
                                            },
                                            attrs: { type: "button" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.toggleCategory(
                                                  category.id
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("+")]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    category.categories.length &&
                                    _vm.shouldExpandCategory(category.id)
                                      ? _c(
                                          "a",
                                          {
                                            staticStyle: {
                                              "padding-left": "50px",
                                            },
                                            attrs: { type: "button" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.toggleCategory(
                                                  category.id
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("-\n                       ")]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                                _vm._v(" "),
                                _vm.shouldExpandCategory(category.id)
                                  ? _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          position: "relative",
                                          display: "grid",
                                          left: "30px",
                                        },
                                      },
                                      _vm._l(
                                        category.categories,
                                        function (subCategory) {
                                          return _c(
                                            "label",
                                            {
                                              staticClass:
                                                "ms-checkbox-pink m-0",
                                            },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.searchParams
                                                        .categories,
                                                    expression:
                                                      "searchParams.categories",
                                                  },
                                                ],
                                                attrs: { type: "checkbox" },
                                                domProps: {
                                                  value: String(
                                                    subCategory?.id
                                                  ),
                                                  checked: Array.isArray(
                                                    _vm.searchParams.categories
                                                  )
                                                    ? _vm._i(
                                                        _vm.searchParams
                                                          .categories,
                                                        String(subCategory?.id)
                                                      ) > -1
                                                    : _vm.searchParams
                                                        .categories,
                                                },
                                                on: {
                                                  change: [
                                                    function ($event) {
                                                      var $$a =
                                                          _vm.searchParams
                                                            .categories,
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = String(
                                                            subCategory?.id
                                                          ),
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            _vm.$set(
                                                              _vm.searchParams,
                                                              "categories",
                                                              $$a.concat([$$v])
                                                            )
                                                        } else {
                                                          $$i > -1 &&
                                                            _vm.$set(
                                                              _vm.searchParams,
                                                              "categories",
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                )
                                                            )
                                                        }
                                                      } else {
                                                        _vm.$set(
                                                          _vm.searchParams,
                                                          "categories",
                                                          $$c
                                                        )
                                                      }
                                                    },
                                                    function ($event) {
                                                      return _vm.filterChanged()
                                                    },
                                                  ],
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("span", {
                                                staticClass: "checkmark",
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                { staticClass: "text" },
                                                [
                                                  _vm._v(
                                                    _vm._s(subCategory?.name) +
                                                      "\n                               "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    )
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                        ]
                      )
                    }),
                  ]
                }),
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "l-accordion",
              { attrs: { title: _vm.t("design.type"), isOpen: "" } },
              _vm._l(_vm.fontTypesList, function (font) {
                return _c(
                  "div",
                  { staticClass: "categorie-filter-type-wrapper" },
                  [
                    _c("ul", { staticClass: "ms-checkbox-list" }, [
                      _c("li", [
                        _c("label", { staticClass: "ms-checkbox-pink m-0" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.searchParams.fontType,
                                expression: "searchParams.fontType",
                              },
                            ],
                            attrs: { type: "checkbox" },
                            domProps: {
                              value: font?.id,
                              checked: Array.isArray(_vm.searchParams.fontType)
                                ? _vm._i(_vm.searchParams.fontType, font?.id) >
                                  -1
                                : _vm.searchParams.fontType,
                            },
                            on: {
                              change: [
                                function ($event) {
                                  var $$a = _vm.searchParams.fontType,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = font?.id,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.searchParams,
                                          "fontType",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.searchParams,
                                          "fontType",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(_vm.searchParams, "fontType", $$c)
                                  }
                                },
                                function ($event) {
                                  return _vm.filterChanged()
                                },
                              ],
                            },
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "checkmark" }),
                          _vm._v(" "),
                          _c("span", { staticClass: "text" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.t(
                                    "search-filters.operation-type." +
                                      font?.name.toLowerCase()
                                  )
                                ) +
                                " "
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]
                )
              }),
              0
            ),
          ]
        : _vm.searchParams?.type === "collection"
        ? [
            _c(
              "l-accordion",
              { attrs: { title: "search.filter.type-of-content", isOpen: "" } },
              [
                _c("div", { staticClass: "select-wrap" }, [
                  _c(
                    "div",
                    {
                      staticClass: "select-wrap-all",
                      class: {
                        "opacity-for-select":
                          _vm.searchParams.typeOfContent.length ===
                          _vm.collectionTypesList.length,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.selectAllCollections()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.t("search.filter.selectAll")) +
                          "\n                "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "select-wrap-clear",
                      class: {
                        "opacity-for-select":
                          !_vm.searchParams.typeOfContent.length,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.selectAllCollections(false)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.t("search.filter.clearAll")) +
                          "\n                "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _vm._l(_vm.collectionTypesList, function (collection) {
                  return _c(
                    "div",
                    { staticClass: "categorie-filter-type-wrapper" },
                    [
                      _c("ul", { staticClass: "ms-checkbox-list" }, [
                        _c("li", [
                          _c("label", { staticClass: "ms-checkbox-pink m-0" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.searchParams.typeOfContent,
                                  expression: "searchParams.typeOfContent",
                                },
                              ],
                              attrs: { type: "checkbox" },
                              domProps: {
                                value: collection?.id,
                                checked: Array.isArray(
                                  _vm.searchParams.typeOfContent
                                )
                                  ? _vm._i(
                                      _vm.searchParams.typeOfContent,
                                      collection?.id
                                    ) > -1
                                  : _vm.searchParams.typeOfContent,
                              },
                              on: {
                                change: [
                                  function ($event) {
                                    var $$a = _vm.searchParams.typeOfContent,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = collection?.id,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.searchParams,
                                            "typeOfContent",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.searchParams,
                                            "typeOfContent",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(
                                        _vm.searchParams,
                                        "typeOfContent",
                                        $$c
                                      )
                                    }
                                  },
                                  function ($event) {
                                    return _vm.filterChanged()
                                  },
                                ],
                              },
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "checkmark" }),
                            _vm._v(" "),
                            _c("span", { staticClass: "text" }, [
                              _vm._v(" " + _vm._s(collection?.name) + " "),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]
                  )
                }),
              ],
              2
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }