<template>
<div class="horizontal-list-container">
        <tiny-slider  class="design-slide-container" :items="2" :slideBy="2" :loop="false" :responsive="slideOptions.responsive" :controlsContainer="controlsContainerSelector" :nav="true" :navContainer="navContainerSelector" :controls="false" :preventScrollOnTouch="slideOptions.preventScrollOnTouch">
        <div  v-for="design in computedDesigns" :key="design.sku"  class="design-slide-item mt-3">
            <div v-if="useSimpleListEntity">
                <SimpleListEntity :entity="design" />
            </div>
            <div v-else>
                <ListEntity :entity="design" :iscol="design.isCollection" />
            </div>
        </div>
    </tiny-slider>
    <div class="controls-container" :class="controlsContainerClass">
        <span class="left">
            <i class="fas fa-chevron-left left"></i>
        </span>
        <span class="right">
            <i class="fas fa-chevron-right right"></i>
        </span>
    </div>
    <div class="nav-container" :class="navContainerClass">
        <button v-for="i in computedDesigns.length" :key="'key' + i" class="navigation-dot">
        </button>
    </div>
</div>
</template>

<script>
import SimpleListEntity from "./SimpleListEntity";
import VueTinySlider from 'vue-tiny-slider';
import ListEntity from "./card/ListEntity.vue";

export default {
    name: "HorizontalList",
    data: () => ({
        nextButton: `<i class="fas fa-chevron-right right"></i>`,
        prevButton: `<i class="fas fa-chevron-left left"></i>`,
        slideOptions: {
            navContainer: 'tiny-slider-nav-container',
            controlsContainer: 'tiny-slider-controls-container',
            preventScrollOnTouch: 'force',
            responsive: {
                768: {
                    items: 3,
                    slideBy: 3,
                    controls: true
                },
                1024: {
                    items: 4,
                    slideBy: 4,
                    controls: true
                }
            }
        },
    }),
    components: {
        SimpleListEntity,
        'tiny-slider': VueTinySlider,
        ListEntity,
    },
    computed: {
        controlsContainerClass: function () {
            return this.slideOptions.controlsContainer + '-' + this._uid;
        },
        controlsContainerSelector: function () {
            return '.' + this.controlsContainerClass;
        },
        navContainerClass: function () {
            return this.slideOptions.navContainer + '-' + this._uid;
        },
        navContainerSelector: function () {
            return '.' + this.navContainerClass;
        },
        computedDesigns: function () {
            if (typeof this.designs === 'string') {
                return JSON.parse(this.designs);
            }

            return this.designs;
        },
    },
    props: {
        designs: [String, Array],
        useSimpleListEntity: Boolean,
    },
}
</script>
