<template>
    <div @click="onClick" class="pink-btn view-more m-auto c-pointer" >
        <i>{{t('viewmore')}}</i>
    </div>
</template>

<script>
import {
    translate
} from "../utils/utils";
    export default {
        name: "LoadMore",
        props: {
            onClick: Function
        },
        methods:{
            t(key) {
                return translate(key)
            },
        }
    }

</script>
