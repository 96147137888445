var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.modalMask
      ? _c("div", { staticClass: "modal-mask", on: { click: _vm.closeModal } })
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "modal-wrapper",
        attrs: { tabindex: "-1", role: "dialog" },
      },
      [_vm._t("default")],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }