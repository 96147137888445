var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "thankyouPage-wrapper container" }, [
    _c("h1", [_vm._v(_vm._s(_vm.t("checkout.confirmation.success")))]),
    _vm._v(" "),
    _c("img", {
      attrs: {
        src: "/static/icons/checkout-confirmation-success.png",
        alt: "",
      },
    }),
    _vm._v(" "),
    _c("div", {
      staticClass: "confirmation-text",
      domProps: { innerHTML: _vm._s(_vm.t("checkout.confirmation.text")) },
    }),
    _vm._v(" "),
    this.profileData
      ? _c("div", [
          _c("p", { staticClass: "recipent" }, [
            _vm._v(_vm._s(_vm.t("checkout.confirmation.receipt-sent-to"))),
          ]),
          _vm._v(" "),
          _c("p", [_vm._v(_vm._s(this.profileData.email))]),
          _vm._v(" "),
          _c("div", { staticClass: "btn-wrapper" }, [
            _c(
              "a",
              {
                staticClass: "pink-btn",
                staticStyle: { "max-width": "60%" },
                attrs: { href: _vm.myPurchaseLink },
              },
              [
                _vm._v(
                  _vm._s(_vm.t("checkout.confirmation.purchased-link-label"))
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "blank-btn",
                staticStyle: { "max-width": "60%" },
                attrs: { href: _vm.myPurchasesLink },
              },
              [
                _vm._v(
                  _vm._s(_vm.t("checkout.confirmation.my-purchases-link-label"))
                ),
              ]
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }