var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.loggedIn || !_vm.hasSub
    ? _c("div", { staticClass: "design-user-info" }, [
        _c("div", { staticClass: "sub-login" }, [
          !_vm.loggedIn
            ? _c("p", [
                _c("a", { attrs: { href: _vm.url, target: "_blank" } }, [
                  _vm._v(_vm._s(_vm.t("design-page.login"))),
                ]),
                _vm._v(
                  " " + _vm._s(_vm.t("design-page.login-info")) + "\n        "
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.hasSub
            ? _c("p", [
                _c(
                  "a",
                  { attrs: { href: "https://mysewnet.com/en/pricing" } },
                  [_vm._v(_vm._s(_vm.t("design-page.subscribe")))]
                ),
                _vm._v(
                  " " +
                    _vm._s(_vm.t("design-page.subscribe-info")) +
                    "\n        "
                ),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "design-user-info-reload" }, [
          _vm._v(
            "\n        " + _vm._s(_vm.t("design-page.reload-info")) + "\n    "
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }