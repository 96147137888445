<script>
import Swiper from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import { EventBus } from "../../utils/eventBus";
import ListEntity from "../card/ListEntity.vue";
import {
    alertModal
} from '../../components/alert-modal';
import {
    translate,
    generalErrorCallback,
    handleFetchErrors
} from "../../utils/utils";

export default {
    name: "ProductsSwiper",
    components: { ListEntity },
    mounted() {
        EventBus.$on('filterChanged', this.filterChangedEvent);
        new Swiper(this.$refs.swiper, {
            slidesPerView: 'auto',
            spaceBetween: 16,
            // slidesPerGroup: 2,
            mousewheel: true,
            // Navigation arrows
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        })
        this.fetchProducts();
    },
    data() {
        return {
            shouldShow: true,
            fetchedproducts: []
        }
    },
    computed: {
        compiledProducts: function () {
            if (typeof this.products === "string") {
                return (JSON.parse(this.products));
            }
            return (this.products);
        },
    },
    props: {
        contenttype: String,
        products: String,
    },
    methods: {
        filterChangedEvent(filterType) {
            this.shouldShow = filterType === this.contenttype
        },
        fetchProducts: function () {
            var compiledURLFilter = "?sku=";
            this.compiledProducts.forEach(sku => {
                compiledURLFilter += sku + ',';
            });

            let ProductsURl = window.location.origin + '/api/v2/search' + compiledURLFilter;
            fetch(ProductsURl, {
                method: 'GET',

                headers: {

                }
            }).then(handleFetchErrors)
                .then(result => {


                    if (result) {
                        this.fetchedproducts = result.hits.map((product) => {
                            return {
                                url: product.designPageUrl,
                                ...product,
                            }
                        });
                    }
                })
                .catch(result => {
                    alertModal();
                });
        },

    },
}
</script>

<template>
    <!-- Slider main container -->
    <div ref="swiper" class="swiper" v-show="shouldShow && fetchedproducts.length">
        <!-- Additional required wrapper -->
        <div class="swiper-wrapper">
            <!-- Slides -->
            <template v-for="design in fetchedproducts">
                <div class="swiper-slide">
                    <ListEntity :entity="design"></ListEntity>
                </div>
            </template>
        </div>
        <!-- If we need pagination -->
        <div class="swiper-pagination"></div>

        <!-- If we need navigation buttons -->
        <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div>

        <!-- If we need scrollbar -->
        <div class="swiper-scrollbar"></div>
    </div>
</template>

<style lang="scss" scoped>
.swiper {
    margin: 0;
}
.swiper-button-next,
.swiper-button-prev {
    background-color: white;
    padding: 8px;
    border-radius: 100%;
    box-shadow: 0 10px 30px #0000003E;
    height: 44px;
    width: 44px;

    &::after {
        font-size: 16px;
        color: #161614;
    }

    &.swiper-button-disabled {
        display: none;
    }
}

.swiper-slide {
    width: 260px;
}

.hidden {
    display: none;
}

/* Tablet devices */
@media screen and (max-width: 1023px) {
    .swiper-slide {
        width: 224px;
    }
    .swiper {
        margin: 0 10px;
    }
}

/* Mobile devices */
@media screen and (max-width: 767px) {
    .swiper-slide {
        width: 174px;
    }
    .swiper {
        margin: 0 3px;
    }
}
</style>
