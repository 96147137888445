var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "like-box", on: { click: _vm.onFavoriteClick } },
    [
      !_vm.isFavorite
        ? _c(
            "IconsBase",
            { attrs: { iconColor: "#000", iconName: "Favorite" } },
            [_c("favorite-icon")],
            1
          )
        : _c(
            "IconsBase",
            { attrs: { iconColor: "#000", iconName: "Favorite" } },
            [_c("favorite-active-icon")],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }