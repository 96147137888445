<template>
<div class="search-wrapper">
    <div class="input-wrapper">
        <input type="text" class="search-input" :placeholder="searchPlaceholder" id="txtSearch" name="q" autocomplete="off" v-model="value" @keydown.enter='enter'>
        <div class="input-group-btn">
            <button class="btn" type="submit">
                <i class="fas fa-search"></i>
            </button>
        </div>
    </div>
</div>
</template>

<script>
import {
    getQueryParameterValue,
    composeUrlFromQueryVariables,
    generalErrorCallback,
    translate
} from "../utils/utils";
import {
    mixin as clickaway
} from 'vue-clickaway';

export default {
    name: "DesignSearchInput",
    mixins: [clickaway],
    data: () => ({
        value: "",
    }),
    components: {
    },
    props: {
        searchPlaceholder: String
    },
    created: function () {
    },
    mounted() {
    },
    methods: {
        onSubmit: function (event = null) {
            if (event) {
                event.preventDefault();
            }

            this.$store.commit("updateDesignQueryData", {
                q: this.value.trim().replace(/\s+/g, ' ')
            });
        },

        // When enter key pressed on the input
        enter(event) {
            // Close mobile keyboard
            event.target.blur();
            this.onSubmit();
        },

        t(key) {
            return translate(key)
        }
    },
    computed: {

    },
    watch: {
        value(value) {
            this.onSubmit();
        }
    }
}
</script>

<style scoped lang="scss">
@import "../globals";

.search-wrapper {
    overflow: hidden;
    width: 100%;

    .input-wrapper {
        width: 100%;

        border: solid;
        border-width: 0px;
        background-color: white;
        position: relative;

        @include media-breakpoint-up(md) {
            width: 360px;
        }

        >input {
            width: 100%;
            border: none;
            background-color: rgba(0, 0, 0, 0);
            outline: 0;
            font-family: "soleil-light", sans-serif;
            height: 45px;
            border: 1px solid #adadad;
            padding: 16px;
            border-radius: 8px;
        }

        .input-group-btn {
            position: absolute;
            top: 10px;
            right: 0;
        }
    }
}

</style>
