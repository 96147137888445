var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.shouldShow
    ? _c("div", { staticClass: "title-with-link" }, [
        _c("h3", [_vm._v(_vm._s(_vm.title))]),
        _vm._v(" "),
        _c("div", [
          _c("a", { attrs: { href: _vm.CompiledLink() } }, [
            _c("span", { staticClass: "link-text" }, [
              _vm._v(_vm._s(_vm.linktext)),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "link-arrow" }, [_vm._v("›")]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }