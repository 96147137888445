<template>
<div class="category-designs">

    <div class="mt-4">
        <div v-if="loading" class="loading-container d-flex align-items-center justify-content-center">
            <div class="spinner-grow" role="status">
                <span class="sr-only">{{ t("search.loading") }}</span>
            </div>
        </div>
        <div v-if="designs.length > 0" class="row">
            <div v-for="design in designs" :key="design.sku" class="col-lg-3 col-md-4 col-sm-6 col-6 mt-4">
                <ListEntity :entity="design" @favoriteClickEvent="handleFavoriteClickEvent" />
            </div>
        </div>
        <div v-if="errorState" class="vue-error-message">
            {{ t("search.fetcherror") }}
        </div>
        <div v-if="loadingMore" class="loading-container d-flex align-items-center justify-content-center">
            <div class="spinner-grow" role="status"></div>
        </div>
        <div class="search-count-result-wrapper">
            <p style="padding:10px" class="result-length">{{ t('search.results.showing', { current: designs.length, total: hits }) }}</p>
            <div class="storage-bar">
                <span id="storage-bar-fill" class="storage-bar-fill"></span>
            </div>
        </div>
        <div class="mt-4">
            <LoadMore v-if="hits > pageSize + offset" :onClick="loadMoreClick" />
        </div>
    </div>
</div>
</template>

<script>
import {
    generalErrorCallback,
    translate,
    handleFetchErrors
} from "../utils/utils";
import {
    alertModal
} from "../components/alert-modal";
import ListEntity from "./card/ListEntity.vue";
import LoadMore from "./LoadMore";
import SendDesign from "./SendDesign";
import SaveDesignVault from "./SaveDesignVault";

export default {
    name: "CategoryDesigns",
    data: () => ({
        apiUrl: "/api/v2/search",
        designs: [],
        hits: 0,
        pageSize: 24,
        offset: 0,
        loading: true,
        loadingMore: false,
        errorState: false,
    }),
    components: {
        ListEntity,
        LoadMore,
        SendDesign,
        SaveDesignVault,
    },
    props: {
        categoryid: {
            type: String,
            required: true
        },
        type:String
    },
    created: function () {
        this.fetchResult();
    },
    computed: {
        SearchWidth() {
            return (100 * this.designs.length / this.hits);
        },
        resultsCountText() {
            return this.t('search.results.showing', { current: this.designs.length, total: this.hits });
        }
    },
    methods: {
        fetchResult: function (loadmore) {
            
            const url = window.location.origin + this.apiUrl +"?type="+this.type + "&categories=" + this.categoryid + "&offset=" + this.offset ;
            this.errorState = false;
            this.loading = loadmore ? false : true;
            this.loadingMore = loadmore ? true : false;

            $.ajax({
                url: url,
                method: "GET",
                dataType: "json",
                beforeSend: xhr => {
                    xhr.setRequestHeader(
                        "Cache-Control",
                        "no-store, no-cache, must-revalidate, max-age=0"
                    );
                },
                success: (data) => {
                    if (!this.loadingMore) {
                        this.designs = [];
                    }
                    console.table(data);
                    this.designs = [...this.designs, ...data.hits.map(design => {
                        return {
                            url: design.designPageUrl,
                            ...design
                        }
                    })];
                    this.hits = data.total;
                },
                error: event => {
                    this.errorState = true;
                    generalErrorCallback(event, url);
                },
                complete: event => {
                    this.loading = false;
                    this.loadingMore = false;
                    var elem = document.getElementById("storage-bar-fill");
                    if(elem){ elem.style.width = this.SearchWidth + '%';}
                   

                }
            });
        },
        loadMoreClick() {
            this.offset = this.offset > 0 ? this.offset + this.pageSize : this.pageSize;
            this.fetchResult(true);
        },
        handleFavoriteClickEvent(favoritePromise, sku) {
            favoritePromise.then(handleFetchErrors)
                .then(function (result) {
                    if (result.success === false) {
                        // Aborts and runs catch function
                        throw Error(result.error);
                    }
                }).catch(function (error) {
                    // TODO show custom error message
                    alertModal();
                });
        },
        t(key, variables) {
            return translate(key, variables)
        },
    },
}
</script>

<style lang="scss">
@import "../globals";

.category-designs {
    a {
        color: $body-text-color;
    }

    .loading-container {
        min-height: 100px;
    }
}
</style>
