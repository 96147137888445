var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      staticClass: "l-btn",
      class: [
        _vm.type,
        _vm.color,
        _vm.size,
        { block: _vm.block, loading: _vm.isLoading },
        _vm.getIconClass(),
      ],
      attrs: { disabled: _vm.isDisabled },
    },
    [
      _vm.leftIconSlot
        ? _c("span", { staticClass: "left-icon" }, [_vm._t("left_icon")], 2)
        : _vm._e(),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "l-btn__text",
          class: [
            {
              "with-left-icon": _vm.leftIconSlot,
              "with-right-icon": _vm.rightIconSlot,
            },
          ],
        },
        [_vm._t("default")],
        2
      ),
      _vm._v(" "),
      _vm.rightIconSlot
        ? _c("span", { staticClass: "right-icon" }, [_vm._t("right_icon")], 2)
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }