<script>
import LButton from "./buttons/LButton.vue";

export default {
    name: "InstructionButton",
    components: [LButton],
    props: {
        left: {
            type: String,
        },
        buttonText: {
            type: String,
            required: true
        },
        right: {
            type: String
        },
        buttonUrl:{
            type: String
        }

    },
    methods: {
        openUrlInNewTab() {
            console.log("Button clicked");
            if (this.buttonUrl) {
                    window.location.href = this.buttonUrl;
            }
    },
    }
}
</script>

<template>

    <div  class="instruction-button">

        <l-button  @click.native="openUrlInNewTab">
            <template v-slot:left_icon>
                <img :src="left" />
            </template>
            <slot>{{buttonText}}</slot>
            <template v-slot:right_icon>
                <img :src="right" />
            </template>
        </l-button>
    </div>
</template>

<style scoped lang="scss">

</style>
