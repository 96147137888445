<template>
<div class="container d-flex flex-wrap">
    <div class="mt-4 col-12">
        <h2>{{ t("collection-page.related-collections")}}</h2>
        <div v-if="loading" class="loading-container d-flex align-items-center justify-content-center">
            <div class="spinner-grow" role="status">
                <span class="sr-only">{{ t("search.loading") }}</span>
            </div>
        </div>
        <div v-if="relatedCollections.length" class="row">
            <div class="col-12">
                <HorizontalList v-bind:designs="compiledCollections"  />
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    translate,
    generalErrorCallback,
    handleFetchErrors
} from "../utils/utils";
import {
    alertModal
} from "../components/alert-modal";
import ListEntity from "./card/ListEntity.vue";
import HorizontalList from "./HorizontalList";

export default {
    name: "RelatedCollections",
    data: () => ({
        apiUrl: "/api/collections",
        errorState: false,
        loading: true,
        relatedCollections: [],
    }),
    props: {
        sku: String,
    },
    components: {
        ListEntity,
        HorizontalList,
    },
    created: function () {
        this.fetchResult();
        this.defineURL();
    },
    computed:{
        compiledCollections(){    
        this.relatedCollections.forEach(col => {
            //col.iscol = true;
            console.log(col);
            
           
            
        });
        return(this.relatedCollections);
        }
        
 
    },
    methods: {
        onClick: function () {

        },
        defineURL(){
            this.relatedCollections.forEach(collection => {
                collection.setUrl("https://local-library.qa.mysewnet.com/collections/"+collection.sku);
                console.log(this.relatedCollections[0].url);
                
            });
        },
        handleFavoriteClickEvent(favoritePromise, sku) {
            favoritePromise.then(handleFetchErrors)
                .then(function (result) {
                    if (result.success === false) {
                        // Aborts and runs catch function
                        throw Error(result.error);
                    }
                }).catch(function (error) {
                    // TODO show custom error message
                    alertModal();
                });
        },
        fetchResult: function () {
            const self = this;
            const url = window.location.origin + self.apiUrl + "/" + self.sku + "/related";

            self.errorState = false;
            self.loading = true;
            $.getJSON(
                url,
                (data) => {
                    if (!data.collections) {
                        return;
                    }
                    self.relatedCollections = data.collections.map(collection => {
                        return {
                            url: window.location.origin +"/collections/"+collection.sku,
                            ...collection
                        }
                    });

                    self.loading = false;
                },
            ).fail(function (event) {
                self.errorState = true;
                generalErrorCallback(event, this.url);

                self.loading = false
            });
            
        },
        t(key) {
            return translate(key)
        }
    },
}
</script>
