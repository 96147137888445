<template>
<div @click="onClick" class="scroll-to-top">
    <i class="fas fa-arrow-up"></i>
</div>
</template>

<script>
import $ from "jquery";

export default {
    name: "ScrollToTop",

    methods: {
        onClick: function () {
            $("html, body").animate({
                scrollTop: 0
            }, "slow");
        }
    }
}
</script>
