<template>
<div>
    <div class="card-image">

        <div class="card-image__current">

            <img class="card-image__current-img"  :src="currentImage.image" />
        </div>
        <template>
    <!-- Slider main container -->
    <div  style="padding:20px" ref="swiper" class="swiper" v-show="computedDesignImages.length>1">
        <!-- Additional required wrapper -->
        <div class="swiper-wrapper">
            <!-- Slides -->
            <template  v-for="(item, idx) in computedDesignImages">
                <div
                    style="border: 1px solid #D8D8D8; border-radius: 16px; justify-content: center; display: flex;"
                    @click="changeCurrentImage(idx)"
                    :style="{ border: currentIndex === idx ? '5px solid #C3215B8B': '1px solid #D8D8D8' }"
                     class="swiper-slide">
                    <img style="  max-height: 174; width:260"  class="card-image__list-item__img" :src="item.image" />
                </div>
            </template>
        </div>
        <!-- If we need pagination -->
        <div class="swiper-pagination"></div>

        <!-- If we need navigation buttons -->
        <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div>

        <!-- If we need scrollbar -->
        <div class="swiper-scrollbar"></div>
    </div>
</template>

    </div>
</div>
</template>

<script>
import { translate} from '../utils/utils';
import Swiper from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import PurchasedTag from './icons/common/PurchasedTag.vue';
export default {
    name: "DesignImageGallery",
    data: () => ({
        currentImage: null,
        currentIndex:0,
    }),
    created: function () {
        this.currentImage = this.computedDesignImages[0];
    },
    mounted(){
        new Swiper(this.$refs.swiper, {
            slidesPerView: 'auto',
            spaceBetween: 16,
            // slidesPerGroup: 2,
            mousewheel: true,
            // Navigation arrows
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        })
    },
    methods: {
        t(key) {
            return translate(key);
        },
        changeCurrentImage(index) {
            this.currentImage = this.computedDesignImages[index];
            this.currentIndex = index;
        }
    },
    computed: {
        computedDesignImages: function () {
            if (typeof this.images === 'string') {
                return JSON.parse(this.images);
            }
            return this.images;
        }
    },
    props: {
        hasAdditionalImages: Boolean,
        isPurchased: Boolean,
        images: {
            type: [String, Array],
            default: []
        }
    },
    components: { PurchasedTag }
}
</script>
<style scoped lang="scss">
.card-image {
    &__current {
        max-width: 1088px;
        width: 100%;
        max-height: 622px;
        display: grid;
        
        justify-content: center;
        align-items: center;
        border: 1px solid #D8D8D8;
        border-radius: 12px;
        &-img {
            padding: 40px;
            max-height: 622px;
            width: auto;
            height: auto;
        }
    }
    &__list {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 16px;
        margin-top: 16px;
        &-item {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 260px;
            height: 174px;
            border: 1px solid #D8D8D8;
            border-radius: 12px;
            cursor: pointer;
            &__img {
                padding: 10px;
                height: 174px;
            }

        }
    }
    
.swiper {
    margin: 0;
}
.swiper-button-next,
.swiper-button-prev {
    
    background-color: white;
    padding: 8px;
    border-radius: 100%;
    box-shadow: 0 10px 30px #0000003E;
    max-height: 174px;
    max-width: 260px;

    &::after {
        font-size: 16px;
        color: #161614;
    }

    &.swiper-button-disabled {
        display: none;
    }
}

.swiper-slide {
   max-width: 260px;

    }


.hidden {
    display: none;
}

/* Tablet devices */
@media screen and (max-width: 1023px) {
    .swiper-slide {
        width: 224px;
    }
    .swiper {
        margin: 0 10px;
    }
}

/* Mobile devices */
@media screen and (max-width: 767px) {
    .swiper-slide {
        width: 174px;
    }
    .swiper {
        margin: 0 3px;
    }
}
}
/* Tablet devices */
@media screen and (max-width: 1023px) {
.card-image {
    &__current {
        max-height: 358px;
        &-img {
            max-height: 358px;
        }
    }
    &__list {
        &-item {
            width: 164px;
            height: 87px;
            &__img {
                height: 87px;
            }
        }
    }
}
    //164 87
}
/* Mobile devices */
@media screen and (max-width: 767px) {
    .card-image {
        &__current {
            max-height: 358px;
            &-img {
                max-height: 358px;
            }
        }
        &__list {
            &-item {
                width: 82px;
                height: 82px;
                &__img {
                    height: 82px;
                }
            }
        }
    }
}
</style>
