var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "instruction-button" },
    [
      _c(
        "l-button",
        {
          nativeOn: {
            click: function ($event) {
              return _vm.openUrlInNewTab.apply(null, arguments)
            },
          },
          scopedSlots: _vm._u([
            {
              key: "left_icon",
              fn: function () {
                return [_c("img", { attrs: { src: _vm.left } })]
              },
              proxy: true,
            },
            {
              key: "right_icon",
              fn: function () {
                return [_c("img", { attrs: { src: _vm.right } })]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm._v(" "),
          _vm._t("default", function () {
            return [_vm._v(_vm._s(_vm.buttonText))]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }