<template>

    <div class="l-active-filter" v-show="getMergedList.length">
        <span @click="clearAllFilters">Clear All</span>
        <div v-for="tag in getMergedList">
            <l-tag @close="removeSpecificFilter(tag)" closable>{{ tag.name }}</l-tag>
        </div>
    </div>
</template>

<script>
import { translate } from "../utils/utils";
export default {
    name: "Tags",
    props: {
        categoryList: {
            type: Array,
            default: [],
        },
        techniqueList: {
            type: Array,
            default: [],
        },
        fontList: {
            type: Array,
            default: [],
        },
        collectionList: {
            type: Array,
            default: [],
        },
    },
    computed: {
        getMergedList() {
            // separate for coloring same type or something like this
            return [...this.categoryList, ...this.techniqueList, ...this.fontList, ...this.collectionList];
        },
    },
    methods: {
        clearAllFilters() {
          this.$emit('clearFilter');
        },
        removeSpecificFilter(tag) {
          this.$emit('removeSpecificFilter', tag);
        },
        t(key) {
            return translate(key);
        }
    }
}
</script>
<style scoped lang="scss">
.l-active-filter {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    & span {
        cursor: pointer;
        text-decoration: underline;
    }
}
</style>
