<script>
import { EventBus } from "../../utils/eventBus";

export default {
  name: "TextWithTitle",
  props: {
    title: String,
    link: String,
    description: String,
    contenttype:String,
  },
  data() {
    return {
      shouldShow: true,
    }
  },
  mounted() {
    EventBus.$on('filterChanged', this.filterChangedEvent);
  },
  methods: {
    navigateToLink() {
        window.location.href = this.CompiledLink();
    },
    filterChangedEvent(filterType) {
        this.shouldShow = filterType === this.contenttype
    },
    CompiledLink(){
        console.log( window.location.origin + this.link);
        return  window.location.origin + this.link;
    }
  },

}
</script>

<template>
    <div v-if="shouldShow" class="area py-2 text-with-title">
      <div class="content-wrapper">
        <h3>{{ title }}</h3>
  
        <div :style="{ cursor: link ? 'pointer' : 'auto' }"  @click="navigateToLink" v-html="description"></div>
        
      </div>
    </div>
  </template>
  <style>
  </style>
