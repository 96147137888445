import { render, staticRenderFns } from "./CollectionsFavorites.vue?vue&type=template&id=0042693c&scoped=true"
import script from "./CollectionsFavorites.vue?vue&type=script&lang=js"
export * from "./CollectionsFavorites.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0042693c",
  null
  
)

export default component.exports