<template>
    <span class="text">{{ this.displayHeight + " x " + this.displayWidth +" x "+ this.displayLength +" "+ this.t("display-unit." + this.displayUnit)}}</span>
</template>
    
    <script>
    import {
        generalErrorCallback,
        translate
    } from "../utils/utils";
    
    export default {
        name: "CraftingDesignDimensions",
        created: function () {},
        methods: {
            mmToInch(dimension) {
                return parseFloat((dimension) / 25.4).toFixed(2).toString();
            },
            t(key) {
                return translate(key);
            }
        },
        computed: {
            displayUnit() {
                    return this.$store.state.displayUnit;
            },
            displayHeight() {
                this.heightinmm = this.heightinmm.replace(",", ".");
                return (this.displayUnit === "inch") ? this.mmToInch(this.heightinmm) : this.heightinmm;
            },
            displayWidth() {
                this.widthinmm = this.widthinmm.replace(",", ".");
                return (this.displayUnit === "inch") ? this.mmToInch(this.widthinmm) : this.widthinmm;
            },
            displayLength() {
                this.lengthtinmm = this.lengthtinmm.replace(",", ".");
                return (this.displayUnit === "inch") ? this.mmToInch(this.lengthtinmm) : this.lengthtinmm;
            },
        },
        props: {
            heightinmm: {
                type: String
            },
            lengthtinmm: {
                type: String
            },
            widthinmm: {
                type: String
            }
        },
    }
    </script>
    