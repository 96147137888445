<template>
    <div class="simple-sort-container">
        <select style="font-weight:bold;" class="ms-btn-default m-0 pl-1 mr-2" v-model="internalOrderKey" @change="orderKeyChange">
            <option class="optionP" v-for="option in options" :key="option.id" :value="option.value" >{{ option.text }}</option>
        </select>
    </div>
</template>
<style >
.optionP{
    border-radius:33px;
    font-weight: bold;
    text-align:  left;
}
</style>
<script>

import {translate as t} from "../utils/utils";

export default {
    name: "SimpleSortBy",
    data: () => ({
        filterSettings: {
            orderKey: "",
            order: "",
        },
        options: [
            { id: 0, value: "default", text: t('sort.sortby'), orderKey: "", order: "asc" },
            { id: 1, value: "priceGroupDesc", text: t('sort.priceDesc'), orderKey: "priceGroup", order: "desc" },
            { id: 2, value: "priceGroupAsc", text: t('sort.priceAsc'), orderKey: "priceGroup", order: "asc" },
            { id: 3, value: "publishedDateDesc", text: t('sort.dateDesc'), orderKey: "publishedDate", order: "desc" },
            { id: 4, value: "publishedDateAsc", text: t('sort.dateAsc'), orderKey: "publishedDate", order: "asc" },
        ],
        selectedOrderKeyObject: null,
        internalOrderKey: "",
            
    }),

    created: function () {
        this.selectedOrderKeyObject = this.options[0];
        this.internalOrderKey = this.selectedOrderKeyObject.value;
        this.filterSettings.orderKey = this.selectedOrderKeyObject.orderKey;
        this.filterSettings.order = this.selectedOrderKeyObject.order;
    },
    
    methods: {
        orderKeyChange: function () {
            let matches = this.options.filter(orderObject => orderObject.value === this.internalOrderKey);
            this.selectedOrderKeyObject = matches.length > 0 ? matches[0] : this.options[0];
            this.filterSettings.orderKey = this.selectedOrderKeyObject.orderKey;
            this.filterSettings.order = this.selectedOrderKeyObject.order;
            this.internalOrderKey = this.selectedOrderKeyObject.value;
            this.$emit('sort-changed', {
                orderKey: this.selectedOrderKeyObject.orderKey,
                order: this.selectedOrderKeyObject.order
            });
        }
    }
}
</script>
