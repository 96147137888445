<template>
    <div class="subscription-info" v-if="!hasValidSubscription">
        <p>{{ t('subscription-info.pdp.text') }}</p>
        <l-button type="secondary" color="pink" block="true" @click.native="openLibrarySubscriptionsLink">
            {{ t('action-banner.subscribe-now') }}
        </l-button>
    </div>
</template>

<script>
import {translate} from '../utils/utils';

export default {
    name: "SubscriptionInfo",
    props: {
        hasValidSubscription: Boolean,
        subscribeLink: String
    },
    methods: {
        t(key) {
            return translate(key)
        },
        openLibrarySubscriptionsLink() {
            window.location.href = this.subscribeLink;
        }
    }
}
</script>

<style lang="scss" scoped>
.subscription-info {
    margin-top: 16px;
    padding: 13px;
    background: #F7F7F7;
    border-radius: 16px;
    font-size: 16px;

    p {
        text-align: center;
    }

    button {
        grid-template-columns: 1fr;
    }
}

</style>
