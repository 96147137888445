var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        id: "rename",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        width: "32",
        height: "32",
        viewBox: "0 0 32 32",
      },
    },
    [
      _c("defs", [
        _c("clipPath", { attrs: { id: "clip-path" } }, [
          _c("rect", {
            attrs: {
              id: "Rectangle_81981",
              "data-name": "Rectangle 81981",
              width: "32",
              height: "32",
              fill: "none",
            },
          }),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "g",
        {
          attrs: {
            id: "Group_186316",
            "data-name": "Group 186316",
            "clip-path": "url(#clip-path)",
          },
        },
        [
          _c("line", {
            attrs: {
              id: "Line_4160",
              "data-name": "Line 4160",
              x2: "24.71",
              transform: "translate(3.645 28)",
              fill: "none",
              stroke: "#555",
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
              "stroke-width": "2",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              id: "Path_136160",
              "data-name": "Path 136160",
              d: "M10.637,22.171l-3.809.762A2.155,2.155,0,0,1,4.292,20.4l.761-3.808a2.163,2.163,0,0,1,.59-1.1L18.023,2.953a2.156,2.156,0,0,1,3.048,0L24.119,6a2.154,2.154,0,0,1,0,3.046l-2.177,2.336-10.2,10.2A2.155,2.155,0,0,1,10.637,22.171Z",
              fill: "none",
              stroke: "#555",
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
              "stroke-width": "1.995",
            },
          }),
          _vm._v(" "),
          _c("line", {
            attrs: {
              id: "Line_4161",
              "data-name": "Line 4161",
              x2: "6.096",
              y2: "6.095",
              transform: "translate(15.738 5.235)",
              fill: "none",
              stroke: "#555",
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
              "stroke-width": "1.995",
            },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }