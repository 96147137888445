<template>
  <div>
    <l-popper trigger="clickToOpen" :options="{
      placement: 'auto',
      modifiers: { offset: { offset: '0,10px' } }
    }">

      <div class="popper" >
     
        <SubscriptionTooltip v-if="isOpenTooltip"/>

        <SaveDesignVault v-else :sku="sku" :designCode="designCode" :designtype="designtype" :designs="computedDesigns"
          :nameOfDesign="nameOfDesign" :designname="designname" :isPurchased="isPurchased == true"
          :priceDisplay="priceDisplay" :allowedCountry="allowedCountry" :iscollection="iscollection" />

      </div>
      <l-button  type="small" slot="reference" @click.native="onClick()" >
        <IconsBase   :iconName='t("upload.tocloud.modal.header")'>
          <upload-icon  />
        </IconsBase>
      </l-button>
    </l-popper>
  </div>
</template>
<style>

</style>
<script>
import Modal from "./Modal";
import SaveDesignVault from './SaveDesignVault';
import { EventBus } from "../utils/eventBus";
import { translate, userHasSubscription } from "../utils/utils";
import Popper from 'vue-popperjs';
import LButton from "./buttons/LButton.vue";
import IconsBase from "./icons/IconsBase.vue";
import UploadIcon from "./icons/common/UploadIcon.vue";
import SubscriptionTooltip from "./tooltip/SubscriptionTooltip.vue";
export default {
  name: "SaveDesignVaultButton",
  props: {
    iscollection: String,
    designs: String,
    designname: String,
    sku: String,
    isPurchased: Boolean,
    priceDisplay: String,
    allowedCountry: false,
    designCode: String,
    designtype: String,
    nameOfDesign: String,
  },
  components: {
    Modal,
    SaveDesignVault,
    LButton,
    IconsBase,
    UploadIcon,
    SubscriptionTooltip,
    'l-popper': Popper,
  },
  data() {
    return {
      isOpenUploadTooltip: false,
      isOpenTooltip: false,
      showUploadModal: false,
      hasSub: !!userHasSubscription(),
    }
  },
  computed: {
    computedDesigns: function () {
      if (typeof this.designs === 'string') {
        return JSON.parse(this.designs);
      }
      return this.designs;
    },
    shouldOfferSubscription() {
      return userHasSubscription() === false && !this.isPurchased;
    }
  },
  methods: {
    t(key) {
      return translate(key)
    },
    onClick() {
      if (this.shouldOfferSubscription) {
        this.isOpenTooltip = true
      } else {
        this.isOpenUploadTooltip = true;
      }
    },
    closeModal() {
      this.showUploadModal = false;
    },
  }
}
</script>
