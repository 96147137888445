<template>
    <span :class="{ 'purchased-label': !pdp, 'purchased-pdp-label': pdp }"
        style="display: flex; text-align: center; align-items: center; justify-content: space-between;">
        <PurchasedIcon style="padding: 2px;" />
        <p  :class="{ 'purchased-text': !pdp }" style="position:relative;color: #74A765;font-weight: bold; top:8px;">PURCHASED</p>
        <div></div>
    </span>
</template>

<script>

import PurchasedIcon from "./PurchasedIcon.vue";

export default {
    name: "PurchasedTag",
    components: { PurchasedIcon },

    props: {
        pdp: Boolean,
    }

}
</script>

<style lang="scss">
.purchased-label {
    max-width: 40%;
    background-color: #E9F2E8;
    border-radius: 8px;
    color: #74A765;
    font-family: soleil-light, Helvetica, Arial, sans-serif;
    font-size: 12px;
    left: 0;
    letter-spacing: 1px;
    padding: 6px 12px;
    font-weight: bold;
    text-transform: uppercase;
    bottom: 10px;

}
.purchased-pdp-label {
        
    max-width: 100%;
    background-color: #E9F2E8;
    border-radius: 8px;
    color: #74A765;
    font-family: soleil-light, Helvetica, Arial, sans-serif;
    font-size: 12px;
    left: 0;
    letter-spacing: 1px;
    padding: 6px 12px;
    font-weight: bold;
    text-transform: uppercase;
    bottom: 10px;

    }

/* Media query for mobile devices */
@media (max-width: 991px) {
    .purchased-label {
        background-color: #E9F2E8;
        border-radius: 8x;
        text-align: left;
        font-size: xx-small;
        width: 40px;

    }

    .purchased-label .purchased-text {
        display: none;
        /* Hide the text on mobile */
    }

    .purchased-pdp-label {
        
        background-color: #E9F2E8;
        border-radius: 8px;
        color: #74A765;
        font-family: soleil-light, Helvetica, Arial, sans-serif;
        font-weight: bold;
        text-transform: uppercase;
        border-radius: 8x;
        text-align: left;
        font-size: xx-small;
        width: 100%;

    }
}</style>
