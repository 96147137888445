var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "remove-from-cart", on: { click: _vm.removeFromCart } },
    [
      _vm.isLoading
        ? _c("div", {
            staticClass: "spinner-grow spinner-grow-sm",
            attrs: { role: "status" },
          })
        : _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }