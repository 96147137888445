var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("h1", [_vm._v(_vm._s(_vm.computedcollection.sku))]),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "card-img-wrapper",
          attrs: { href: _vm.computedcollection.url },
        },
        [
          _c("img", {
            directives: [
              {
                name: "lazy",
                rawName: "v-lazy",
                value: _vm.category,
                expression: "category",
              },
            ],
          }),
        ]
      ),
      _vm._v(" "),
      _c("ListEntity", {
        attrs: { entity: _vm.computedcollection },
        on: { favoriteClickEvent: _vm.handleFavoriteClickEvent },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }