const user = {
    state: {
        preferredFormat: null,
        preferredCraftingFormat: null
    },
    getters: {
        preferredFormat (state) {{
            return state.preferredFormat;
        }},
        preferredCraftingFormat (state) {{
            return state.preferredCraftingFormat;
        }}
    },
    mutations: {
        updatePreferredFormat(state, payload) {
            state.preferredFormat = payload.value;
        },
        updatePreferredCraftingFormat(state, payload) {
            state.preferredCraftingFormat = payload.value;
        }
    },
    actions: {
        fetchPreferredFormat({ commit }) {
            callUserApi("GET", (data) => {
                if (data.format.sewing) {
                    const value = data.format.sewing;
                    commit('updatePreferredFormat', {
                        value
                    });
                } else {
                    // Set default format to vp3.
                    const value = 'vp3';
                    commit('updatePreferredFormat', {
                        value
                    });
                }
            });
        },
        fetchPreferredCraftingFormat({ commit }) {
            callUserApi("GET", (data) => {
                if (data.format.crafting) {
                    const value = data.format.crafting;
                    commit('updatePreferredCraftingFormat', {
                        value
                    });
                } else {
                    // Set default format to ccm.
                    const value = 'ccm';
                    commit('updatePreferredCraftingFormat', {
                        value
                    });
                }
            });
        },
        storePreferredCraftingFormat({}, payload)  {
            callUserApi("POST", null, { format: payload.value });
        },
        storePreferredFormat({}, payload)  {
            callUserApi("POST", null, { format: payload.value });
        }
    }
}

const apiUrl = '/api/user/format';

function callUserApi(method, successCallback, data) {
    const url = window.location.origin + apiUrl;

    const ajaxOptions = {
        url: url,
        method: method,
        dataType: "json",
        beforeSend: xhr => {
            xhr.setRequestHeader(
                "Cache-Control",
                "no-store, no-cache, must-revalidate, max-age=0"
            );
        },
        success: data => {
            if (successCallback) {
                successCallback(data);
            }
        },
    }

    if (method === "POST" && data) {
        ajaxOptions.data = data;
    }

    $.ajax(ajaxOptions);
}

export default user;
