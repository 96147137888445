<template>
    <div class="save-design-wrap">
        <div class="modal-header">
      <img style="width: 40px; height: 40px; padding: 3px;" src="/static/icons/vault.svg" alt>
      <p style="font-weight: bold; top:8px; position: relative;">{{ t("upload.tocloud.modal.header") }}</p>
        </div>
        <div class="modal-body">
      <p v-if="!hasError && !uploadSuccess">
        {{ t(uploadText) }} <span class="bold">{{ designname }}</span>
      </p>
  
      <p v-if="!hasError && uploadSuccess" class="bold">
        {{ t("save-to.heading-design-saved-success") }}
      </p>
  
      <div v-if="hasError">
        {{ t(errorText) }}
      </div>
  
      <p v-if="uploadSuccess">
        {{ t("save-to.design-saved-success-msg", { filename: filename }) }}
      </p>
      
      <div v-if="!hasError && !uploadSuccess && isPurchased && !isquilt && !iscrafting">
        <p>{{ t("design.download-design-format") }}</p>
        <PreferredFormat style="max-width: -webkit-fill-available;" @getSelectedFormat="handleSelectedFormat" :isStandAlone="true"></PreferredFormat>
      </div>
  </div>
      <div class="save-design-wrap__actions">


  
        <LButton v-if="!uploadSuccess && !hasError" color="pink" size="tiny" @click.native="uploadDesign()">
          {{t(uploadText)}}
        </LButton>
      </div>
    </div>
  </template>
  
  <style lang="scss">
  @import "../globals";
  
  .save-design-wrap {
    width: 100%;
    padding: 5px;
    text-align: left;
    
    & h5 {
      font-family: soleil-semibold, Helvetica, Arial, sans-serif;
      font-size: 16px;
    }
  
    & p {
      font-size: 14px;
    }
  
    &__actions {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 5px;
    }
    .modal-body{
        & p{
            padding-left: 5px;
            font-size: 12px;
        }
    }
    .modal-header{
        display: inline-flex;
        border: none;
    }
  }
  </style>

<script>
import {
    userHasSubscription
} from "../utils/utils";
import {
    translate
} from "../utils/utils";
import PreferredFormat from "./PreferredFormat";
import {
    mapGetters,
    mapMutations
} from "vuex";
import {
    EventBus
} from "../utils/eventBus";
import {
    subscriptionModal
} from "../components/subscription-modal";
import store from "../store";
export default {
    name: "SaveDesignVault",
    components: {
        PreferredFormat
    },
    props: {
        iscollection:String,
        designtype:String,
        designname:String,
        sku:String,
        isPurchased:Boolean,
        priceDisplay:String,
        allowedCountry:Boolean,
        designCode:String,
        nameOfDesign:String,
        designs:Array,

    },
    data() {
        return {
            addToCartLoading: false,
            format: 'vp3',
            uploading: false,
      
            errorText: null,
            uploadSuccessful: false,
            filename: this.designname,
            apiUrl: "/api/designs",
            clients: [],
            modalOpen: false,
            loading: false,
            errorState: false,
            designSent: null,
            targetClient: null,
            designimage: '',
            hasSub: !!userHasSubscription(),
        }
    },

    computed: {
        ...mapGetters([
            'preferredFormat',
            'designCodes',
        ]),
        ...mapMutations([
            'updateDesignCodes'
        ]),
        uploadText() {
            return this.uploading ? "design.uploading" : "design.upload"
        },
        hasError() {
            return this.errorText ? true : false;
        },
        uploadSuccess() {
            return !this.hasError && this.uploadSuccessful;
        },
        isquilt() {
            return this.designtype === "quilt";
        },
        iscrafting(){
            return this.designtype ==="crafting";
        },
        inCart: function () {
            return this.designCodes.indexOf(this.designCode) !== -1;
        },
        isAddToCartLoading: function () {
            return this.addToCartLoading;
        }
    },
    created() {

        if (this.isPurchased) {
            this.format = this.preferredFormat ? this.preferredFormat : 'vp3';
        }

        if (this.hasSub && !this.isPurchased) {
            //this.uploadDesign();
        }
    },
    methods: {
        UpgradeSubscription: function () {
            window.location.href = '/Subscription/';
        },

        addToCart() {
            if (!this.inCart && !this.isAddToCartLoading && !this.isPurchased) {
                this.addToCartLoading = true;
                const url = "/api/cart/add-to-cart?";
                xhr => {
                        xhr.setRequestHeader(
                            "Cache-Control",
                            "no-store, no-cache, must-revalidate, max-age=0"
                        );
                    },
                    $.ajax({
                        url: url,
                        method: "POST",
                        data: {
                            skucode: this.sku,
                            designcode: this.designCode,
                            nameofdesign: this.nameOfDesign,
                            type: 'design' // TODO: Implement a way to determine if it should be collection or design
                        },

                        success: data => {
                            let designCodes = [];
                            data.lineitems.forEach((e) => {
                                designCodes.push(e["designcode"])
                            })
                            store.commit('updateDesignCodes', {
                                designCodes
                            });
                            store.commit('updateCartItems', {
                                data
                            })
                        },
                        error: event => {
                            translatedAlertModal("design-page.add-to-cart.failed.headline", "design-page.add-to-cart.failed.text");
                        },
                        complete: () => {
                            this.addToCartLoading = false;
                        }
                    });
            }
        },

        handleSelectedFormat(format) {
            this.format = format;
        },

        uploadDesign() {

            if (!this.uploading) {
                this.uploading = true;
                var endpoint = "";
                console.log(this.iscollection);
                    if(this.iscollection == 'true'){
                        
                        endpoint="saveCollectiontovault";
                    }else{
                        endpoint="savetovault";
                    }
                   
                    let url = `/api/mysewnet/${endpoint}/${this.designCode}`;

                    if (this.isPurchased) {
                        if (this.designtype === "crafting")
                            url = `/api/mysewnet/${endpoint}/${this.designCode}/ccm`;
                        else
                            url = `/api/mysewnet/${endpoint}/${this.designCode}/${this.format}`;
                    }

                    $.ajax({
                        url: url,
                        method: "POST",
                        success: data => {
                            if (!data.success) {

                                if (data.filename !== undefined) {
                                    this.filename = data.filename;
                                }
                                this.errorText = data.error;
                            } else {
                                this.filename = data.filename;
                                this.uploadSuccessful = true;
                            }

                        }
                    });
                
            }
        },
        t(key, variables) {
            return translate(key, variables)
        }
    }
}
</script>
