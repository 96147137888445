<template >
<div>
    <div class="lang-picker-current" @click="isListShown = !isListShown">
        <img class="lang-icon" :src="getLanguageIcon(currentLanguage)">
        {{ currentLanguageName }}
        <i class="fa fa-angle-down" aria-hidden="true"></i>
    </div>

    <div v-show="isListShown" class="lang-list">
        <p> {{ t('languageDropdown.content.europe') }} </p>
        <div v-for="language in europeLanguageList" :key="language.code">
            <a v-if="!northAmerica(language.name) && language.name"
               class="header-language-name-selector font-msn-body"
               @click="onClick(language)"
            >
                <span :class="currentLanguage === language.code ? 'active' : null">
                    {{ language.name }}
                </span>
            </a>
        </div>

        <p> {{ t('languageDropdown.content.northAmerica') }} </p>
        <div v-for="language in northAmericaLanguageList" :key="language.code">
            <a v-if="northAmerica(language.name) && language.name"
               class="header-language-name-selector font-msn-body"
               @click="onClick(language)"
            >
                <span :class="currentLanguage === language.code ? 'active' : null">
                    {{ language.name }}
                </span>
            </a>
        </div>
    </div>
</div>
</template>

<script>
import {
    translate,
    getCurrentLanguageFromCookie,
    getLanguageIcon
} from "../utils/utils";

export default {
    name: "LanguagePicker",
    data: () => ({
        isListShown: false,
        languages: [],
        northAmericaLanguageList: [],
        europeLanguageList: [],
        loading: false,
    }),
    props: {
        languagedata: String
    },
    computed: {
        currentLanguage() {
            return getCurrentLanguageFromCookie();
        },
        currentLanguageName() {
            return this.languages.find((lang) => lang.code === this.currentLanguage).name
        }
    },
    methods: {
        getLanguageIcon,
        northAmerica(lang) {
            return lang === "Canada (English)" || lang === "Canada (French)" || lang === "United States (English)";
        },
        onClick: function (languageObject) {
            const url = window.location.origin + '/api/setculture' + languageObject.href;
            const currentUrl = window.location.href;
            this.currentLanguage = languageObject.code;

            $.ajax({
                url: url,
                method: "POST",
                data: {
                    currentUrl
                },
                dataType: "json",
                beforeSend: xhr => {
                    this.loading = true;
                },
                success: data => {
                    if (data.error) {
                        this.loading = false;
                        return;
                    }

                    let newUrl = '';
                    let urlArray = data.currentUrl.split('/');

                    let matches = this.languages.filter(function (language) {
                        return language.href === ('/' + urlArray[3]);
                    });

                    // Check if the URL has language setting already and if it exists, update to new else insert the language
                    if (data.newCulture == null) {
                        if (urlArray.length >= 4) {
                            if (matches.length >= 1) {
                                urlArray.splice(3, 1);
                            }
                        }
                    } else if (matches.length >= 1) {
                        urlArray[3] = data.newCulture;
                    } else {
                        urlArray.splice(3, 0, data.newCulture);
                    }

                    newUrl = urlArray.join("/");
                    this.$store.commit("clearSearchResult");
                    window.location.href = newUrl;
                },
                error: (request) => {
                    this.loading = false;
                }
            });
        },
        t(key) {
            return translate(key)
        }
    },
    created() {
        this.languages = JSON.parse(this.languagedata);
        this.languages.forEach((lang) => {
            if (!lang.name) return;
            this.northAmerica(lang.name) ?
                this.northAmericaLanguageList.push(lang) : this. europeLanguageList.push(lang)
        })
    },
}
</script>

<style lang="scss" scoped>
.lang-picker-current {
    cursor: pointer;
}
.lang-icon {
    max-height: 20px;
    max-width: 20px;
    display: inline-block;
}
.lang-list {
    background-color: #9e83ae;
    max-height: 40vh;
    overflow-y: auto;
    padding: 5px 12px;
    margin-top: 10px;
    border-radius: 5px;
    & p {
        margin: 0;
    }
    & span {
        &.active {
            text-decoration: underline;
        }
    }
}
</style>


