<template>
<div v-if="order">
    <p class="order-total">{{ t("checkout.details.order-total") }} ({{ order.totalQuantity }} {{ t("checkout.details.items") }})</p>
        <div class="price m-t-10">
            <p>
                {{ t("checkout.details.price") }}<span v-if="!taxNotIncluded()"> ({{ t("checkout.details.incl-tax") }})</span>
            </p>
            <p class="val">
                {{ order.cost[0].display }}
            </p>
        </div>
        <div v-if="order.discounts[0].amount>0" class="price">
            <p>
                {{ t("checkout.details.discount") }}
            </p>
            <p class="val">
                - {{ order.discounts[0].display }}
            </p>
        </div>
        <div v-if="taxNotIncluded()" class="price">
            <p>
                {{ t("checkout.tax.added") }}
            </p>
            <p class="val">
                {{ order.taxTotal }}
            </p>
        </div>
        <div v-if="!taxNotIncluded() && isPaymentStep()" class="price">
            <p>
                {{ t("checkout.tax.included") }}
            </p>
            <p class="val">
                {{ order.taxTotal }}
            </p>
        </div>
        <div class="total">
            <p>
                {{ t("checkout.details.total") }}
            </p>
            <p class="val">
               {{order.orderCost[0].display}} 
            </p>
        </div>

    </div>

</template>

<script>
import {
    translate
} from "../../utils/utils";
import {
    mapGetters
} from "vuex";

export default {
    name: "Summary",
    computed: {
        totalamount(){
            var x = this.order.orderCost[0].amount - this.order.discounts[0].amount ;
            console.log(this.order);
            return(x.toFixed(2));
        },
        ...mapGetters([
            'order',
        ]),
    },
    props: {
        step: String,
    },
    methods: {

        taxNotIncluded() {
            return this.order.currencyCode == "USD" || this.order.currencyCode == "CAD";
        },
        isPaymentStep() {
            return this.step == "payment";
        },
        t(key) {
            return translate(key)
        },
    }
}
</script>

<style lang="scss">
.order-details-spinner-wrapper {
    min-height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.summary {
    padding: 18px;
    background-color: #f9f9f9;

    h4 {
        font-size: 1.2rem;
        text-transform: uppercase;
        font-weight: bold;
        padding-bottom: .25rem;
        border-bottom: 1px solid #cacaca;
        margin-bottom: 1.2rem;
    }

    .order-summary-price {
        margin-bottom: .1rem;

        .price-col {
            font-weight: bold;
            font-size: 1.2rem;
            text-align: right;
        }

        .discount-price-col {
            color: #339900;
        }

        .total-price-col {
            font-size: 1.4rem;
            color: #339900;
        }

        .total-price {
            margin-top: 1.3rem;
        }
    }

}
</style>
