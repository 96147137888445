<template>
    <div :class="{'has-bg': bg }" class="page-back ">
        <div class="container p-0">
            <i @click="onClick" class="fas fa-arrow-left purple-action-icon"></i>
        </div>
    </div>
</template>

<script>
export default {
    name: "BackButton",
    methods: {
        onClick: function () {
            window.history.back();
        }
    },
    props: {
        bg: Boolean,
    },
}
</script>
