var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.shouldShow,
          expression: "shouldShow",
        },
      ],
      staticStyle: { "padding-bottom": "150px" },
    },
    [
      _c("div", { staticClass: "banner-wrapper" }, [
        _c("div", { staticClass: "banner-wrapper__icon" }, [
          _c("img", {
            staticStyle: { "max-height": "128px", "max-width": "128px" },
            attrs: { src: _vm.imageUrl },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "banner-wrapper__info" }, [
          _vm._v(_vm._s(_vm.title)),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "banner-wrapper-action" }, [
          _c("div", { staticClass: "banner-wrapper-action__price" }, [
            _c("span", { domProps: { innerHTML: _vm._s(_vm.description) } }),
          ]),
          _vm._v(" "),
          _c("button", { staticClass: "banner-wrapper-action__button" }, [
            _c(
              "a",
              {
                staticStyle: { color: "white", "text-decoration": "none" },
                attrs: {
                  href: "https://mysewnet.com/products-and-plans/embroidery",
                },
              },
              [
                _c("span", [
                  _vm._v(_vm._s(_vm.t("action-banner.subscribe-now"))),
                ]),
              ]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("button", { staticClass: "banner-wrapper-mobile-action" }, [
          _c(
            "a",
            {
              staticStyle: { color: "white" },
              attrs: {
                href: "https://mysewnet.com/products-and-plans/embroidery",
              },
            },
            [
              _c(
                "span",
                {
                  attrs: {
                    href: "https://mysewnet.com/products-and-plans/embroidery",
                  },
                },
                [_vm._v(_vm._s(_vm.t("action-banner.subscribe-now")))]
              ),
            ]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }