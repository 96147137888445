var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "search-wrapper" }, [
    _c(
      "form",
      {
        ref: "form",
        staticClass: "header-search-form d-flex align-items-center",
        class: { open: _vm.openSuggestion, "has-filter-button": _vm.hasFilter },
        attrs: { action: _vm.searchUrl, method: "GET" },
        on: { submit: _vm.onSubmit },
      },
      [
        _c(
          "div",
          {
            staticClass: "input-wrapper flex-grow-1",
            class: { "top-suggestion-selected": _vm.topSuggestionActive },
          },
          [
            _c("input", {
              directives: [
                {
                  name: "on-clickaway",
                  rawName: "v-on-clickaway",
                  value: _vm.focusout,
                  expression: "focusout",
                },
              ],
              staticClass: "search-input",
              attrs: {
                type: "text",
                placeholder: _vm.t("search.inputplaceholder"),
                id: "txtSearch",
                name: "q",
                autocomplete: "off",
              },
              domProps: { value: _vm.value },
              on: {
                input: function ($event) {
                  return _vm.updateValue($event.target.value)
                },
                keydown: [
                  function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.enter.apply(null, arguments)
                  },
                  function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "down", 40, $event.key, [
                        "Down",
                        "ArrowDown",
                      ])
                    )
                      return null
                    return _vm.down.apply(null, arguments)
                  },
                  function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "up", 38, $event.key, [
                        "Up",
                        "ArrowUp",
                      ])
                    )
                      return null
                    return _vm.up.apply(null, arguments)
                  },
                ],
                focus: _vm.focus,
              },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "separator" }),
            _vm._v(" "),
            _vm._m(0),
            _vm._v(" "),
            _c(
              "ul",
              { staticClass: "dropdown-menu" },
              _vm._l(_vm.suggestions, function (suggestion, index) {
                return _c(
                  "li",
                  {
                    key: index,
                    class: { active: _vm.isActive(index) },
                    on: {
                      click: function ($event) {
                        return _vm.suggestionClick(index)
                      },
                      mouseover: function ($event) {
                        return _vm.suggestionHover(index)
                      },
                      mouseleave: function ($event) {
                        return _vm.suggestionHover(-1)
                      },
                    },
                  },
                  [
                    _c("div", {
                      staticClass: "suggestion",
                      domProps: {
                        innerHTML: _vm._s(_vm.renderSuggestion(suggestion)),
                      },
                    }),
                  ]
                )
              }),
              0
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-btn" }, [
      _c("button", { staticClass: "btn", attrs: { type: "submit" } }, [
        _c("i", { staticClass: "fas fa-search" }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }