<script>
import { EventBus } from "../../utils/eventBus";

export default {
  name: "FeaturedImage",
  props: {
    contenttype: String,
    image: String,
    classes: {
        type: String,
        default: ''
    },
  },
  data() {
    return {
      shouldShow: true,
    }
  },
  mounted() {
    EventBus.$on('filterChanged', this.filterChangedEvent);
  },
  methods: {
    filterChangedEvent(filterType) {
        this.shouldShow = filterType === this.contenttype
    },
  },
    computed: {
        imageUrl() {
            // Extract the src attribute from the HTML string
            const matches = this.image.match(/src="([^"]*)"/);
            return matches ? matches[1] : ''; // Return the extracted URL
        },
    },
}
</script>

<template>
    <div class="featured-img" :class="classes" v-show="shouldShow && imageUrl">
        <img :src="imageUrl" alt="featured image"/>
    </div>
</template>

<style scoped lang="scss">
.featured-img {
    margin: 0 auto;
    width: 100%;
    max-width: 1640px;
    overflow: hidden;

    & img {
        width: 100%;
    }

    &.rounded-image {
        margin-bottom: 20px;
        border-radius: 20px;
    }
}
</style>
