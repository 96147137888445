<template>
    <select v-model="internalOrderKey">
        <template v-for="option in options">
            <option :key="option.id"
                    :hidden="!option.value"
                    :value="option" >
                {{ option.text }}
            </option>
        </template>
    </select>
</template>

<script>
import {composeUrlFromQueryVariables, getQueryParameterValue, translate as t} from "../utils/utils";
import { EventBus } from "../utils/eventBus";
export default {
    name: "SortBy",
    props: {
        sortParams: {
            type: Object,
        }
    },
    computed: {
        internalOrderKey: {
            get() {
                const temp = this.options.find(option =>
                    option.orderKey === this.sortParams.orderKey && option.order === this.sortParams.order)
                return temp ?? this.options[0];
            },
            set(newValue) {
                this.$emit("sortChanged", {
                    orderKey: newValue.orderKey,
                    order: newValue.order,
                })
            },
        },
    },
    data() {
        return {
            selectValue: "",
            options: [
                { id: 0, value: "", text: 'Sort by' },
                { id: 1, value: "priceGroupDesc", text: t('sort.priceDesc'), orderKey: "price", order: "desc" },
                { id: 2, value: "priceGroupAsc", text: t('sort.priceAsc'), orderKey: "price", order: "asc" },
                { id: 3, value: "publishedDateDesc", text: t('sort.dateDesc'), orderKey: "publishedDate", order: "desc" },
                { id: 4, value: "publishedDateAsc", text: t('sort.dateAsc'), orderKey: "publishedDate", order: "asc" },
                /*  { id: 3, value: "colorBlockCountAsc", text: t('sort.colorsAsc'), orderKey: "colorBlockCount", order: "asc" },*/
                /*{ id: 4, value: "colorBlockCountDesc", text: t('sort.colorsDesc'), orderKey: "colorBlockCount", order: "desc" },*/
                /*{ id: 5, value: "stitchCountAsc", text: t('sort.stitchcountAsc'), orderKey: "stitchCount", order: "asc" },*/
                /*{ id: 6, value: "stitchCountDesc", text: t('sort.stitchcountDesc'), orderKey: "stitchCount", order: "desc" },*/
                /*{ id: 7, value: "nameAsc", text: t('sort.nameAsc'), orderKey: "name", order: "asc" },*/
                /*{ id: 8, value: "nameDesc", text: t('sort.nameDesc'), orderKey: "name", order: "desc" },*/
                /*{ id: 9, value: "sizeAsc", text: t('sort.sizeAsc'), orderKey: "size", order: "asc" },*/
                /*{ id: 10, value: "sizeDesc", text: t('sort.sizeDesc'), orderKey: "size", order: "desc" }*/
            ],
        }
    },
    methods: {
        orderKeyChange() {
            this.$emit("sortChanged", {
                orderKey: this.internalOrderKey.orderKey,
                order: this.internalOrderKey.order,
            })
        },
    },
}

</script>
