var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "item-listing-page" }, [
    _c("div", { staticClass: "item-listing-page-sub-header-container" }, [
      _c("h2", [_vm._v(_vm._s(_vm.t("favorites.count", { hits: _vm.hits })))]),
      _vm._v(" "),
      _c("div", { staticClass: "item-listing-page-search-sort-container" }, [
        _c(
          "div",
          { staticClass: "search-input-wrapper" },
          [
            _c("DesignSearchInput", {
              attrs: {
                searchPlaceholder:
                  _vm.t("search.searchin") +
                  " " +
                  _vm.t("search.filter.searchIn.myFavorites"),
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "simple-sort-wrapper" },
          [
            _c("SimpleSortBy", {
              on: { "sort-changed": _vm.handleSortChange },
            }),
          ],
          1
        ),
      ]),
    ]),
    _vm._v(" "),
    !_vm.loading
      ? _c("div", [
          _c("div", { staticClass: "page-margin mt-4n" }, [
            _c("div", { staticClass: "col-md-12 col-lg-12 p-0" }, [
              _vm.searchResult.length > 0
                ? _c(
                    "div",
                    { staticClass: "row" },
                    _vm._l(_vm.searchResult, function (design) {
                      return _c(
                        "div",
                        { key: design.sku, staticClass: "col-md-3 col-6 mt-4" },
                        [_c("ListEntity", { attrs: { entity: design } })],
                        1
                      )
                    }),
                    0
                  )
                : _vm._e(),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "search-count-result-wrapper" }, [
            _c(
              "p",
              {
                staticClass: "result-length",
                staticStyle: { padding: "10px" },
              },
              [
                _vm._v(
                  _vm._s(
                    _vm.t("search.results.showing", {
                      current: _vm.searchResult.length,
                      total: _vm.hits,
                    })
                  )
                ),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "storage-bar" }, [
              _c("span", {
                staticClass: "storage-bar-fill",
                style: { width: _vm.searchWidth + "%" },
                attrs: { id: "storage-bar-fill" },
              }),
            ]),
          ]),
        ])
      : _c("div", [
          _c(
            "div",
            {
              staticClass:
                "loading-container d-flex align-items-center justify-content-center",
            },
            [
              _c(
                "div",
                { staticClass: "spinner-grow", attrs: { role: "status" } },
                [
                  _c("span", { staticClass: "sr-only" }, [
                    _vm._v(_vm._s(_vm.t("search.loading"))),
                  ]),
                ]
              ),
            ]
          ),
        ]),
    _vm._v(" "),
    !_vm.loading && _vm.hits > _vm.pageSize + _vm.offset
      ? _c(
          "div",
          {
            staticClass: "pink-btn view-more m-auto c-pointer",
            on: { click: _vm.loadMoreClick },
          },
          [_c("i", [_vm._v(_vm._s(_vm.t("viewmore")))])]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.loadingMore
      ? _c(
          "div",
          {
            staticClass:
              "loading-container d-flex align-items-center justify-content-center",
          },
          [
            _c(
              "div",
              { staticClass: "spinner-grow", attrs: { role: "status" } },
              [
                _c("span", { staticClass: "sr-only" }, [
                  _vm._v(_vm._s(_vm.t("search.loading"))),
                ]),
              ]
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }