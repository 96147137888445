<script>
import Modal from "./Modal";
import {
    EventBus
} from "../utils/eventBus";
import {translate} from "../utils/utils";
export default {
    name: "CollectionHelpDetails",
    methods: {
        openModal() {
            $(this.$el).modal('show');
        },
        closeModal() {
            this.$emit('closeModal');
        },
        t(key, variables) {
            return translate(key, variables)
        }
    },
    mounted: function() {
        EventBus.$on( 'OpenCollectionHelp', this.openModal );
    }

}
</script>

<template>
<div class="modal fade modal-library" id="collectionQuickHelp" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="collectionQuickHelpTitle" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" @click="closeModal" data-dismiss="modal" aria-label="Close"><img src="/static/icons/close.png">
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="modal-section">
                        <div class="header">
                            <img src="/static/icons/upload.svg" alt="Send">
                            <h5>{{ t('collection.modal_help.send_to_vault')}}</h5>
                        </div>
                        <div class="body">
                            <p v-html="t('collection.modal_help.send_to_vault_text')"></p>
                        </div>
                    </div>
                    <div class="modal-section">
                        <div class="header">
                            <img src="/static/icons/cart-black.svg" alt="Add to cart">
                            <h5>{{ t('collection.modal_help.add_to_cart')}}</h5>
                        </div>
                        <div class="body">
                            <p class="heading" v-html="t('collection_sale.tooltip.Add_To_Cart.head')"></p>
                            <p class="description" v-html="t('collection_sale.tooltip.Add_To_Cart.subHeading')"></p>
                            <p class="sub-heading" v-html="t('collection_sale.tooltip.Add_To_Cart.secondHeading')">"</p>
                            <p class="description" v-html="t('collection_sale.tooltip.Add_To_Cart.Description')"></p>
                        </div>
                    </div>
                    <div class="modal-section">
                        <div class="header">
                            <h5>{{ t('collection.modal_help.subscription_cta_title')}}</h5>
                        </div>
                        <div class="body">
                            <p class="heading" v-html="t('collection.modal_help.subscription.head')"></p>
                            <p class="description" v-html="t('collection.modal_help.subscription.subHeading')"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<style scoped lang="scss">
@import '../globals';

.modal-section {
    .header {
        padding: 32px 48px 10px 48px;
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        img {
            filter: brightness(0);
            -webkit-filter: brightness(0);
            margin-right: 1em;
            width: 18px;
            height: 18px;
        }
        h5 {
            text-transform: uppercase;
        }
    }
    .body {
        padding: 0 64px 32px 64px;
    }
}
</style>
