import store from "./index";
import { translatedAlertModal } from "../components/alert-modal";

const cart = {
    state: {
        cartItems: {
            messages: [],
            lineitems: [],
            cost: [],
            totalQuantity: 0,
        },
        designCodes: [],
    },
    getters: {
        cartItems (state) {
            return state.cartItems;
        },
        designCodes (state) {
            return state.designCodes;
        }
    },
    mutations: {
        updateCartItems(state, payload) {
            state.cartItems = payload.data;
        },
        updateDesignCodes(state, payload) {
            state.designCodes = payload.designCodes;
        }
    },
    actions: {
        fetchAndUpdateCart({ commit }) {
            const url = "/api/cart";
            $.ajax({
                url: url,
                method: "GET",
                beforeSend: xhr => {
                    xhr.setRequestHeader(
                        "Cache-Control",
                        "no-store, no-cache, must-revalidate, max-age=0"
                    );
                },
                success: data => {
                    let designCodes = [];
                    data.lineitems.forEach((e) => {
                        designCodes.push(e["designcode"])
                    })
                    commit('updateDesignCodes', {
                        designCodes
                    });
                    commit('updateCartItems', {
                        data
                    })
                },
                error: event => {
                    translatedAlertModal("cart.failed.headline","cart.failed.text");
                }
            });
        }
    }
}

export default cart;
