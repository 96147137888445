var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "simple-sort-container" }, [
    _c(
      "select",
      {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.internalOrderKey,
            expression: "internalOrderKey",
          },
        ],
        staticClass: "ms-btn-default m-0 pl-1 mr-2",
        staticStyle: { "font-weight": "bold" },
        on: {
          change: [
            function ($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function (o) {
                  return o.selected
                })
                .map(function (o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.internalOrderKey = $event.target.multiple
                ? $$selectedVal
                : $$selectedVal[0]
            },
            _vm.orderKeyChange,
          ],
        },
      },
      _vm._l(_vm.options, function (option) {
        return _c(
          "option",
          {
            key: option.id,
            staticClass: "optionP",
            domProps: { value: option.value },
          },
          [_vm._v(_vm._s(option.text))]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }