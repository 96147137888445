var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal fade modal-library",
      attrs: {
        id: "collectionQuickHelp",
        "data-bs-backdrop": "static",
        "data-bs-keyboard": "false",
        tabindex: "-1",
        "aria-labelledby": "collectionQuickHelpTitle",
        "aria-hidden": "true",
      },
    },
    [
      _c("div", { staticClass: "modal-dialog" }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "modal-header" }, [
            _c(
              "button",
              {
                staticClass: "close",
                attrs: {
                  type: "button",
                  "data-dismiss": "modal",
                  "aria-label": "Close",
                },
                on: { click: _vm.closeModal },
              },
              [_c("img", { attrs: { src: "/static/icons/close.png" } })]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "modal-body" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "modal-section" }, [
                _c("div", { staticClass: "header" }, [
                  _c("img", {
                    attrs: { src: "/static/icons/upload.svg", alt: "Send" },
                  }),
                  _vm._v(" "),
                  _c("h5", [
                    _vm._v(
                      _vm._s(_vm.t("collection.modal_help.send_to_vault"))
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "body" }, [
                  _c("p", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.t("collection.modal_help.send_to_vault_text")
                      ),
                    },
                  }),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "modal-section" }, [
                _c("div", { staticClass: "header" }, [
                  _c("img", {
                    attrs: {
                      src: "/static/icons/cart-black.svg",
                      alt: "Add to cart",
                    },
                  }),
                  _vm._v(" "),
                  _c("h5", [
                    _vm._v(_vm._s(_vm.t("collection.modal_help.add_to_cart"))),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "body" }, [
                  _c("p", {
                    staticClass: "heading",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.t("collection_sale.tooltip.Add_To_Cart.head")
                      ),
                    },
                  }),
                  _vm._v(" "),
                  _c("p", {
                    staticClass: "description",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.t("collection_sale.tooltip.Add_To_Cart.subHeading")
                      ),
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass: "sub-heading",
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.t(
                            "collection_sale.tooltip.Add_To_Cart.secondHeading"
                          )
                        ),
                      },
                    },
                    [_vm._v('"')]
                  ),
                  _vm._v(" "),
                  _c("p", {
                    staticClass: "description",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.t("collection_sale.tooltip.Add_To_Cart.Description")
                      ),
                    },
                  }),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "modal-section" }, [
                _c("div", { staticClass: "header" }, [
                  _c("h5", [
                    _vm._v(
                      _vm._s(
                        _vm.t("collection.modal_help.subscription_cta_title")
                      )
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "body" }, [
                  _c("p", {
                    staticClass: "heading",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.t("collection.modal_help.subscription.head")
                      ),
                    },
                  }),
                  _vm._v(" "),
                  _c("p", {
                    staticClass: "description",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.t("collection.modal_help.subscription.subHeading")
                      ),
                    },
                  }),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }