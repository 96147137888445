var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("g", { attrs: { id: "config", transform: "translate(-6 -6)" } }, [
    _c("path", {
      attrs: {
        id: "Vector",
        d: "M36,14H26",
        fill: "none",
        stroke: "#000",
        "stroke-linecap": "round",
        "stroke-linejoin": "round",
        "stroke-width": "2",
      },
    }),
    _vm._v(" "),
    _c("path", {
      attrs: {
        id: "Vector-2",
        d: "M13,14H8",
        fill: "none",
        stroke: "#000",
        "stroke-linecap": "round",
        "stroke-linejoin": "round",
        "stroke-width": "2",
      },
    }),
    _vm._v(" "),
    _c("path", {
      attrs: {
        id: "Vector-3",
        d: "M21,14a4,4,0,1,1-4-4A4,4,0,0,1,21,14Z",
        fill: "none",
        stroke: "#000",
        "stroke-linecap": "round",
        "stroke-linejoin": "round",
        "stroke-width": "2",
      },
    }),
    _vm._v(" "),
    _c("path", {
      attrs: {
        id: "Vector-2-2",
        "data-name": "Vector",
        d: "M18,30H8",
        fill: "none",
        stroke: "#000",
        "stroke-linecap": "round",
        "stroke-linejoin": "round",
        "stroke-width": "2",
      },
    }),
    _vm._v(" "),
    _c("path", {
      attrs: {
        id: "Vector-2-3",
        "data-name": "Vector-2",
        d: "M36,30H31",
        fill: "none",
        stroke: "#000",
        "stroke-linecap": "round",
        "stroke-linejoin": "round",
        "stroke-width": "2",
      },
    }),
    _vm._v(" "),
    _c("path", {
      attrs: {
        id: "Vector-3-2",
        "data-name": "Vector-3",
        d: "M27,26a4,4,0,1,1-4,4A4,4,0,0,1,27,26Z",
        fill: "none",
        stroke: "#000",
        "stroke-linecap": "round",
        "stroke-linejoin": "round",
        "stroke-width": "2",
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }