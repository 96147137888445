<template>
<div class="item-listing-page">
    <div class="item-listing-page-section-divider"></div>
    <div class="item-listing-page-sub-header-container">
        <h2>{{ t("purchases.collections-count", { count: hits }) }}</h2>
        <div class="item-listing-page-search-sort-container">
            <div class="search-input-wrapper">
                <CollectionSearchInput :searchPlaceholder="t('search.searchin') + ' ' + t('search.filter.searchIn.myPurchases')"/>
            </div>
            <div class="simple-sort-wrapper">
                <SimpleSortBy @sort-changed="handleSortChange" />
            </div>
        </div>
    </div>

    <div v-if="!loading">
        <div class="page-margin mt-4n">
            <div class="col-md-12 col-lg-12 p-0">
                <div class="row" v-if="searchResult.length > 0">
                    <div v-for="collection in searchResult" :key="collection.sku" class="col-md-4 col-12 mt-4">
                        <ListEntity :entity="collection" :iscol="true" />
                    </div>
                </div>
            </div>
        </div>
        <div class="search-count-result-wrapper">
            <p style="padding:10px" class="result-length">{{ t('search.results.showing', { current: searchResult.length, total: hits }) }}</p>
            <div class="storage-bar">
                <span id="storage-bar-fill" :style="{ width: searchWidth + '%'}" class="storage-bar-fill"></span>
            </div>
        </div>
    </div>
    <div v-else>
        <div class="loading-container d-flex align-items-center justify-content-center">
            <div class="spinner-grow" role="status">
                <span class="sr-only">{{ t("search.loading") }}</span>
            </div>
        </div>    
    </div>
    <div v-if="!loading && hits > pageSize + offset" @click="loadMoreClick" class="pink-btn view-more m-auto c-pointer">
        <i>{{t('viewmore')}}</i>
    </div>

    <div v-if="loadingMore" class="loading-container d-flex align-items-center justify-content-center">
        <div class="spinner-grow" role="status">
            <span class="sr-only">{{ t("search.loading") }}</span>
        </div>
    </div>
</div>

</template>

<script>
import {
    composeUrlFromQueryVariables,
    generalErrorCallback,
    translate,
    handleFetchErrors
} from "../utils/utils";
import ListEntity from "./card/ListEntity.vue";
import LoadMore from "./LoadMore";
import SendDesign from "./SendDesign";
import CollectionSearchInput from "./CollectionSearchInput";
import _ from "lodash";
import SimpleSortBy from "./SimpleSortBy";

export default {
    name: "CollectionPurchases",
    data: () => ({
        errorState: false,
        offset: 0,
        pageSize: 12,
        orderkey: "",
        apiUrl: "/api/collections",
        hits: 0,
        searchResult: [],
        loading: false,
        loadingMore: false,
        search: "",
        queryData: {},
        sortChangeTriggered: false,
    }),
    components: {
        SimpleSortBy,
        ListEntity,
        LoadMore,
        SendDesign,
        CollectionSearchInput,
    },
    created: function () {
        this.debouncedFetchResult = _.debounce(() => {
            this.fetchCollectionResult();
        }, 500);

        this.fetchCollectionResult();
    },

    methods: {
        handleSortChange(orderObject) {
            this.sortChangeTriggered = true;
            this.$store.commit("updateCollectionQueryData", orderObject);
        },
        fetchMoreCollectionResult: function () {
            var url = composeUrlFromQueryVariables({
                ...this.queryData,
                limit: String(this.pageSize),
                myPurchases: "true",
                offset: String(this.offset),
            }, window.location.origin + this.apiUrl);

            this.errorState = false;
            this.loadingMore = true;

            $.ajax({
                url: url,
                method: "GET",
                dataType: "json",
                beforeSend: xhr => {
                    xhr.setRequestHeader(
                        "Cache-Control",
                        "no-store, no-cache, must-revalidate, max-age=0"
                    );
                },
                success: data => {
                    this.searchResult = [
                        ...this.searchResult,
                        ...data.collections.map(collection => {
                            return {
                                url: collection.collectionPageUrl,
                                ...collection
                            };
                        })
                    ];
                    this.hits = data.total;
                },
                error: event => {
                    this.errorState = true;
                    generalErrorCallback(event, url);
                },
                complete: event => {
                    this.loadingMore = false;
                }
            });
        },
        fetchCollectionResult: function () {
            this.searchResult = [];
            var url = composeUrlFromQueryVariables({
                ...this.queryData,
                limit: String(this.pageSize),
                myPurchases: "true",
            }, window.location.origin + this.apiUrl);

            this.errorState = false;
            this.loading = true;
            this.offset = 0;

            $.ajax({
                url: url,
                method: "GET",
                dataType: "json",
                beforeSend: xhr => {
                    xhr.setRequestHeader(
                        "Cache-Control",
                        "no-store, no-cache, must-revalidate, max-age=0"
                    );
                },
                success: data => {
                    if (!this.loading) {
                        this.searchResult = [];
                    }

                    this.searchResult = [
                        ...this.searchResult,
                        ...data.collections.map(collection => {
                            return {
                                url: collection.collectionPageUrl,
                                ...collection
                            };
                        })
                    ];

                    this.hits = data.total;
                },
                error: event => {
                    this.errorState = true;
                    generalErrorCallback(event, url);

                },
                complete: event => {
                    this.loading = false;
                }
            });
        },
        loadMoreClick() {
            this.offset = this.offset > 0 ? this.offset + this.pageSize : this.pageSize;
            this.fetchMoreCollectionResult();
        },
        t(key, variables) {
            return translate(key, variables)
        },
    },
    computed: {
        queryUpdate() {
            return this.$store.getters.collectionQueryData;
        },
        searchWidth() {
            return (100 * this.searchResult.length / this.hits);
        },
    },
    watch: {
        queryUpdate(queryData) {
            this.loading = true;
            this.queryData = queryData;

            if (this.sortChangeTriggered) {
                this.fetchCollectionResult();
                this.sortChangeTriggered = false;
            } else {
                this.debouncedFetchResult();
            }
        }
    },
    
}
</script>

<style scoped lang="scss">

</style>
