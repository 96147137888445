var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("g", { attrs: { id: "send", transform: "translate(-110 -6)" } }, [
    _c("rect", {
      attrs: {
        id: "Rectangle_23465",
        "data-name": "Rectangle 23465",
        width: "32",
        height: "32",
        transform: "translate(110 6)",
        fill: "none",
      },
    }),
    _vm._v(" "),
    _c("path", {
      attrs: {
        id: "Path_7384",
        "data-name": "Path 7384",
        d: "M112.789,21.748,137.741,8.191a1.522,1.522,0,0,1,2.241,1.568l-3.744,22.185a1.527,1.527,0,0,1-1.772,1.236,1.5,1.5,0,0,1-.313-.091l-21.208-8.627a1.5,1.5,0,0,1-.156-2.714Z",
        fill: "none",
        stroke: "#fff",
        "stroke-linecap": "round",
        "stroke-linejoin": "round",
        "stroke-width": "2",
      },
    }),
    _vm._v(" "),
    _c("path", {
      attrs: {
        id: "Path_7385",
        "data-name": "Path 7385",
        d: "M129.55,31.2l-5.255,4.481A1.4,1.4,0,0,1,122,34.6V28.179",
        fill: "none",
        stroke: "#fff",
        "stroke-linecap": "round",
        "stroke-linejoin": "round",
        "stroke-width": "2",
      },
    }),
    _vm._v(" "),
    _c("path", {
      attrs: {
        id: "Path_7389",
        "data-name": "Path 7389",
        d: "M121.8,28.064c.035-.1,8.347-9.816,8.347-9.816",
        fill: "none",
        stroke: "#fff",
        "stroke-linecap": "round",
        "stroke-linejoin": "round",
        "stroke-width": "2",
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }