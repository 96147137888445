var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "checkout-details payment" }, [
    _c(
      "div",
      {
        staticClass: "modal fade modal-library",
        attrs: {
          id: "checkoutDetailsLoginModal",
          "data-backdrop": "static",
          "data-keyboard": "false",
          tabindex: "-1",
          "aria-labelledby": "staticBackdropLabel",
          "aria-hidden": "true",
        },
      },
      [
        _c("div", { staticClass: "modal-dialog" }, [
          this.order.allowedCountry && !this.loggedIn
            ? _c("div", { staticClass: "modal-content" }, [
                _c("div", { staticClass: "modal-header" }, [
                  _c("h5", { staticClass: "modal-title" }, [
                    _vm._v(_vm._s(_vm.t("checkout.details.login-headline"))),
                  ]),
                  _vm._v(" "),
                  _vm._m(0),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "modal-body" }, [
                  _c("p", {
                    domProps: {
                      innerHTML: _vm._s(_vm.t("checkout.details.login-info")),
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "modal-footer" }, [
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { href: _vm.loginUrl },
                    },
                    [_vm._v(_vm._s(_vm.t("checkout.details.sign-in")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { href: _vm.registerUrl },
                    },
                    [_vm._v(_vm._s(_vm.t("checkout.details.register")))]
                  ),
                ]),
              ])
            : _c("div", { staticClass: "modal-content" }, [
                _c("div", { staticClass: "modal-header" }, [
                  _c("h5", { staticClass: "modal-title" }, [
                    _vm._v(
                      _vm._s(
                        _vm.t("checkout.details.login-headline.notAllowed")
                      )
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "modal-body" }, [
                  _c("p", [
                    _vm._v(
                      _vm._s(_vm.t("checkout.details.login-info.notAllowed"))
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "modal-footer" }, [
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { href: _vm.selfUrl },
                    },
                    [_vm._v(_vm._s(_vm.t("checkout.details.ok.notAllowed")))]
                  ),
                ]),
              ]),
        ]),
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "shopping-cart" }, [
        _vm.isLoading
          ? _c("div", { staticClass: "spinner-wrapper" }, [
              _c("div", {
                staticClass: "spinner-grow",
                attrs: { role: "status" },
              }),
            ])
          : _vm._e(),
        _vm._v(" "),
        !_vm.isLoading && _vm.order
          ? _c("div", { staticClass: "checkout-wrapper" }, [
              !_vm.order.lineitems.length
                ? _c("div", [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-12" }, [
                        _c("h3", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.t("checkout.empty.details.headline")
                            ),
                          },
                        }),
                        _vm._v(" "),
                        _c("p", {
                          staticClass: "order-details-information",
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.t("checkout.empty.details.information")
                            ),
                          },
                        }),
                        _vm._v(" "),
                        _c("p", { staticClass: "empty" }, [
                          _vm._v(_vm._s(_vm.t("checkout.details.empty"))),
                        ]),
                      ]),
                    ]),
                  ])
                : _c("div", { staticStyle: { position: "relative" } }, [
                    _vm.orderLoading
                      ? _c("div", { staticClass: "cart-loading" }, [
                          _c("div", { staticClass: "spinner-grow" }),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-md-12" }, [
                        _c("h3", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.t("checkout.details.headline")
                            ),
                          },
                        }),
                        _vm._v(" "),
                        _c("p", {
                          staticClass: "order-details-information",
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.t("checkout.details.information")
                            ),
                          },
                        }),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-md-7 product-wrapper" },
                        _vm._l(_vm.groupedItems, function (values, key) {
                          return _c(
                            "div",
                            { staticClass: "product-card-container" },
                            [
                              values.length > 0
                                ? _c("div", [
                                    _c(
                                      "div",
                                      { staticClass: "cart-section" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "cart-section-header",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.t(
                                                  "checkout.cart_header." + key
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _vm._l(values, function (item) {
                                          return _c("div", [
                                            item.extendedData
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass: "product-card",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "product-card-header",
                                                      },
                                                      [
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "design-name",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                item.nameofdesign
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "RemoveFromCart",
                                                          {
                                                            attrs: {
                                                              designCode:
                                                                item.designcode,
                                                            },
                                                          },
                                                          [
                                                            _c("img", {
                                                              staticClass:
                                                                "remove-from-cart-icon",
                                                              attrs: {
                                                                src: "/static/icons/cart-remove.png",
                                                                alt: "",
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c("img", {
                                                      staticClass:
                                                        "product-img",
                                                      attrs: {
                                                        src: item.extendedData
                                                          .thumbnail,
                                                        alt: "",
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("div", [
                                                      item.type == "collection"
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticStyle: {
                                                                position:
                                                                  "relative",
                                                                "text-align":
                                                                  "left",
                                                                display: "flex",
                                                              },
                                                            },
                                                            [
                                                              _c("img", {
                                                                staticStyle: {
                                                                  width: "20px",
                                                                },
                                                                attrs: {
                                                                  src: "/static/icons/collection-1.svg",
                                                                  alt: "",
                                                                },
                                                              }),
                                                              _vm._v(" "),
                                                              item.extendedData
                                                                .totalDesigns
                                                                ? _c("div", [
                                                                    _c(
                                                                      "p",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            "padding-top":
                                                                              "13px",
                                                                            "padding-left":
                                                                              "10px",
                                                                          },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            item
                                                                              .extendedData
                                                                              .totalDesigns
                                                                          ) +
                                                                            " " +
                                                                            _vm._s(
                                                                              _vm.t(
                                                                                "Designs in this collection"
                                                                              )
                                                                            )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ])
                                                                : _c("div", [
                                                                    _c(
                                                                      "p",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            "padding-top":
                                                                              "13px",
                                                                            "padding-left":
                                                                              "10px",
                                                                          },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.t(
                                                                              "Collection"
                                                                            )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "product-description",
                                                        },
                                                        [
                                                          item.extendedData
                                                            .colorBlockCount
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "design-color-count",
                                                                },
                                                                [
                                                                  _c("img", {
                                                                    attrs: {
                                                                      src: "/static/icons/colors.svg",
                                                                      alt: _vm.t(
                                                                        "design-page.color-block-count"
                                                                      ),
                                                                    },
                                                                  }),
                                                                  _vm._v(" "),
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item
                                                                          .extendedData
                                                                          .colorBlockCount
                                                                      )
                                                                    ),
                                                                  ]),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          _vm._v(" "),
                                                          item.extendedData
                                                            .stitchCount
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "design-stitch-count",
                                                                },
                                                                [
                                                                  _c("img", {
                                                                    attrs: {
                                                                      src: "/static/icons/stitch.svg",
                                                                      alt: _vm.t(
                                                                        "design-page.stitch-count"
                                                                      ),
                                                                    },
                                                                  }),
                                                                  _vm._v(" "),
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item
                                                                          .extendedData
                                                                          .stitchCount
                                                                      )
                                                                    ),
                                                                  ]),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          _vm._v(" "),
                                                          item.extendedData
                                                            .craftingHeight
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "design-stitch-count",
                                                                },
                                                                [
                                                                  _c("img", {
                                                                    attrs: {
                                                                      src: "/static/icons/stitch.svg",
                                                                      alt: _vm.t(
                                                                        "design-page.stitch-count"
                                                                      ),
                                                                    },
                                                                  }),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "CraftingDesignDimensions",
                                                                    {
                                                                      attrs: {
                                                                        heightinmm:
                                                                          item
                                                                            .extendedData
                                                                            .craftingHeight,
                                                                        widthinmm:
                                                                          item
                                                                            .extendedData
                                                                            .craftingWidth,
                                                                        lengthtinmm:
                                                                          item
                                                                            .extendedData
                                                                            .craftingLength,
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                          _vm._v(" "),
                                                          item.extendedData
                                                            .height
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "design-height",
                                                                },
                                                                [
                                                                  _c("img", {
                                                                    staticStyle:
                                                                      {
                                                                        width:
                                                                          "20px !important",
                                                                        height:
                                                                          "20px !important",
                                                                      },
                                                                    attrs: {
                                                                      src: "/static/icons/height-arrow.png",
                                                                      alt: _vm.t(
                                                                        "design-page.design-height"
                                                                      ),
                                                                    },
                                                                  }),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "DesignDimensions",
                                                                    {
                                                                      attrs: {
                                                                        dimensioninmm:
                                                                          _vm._f(
                                                                            "dimensionToString"
                                                                          )(
                                                                            item
                                                                              .extendedData
                                                                              .height
                                                                          ),
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                          _vm._v(" "),
                                                          item.extendedData
                                                            .width
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "design-width",
                                                                },
                                                                [
                                                                  _c("img", {
                                                                    staticStyle:
                                                                      {
                                                                        width:
                                                                          "20px !important",
                                                                        height:
                                                                          "20px !important",
                                                                      },
                                                                    attrs: {
                                                                      src: "/static/icons/width-arrow.png",
                                                                      alt: _vm.t(
                                                                        "design-page.design-width"
                                                                      ),
                                                                    },
                                                                  }),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "DesignDimensions",
                                                                    {
                                                                      attrs: {
                                                                        dimensioninmm:
                                                                          _vm._f(
                                                                            "dimensionToString"
                                                                          )(
                                                                            item
                                                                              .extendedData
                                                                              .width
                                                                          ),
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                          _vm._v(" "),
                                                          item.extendedData
                                                            .designsCount
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "design-count",
                                                                  staticStyle: {
                                                                    left: "15px",
                                                                    position:
                                                                      "relative",
                                                                  },
                                                                },
                                                                _vm._l(
                                                                  item
                                                                    .extendedData
                                                                    .designsCount,
                                                                  function (
                                                                    value,
                                                                    key
                                                                  ) {
                                                                    return _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "design-tag",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "design-type",
                                                                          },
                                                                          [
                                                                            key ===
                                                                            "embroidery"
                                                                              ? _c(
                                                                                  "img",
                                                                                  {
                                                                                    staticStyle:
                                                                                      {
                                                                                        width:
                                                                                          "15px",
                                                                                      },
                                                                                    attrs:
                                                                                      {
                                                                                        src: "/static/icons/emb-1.svg",
                                                                                        alt: "",
                                                                                      },
                                                                                  }
                                                                                )
                                                                              : _vm._e(),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            key ===
                                                                            "crafting"
                                                                              ? _c(
                                                                                  "img",
                                                                                  {
                                                                                    staticStyle:
                                                                                      {
                                                                                        width:
                                                                                          "15px",
                                                                                      },
                                                                                    attrs:
                                                                                      {
                                                                                        src: "/static/icons/crafting-1.svg",
                                                                                        alt: "",
                                                                                      },
                                                                                  }
                                                                                )
                                                                              : _vm._e(),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            key ===
                                                                            "font"
                                                                              ? _c(
                                                                                  "IconsBase",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        "icon-color":
                                                                                          "black",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "fonts-icon"
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              : _vm._e(),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "i",
                                                                              {
                                                                                staticClass:
                                                                                  "design-tag-count",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "(" +
                                                                                    _vm._s(
                                                                                      value
                                                                                    ) +
                                                                                    ")"
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ]
                                                                    )
                                                                  }
                                                                ),
                                                                0
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      item.type == "embroidery"
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticStyle: {
                                                                position:
                                                                  "relative",
                                                                "text-align":
                                                                  "left",
                                                                display: "flex",
                                                              },
                                                            },
                                                            [
                                                              _c("img", {
                                                                staticStyle: {
                                                                  width: "20px",
                                                                },
                                                                attrs: {
                                                                  src: "/static/icons/emb-1.svg",
                                                                  alt: "",
                                                                },
                                                              }),
                                                              _vm._v(" "),
                                                              _c(
                                                                "p",
                                                                {
                                                                  staticStyle: {
                                                                    "padding-top":
                                                                      "13px",
                                                                    "padding-left":
                                                                      "10px",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.t(
                                                                        "Embroidery Designs"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      item.type == "crafting"
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticStyle: {
                                                                position:
                                                                  "relative",
                                                                left: "10px",
                                                                "text-align":
                                                                  "left",
                                                                display: "flex",
                                                              },
                                                            },
                                                            [
                                                              _c("img", {
                                                                staticStyle: {
                                                                  width: "20px",
                                                                },
                                                                attrs: {
                                                                  src: "/static/icons/crafting-1.svg",
                                                                  alt: "",
                                                                },
                                                              }),
                                                              _vm._v(" "),
                                                              _c(
                                                                "p",
                                                                {
                                                                  staticStyle: {
                                                                    "padding-top":
                                                                      "13px",
                                                                    "padding-left":
                                                                      "10px",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.t(
                                                                        "crafting"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      item.type == "font"
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticStyle: {
                                                                position:
                                                                  "relative",
                                                                left: "10px",
                                                                "text-align":
                                                                  "left",
                                                                display: "flex",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "IconsBase",
                                                                {
                                                                  staticStyle: {
                                                                    top: "2px",
                                                                    position:
                                                                      "relative",
                                                                  },
                                                                  attrs: {
                                                                    "icon-color":
                                                                      "black",
                                                                    width:
                                                                      "20px",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "fonts-icon"
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "p",
                                                                {
                                                                  staticStyle: {
                                                                    "padding-left":
                                                                      "10px",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.t(
                                                                        "Fonts"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      item.extendedData
                                                        .categories &&
                                                      item.extendedData
                                                        .categories.length >
                                                        0 &&
                                                      item.type !== "collection"
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "design-categories",
                                                            },
                                                            _vm._l(
                                                              item.extendedData
                                                                .categories,
                                                              function (
                                                                category
                                                              ) {
                                                                return _c(
                                                                  "div",
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "design-category",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            category
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                            0
                                                          )
                                                        : _vm._e(),
                                                    ]),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "product-price",
                                                      },
                                                      [
                                                        _c("p", [
                                                          _vm._v(
                                                            _vm._s(
                                                              item.listPrice
                                                                .display
                                                            )
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : _c("div", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "product-card",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "product-card-header",
                                                        },
                                                        [
                                                          _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "design-name",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.nameofdesign
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "RemoveFromCart",
                                                            {
                                                              attrs: {
                                                                designCode:
                                                                  item.designcode,
                                                              },
                                                            },
                                                            [
                                                              _c("img", {
                                                                staticClass:
                                                                  "remove-from-cart-icon",
                                                                attrs: {
                                                                  src: "/static/icons/cart-remove.png",
                                                                  alt: "",
                                                                },
                                                              }),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c("img", {
                                                        staticClass:
                                                          "product-img",
                                                        attrs: {
                                                          src: item.thumbnail,
                                                          alt: "",
                                                        },
                                                      }),
                                                      _vm._v(" "),
                                                      _c("div", {
                                                        staticClass:
                                                          "product-description",
                                                      }),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "product-price",
                                                        },
                                                        [
                                                          _c("p", [
                                                            _vm._v(
                                                              _vm._s(
                                                                item.listPrice
                                                                  .display
                                                              )
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]),
                                          ])
                                        }),
                                      ],
                                      2
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          )
                        }),
                        0
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-md-5 order-details-wrapper" },
                        [
                          _c(
                            "div",
                            { staticClass: "price-wrapper" },
                            [
                              _c("Summary", {
                                attrs: { "data-vue": "", step: "details" },
                              }),
                              _vm._v(" "),
                              _c(
                                "checkoutaccordion",
                                {
                                  attrs: {
                                    title: _vm.t("checkout.details.coupons"),
                                    "is-open": true,
                                  },
                                },
                                [
                                  _c("Coupons", {
                                    attrs: { "with-title": false },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "design-format-wrapper" },
                                [
                                  !_vm.loggedIn
                                    ? _c("div", {
                                        staticClass:
                                          "disabled-preferred-format",
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "format-info" }, [
                                    _c(
                                      "div",
                                      { staticClass: "format-info-header" },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: "/static/icons/emb-1.svg",
                                            alt: "",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("h4", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.t(
                                                "checkout.details.format-header-embroidery"
                                              )
                                            )
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.t("checkout.details.format-info")
                                        )
                                      ),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "mb-3 form-check design-select",
                                    },
                                    [
                                      _c("PreferredFormat", {
                                        attrs: { isCheckout: true },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "mb-3 form-check design-check",
                                    },
                                    [
                                      _c("input", {
                                        staticClass: "form-check-input",
                                        attrs: {
                                          type: "checkbox",
                                          id: "save-format",
                                        },
                                        domProps: { checked: _vm.saveFormat },
                                        on: { change: _vm.formatSaveHandler },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "label",
                                        {
                                          staticClass: "form-check-label term",
                                          attrs: { for: "save-format" },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.t(
                                                "checkout.details.save-format"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "crafting-format-wrapper" },
                                [
                                  !_vm.loggedIn
                                    ? _c("div", {
                                        staticClass:
                                          "disabled-preferred-format",
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "format-info" }, [
                                    _c(
                                      "div",
                                      { staticClass: "format-info-header" },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: "/static/icons/crafting-1.svg",
                                            alt: "",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("h4", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.t(
                                                "checkout.details.format-header-craftingcutting"
                                              )
                                            )
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("p", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.t(
                                            "checkout.details.format-crafting-info"
                                          )
                                        ),
                                      },
                                    }),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "mb-3 form-check design-select",
                                    },
                                    [
                                      _c("PreferredCraftingFormat", {
                                        attrs: { isCheckout: true },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("p", { staticClass: "cart-instruction" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "cart-instruction-link",
                                    attrs: {
                                      href: "https://mysewnet.com/en/join-mysewnet/mysewnet-enabled-machines/",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.t("checkout.details.download-info")
                                      )
                                    ),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "row" }, [
                                _c(
                                  "div",
                                  { staticClass: "btn-wrapper-profile" },
                                  [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "card-detals-btn pink-btn",
                                        attrs: { disabled: !_vm.canProceed },
                                        on: {
                                          click: function ($event) {
                                            return _vm.changeStep("Billing")
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                            " +
                                            _vm._s(
                                              _vm.t(
                                                _vm.isFreeOrder
                                                  ? "checkout.billing.headline"
                                                  : "checkout.details.proceed-to-payment"
                                              )
                                            ) +
                                            "\n                                        "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]),
                  ]),
            ])
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "close",
        staticStyle: { color: "white" },
        attrs: {
          type: "button",
          "data-dismiss": "modal",
          "aria-label": "Close",
        },
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }