<template>
<div @click="onClick" class="scroll-arrow-navigator">
    <i v-if="right" class="fas fa-chevron-right right"></i>
    <i v-if="left" class="fas fa-chevron-left left"></i>
</div>
</template>

<script>
import $ from "jquery";

export default {
    name: "ScrollArrow",
    methods: {
        onClick: function (event) {
            var scrollElement = $(this.scrollselector);
            var item = scrollElement.find('[class^="col"]'); // item in the scrollable div to calculate scroll size
            if (this.left) {
                scrollElement.animate({
                    scrollLeft: scrollElement.scrollLeft() - item.outerWidth()
                }, "slow");
            } else if (this.right) {
                scrollElement.animate({
                    scrollLeft: scrollElement.scrollLeft() + item.outerWidth()
                }, "slow");
            }
        }
    },
    props: {
        scrollselector: String,
        left: Boolean,
        right: Boolean
    }
}
</script>
