<template>
    <div class="card-item">
        <div class="card-item-header">
            <a :href="generatedURL">
                <img
                    :src="entity.thumbnail"
                    lazy="loaded">
            </a>

            <div class="card-item-header__icon left">
                <IconsBase :iconName="type" icon-color="#fff">
                    <collection-icon v-if="type === 'collection'" />
                    <embroidery-icon v-if="type === 'embroidery'"/>
                    <fonts-icon v-if="type === 'fonts'"/>
                    <crafting-icon v-if="type === 'crafting'"/>
                </IconsBase>
            </div>
            <div class="card-item-header__icon right" @click="onFavoriteClick">
                <IconsBase iconName="Favorite" v-if="!isFavorite">
                    <favorite-icon />
                </IconsBase>
                <IconsBase iconName="Favorite" v-else iconColor="#C61A5A">
                    <favorite-active-icon />
                </IconsBase>
            </div>
        </div>
        <div class="card-item-body">
            {{ entity.name }}
        </div>
        <div class="card-item-footer">
            <template v-if="!entity.isCollection && entity.designtype != 'font'">
                <SendToMachineButton :sku="entity.sku" :is-purchased="entity.isPurchased" :designname="entity.label" :designimage="entity.thumbnail" :designtype="entity.designtype" />
            </template>
            
            <SaveDesignVaultButton v-if="entity.designtype != 'font'" :designtype="entity.designtype" :iscollection="entity.isCollection ?'true':'false'" :designimage="entity.thumbnail || noImageUrl" :designname="entity.label" :sku="entity.priceGroup" :is-purchased="entity.isPurchased" :designs="computedDesigns"  :price-display="entity.price ? entity.price.display : ''" :allowedCountry="showPrice" :designCode="entity.sku" :nameOfDesign="entity.name" />
            <DownloadButton  v-if="canDownload && entity.designtype != 'font'" :image="entity.thumbnail || noImageUrl" :sku="entity.sku" :name="entity.label" :designtype="entity.designtype" :isCollection="entity.isCollection"  />
            <PurchasedTag v-if="entity.isPurchased"/>

            <AddToCart v-if="entity.isAvailableAsSinglePurchase && !entity.isPurchased" :skuCode="entity.priceGroup" :designCode="entity.sku" :price="entity.price" :nameOfDesign="entity.name" :allowedCountry="entity.allowedCountry" :free="free" :iscol="entity.isCollection" :itemtype="entity.designtype" />
        </div>
    </div>
</template>

<script>
import { subscriptionModal } from "../../components/subscription-modal";
import { switchFavorite, isLoggedIn, translate, userHasSubscription } from '../../utils/utils';
import { EventBus } from "../../utils/eventBus";
import SendToMachineButton from '../SendToMachineButton.vue';
import SaveDesignVaultButton from '../SaveDesignVaultButton.vue'
import { loginModal } from "../../components/login-modal";

import AddToCart from "../AddToCart.vue";
import DownloadButton from "../DownloadButton.vue";
import PurchasedTag from '../icons/common/PurchasedTag.vue';

import Modal from "../Modal.vue";
import DownloadDesign from "../DownloadDesign.vue";
import { mapGetters, mapMutations } from 'vuex'
import store from "../../store";
import { translatedAlertModal } from "../../components/alert-modal";
import IconsBase from "../icons/IconsBase.vue"
import FavoriteIcon from "../icons/common/FavoriteIcon.vue";
import ShoppingCartIcon from "../icons/common/ShoppingCartIcon.vue";
import CollectionIcon from "../icons/common/CollectionIcon.vue";
import EmbroideryIcon from "../icons/common/EmbroideryIcon.vue";
import FontsIcon from "../icons/common/FontsIcon.vue";
import CraftingIcon from "../icons/common/CraftingIcon.vue";
import FavoriteActiveIcon from "../icons/common/FavoriteActiveIcon.vue";

export default {
    name: "ListEntity",
    props: {
        entity: Object,
    },
    data() {
        return {
            tempimage:this.entity.thumbnail,
            isFavorite:null,
            noImageUrl: "https://via.placeholder.com/600",
            isUpdating: false,
        }
    },

    components: {
        PurchasedTag,
        SendToMachineButton,
        SaveDesignVaultButton,
        AddToCart,
        DownloadButton,
        IconsBase,
        FavoriteIcon,
        ShoppingCartIcon,
        CollectionIcon,
        EmbroideryIcon,
        FontsIcon,
        CraftingIcon,
        FavoriteActiveIcon,

    },
    methods: {
        t(key) {
            return translate(key)
        },
        onFavoriteClick: function name(event) {
            event.preventDefault();

            if (this.isUpdating) {
                return;
            }

            if (isLoggedIn() === false) {
                loginModal();
                return;
            }

            this.isUpdating = true;

            const designEntity = this;
            var Type  ;
            if(this.entity.fontPageUrl != null && this.entity.fontPageUrl != ""){
                Type =  "font";

            }else{
                if(this.entity.isCollection){
                    Type="collection"
                }else{ Type =   this.entity.designtype;}
             

            }
            console.log(Type);
            const favoritePromise = switchFavorite(this.entity.sku, this.entity.isFavorite, Type);

            this.entity.isFavorite = !this.entity.isFavorite;
            this.isFavorite = !this.isFavorite;
            // Set as not loading when the fetch has finished.
            // We have to use finally .instead of .then as to not interfere
            // with the actual response of the fetch action.
            // As for now, we don't actually care about the result in this component.
            favoritePromise.finally(function () {
                designEntity.isUpdating = false;
            });

            // Inform parents that may be listening the the favoriteClick event
            this.$emit('favoriteClickEvent', favoritePromise, this.entity.sku);
        },

    },

    computed: {
        computedDesigns(){
            this.isFavorite = this.entity.isFavorite;
            if(this.entity.fontPageUrl != null && this.entity.fontPageUrl != ""){
                this.entity.designtype =  "font";
            }
            if(this.entity.designs){
                
                return (JSON.stringify(this.entity.designs));
            }
            else 
            {var emptydesigns=[];
                return (JSON.stringify(emptydesigns))}
        },
        free: function () {
            return (this.singlePurchase && this.entity.price && this.entity.price.price === 0);
        },
        canDownload: function () {
            return this.entity.isPurchased;
        },
        singlePurchase: function () {
            return !!(this.entity.isAvailableAsSinglePurchase && this.entity.priceGroup && this.entity.price);
        },
        hasSub: function () {
            return userHasSubscription();
        },
        sku() {
            return this.downloadSku;
        },
        showPrice: function () {
            return this.entity.allowedCountry;

        },
        generatedURL(){
            if(this.entity.isCollection){
                return this.entity.collectionPageUrl;
            }
            else if (typeof this.entity.fontPageUrl !== "undefined" && this.entity.fontPageUrl !== null){
                return this.entity.fontPageUrl;
            } else {
                return this.entity.designPageUrl;
            }
        },
        type() {
            if(this.entity.isCollection){
                return "collection";
            }
            else if (typeof this.entity.fontPageUrl !== "undefined" && this.entity.fontPageUrl !== null){
                return "fonts";
            } else 
            switch (this.entity.designtype) {
                case "embroidery": {
                    return "embroidery"
                }
                case "crafting": {
                    return "crafting"
                }
                default: {
                    return 'embroidery'
                }
            }
        },
    },
}
</script>

<style scoped lang="scss">
.card-item {
    --border: #D8D8D8;
    font-family: 'soleil-regular', sans-serif;
    border: 1px solid var(--border);
    border-radius: 12px;
    text-align: center;
    min-width: 224px;
    max-width: 398px;
    width: 100%;
    &-header {
        position: relative;
        
        justify-content: center;
        align-items: center;
        &__icon {
            position: absolute;
            cursor: pointer;
            top: 6px;
            background-color: rgb(179, 179, 179);
            border-radius: 8px;
            padding: 6px;
            &:hover {
                background-color: rgba(0, 0, 0, 0.325);
            }
            &.left {
                left: 6px;
            }
            &.right {
                right: 6px;
            }
        }
    }
    &-body {
        padding: 16px;
        font-size: 16px;
        height: 80px;
    }
    &-footer {
        min-height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 12px;
        padding: 6px 0;
        border-top: 1px solid var(--border);
    }
}
/* Tablet devices */
@media screen and (max-width: 1023px) {
    .card-item {
        max-width: 224px;
        min-width: 174px;
        &-body {
            padding-bottom: 0;
            font-size: 14px;
            height: 60px;
        }
        &-footer {
            border: none;
            gap: 8px;
            padding: 0;
        }
    }
}
/* Mobile devices */
@media screen and (max-width: 767px) {
    .card-item {
        min-width: 100px;
        &-body {
          font-size: 12px;
          height: 50px;
        }
    }
}
</style>
