<template>
<div class="row">
    <div class="container mb-4">
        <div class="search-result-wrapper">
            <div v-if="isLoading" class="loading-container d-flex align-items-center justify-content-center">
                <div class="spinner-grow" role="status">
                    <span class="sr-only">{{ t("search.loading") }}</span>
                </div>
            </div>
            <div v-else-if="currentList.length">
                <div class="mt-4">
                    <div class="page-margin w-100">
                        <div class="col-md-12 col-lg-12 p-0">
                            <div class="row">
                                <div v-for="result in currentList" :key="result.sku"
                                     class="col-lg-4 col-md-4 col-6 mt-4">
                                    <ListEntity :entity="result" @favoriteClickEvent="handleFavoriteClickEvent" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="isMoreResultsLoading" class="loading-container d-flex align-items-center justify-content-center">
                    <div class="spinner-grow" role="status">
                        <span class="sr-only">{{ t("search.loading") }}</span>
                    </div>
                </div>
                <div class="search-count-result-wrapper">
                    <p style="padding:10px" class="result-length">{{ t('search.results.showing', { current: dataList.length, total: total }) }}</p>
                    <div class="storage-bar">
                        <span id="storage-bar-fill" class="storage-bar-fill"></span>
                    </div>
                </div>
            </div>
            <div v-if="!isLoading && currentList.length === 0 ">
                {{ t("search.empty_result") }}
            </div>
        </div>
    </div>
<!--    <ScrollToTop />-->

</div>
</template>

<script>
import { translate, handleFetchErrors } from "../utils/utils";
import { alertModal } from "../components/alert-modal";
import ListEntity from "./card/ListEntity.vue";
import SaveDesignVault from "./SaveDesignVault";
import LoadMore from "./LoadMore";
import SendDesign from "./SendDesign";
// import ScrollToTop from './ScrollToTop';
export default {
    name: "SearchResult",
    data: () => ({
        tempScrollTop: null,
        currentList: [],
    }),
    components: {
        ListEntity,
        LoadMore,
        SendDesign,
        SaveDesignVault,
        // ScrollToTop,
    },
    props: {
        dataList: {
            type: Array,
            default: []
        },
        isGettingMore: {
            type: Boolean,
            default: false,
        },
        isLoading: {
            type: Boolean,
            default: true,
        },
        isMoreResultsLoading: {
            type: Boolean,
            default: false,
        },
        total: {
            type: Number,
            default: 0,
        },
    },
    watch: {
        dataList(newList, oldList ) {
                this.currentList = newList;
        }
    },
    created() {
        // Scroll to last position after navigating back to page
        if (this.tempScrollTop) {
            $window.scrollTop(this.tempScrollTop);
        }

        // Save scroll position
        document.addEventListener('scroll', (evt) => {
            this.tempScrollTop = $(window).scrollTop();
        }, {
            capture: false,
            passive: true
        });
    },
    computed: {
        // SearchWidth() {
        //     if(this.hits===0){
        //         this.viewAll(false);
        //     }
        //     return (100 * this.searchResult.length / this.$store.state.searchResult.hits);
        // },
        // showScrollToTop: function () {
        //     return this.searchResult.length > 4;
        // }
    },
    methods: {
        handleFavoriteClickEvent: function (favoritePromise, sku) {
            const that = this;

            favoritePromise.then(handleFetchErrors)
                .then(function (result) {
                    if (result.success === false) {
                        // Aborts and runs catch function
                        throw Error(result.error);
                    }

                    that.$store.commit("replaceSearchResult", that.searchResult);
                }).catch(function (error) {
                    // TODO show custom error message
                    alertModal();
                });
        },
        t(key, variables) {
            return translate(key, variables)
        },

    }
}
</script>

<style lang="scss">
@import "../globals";

div.search-result-wrapper {
    .loading-container {
        min-height: 100px;
    }
}
</style>
