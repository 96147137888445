<template>
  <div>
    <l-popper
        trigger="clickToOpen"
        :options="{
      placement: 'auto',
      modifiers: { offset: { offset: '0,10px' } }
    }">
        <div class="popper">
            <SubscriptionTooltip v-if="isOpenTooltip" />
            <SendToMachineTooltip  v-else
                                    :sku="sku" 
                                    :isPurchased="isPurchased" 
                                    :designname="designname" 
                                    :designtype="designtype" 
                                    :sendButtonEventId="sendButtonEventId"
            />
        </div>
        <l-button type="small"
                  slot="reference"
                  style="background-color:rgba(0, 0, 0, 0.3);"
                  @click.native="onClick()"
        >
            <IconsBase :iconName='t("send-to.heading-send-to")'>
                <send-icon/>
            </IconsBase>
        </l-button>
    </l-popper>
  </div>
</template>

<script>
import { EventBus } from "../utils/eventBus";
import IconsBase from "./icons/IconsBase.vue";
import SendIcon from "./icons/common/SendIcon.vue";
import LButton from "./buttons/LButton.vue";
import SubscriptionTooltip from "./tooltip/SubscriptionTooltip.vue";
import SendToMachineTooltip from "./tooltip/SendToMachineTooltip.vue";
import { translate, userHasSubscription } from "../utils/utils";
import Popper from 'vue-popperjs';
export default {
    name: "SendToMachineButtonItemDetails",
    data() {
        return {
            hasSub: !!userHasSubscription(),
            isOpenTooltip:false,
            sendButtonEventId: 'sendButtonPressedEvent_' + this._uid
        }
    },
    components: { SubscriptionTooltip,IconsBase, SendIcon, LButton ,'l-popper': Popper, SendToMachineTooltip ,EventBus },

    created: function () {
        this.currentImage = this.computedDesignImages[0];
    },
    computed: {
        computedDesignImages: function () {
            if (typeof this.designimage === 'string') {
                return JSON.parse(this.designimage);
            }

            return this.designimage;
        }
    },
    methods: {
        t(key) {
            return translate(key)
        },
        onClick: function (event) {
            EventBus.$emit(this.sendButtonEventId);
            if (this.shouldOfferSubscription) {
                this.isOpenTooltip = true
            } 
        
        },
        t(key, variables) {
            return translate(key, variables)
        }
    },
    props: {
        sku: String,
        isPurchased: Boolean,
        designname: String,
        designimage: String,
        designtype: String,
    },
}
</script>
