var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "32",
        height: "32",
        viewBox: "0 0 32 32",
      },
    },
    [
      _c("g", { attrs: { id: "speed", transform: "translate(618 -54)" } }, [
        _c("rect", {
          attrs: {
            id: "Rectangle_5533",
            "data-name": "Rectangle 5533",
            width: "32",
            height: "32",
            transform: "translate(-618 54)",
            fill: "none",
          },
        }),
        _vm._v(" "),
        _c("g", { attrs: { id: "sound" } }, [
          _c("path", {
            attrs: {
              id: "Vector-10",
              d: "M-616,78v6",
              fill: "none",
              stroke: "#555",
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
              "stroke-width": "2",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              id: "Vector-11",
              d: "M-609,75v9",
              fill: "none",
              stroke: "#555",
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
              "stroke-width": "2",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              id: "Vector-12",
              d: "M-602,70V84",
              fill: "none",
              stroke: "#555",
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
              "stroke-width": "2",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              id: "Vector-13",
              d: "M-595,66V84",
              fill: "none",
              stroke: "#555",
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
              "stroke-width": "2",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              id: "Vector-14",
              d: "M-588,56V84",
              fill: "none",
              stroke: "#555",
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
              "stroke-width": "2",
            },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }