var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      on: {
        mouseover: function ($event) {
          _vm.isHovering = true
        },
        mouseleave: function ($event) {
          _vm.isHovering = false
        },
      },
    },
    [
      _c(
        "a",
        {
          staticClass: "nav-link dropdown-toggle dropdown-arrow expanded",
          attrs: {
            href: "",
            id: "library",
            role: "button",
            "data-toggle": "dropdown",
            "aria-expanded": "false",
          },
        },
        [
          _c("img", {
            staticClass: "lang-icon",
            attrs: { src: _vm.getLanguageIcon(_vm.currentLanguage) },
          }),
        ]
      ),
      _vm._v(" "),
      _vm.isHovering
        ? _c(
            "div",
            {
              staticClass: "dropdown-locator drop-lang-menu-selector show",
              staticStyle: { left: "-161px" },
            },
            [
              _c(
                "ul",
                {
                  staticClass:
                    "dropdown-menu dropdown-menu-end dropdown-lang-menu",
                  attrs: { "aria-labelledby": "dropdownMenuButton1" },
                },
                [
                  _vm.supportedLanguage()
                    ? _c(
                        "div",
                        { staticClass: "current-country-wrapper" },
                        [
                          _c("li", [
                            _c(
                              "a",
                              {
                                staticClass:
                                  "dropdown-item current-country font-msn-semibold",
                                attrs: { href: "#" },
                              },
                              [_vm._v(_vm._s(_vm.t("languageDropdown.header")))]
                            ),
                          ]),
                          _vm._v(" "),
                          _vm._l(_vm.languages, function (language) {
                            return _c("li", { key: language.code }, [
                              _vm.currentLanguage == language.code
                                ? _c("a", [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "dropdown-item current-country-name font-msn-body",
                                      },
                                      [_vm._v(_vm._s(language.name))]
                                    ),
                                  ])
                                : _vm._e(),
                            ])
                          }),
                          _vm._v(" "),
                          _vm._m(0),
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.loading
                    ? _c("div", [
                        _c(
                          "div",
                          {
                            staticClass:
                              "filter-accordion-item dropdown-region",
                          },
                          [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "current-font dropdown-region-header font-msn-semibold",
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.t("languageDropdown.subHeader"))
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "filter-accordion-item__headingWrapper",
                              },
                              [
                                _c("p", {
                                  staticClass: "filter-accordion-item__label",
                                }),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    class: [
                                      "accordion-button",
                                      { collapsed: !_vm.shouldExpand1 },
                                    ],
                                    staticStyle: {
                                      "max-height": "12px !important",
                                      "font-weight": "bold",
                                      "font-size": "12px",
                                    },
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        _vm.shouldExpand1 = !_vm.shouldExpand1
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(
                                          _vm.t(
                                            "languageDropdown.content.europe"
                                          )
                                        ) +
                                        "\n                        "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.shouldExpand1
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "filter-accordion-item__content",
                                  },
                                  _vm._l(_vm.languages, function (language) {
                                    return _c("div", { key: language.code }, [
                                      !_vm.northAmerica(language.name) &&
                                      language.name
                                        ? _c(
                                            "a",
                                            {
                                              staticClass:
                                                "header-language-name-selector font-msn-body",
                                              class:
                                                _vm.currentLanguage ===
                                                language.code
                                                  ? "active"
                                                  : "",
                                              staticStyle: {
                                                padding: "10px",
                                                "padding-left": "30px",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.onClick(language)
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "font-size": "90%",
                                                    "font-weight": "normal",
                                                  },
                                                },
                                                [_vm._v(_vm._s(language.name))]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ])
                                  }),
                                  0
                                )
                              : _vm._e(),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.loading
                    ? _c("div", [
                        _c(
                          "div",
                          {
                            staticClass:
                              "filter-accordion-item dropdown-region",
                            staticStyle: { "border-radius": "8px !important" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "filter-accordion-item__headingWrapper",
                              },
                              [
                                _c("p", {
                                  staticClass: "filter-accordion-item__label",
                                }),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    class: [
                                      "accordion-button",
                                      { collapsed: !_vm.shouldExpand },
                                    ],
                                    staticStyle: {
                                      "max-height": "20px !important",
                                      "font-weight": "bold",
                                      "font-size": "12px",
                                    },
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        _vm.shouldExpand = !_vm.shouldExpand
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(
                                          _vm.t(
                                            "languageDropdown.content.northAmerica"
                                          )
                                        ) +
                                        "\n                        "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.shouldExpand
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "filter-accordion-item__content",
                                  },
                                  _vm._l(_vm.languages, function (language) {
                                    return _c("div", { key: language.code }, [
                                      _vm.northAmerica(language.name) &&
                                      language.name
                                        ? _c(
                                            "a",
                                            {
                                              staticClass:
                                                "header-language-name-selector font-msn-body",
                                              class:
                                                _vm.currentLanguage ===
                                                language.code
                                                  ? "active"
                                                  : "",
                                              staticStyle: {
                                                padding: "10px",
                                                "padding-left": "30px",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.onClick(language)
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "font-size": "90%",
                                                    "font-weight": "normal",
                                                  },
                                                },
                                                [_vm._v(_vm._s(language.name))]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ])
                                  }),
                                  0
                                )
                              : _vm._e(),
                          ]
                        ),
                        _vm._v(" "),
                        _vm._m(1),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.loading
                    ? _c("div", [
                        _c(
                          "div",
                          {
                            staticClass: "spinner-grow",
                            staticStyle: {
                              position: "relative",
                              left: "100px",
                              "max-height": "10px",
                              "max-width": "10px",
                            },
                            attrs: { role: "status" },
                          },
                          [
                            _c("span", { staticClass: "sr-only" }, [
                              _vm._v(_vm._s(_vm.t("search.loading"))),
                            ]),
                          ]
                        ),
                      ])
                    : _vm._e(),
                ]
              ),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [_c("hr", { staticClass: "dropdown-divider" })])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "countries-list" }, [
      _c("a", {
        staticClass: "font-msn-body",
        attrs: { href: "https://staging.mysewnet.com/en/country-list/" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }