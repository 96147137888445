var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.shouldShow
    ? _c("div", { staticClass: "area py-2 text-with-title" }, [
        _c("div", { staticClass: "content-wrapper" }, [
          _c("h3", [_vm._v(_vm._s(_vm.title))]),
          _vm._v(" "),
          _c("div", {
            style: { cursor: _vm.link ? "pointer" : "auto" },
            domProps: { innerHTML: _vm._s(_vm.description) },
            on: { click: _vm.navigateToLink },
          }),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }