var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("g", { attrs: { id: "emb", transform: "translate(-6 -6)" } }, [
    _c("path", {
      attrs: {
        id: "Path_64298",
        "data-name": "Path 64298",
        d: "M28.733,14.106a6.992,6.992,0,0,0-13.466,0",
        fill: "none",
        "stroke-linejoin": "round",
        "stroke-width": "2",
      },
    }),
    _vm._v(" "),
    _c("path", {
      attrs: {
        id: "Rectangle_23738",
        "data-name": "Rectangle 23738",
        d: "M2.333,0h0A11.667,11.667,0,0,1,14,11.667V23a0,0,0,0,1,0,0h0A14,14,0,0,1,0,9V2.333A2.333,2.333,0,0,1,2.333,0Z",
        transform: "translate(8 13)",
        fill: "none",
        "stroke-linejoin": "round",
        "stroke-width": "2",
      },
    }),
    _vm._v(" "),
    _c("path", {
      attrs: {
        id: "Rectangle_23739",
        "data-name": "Rectangle 23739",
        d: "M14,0h0a0,0,0,0,1,0,0V11.333A11.667,11.667,0,0,1,2.333,23h0A2.333,2.333,0,0,1,0,20.667V14A14,14,0,0,1,14,0Z",
        transform: "translate(36 36) rotate(-180)",
        fill: "none",
        "stroke-linejoin": "round",
        "stroke-width": "2",
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }