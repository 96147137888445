<template>
<div class="checkout p-md-4">
    <component :is="checkoutStep" @handleChange="changeStep"></component>
</div>
</template>

<script>
import {
    mapMutations
} from 'vuex'
import Details from "./Details";
import Billing from "./Billing";
import Payment from "./Payment";
import Confirmation from "./Confirmation";
import ConfirmationFree from "./ConfirmationFree";
import FailedFree from "./FailedFree";
import Failed from "./Failed";

export default {
    name: 'Checkout',
    components: {
        Details,
        Billing,
        Payment,
        Confirmation,
        ConfirmationFree,
        Failed,
        FailedFree,
    },
    data() {
        return {
            checkoutStep: "Details",
        }
    },
    methods: {
        changeStep: function (component) {
            console.log("changeStep component: " + component);
            this.checkoutStep = component;
        }
    },
    mounted() {
        window.checkout = this;
    }
};
</script>

<style lang="scss">
@import "../../globals";

.checkout {
    margin-top: 25px;
    @include media-breakpoint-down(sm) {   
        padding: 0 !important;
    }

    h3 {
        font-size: 1.4rem;
        font-weight: 600;
        color: $body-text-color;
    }

    .change-step-wrapper {
        display: flex;
        justify-content: space-between;
        margin-top: 1rem;

        .change-step {
            background: none;
            border: 0;
            text-transform: uppercase;
            font-weight: bold;
        }

        &.first-step {
            justify-content: flex-end;
        }
    }
}
</style>
