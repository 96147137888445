var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "32",
        height: "32",
        viewBox: "0 0 32 32",
      },
    },
    [
      _c(
        "g",
        { attrs: { id: "file_unknown", transform: "translate(-526 -6)" } },
        [
          _c("rect", {
            attrs: {
              id: "Rectangle_20871",
              "data-name": "Rectangle 20871",
              width: "32",
              height: "32",
              transform: "translate(526 6)",
              fill: "none",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              id: "Path_41838",
              "data-name": "Path 41838",
              d: "M556,19V33.2a2.8,2.8,0,0,1-2.8,2.8H530.8a2.8,2.8,0,0,1-2.8-2.8h0V10.8A2.8,2.8,0,0,1,530.8,8H545",
              fill: "none",
              stroke: "#555",
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
              "stroke-width": "2",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              id: "Path_41839",
              "data-name": "Path 41839",
              d: "M556,19h-8.25A2.75,2.75,0,0,1,545,16.25V8Z",
              fill: "none",
              stroke: "#555",
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
              "stroke-width": "2",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              id: "Path_64466",
              "data-name": "Path 64466",
              d: "M533,20a3,3,0,1,1,3,3v3",
              fill: "none",
              stroke: "#fff",
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
              "stroke-width": "2",
            },
          }),
          _vm._v(" "),
          _c("circle", {
            attrs: {
              id: "Ellipse_1097",
              "data-name": "Ellipse 1097",
              cx: "0.75",
              cy: "0.75",
              r: "0.75",
              transform: "translate(535.25 29.25)",
              fill: "none",
              stroke: "#fff",
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
              "stroke-width": "2",
            },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }