<template>
    <div class="container">
        <div class="search-page">
            <div class="search-page__box">
                <div class="place-holder">
                </div>
                <div class="search-header">
                    <div class="search-header__input">
                        <LSearchInput @search="searchTextChanged" :isOnSearchPage="isOnSearchPage"></LSearchInput>
                        <MobileFilterModal @searchWithFilter="searchWithFilter" @resetFilter="resetFilter"/>
                    </div>
                    <div class="search-header__info">
                        <div class="search-header__info-result" :style="{ opacity: searchParams.q ? 1 : 0 }">
                            There are <span>{{ totalResultCount }}</span> results found for
                            “<span>{{ searchParams.q }}</span>”
                        </div>
                        <div class="search-header__info-sort">
                            <SortBy
                                @sortChanged="sortChanged"
                                :sortParams="{
                                    orderKey: this.searchParams.orderKey,
                                    order: this.searchParams.order,
                                }"
                            />
                        </div>
                    </div>
                    <Tags @clearFilter="clearAllFilter"
                          @removeSpecificFilter="removeSpecificFilter"
                          :categoryList="selectedCategories"
                          :techniqueList="selectedTechniques"
                          :fontList="selectedFontType"
                          :collectionList="selectedCollections"
                    />
                </div>
            </div>
        </div>

        <div class="search-body">
            <div class="search-body-filter">
                <FilterForSearch @searchWithFilter="searchWithFilter" isReactive/>
            </div>
            <div class="search-body-result">
                <div class="category-list">
                    <MainFilters @filterChanged="setType"
                                    :total="totalResultCount"
                                    :activeFilter="searchParams.type? searchParams.type : 'all'" />
                </div>
                <SearchResult :dataList="searchResults"
                              :isLoading="resultsLoading"
                              :isMoreResultsLoading="isMoreResultsLoading"
                              :total="totalResultCount"
                              :isGettingMore="isGettingMore"
                />
                <div class="search-view-more" v-if="totalResultCount > searchResults.length">
                    <l-button color="pink" @click.native="getResults(true)">{{ t('viewmore') }}</l-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LSearchInput from "./input/LSearchInput.vue";
import SearchResult from "./SearchResult.vue";
import AccordionComponent from "./AccordionComponent.vue";
import LAccordion from "./LAccordion.vue";
import { composeUrlFromQueryVariables, generalErrorCallback, translate } from "../utils/utils";
import $ from "jquery";
import MobileFilterModal from "./pages/search/MobileFilterModal.vue";
import SortBy from "./SortBy.vue";
import Tags from "./Tags.vue";
import FilterForSearch from "./pages/search/FilterForSearch.vue";
import MainFilters from "./pages/search/MainFilters.vue";

const API_URL = "/api/v2/search";

export default {
    name: "SearchFilter",
    components: {
        Tags,
        SortBy,
        MobileFilterModal,
        SearchResult,
        LSearchInput,
        AccordionComponent,
        LAccordion,
        FilterForSearch,
        MainFilters
    },
    data: () => ({
        loading: true,
        errorState: true,
        resultsLoading: true,
        isMoreResultsLoading: true,
        searchResults: [],
        searchParams: {},
        totalResultCount: 0,
        tagList: [],
        categoryList: [],
        techniqueList: [],
        isGettingMore: false,
        fontTypes: [
            {
                id: 'cut',
                name: "cut"
            },
            {
                id: 'write',
                name: "write"
            },
        ],
        collectionTypes: [
            {
                id: 'embroidery_designs',
                name: "embroidery designs"
            },
            {
                id: 'crafting_images',
                name: "crafting images"
            },
            {
                id: 'crafting_projects',
                name: "crafting projects"
            },
            {
                id: 'fonts',
                name: "fonts"
            },
        ],
        operationTypes: [
            {
                id: 'cut',
                name: 'Cut'
            },
            {
                id: 'print_then_cut',
                name: 'Print and cut'
            },
            {
                id: 'engrave',
                name: 'Engrave'
            },
            {
                id: 'deboss',
                name: 'Deboss'
            },
            {
                id: 'pen',
                name: 'Pen'
            }
        ]
    }),
    mounted() {
        this.getSearchParams();
        this.getResults();
        this.getTechniqueFilterOptions();
        this.getCategoryFilterOptions();
        //this.getFontFilterOptions();
    },
    props: {
        searchUrl: String,
        isOnSearchPage: Boolean,
        availableFilters: {
            type: String,
        }
    },
    computed: {
        displayUnit() {
            return this.$store.state.displayUnit;
        },
        selectedCategories() {
    if (!this.searchParams.categories || !Array.isArray(this.searchParams.categories)) {
        return [];
    }

    const findSelectedCategories = (categories, selectedIds) => {
        return categories.reduce((result, category) => {
            if (selectedIds.includes(String(category?.id))) {
                result.push({ type: 'category', ...category });
            }

            if (category.categories && category.categories.length > 0) {
                const subCategories = findSelectedCategories(category.categories, selectedIds);
                result.push(...subCategories);
            }

            return result;
        }, []);
    };

    return findSelectedCategories(this.categoryList, this.searchParams.categories);
},
        selectedFontType() {
            if (!this.searchParams.fontType || !Array.isArray(this.searchParams.fontType)) {
                return [];
            }
            return this.fontTypes
                .filter(font => this.searchParams.fontType.includes(font.name))
                .map(font => ({type: 'fontType', ...font}));
        },
        selectedTechniques() {
            if (!this.searchParams.techniques || !Array.isArray(this.searchParams.techniques)) {
                return [];
            }
            return this.techniqueList
                .filter(technique => this.searchParams.techniques.includes(String(technique?.id)))
                .map(technique => ({type: 'technique', ...technique}));
        },
        selectedCollections() {
            if (!this.searchParams.typeOfContent || !Array.isArray(this.searchParams.typeOfContent)) {
                return [];
            }
            return this.collectionTypes
                .filter(collection => this.searchParams.typeOfContent.includes(String(collection?.id)))
                .map(collection => ({type: 'typeOfContent', ...collection}));
        },
        selectedCraftingOperations() {
            if (!this.searchParams.operationTypes || !Array.isArray(this.searchParams.operationTypes)) {
                return [];
            }
            return this.operationTypesList
                .filter(operation => this.searchParams.operationTypes.includes(String(operation?.id)))
                .map(operation => ({type: 'operationType', ...operation}));
        }
    },
    methods: {
        getSearchParams() {
            const params = this.getSearchParamsFromUrl()
            this.searchParams = {
                type: params.type?? "", // all
                q: params.q ?? "", // all
                orderKey: params.orderKey ?? "", // all
                order: params.order ?? "", // all
                categories: params.categories? params.categories.split(',') : [], // design
                techniques: params.techniques? params.techniques.split(',') : [], // design
                widthMin: params.widthMin?? "", // design
                widthMax: params.widthMax?? "", // design
                heightMin: params.heightMin?? "", // design
                heightMax: params.heightMax?? "", // design
                colorBlockCountMax: params.colorBlockCountMax?? "", // design
                stitchCountMax: params.stitchCountMax?? "", // design
                designtype: params.designtype?? "", // design
                craftingType: params.craftingType?? "", // design
                categoryType: params.categoryType?? "", // design
                fontType: params.fontType? params.fontType.split(',') : [], // fonts
                typeOfContent: params.typeOfContent? params.typeOfContent.split(',') : [], // collection
                operationType: params.operationType? params.operationType.split(',') : [], // crafting
            }
            this.$store.commit("setSearchParams", this.searchParams);
            this.$store.commit('setSearchType',  this.searchParams.type);
        },
        resetFilter() {
            this.clearAllFilter();
        },
        getSearchParamsFromUrl() {
            const url = new URL(window.location.href);
            const searchParams = url.searchParams;
            const paramsObject = {};
            for (const [key, value] of searchParams) {
                paramsObject[key] = value;
            }
            return paramsObject;
        },
        getResults(loadMore = false) {
            this.isGettingMore = loadMore;
            if (loadMore) {
                this.isMoreResultsLoading = true
            } else {
                this.resultsLoading = true
            }
            this.getCategoryFilterOptions();
            const url = composeUrlFromQueryVariables({
                ...this.getSearchParamsFromUrl(),
                offset: loadMore? this.searchResults.length : 0,
            }, window.location.origin + API_URL);
            $.ajax({
                url: url,
                method: "GET",
                dataType: "json",
                beforeSend: xhr => {
                    xhr.setRequestHeader(
                        "Cache-Control",
                        "no-store, no-cache, must-revalidate, max-age=0"
                    );
                },
                success: data => {
                    if (loadMore) {
                        this.searchResults = this.searchResults.concat(
                            data.hits.map(design => {
                                return {
                                    url: design.designPageUrl,
                                    isFavorite: false,
                                    ...design
                                }
                            })
                        )
                    } else {
                        this.searchResults = data.hits.map(design => {
                            return {
                                url: design.designPageUrl,
                                isFavorite: false,
                                ...design
                            }
                        })
                    }

                    this.totalResultCount = data.total
                },
                error: event => {
                    // TODO hide page here maybe or some modal with server error
                    //this.errorState = true;
                    generalErrorCallback(event, url);
                },
                complete: event => {
                    // finish load
                    this.resultsLoading = false;
                    this.isMoreResultsLoading = false;
                }
            });
        },
        searchWithFilter() {
            this.searchParams = this.$store.state.searchParams;
            this.updateUrl();
            this.getResults();
        },
        searchTextChanged(text) {
            this.searchParams.q = text
            this.updateUrl();
            this.getResults();
        },
        filterChanged() {
            this.updateUrl();
            this.getResults();
        },
        setType(selectedType) {
            this.searchParams.type = selectedType;
            this.clearAllFilter();
        },
        async onSubmit () {
            if (!this.loading) {
                this.loading = true;
                if (this.displayUnit === "inch") {
                    // inch To Mm
                }
                // this.filterSettings.heightMin = isNaN(parseFloat(this.filterSizeOptions.heightMin)) ?
                // "" : ((parseFloat(this.filterSizeOptions.heightMin)).toFixed(4)).toString();

                this.getResults();
                this.loading = false
            }
        },
        sortChanged({orderKey, order}) {
            this.searchParams.orderKey = orderKey;
            this.searchParams.order = order;
            this.updateUrl();
            this.getResults();
        },
        removeSpecificFilter(filter) {
            if (filter.type === 'category' || filter.categories) {
                this.searchParams.categories = this.searchParams.categories.filter(categoryId => categoryId !== String(filter?.id));
            }
            if (filter.type === 'technique') {
                this.searchParams.techniques = this.searchParams.techniques.filter(techniqueId => techniqueId !== String(filter?.id));
            }
            if (filter.type === 'typeOfContent') {
                this.searchParams.typeOfContent = this.searchParams.typeOfContent.filter(type => type !== filter?.id);
            }
            if (filter.type === "fontType") {
                this.searchParams.fontType = this.searchParams.fontType.filter(font => font !== filter?.id);
            }
            if (filter.type === "operationTypesList") {
                this.searchParams.operationTypesList = this.searchParams.operationTypesList.filter(operation => operation !== filter?.id);
            }
            this.filterChanged();
        },
        clearAllFilter() {
          // except type and search text
            const text = this.searchParams.q
            const type = this.searchParams.type
            for (const key in this.searchParams) {
                if (Array.isArray(this.searchParams[key])) {
                    this.searchParams[key] = [];
                } else {
                    this.searchParams[key] = "";
                }
            }
            this.searchParams.type = type;
            this.searchParams.q = text;
            this.$store.commit('setSearchType', type);
            this.updateUrl();
            this.getResults();
        },
        updateUrl() {
            window.history.replaceState({}, document.title, composeUrlFromQueryVariables(this.searchParams));
        },
        getCategoryFilterOptions() {
            const self = this;
            let url = window.location.origin + "/api/categories/all";
            if (this.searchParams.type !== "") {
                url += "?type=" + this.searchParams.type
            }
            self.errorState = false;
            self.loading = true;

            $.getJSON(
                url,
                (data) => {
                    this.categoryList = data.categories;
                    this.$store.commit("setCategoryList", data.categories);
                    self.loading = false;
                },
            ).fail(function (event) {
                self.errorState = true;
                generalErrorCallback(event, this.url);
            });
        },

        getTechniqueFilterOptions() {
            const url = window.location.origin + "/api/techniques/all";
            this.errorState = false;
            this.loading = true;

            $.getJSON(
                url,
                (data) => {
                    this.techniqueList = data.techniques;
                    this.$store.commit("setTechniqueList", data.techniques);
                    this.loading = false;
                },
            ).fail(function (event) {
                this.errorState = true;
                generalErrorCallback(event, this.url);
            });
        },
        decrementColorCount (){
            this.searchParams.colorBlockCountMax = this.searchParams.colorBlockCountMax > 1 ?
                this.decrementString(this.searchParams.colorBlockCountMax) : '1'
            this.onSubmit()
        },
        incrementColorCount (){
            this.searchParams.colorBlockCountMax = this.searchParams.colorBlockCountMax < 99 ?
                this.incrementString(this.searchParams.colorBlockCountMax): '99'
            this.onSubmit()
        },
        incrementString(s) {
            return s.length ? (parseInt(s) + 1).toString() : "1";
        },
        decrementString(s) {
            return s.length ? (parseInt(s) - 1).toString() : "1";
        },
        t(key, variables) {
            return translate(key, variables)
        },
        limit(event) {
            let keyCode = (event.keyCode ? event.keyCode : event.which);
            let _this = event.target;
            // Backspace, tab, right, left arrows
            const allowedKeys = [8, 9, 37, 39];

            if (allowedKeys.includes(keyCode)) {
                return true;
            }

            if (_this.value.length >= event.target.getAttribute("data-limit").length) {
                event.preventDefault(); // prevent other keyboard events and numbers beyond limit
                return false;
            }
        },
    }
}
</script>

<style lang="scss">
.search-view-more {
    display: flex;
    align-items: center;
    justify-content: center;
}
.filter-wrapper {
    text-align: center;
    flex-grow: 0;
    flex-shrink: 0;

    >i {
        cursor: pointer;
    }
}
.filter-wrapper {
    width: 50px;
    height: 40px;
    font-size: 24px;
    line-height: 40px;
}

@media(min-width: 768px) {

    // Desktop search
    .filter-wrapper {
        width: 40px;
        height: 40px;
        font-size: 18px;
        line-height: 40px;
    }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}</style>
<style scoped lang="scss">
.place-holder {
    width: 337px;
}
.search-page {
    padding: 10px;
    max-width: 1676px;
    margin: 0 auto;
    &__box {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        gap: 70px;
    }
}
.search-header {
    max-width: 1224px;
    width: 100%;
    border-bottom: 1px solid #70707033;
    text-align: right;
    padding: 16px 0 25px 0;
    margin-bottom: 24px;
    &__input {
        padding-bottom: 16px;
    }
    &__info {
        padding-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &-sort {
            width: 260px;
            & select {
                width: 100%;
                outline: none;
                padding: 12px;
                font-size: 16px;
                box-sizing: border-box;
                background-color: #fff;
                border: 1px solid #D8D8D8;
                border-radius: 12px;
                cursor: pointer;
            }
        }
        &-result {
            & span {
                font-weight: bold;
            }

        }
    }
}
.search-body {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 70px;
    padding: 0 10px;
    &-filter {
        width: 337px;
    }
    &-result {
        max-width: 1224px;
        width: 100%;
    }
}
.category-list {
    margin-bottom: 24px;
}
/* Tablet devices */
@media screen and (max-width: 1023px) {
    .place-holder {
        display: none;
    }
    .search-header {
        &__input {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 16px;
        }
        &__info {
            width:100%;
           // display: none;
        }
        &__active-filter {
            display: none;
        }
        &__info-result{
            display: none;
        }
        &__info-sort{
            width: 100%;
        }
    }
    .search-body {
        &-filter {
            display: none;
        }
    }
}
/* Mobile devices */
@media screen and (max-width: 767px) {
}
</style>
