<template>
<div class="checkout-details payment">
    <div class="container">
        <div class="shopping-cart">
            <div v-if="isLoading || isLoadingFreeOrder" class="spinner-wrapper">
                <div class="spinner-grow" role="status"></div>
            </div>
            <div v-if="!isLoading && !isLoadingFreeOrder && order" class="checkout-wrapper row">
                <div class="row">
                    <div class="col-md-6 left">
                        <h3>{{ t("checkout.billing.headline") }}</h3>
                        <p v-html='t("checkout.billing.text")'></p>
                        <form id='address-form' class="address-form">
                            <div class="mb-3" :class="{ 'invalid': !validateStreetAddress() }">
                                <label for="address" class="form-label">{{ t("checkout.billing.streetAddress") }}</label>
                                <input type="text" class="form-control" id="address" placeholder="your address" autocomplete="on" v-model="streetAddress" @change="clearCybersourceCallbackError('streetaddress')">
                                <span class="validation-message textarea" v-if="!validateStreetAddress()">{{ translateError('streetaddress') }}</span>
                            </div>
                            <div :class="{ 'invalid': !validateLocality() }" class="mb-3">
                                <label for="city" class="form-label">{{ t("checkout.billing.locality") }}</label>
                                <input type="text" class="form-control" id="city" placeholder="your city" autocomplete="on" v-model="locality" @change="clearCybersourceCallbackError('locality')">
                                <span class="validation-message" v-if="!validateLocality()">{{ translateError('locality') }}</span>
                            </div>
                            <div :class="{ 'invalid': !validateCountry() }" class="mb-3">
                                <label for="country" class="form-label">{{ t("checkout.billing.country") }}</label>
                                <select class="form-select" id="country" @change="changeCountry($event)" v-model="country">
                                    <option value="null">{{ t("checkout.billing.select-a-country") }}</option>
                                    <option v-for="country in countryOptions" :value="country.value" :key="country.value">
                                        {{ country.text }}
                                    </option>
                                </select>
                                <span class="validation-message" v-if="!validateCountry()">{{ translateError('country') }}</span>
                            </div>
                            <div :class="{ 'invalid': !validateRegion() }" class="mb-3">
                                <label for="state" class="form-label">{{ t("checkout.billing.region") }}</label>
                                <select class="form-select" id="state" autocomplete="on" v-model="region" :disabled="!country" @change="clearCybersourceCallbackError('region')">
                                    <option value="null">{{ t("checkout.billing.select-a-state") }}</option>
                                    <option v-for="region in computedRegionOptions" :value="region.value" :key="region.value">
                                        {{ region.text }}
                                    </option>
                                </select>

                                <span class="validation-message" v-if="!validateRegion()">{{ translateError('region') }}</span>
                            </div>
                            <div class="mb-3 form-group" :class="{ 'invalid': !validatePostalCode() }">
                                <label for="postal-code" class="form-label">{{ t("checkout.billing.postalCode") }}</label>
                                <input v-if="country == 'AT'" v-mask="'####'" id="postal-code" name="postal-code" inputmode="numeric" autocomplete="on" type="text" :placeholder='t("checkout.billing.zipcode-placeholder-at")' v-model="postalCode" class="form-control" @change="clearCybersourceCallbackError('postalcode')"/>
                                <input v-else-if="country == 'AU'" v-mask="'####'" id="postal-code" name="postal-code" inputmode="numeric" autocomplete="on" type="text" :placeholder='t("checkout.billing.zipcode-placeholder-au")' v-model="postalCode" class="form-control" @change="clearCybersourceCallbackError('postalcode')"/>
                                <input v-else-if="country == 'CH'" v-mask="'####'" id="postal-code" name="postal-code" inputmode="numeric" autocomplete="on" type="text" :placeholder='t("checkout.billing.zipcode-placeholder-ch")' v-model="postalCode" class="form-control" @change="clearCybersourceCallbackError('postalcode')"/>
                                <input v-else-if="country == 'DE'" v-mask="'#####'" id="postal-code" name="postal-code" inputmode="numeric" autocomplete="on" type="text" :placeholder='t("checkout.billing.zipcode-placeholder-de")' v-model="postalCode" class="form-control" @change="clearCybersourceCallbackError('postalcode')"/>
                                <input v-else-if="country == 'DK'" v-mask="['##', '####']" id="postal-code" name="postal-code" inputmode="numeric" autocomplete="on" type="text" :placeholder='t("checkout.billing.zipcode-placeholder-dk")' v-model="postalCode" class="form-control" @change="clearCybersourceCallbackError('postalcode')"/>
                                <input v-else-if="country == 'FI'" v-mask="'#####'" id="postal-code" name="postal-code" inputmode="numeric" autocomplete="on" type="text" :placeholder='t("checkout.billing.zipcode-placeholder-fi")' v-model="postalCode" class="form-control" @change="clearCybersourceCallbackError('postalcode')"/>
                                <input v-else-if="country == 'GB'" v-mask="['AX XXX','AXX XXA','AXXX XAA']" id="postal-code" name="postal-code" autocomplete="on" type="text" :placeholder='t("checkout.billing.zipcode-placeholder-gb")' v-model="postalCode" class="form-control" @change="clearCybersourceCallbackError('postalcode')"/>
                                <input v-else-if="country == 'IT'" v-mask="'#####'" id="postal-code" name="postal-code" inputmode="numeric" autocomplete="on" type="text" :placeholder='t("checkout.billing.zipcode-placeholder-it")' v-model="postalCode" class="form-control" @change="clearCybersourceCallbackError('postalcode')"/>
                                <input v-else-if="country == 'NL'" v-mask="['####AA', '#### AA']" id="postal-code" name="postal-code" autocomplete="on" type="text" :placeholder='t("checkout.billing.zipcode-placeholder-nl")' v-model="postalCode" class="form-control" @change="clearCybersourceCallbackError('postalcode')"/>
                                <input v-else-if="country == 'NO'" v-mask="'####'" id="postal-code" name="postal-code" inputmode="numeric" autocomplete="on" type="text" :placeholder='t("checkout.billing.zipcode-placeholder-no")' v-model="postalCode" class="form-control" @change="clearCybersourceCallbackError('postalcode')"/>
                                <input v-else-if="country == 'US'" v-mask="['#####', '#####-####']" id="postal-code" name="postal-code" inputmode="numeric" autocomplete="on" type="text" :placeholder='t("checkout.billing.zipcode-placeholder-us")' v-model="postalCode" class="form-control" @change="clearCybersourceCallbackError('postalcode')"/>
                                <input v-else-if="country == 'CA'" v-mask="['A#A #A#']" id="postal-code" name="postal-code" autocomplete="on" type="text" :placeholder='t("checkout.billing.zipcode-placeholder-ca")' v-model="postalCode" class="form-control" @change="clearCybersourceCallbackError('postalcode')"/>
                                <input v-else-if="country == 'SE'" v-mask="'### ##'" id="postal-code" name="postal-code" inputmode="numeric" autocomplete="on" type="text" :placeholder='t("checkout.billing.zipcode-placeholder-se")' v-model="postalCode" class="form-control" @change="clearCybersourceCallbackError('postalcode')"/>
                                <input v-else-if="country == 'ES'" v-mask="'#####'" id="postal-code" name="postal-code" inputmode="numeric" autocomplete="on" type="text" :placeholder='t("checkout.billing.zipcode-placeholder-se")' v-model="postalCode" class="form-control" @change="clearCybersourceCallbackError('postalcode')"/>
                                <input v-else v-mask="'XXXXXXXXXXXX'" id="postal-code" name="postal-code" autocomplete="on" type="text" :placeholder='t("checkout.billing.zipcode-placeholder-none")' v-model="postalCode" class="form-control" @change="clearCybersourceCallbackError('postalcode')"/>
                                <span class="validation-message" v-if="!validatePostalCode()">{{ translateError('postalcode') }}</span>
                            </div>
                            <div class="mb-3 form-check" :class="{ 'invalid': !validateTerms() }">
                                <input type="checkbox" class="form-check-input" id="term-check" v-model="terms">
                                <label v-html='t("checkout.payment.terms")' class="form-check-label term" for="term-check">
                                </label>
                                <span class="validation-message terms" v-if="!validateTerms()">{{ translateError('terms') }}</span>
                            </div>
                            <div class="mb-3 form-group" v-if="hasUnknownError">
                                <p class="general-error-message">
                                    {{ t('error-text') }}
                                </p>
                            </div>
                        </form>
                    </div>
                    <div @click="isValidUSZip(postalCode)" class="col-md-6 col order-details-wrapper">
                        <div class="price-wrapper">
                            <Summary data-vue step="details"> </Summary>
                        </div>
                    </div>
                </div>
            </div>
            <div class="btn-wrapper-profile">
                <a @click="changeStep('Details', $event)" style="cursor:pointer;" class="blank-btn">{{ t('checkout.actions.back') }}</a>
                <button v-if="isFreeOrder" type="submit" form="address-form" class="card-detals-btn pink-btn" :disabled="!isValid" @click="onConfirmFree($event)">{{ t('checkout.actions.free.confirm-and-pay') }}</button>
                <button v-else type="submit" form="address-form" class="card-detals-btn pink-btn" :disabled="!isValid" @click="onSubmit($event)">{{ t('checkout.actions.billing.enter-card-details') }}</button>
            </div>
        </div>
    </div>
</div>
</template>

<style lang="scss">
@import "../../globals";

    .checkout-step.billing {
        .form-group {
            position: relative;
            margin-bottom: 0;
            padding-bottom: 2.6rem;
        }
    }

    #street-address {
        height: 120px;
    }

    .submit-wrapper {
        margin: 0 auto;
        max-width: 350px;

        input {
            text-transform: uppercase;
            font-weight: bold;
            padding: 14px;

        }
    }
    .general-error-message {
        color: #e40000 !important;
    }
</style>

<script>
import { mask } from 'vue-the-mask';
import { translate, apiCall } from "../../utils/utils";
import store from "../../store";
import { mapGetters } from "vuex";
import Summary from "./Summary";
import { SmoothScrollToTop } from "../../utils/utils";

export default {
    directives: {
        mask
    },
    name: "Billing",
    props: ["handleChange"],
    components: {
        Summary
    },
    data: () => ({
        dataHash: null,
        terms: false,
        streetAddress: null,
        locality: null,
        country: null,
        region: null,
        postalCode: null,
        countryOptions: [
            {
                text: "Austria",
                value: "AT",
                regions: [{
                        text: "Burgenland",
                        value: "Burgenland"
                    },
                    {
                        text: "Kärnten",
                        value: "Kärnten"
                    },
                    {
                        text: "Niederösterreich",
                        value: "Niederösterreich"
                    },
                    {
                        text: "Oberösterreich",
                        value: "Oberösterreich"
                    },
                    {
                        text: "Salzburg",
                        value: "Salzburg"
                    },
                    {
                        text: "Steiermark",
                        value: "Steiermark"
                    },
                    {
                        text: "Tirol",
                        value: "Tirol"
                    },
                    {
                        text: "Vorarlberg",
                        value: "Vorarlberg"
                    },
                    {
                        text: "Wien",
                        value: "Wien"
                    },
                ]
            },
            {
                text: "Australia",
                value: "AU",
                regions: [{
                        text: "Australian Capital Territories",
                        value: "ACT"
                    },
                    {
                        text: "New South Wales",
                        value: "NSW"
                    },
                    {
                        text: "Northern Territory",
                        value: "NT"
                    },
                    {
                        text: "Queensland",
                        value: "QLD"
                    },
                    {
                        text: "South Australia",
                        value: "SA"
                    },
                    {
                        text: "Tasmania",
                        value: "TAS"
                    },
                    {
                        text: "Victoria",
                        value: "VIC"
                    },
                    {
                        text: "Western Australia",
                        value: "WA"
                    },
                    {
                        text: "New Zealand",
                        value: "NZ"
                    },
                ]
            },
            {
                text: "Canada",
                value: "CA",
                regions: [{
                        text: "AB",
                        value: "AB"
                    },
                    {
                        text: "BC",
                        value: "BC"
                    },
                    {
                        text: "MB",
                        value: "MB"
                    },
                    {
                        text: "NB",
                        value: "NB"
                    },
                    {
                        text: "NL",
                        value: "NL"
                    },
                    {
                        text: "NT",
                        value: "NT"
                    },
                    {
                        text: "NS",
                        value: "NS"
                    },
                    {
                        text: "NU",
                        value: "NU"
                    },
                    {
                        text: "ON",
                        value: "ON"
                    },
                    {
                        text: "PE",
                        value: "PE"
                    },
                    {
                        text: "QC",
                        value: "QC"
                    },
                    {
                        text: "SK",
                        value: "SK"
                    },
                    {
                        text: "YT",
                        value: "YT"
                    },
                ]
            },
            {
                text: "Switzerland",
                value: "CH",
                regions: [{
                        text: "Aargau",
                        value: "AG"
                    },
                    {
                        text: "Appenzell Ausserrhoden",
                        value: "AR"
                    },
                    {
                        text: "Appenzell Innerrhoden",
                        value: "AI"
                    },
                    {
                        text: "Basel-Landschaft",
                        value: "BL"
                    },
                    {
                        text: "Basel-Stadt",
                        value: "BS"
                    },
                    {
                        text: "Bern",
                        value: "BE"
                    },
                    {
                        text: "Fribourg",
                        value: "FR"
                    },
                    {
                        text: "Genéve",
                        value: "GE"
                    },
                    {
                        text: "Glarus",
                        value: "GL"
                    },
                    {
                        text: "Graubünden",
                        value: "GR"
                    },
                    {
                        text: "Jura",
                        value: "JU"
                    },
                    {
                        text: "Luzern",
                        value: "LU"
                    },
                    {
                        text: "Neuchâtel",
                        value: "NE"
                    },
                    {
                        text: "Nidwalden",
                        value: "NW"
                    },
                    {
                        text: "Obwalden",
                        value: "OW"
                    },
                    {
                        text: "Sankt Gallen",
                        value: "SG"
                    },
                    {
                        text: "Schaffhausen",
                        value: "SH"
                    },
                    {
                        text: "Schwyz",
                        value: "SZ"
                    },
                    {
                        text: "Solothurn",
                        value: "SO"
                    },
                    {
                        text: "Thurgau",
                        value: "TG"
                    },
                    {
                        text: "Ticino",
                        value: "TI"
                    },
                    {
                        text: "Uri",
                        value: "UR"
                    },
                    {
                        text: "Valais",
                        value: "VS"
                    },
                    {
                        text: "Vaud",
                        value: "VD"
                    },
                    {
                        text: "Zug",
                        value: "ZG"
                    },
                    {
                        text: "Zürich",
                        value: "ZH"
                    },
                ]
            },
            {
                text: "Germany",
                value: "DE",
                regions: [{
                        text: "Baden-Württemberg",
                        value: "BW"
                    },
                    {
                        text: "Bayern",
                        value: "BY"
                    },
                    {
                        text: "Berlin",
                        value: "BE"
                    },
                    {
                        text: "Brandenburg",
                        value: "BB"
                    },
                    {
                        text: "Bremen",
                        value: "HB"
                    },
                    {
                        text: "Hamburg",
                        value: "HH"
                    },
                    {
                        text: "Hessen",
                        value: "HE"
                    },
                    {
                        text: "Mecklenburg-Vorpommern",
                        value: "MV"
                    },
                    {
                        text: "Niedersachsen",
                        value: "NI"
                    },
                    {
                        text: "Nordrhein-Westfalen",
                        value: "NW"
                    },
                    {
                        text: "Rheinland-Pfalz",
                        value: "RP"
                    },
                    {
                        text: "Saarland",
                        value: "SL"
                    },
                    {
                        text: "Sachsen",
                        value: "SN"
                    },
                    {
                        text: "Sachsen-Anhalt",
                        value: "ST"
                    },
                    {
                        text: "Schleswig-Holstein",
                        value: "SH"
                    },
                    {
                        text: "Thüringen",
                        value: "TH"
                    },
                ],
            },
            {
                text: "Denmark",
                value: "DK",
                regions: [{
                        text: "Hovedstaden",
                        value: "Hovedstaden"
                    },
                    {
                        text: "Sjaelland",
                        value: "Sjaelland"
                    },
                    {
                        text: "Syddanmarkringen",
                        value: "Syddanmark"
                    },
                    {
                        text: "Midtjylland",
                        value: "Midtjylland"
                    },
                    {
                        text: "Nordjylland",
                        value: "Nordjylland"
                    },
                ]
            },
            {
                text: "Finland",
                value: "FI",
                regions: [{
                        text: "Lapland",
                        value: "Lapland"
                    },
                    {
                        text: "North Ostrobothnia",
                        value: "North-Ostrobothnia"
                    },
                    {
                        text: "Kainuu",
                        value: "Kainuu"
                    },
                    {
                        text: "North Karelia",
                        value: "North-Karelia"
                    },
                    {
                        text: "Pohjois-Savo",
                        value: "Pohjois-Savo"
                    },
                    {
                        text: "Etelä-Savo",
                        value: "Etelä-Savo"
                    },
                    {
                        text: "South Ostrobothnia",
                        value: "South-Ostrobothnia"
                    },
                    {
                        text: "Ostrobothnia",
                        value: "Ostrobothnia"
                    },
                    {
                        text: "Pirkanmaa",
                        value: "Pirkanmaa"
                    },
                    {
                        text: "Satakunta",
                        value: "0Satakunta"
                    },
                    {
                        text: "Central Ostrobothnia",
                        value: "1Central-Ostrobothnia"
                    },
                    {
                        text: "Central Finland",
                        value: "2Central-Finland"
                    },
                    {
                        text: "Southwest Finland",
                        value: "Southwest-Finland"
                    },
                    {
                        text: "South Karelia",
                        value: "South-Karelia"
                    },
                    {
                        text: "Päijät-Häme",
                        value: "Päijät-Häme"
                    },
                    {
                        text: "Kanta-Häme",
                        value: "Kanta-Häme"
                    },
                    {
                        text: "Uusimaa",
                        value: "Uusimaa"
                    },
                    {
                        text: "Kymenlaakso",
                        value: "Kymenlaakso"
                    },
                    {
                        text: "Åland",
                        value: "Åland"
                    },
                ]
            },
            {
                text: "Great Britain",
                value: "GB",
                regions: [{
                        text: "Aberdeen City",
                        value: "ABE"
                    },
                    {
                        text: "Aberdeenshire",
                        value: "ABD"
                    },
                    {
                        text: "Angus",
                        value: "ANS"
                    },
                    {
                        text: "Antrim and Newtownabbey",
                        value: "ANN"
                    },
                    {
                        text: "Ards and North Down",
                        value: "AND"
                    },
                    {
                        text: "Argyll and Bute",
                        value: "AGB"
                    },
                    {
                        text: "Armagh, Banbridge and Craigavon",
                        value: "ABC"
                    },
                    {
                        text: "Barking and Dagenham",
                        value: "BDG"
                    },
                    {
                        text: "Barnet",
                        value: "BNE"
                    },
                    {
                        text: "Barnsley",
                        value: "BNS"
                    },
                    {
                        text: "Bath and North East Somerset",
                        value: "BAS"
                    },
                    {
                        text: "Bedford",
                        value: "BDF"
                    },
                    {
                        text: "Belfast",
                        value: "BFS"
                    },
                    {
                        text: "Bexley",
                        value: "BEX"
                    },
                    {
                        text: "Birmingham",
                        value: "BIR"
                    },
                    {
                        text: "Blackburn with Darwen",
                        value: "BBD"
                    },
                    {
                        text: "Blackpool",
                        value: "BPL"
                    },
                    {
                        text: "Blaenau Gwent",
                        value: "BGW"
                    },
                    {
                        text: "Bolton",
                        value: "BOL"
                    },
                    {
                        text: "Bournemouth",
                        value: "BMH"
                    },
                    {
                        text: "Bracknell Forest",
                        value: "BRC"
                    },
                    {
                        text: "Bradford",
                        value: "BRD"
                    },
                    {
                        text: "Brent",
                        value: "BEN"
                    },
                    {
                        text: "Bridgend [Pen-y-bont ar Ogwr GB-POG]",
                        value: "BGE"
                    },
                    {
                        text: "Brighton and Hove",
                        value: "BNH"
                    },
                    {
                        text: "Bristol, City of",
                        value: "BST"
                    },
                    {
                        text: "Bromley",
                        value: "BRY"
                    },
                    {
                        text: "Buckinghamshire",
                        value: "BKM"
                    },
                    {
                        text: "Bury",
                        value: "BUR"
                    },
                    {
                        text: "Caerphilly [Caerffili GB-CAF]",
                        value: "CAY"
                    },
                    {
                        text: "Calderdale",
                        value: "CLD"
                    },
                    {
                        text: "Cambridgeshire",
                        value: "CAM"
                    },
                    {
                        text: "Camden",
                        value: "CMD"
                    },
                    {
                        text: "Cardiff [Caerdydd GB-CRD]",
                        value: "CRF"
                    },
                    {
                        text: "Carmarthenshire [Sir Gaerfyrddin GB-GFY]",
                        value: "CMN"
                    },
                    {
                        text: "Causeway Coast and Glens",
                        value: "CCG"
                    },
                    {
                        text: "Central Bedfordshire",
                        value: "CBF"
                    },
                    {
                        text: "Ceredigion [Sir Ceredigion]",
                        value: "CGN"
                    },
                    {
                        text: "Cheshire East",
                        value: "CHE"
                    },
                    {
                        text: "Cheshire West and Chester",
                        value: "CHW"
                    },
                    {
                        text: "Clackmannanshire",
                        value: "CLK"
                    },
                    {
                        text: "Conwy",
                        value: "CWY"
                    },
                    {
                        text: "Cornwall",
                        value: "CON"
                    },
                    {
                        text: "Coventry",
                        value: "COV"
                    },
                    {
                        text: "Croydon",
                        value: "CRY"
                    },
                    {
                        text: "Cumbria",
                        value: "CMA"
                    },
                    {
                        text: "Darlington",
                        value: "DAL"
                    },
                    {
                        text: "Denbighshire [Sir Ddinbych GB-DDB]",
                        value: "DEN"
                    },
                    {
                        text: "Derby",
                        value: "DER"
                    },
                    {
                        text: "Derbyshire",
                        value: "DBY"
                    },
                    {
                        text: "Derry and Strabane",
                        value: "DRS"
                    },
                    {
                        text: "Devon",
                        value: "DEV"
                    },
                    {
                        text: "Doncaster",
                        value: "DNC"
                    },
                    {
                        text: "Dorset",
                        value: "DOR"
                    },
                    {
                        text: "Dudley",
                        value: "DUD"
                    },
                    {
                        text: "Dumfries and Galloway",
                        value: "DGY"
                    },
                    {
                        text: "Dundee City",
                        value: "DND"
                    },
                    {
                        text: "Durham County",
                        value: "DUR"
                    },
                    {
                        text: "Ealing",
                        value: "EAL"
                    },
                    {
                        text: "East Ayrshire",
                        value: "EAY"
                    },
                    {
                        text: "East Dunbartonshire",
                        value: "EDU"
                    },
                    {
                        text: "East Lothian",
                        value: "ELN"
                    },
                    {
                        text: "East Renfrewshire",
                        value: "ERW"
                    },
                    {
                        text: "East Riding of Yorkshire",
                        value: "ERY"
                    },
                    {
                        text: "East Sussex",
                        value: "ESX"
                    },
                    {
                        text: "Edinburgh, City of",
                        value: "EDH"
                    },
                    {
                        text: "Eilean Siar",
                        value: "ELS"
                    },
                    {
                        text: "Enfield",
                        value: "ENF"
                    },
                    {
                        text: "England",
                        value: "ENG"
                    },
                    {
                        text: "England and Wales",
                        value: "EAW"
                    },
                    {
                        text: "Essex",
                        value: "ESS"
                    },
                    {
                        text: "Falkirk",
                        value: "FAL"
                    },
                    {
                        text: "Fermanagh and Omagh",
                        value: "FMO"
                    },
                    {
                        text: "Fife",
                        value: "FIF"
                    },
                    {
                        text: "Flintshire [Sir y Fflint GB-FFL]",
                        value: "FLN"
                    },
                    {
                        text: "Gateshead",
                        value: "GAT"
                    },
                    {
                        text: "Glasgow City",
                        value: "GLG"
                    },
                    {
                        text: "Gloucestershire",
                        value: "GLS"
                    },
                    {
                        text: "Great Britain",
                        value: "GBN"
                    },
                    {
                        text: "Greenwich",
                        value: "GRE"
                    },
                    {
                        text: "Gwynedd",
                        value: "GWN"
                    },
                    {
                        text: "Hackney",
                        value: "HCK"
                    },
                    {
                        text: "Halton",
                        value: "HAL"
                    },
                    {
                        text: "Hammersmith and Fulham",
                        value: "HMF"
                    },
                    {
                        text: "Hampshire",
                        value: "HAM"
                    },
                    {
                        text: "Haringey",
                        value: "HRY"
                    },
                    {
                        text: "Harrow",
                        value: "HRW"
                    },
                    {
                        text: "Hartlepool",
                        value: "HPL"
                    },
                    {
                        text: "Havering",
                        value: "HAV"
                    },
                    {
                        text: "Herefordshire",
                        value: "HEF"
                    },
                    {
                        text: "Hertfordshire",
                        value: "HRT"
                    },
                    {
                        text: "Highland",
                        value: "HLD"
                    },
                    {
                        text: "Hillingdon",
                        value: "HIL"
                    },
                    {
                        text: "Hounslow",
                        value: "HNS"
                    },
                    {
                        text: "Inverclyde",
                        value: "IVC"
                    },
                    {
                        text: "Isle of Anglesey [Sir Ynys M\ufffdn GB-YNM]",
                        value: "AGY"
                    },
                    {
                        text: "Isle of Wight",
                        value: "IOW"
                    },
                    {
                        text: "Isles of Scilly",
                        value: "IOS"
                    },
                    {
                        text: "Islington",
                        value: "ISL"
                    },
                    {
                        text: "Kensington and Chelsea",
                        value: "KEC"
                    },
                    {
                        text: "Kent",
                        value: "KEN"
                    },
                    {
                        text: "Kingston upon Hull",
                        value: "KHL"
                    },
                    {
                        text: "Kingston upon Thames",
                        value: "KTT"
                    },
                    {
                        text: "Kirklees",
                        value: "KIR"
                    },
                    {
                        text: "Knowsley",
                        value: "KWL"
                    },
                    {
                        text: "Lambeth",
                        value: "LBH"
                    },
                    {
                        text: "Lancashire",
                        value: "LAN"
                    },
                    {
                        text: "Leeds",
                        value: "LDS"
                    },
                    {
                        text: "Leicester",
                        value: "LCE"
                    },
                    {
                        text: "Leicestershire",
                        value: "LEC"
                    },
                    {
                        text: "Lewisham",
                        value: "LEW"
                    },
                    {
                        text: "Lincolnshire",
                        value: "LIN"
                    },
                    {
                        text: "Lisburn and Castlereagh",
                        value: "LBC"
                    },
                    {
                        text: "Liverpool",
                        value: "LIV"
                    },
                    {
                        text: "London, City of",
                        value: "LND"
                    },
                    {
                        text: "Luton",
                        value: "LUT"
                    },
                    {
                        text: "Manchester",
                        value: "MAN"
                    },
                    {
                        text: "Medway",
                        value: "MDW"
                    },
                    {
                        text: "Merthyr Tydfil [Merthyr Tudful GB-MTU]",
                        value: "MTY"
                    },
                    {
                        text: "Merton",
                        value: "MRT"
                    },
                    {
                        text: "Mid Ulster",
                        value: "MUL"
                    },
                    {
                        text: "Mid and East Antrim",
                        value: "MEA"
                    },
                    {
                        text: "Middlesbrough",
                        value: "MDB"
                    },
                    {
                        text: "Midlothian",
                        value: "MLN"
                    },
                    {
                        text: "Milton Keynes",
                        value: "MIK"
                    },
                    {
                        text: "Monmouthshire [Sir Fynwy GB-FYN]",
                        value: "MON"
                    },
                    {
                        text: "Moray",
                        value: "MRY"
                    },
                    {
                        text: "Neath Port Talbot [Castell-nedd Port Talbot GB-CTL]",
                        value: "NTL"
                    },
                    {
                        text: "Newcastle upon Tyne",
                        value: "NET"
                    },
                    {
                        text: "Newham",
                        value: "NWM"
                    },
                    {
                        text: "Newport [Casnewydd GB-CNW]",
                        value: "NWP"
                    },
                    {
                        text: "Newry, Mourne and Down",
                        value: "NMD"
                    },
                    {
                        text: "Norfolk",
                        value: "NFK"
                    },
                    {
                        text: "North Ayrshire",
                        value: "NAY"
                    },
                    {
                        text: "North East Lincolnshire",
                        value: "NEL"
                    },
                    {
                        text: "North Lanarkshire",
                        value: "NLK"
                    },
                    {
                        text: "North Lincolnshire",
                        value: "NLN"
                    },
                    {
                        text: "North Somerset",
                        value: "NSM"
                    },
                    {
                        text: "North Tyneside",
                        value: "NTY"
                    },
                    {
                        text: "North Yorkshire",
                        value: "NYK"
                    },
                    {
                        text: "Northamptonshire",
                        value: "NTH"
                    },
                    {
                        text: "Northern Ireland",
                        value: "NIR"
                    },
                    {
                        text: "Northumberland",
                        value: "NBL"
                    },
                    {
                        text: "Nottingham",
                        value: "NGM"
                    },
                    {
                        text: "Nottinghamshire",
                        value: "NTT"
                    },
                    {
                        text: "Oldham",
                        value: "OLD"
                    },
                    {
                        text: "Orkney Islands",
                        value: "ORK"
                    },
                    {
                        text: "Oxfordshire",
                        value: "OXF"
                    },
                    {
                        text: "Pembrokeshire [Sir Benfro GB-BNF]",
                        value: "PEM"
                    },
                    {
                        text: "Perth and Kinross",
                        value: "PKN"
                    },
                    {
                        text: "Peterborough",
                        value: "PTE"
                    },
                    {
                        text: "Plymouth",
                        value: "PLY"
                    },
                    {
                        text: "Poole",
                        value: "POL"
                    },
                    {
                        text: "Portsmouth",
                        value: "POR"
                    },
                    {
                        text: "Powys",
                        value: "POW"
                    },
                    {
                        text: "Reading",
                        value: "RDG"
                    },
                    {
                        text: "Redbridge",
                        value: "RDB"
                    },
                    {
                        text: "Redcar and Cleveland",
                        value: "RCC"
                    },
                    {
                        text: "Renfrewshire",
                        value: "RFW"
                    },
                    {
                        text: "Rhondda, Cynon, Taff [Rhondda, Cynon,Taf]",
                        value: "RCT"
                    },
                    {
                        text: "Richmond upon Thames",
                        value: "RIC"
                    },
                    {
                        text: "Rochdale",
                        value: "RCH"
                    },
                    {
                        text: "Rotherham",
                        value: "ROT"
                    },
                    {
                        text: "Rutland",
                        value: "RUT"
                    },
                    {
                        text: "Salford",
                        value: "SLF"
                    },
                    {
                        text: "Sandwell",
                        value: "SAW"
                    },
                    {
                        text: "Scotland",
                        value: "SCT"
                    },
                    {
                        text: "Scottish Borders, The",
                        value: "SCB"
                    },
                    {
                        text: "Sefton",
                        value: "SFT"
                    },
                    {
                        text: "Sheffield",
                        value: "SHF"
                    },
                    {
                        text: "Shetland Islands",
                        value: "ZET"
                    },
                    {
                        text: "Shropshire",
                        value: "SHR"
                    },
                    {
                        text: "Slough",
                        value: "SLG"
                    },
                    {
                        text: "Solihull",
                        value: "SOL"
                    },
                    {
                        text: "Somerset",
                        value: "SOM"
                    },
                    {
                        text: "South Ayrshire",
                        value: "SAY"
                    },
                    {
                        text: "South Gloucestershire",
                        value: "SGC"
                    },
                    {
                        text: "South Lanarkshire",
                        value: "SLK"
                    },
                    {
                        text: "South Tyneside",
                        value: "STY"
                    },
                    {
                        text: "Southampton",
                        value: "STH"
                    },
                    {
                        text: "Southend-on-Sea",
                        value: "SOS"
                    },
                    {
                        text: "Southwark",
                        value: "SWK"
                    },
                    {
                        text: "St. Helens",
                        value: "SHN"
                    },
                    {
                        text: "Staffordshire",
                        value: "STS"
                    },
                    {
                        text: "Stirling",
                        value: "STG"
                    },
                    {
                        text: "Stockport",
                        value: "SKP"
                    },
                    {
                        text: "Stockton-on-Tees",
                        value: "STT"
                    },
                    {
                        text: "Stoke-on-Trent",
                        value: "STE"
                    },
                    {
                        text: "Suffolk",
                        value: "SFK"
                    },
                    {
                        text: "Sunderland",
                        value: "SND"
                    },
                    {
                        text: "Surrey",
                        value: "SRY"
                    },
                    {
                        text: "Sutton",
                        value: "STN"
                    },
                    {
                        text: "Swansea [Abertawe GB-ATA]",
                        value: "SWA"
                    },
                    {
                        text: "Swindon",
                        value: "SWD"
                    },
                    {
                        text: "Tameside",
                        value: "TAM"
                    },
                    {
                        text: "Telford and Wrekin",
                        value: "TFW"
                    },
                    {
                        text: "Thurrock",
                        value: "THR"
                    },
                    {
                        text: "Torbay",
                        value: "TOB"
                    },
                    {
                        text: "Torfaen [Tor-faen]",
                        value: "TOF"
                    },
                    {
                        text: "Tower Hamlets",
                        value: "TWH"
                    },
                    {
                        text: "Trafford",
                        value: "TRF"
                    },
                    {
                        text: "United Kingdom",
                        value: "UKM"
                    },
                    {
                        text: "Vale of Glamorgan, The [Bro Morgannwg GB-BMG]",
                        value: "VGL"
                    },
                    {
                        text: "Wakefield",
                        value: "WKF"
                    },
                    {
                        text: "Wales [Cymru GB-CYM]",
                        value: "WLS"
                    },
                    {
                        text: "Walsall",
                        value: "WLL"
                    },
                    {
                        text: "Waltham Forest",
                        value: "WFT"
                    },
                    {
                        text: "Wandsworth",
                        value: "WND"
                    },
                    {
                        text: "Warrington",
                        value: "WRT"
                    },
                    {
                        text: "Warwickshire",
                        value: "WAR"
                    },
                    {
                        text: "West Berkshire",
                        value: "WBK"
                    },
                    {
                        text: "West Dunbartonshire",
                        value: "WDU"
                    },
                    {
                        text: "West Lothian",
                        value: "WLN"
                    },
                    {
                        text: "West Sussex",
                        value: "WSX"
                    },
                    {
                        text: "Westminster",
                        value: "WSM"
                    },
                    {
                        text: "Wigan",
                        value: "WGN"
                    },
                    {
                        text: "Wiltshire",
                        value: "WIL"
                    },
                    {
                        text: "Windsor and Maidenhead",
                        value: "WNM"
                    },
                    {
                        text: "Wirral",
                        value: "WRL"
                    },
                    {
                        text: "Wokingham",
                        value: "WOK"
                    },
                    {
                        text: "Wolverhampton",
                        value: "WLV"
                    },
                    {
                        text: "Worcestershire",
                        value: "WOR"
                    },
                    {
                        text: "Wrexham [Wrecsam GB-WRC]",
                        value: "WRX"
                    },
                    {
                        text: "York",
                        value: "YOR"
                    }
                ]
            },
            {
                text: "Italy",
                value: "IT",
                regions: [{
                        text: "Chieti",
                        value: "CH"
                    },
                    {
                        text: "L'Aquila",
                        value: "AQ"
                    },
                    {
                        text: "Pescara",
                        value: "PE"
                    },
                    {
                        text: "Teramo",
                        value: "TE"
                    },
                    {
                        text: "Matera",
                        value: "MT"
                    },
                    {
                        text: "Potenza",
                        value: "PZ"
                    },
                    {
                        text: "Cosenza",
                        value: "CS"
                    },
                    {
                        text: "Catanzaro",
                        value: "CZ"
                    },
                    {
                        text: "Crotone",
                        value: "KR"
                    },
                    {
                        text: "Reggio Calabria",
                        value: "RC"
                    },
                    {
                        text: "Vibo Valentia",
                        value: "VV"
                    },
                    {
                        text: "Avellino",
                        value: "AV"
                    },
                    {
                        text: "Benevento",
                        value: "BN"
                    },
                    {
                        text: "Caserta",
                        value: "CE"
                    },
                    {
                        text: "Napoli",
                        value: "NA"
                    },
                    {
                        text: "Salerno",
                        value: "SA"
                    },
                    {
                        text: "Bologna",
                        value: "BO"
                    },
                    {
                        text: "Ferrara",
                        value: "FE"
                    },
                    {
                        text: "Forli",
                        value: "FO"
                    },
                    {
                        text: "Modena",
                        value: "MO"
                    },
                    {
                        text: "Piacenza",
                        value: "PC"
                    },
                    {
                        text: "Parma",
                        value: "PR"
                    },
                    {
                        text: "Ravenna",
                        value: "RA"
                    },
                    {
                        text: "Reggio Emilia",
                        value: "RE"
                    },
                    {
                        text: "Rimini",
                        value: "RN"
                    },
                    {
                        text: "Gorizia",
                        value: "GO"
                    },
                    {
                        text: "Pordenone",
                        value: "PN"
                    },
                    {
                        text: "Trieste",
                        value: "TS"
                    },
                    {
                        text: "Udine",
                        value: "UD"
                    },
                    {
                        text: "Frosinone",
                        value: "FR"
                    },
                    {
                        text: "Latina",
                        value: "LT"
                    },
                    {
                        text: "Rieti",
                        value: "RI"
                    },
                    {
                        text: "Roma",
                        value: "RM"
                    },
                    {
                        text: "Viterbo",
                        value: "VT"
                    },
                    {
                        text: "Genova",
                        value: "GE"
                    },
                    {
                        text: "Imperia",
                        value: "IM"
                    },
                    {
                        text: "La Spezia",
                        value: "SP"
                    },
                    {
                        text: "Savona",
                        value: "SV"
                    },
                    {
                        text: "Bergamo",
                        value: "BG"
                    },
                    {
                        text: "Brescia",
                        value: "BS"
                    },
                    {
                        text: "Como",
                        value: "CO"
                    },
                    {
                        text: "Cremona",
                        value: "CR"
                    },
                    {
                        text: "Lecco",
                        value: "LC"
                    },
                    {
                        text: "Lodi",
                        value: "LO"
                    },
                    {
                        text: "Mantova",
                        value: "MN"
                    },
                    {
                        text: "Milano",
                        value: "MI"
                    },
                    {
                        text: "Pavia",
                        value: "PV"
                    },
                    {
                        text: "Sondrio",
                        value: "SO"
                    },
                    {
                        text: "Varese",
                        value: "VA"
                    },
                    {
                        text: "Ancona",
                        value: "AN"
                    },
                    {
                        text: "Ascoli Piceno",
                        value: "AP"
                    },
                    {
                        text: "Macerata",
                        value: "MC"
                    },
                    {
                        text: "Pesaro",
                        value: "PS"
                    },
                    {
                        text: "Campobasso",
                        value: "CB"
                    },
                    {
                        text: "Isernia",
                        value: "IS"
                    },
                    {
                        text: "Alessandria",
                        value: "AL"
                    },
                    {
                        text: "Asti",
                        value: "AT"
                    },
                    {
                        text: "Biella",
                        value: "BI"
                    },
                    {
                        text: "Cuneo",
                        value: "CN"
                    },
                    {
                        text: "Novara",
                        value: "NO"
                    },
                    {
                        text: "Torino",
                        value: "TO"
                    },
                    {
                        text: "Verbania",
                        value: "VB"
                    },
                    {
                        text: "Vercelli",
                        value: "VC"
                    },
                    {
                        text: "Bari",
                        value: "BA"
                    },
                    {
                        text: "Brindisi",
                        value: "BR"
                    },
                    {
                        text: "Foggia",
                        value: "FG"
                    },
                    {
                        text: "Lecce",
                        value: "LE"
                    },
                    {
                        text: "Taranto",
                        value: "TA"
                    },
                    {
                        text: "Cagliari",
                        value: "CA"
                    },
                    {
                        text: "Nuoro",
                        value: "NU"
                    },
                    {
                        text: "Oristano",
                        value: "OR"
                    },
                    {
                        text: "Sassari",
                        value: "SS"
                    },
                    {
                        text: "Agrigento",
                        value: "AG"
                    },
                    {
                        text: "Caltanissetta",
                        value: "CL"
                    },
                    {
                        text: "Catania",
                        value: "CT"
                    },
                    {
                        text: "Enna",
                        value: "EN"
                    },
                    {
                        text: "Messina",
                        value: "ME"
                    },
                    {
                        text: "Palermo",
                        value: "PA"
                    },
                    {
                        text: "Ragusa",
                        value: "RG"
                    },
                    {
                        text: "Siracusa",
                        value: "SR"
                    },
                    {
                        text: "Trapani",
                        value: "TP"
                    },
                    {
                        text: "Arezzo",
                        value: "AR"
                    },
                    {
                        text: "Firenze",
                        value: "FI"
                    },
                    {
                        text: "Grosseto",
                        value: "GR"
                    },
                    {
                        text: "Livorno",
                        value: "LI"
                    },
                    {
                        text: "Lucca",
                        value: "LU"
                    },
                    {
                        text: "Massa",
                        value: "MS"
                    },
                    {
                        text: "Pisa",
                        value: "PI"
                    },
                    {
                        text: "Pistoia",
                        value: "PT"
                    },
                    {
                        text: "Prato",
                        value: "PO"
                    },
                    {
                        text: "Siena",
                        value: "SI"
                    },
                    {
                        text: "Perugia",
                        value: "PG"
                    },
                    {
                        text: "Termi",
                        value: "TR"
                    },
                    {
                        text: "Aosta",
                        value: "AO"
                    },
                    {
                        text: "Belluno",
                        value: "BL"
                    },
                    {
                        text: "Padova",
                        value: "PD"
                    },
                    {
                        text: "Rovigo",
                        value: "RO"
                    },
                    {
                        text: "Treviso",
                        value: "TV"
                    },
                    {
                        text: "Venezia",
                        value: "VE"
                    },
                    {
                        text: "Vicenza",
                        value: "VI"
                    },
                    {
                        text: "Verona",
                        value: "VR"
                    },
                    {
                        text: "Bolzano",
                        value: "BZ"
                    },
                    {
                        text: "Trento",
                        value: "TN"
                    }
                ]
            },
            {
                text: "Netherlands",
                value: "NL",
                regions: [{
                        text: "Noord-Holland",
                        value: "NH"
                    },
                    {
                        text: "Zuid-Holland",
                        value: "ZH"
                    },
                    {
                        text: "Zeeland",
                        value: "ZE"
                    },
                    {
                        text: "Noord-Brabant",
                        value: "NB"
                    },
                    {
                        text: "Utrecht",
                        value: "UT"
                    },
                    {
                        text: "Flevoland",
                        value: "FL"
                    },
                    {
                        text: "Friesland",
                        value: "FR"
                    },
                    {
                        text: "Groningen",
                        value: "GR"
                    },
                    {
                        text: "Drenthe",
                        value: "DR"
                    },
                    {
                        text: "Overijssel",
                        value: "OV"
                    },
                    {
                        text: "Gelderland",
                        value: "GE"
                    },
                    {
                        text: "Limburg",
                        value: "LI"
                    }
                ]
            },
            {
                text: "Norway",
                value: "NO",
                regions: [{
                        text: "Akershus",
                        value: "Akershus"
                    },
                    {
                        text: "Aust-Agder",
                        value: "Aust-Agder"
                    },
                    {
                        text: "Buskerud",
                        value: "Buskerud"
                    },
                    {
                        text: "Finnmark",
                        value: "Finnmark"
                    },
                    {
                        text: "Hedmark",
                        value: "Hedmark"
                    },
                    {
                        text: "Hordaland",
                        value: "Hordaland"
                    },
                    {
                        text: "M\u00f8re og Romsdal",
                        value: "M\u00f8re-og-Romsdal"
                    },
                    {
                        text: "Nordland",
                        value: "Nordland"
                    },
                    {
                        text: "Oppland",
                        value: "Oppland"
                    },
                    {
                        text: "Oslo",
                        value: "Oslo"
                    },
                    {
                        text: "Rogaland",
                        value: "Rogaland"
                    },
                    {
                        text: "Romsa",
                        value: "Romsa"
                    },
                    {
                        text: "Sogn og Fjordane",
                        value: "Sogn-og-Fjordane"
                    },
                    {
                        text: "Telemark",
                        value: "Telemark"
                    },
                    {
                        text: "Troms",
                        value: "Troms"
                    },
                    {
                        text: "Tr\u00f8ndelag",
                        value: "Tr\u00f8ndelag"
                    },
                    {
                        text: "Vest-Agder",
                        value: "Vest-Agder"
                    },
                    {
                        text: "Vestfold",
                        value: "Vestfold"
                    },
                    {
                        text: "\u00d8stfold",
                        value: "\u00d8stfold"
                    },
                    {
                        text: "Jan Mayen",
                        value: "Jan-Mayen"
                    },
                    {
                        text: "Svalbard",
                        value: "Svalbard"
                    }
                ]
            },
            {
                text: "Sweden",
                value: "SE",
                regions: [{
                        text: "\u00c5ngermanland",
                        value: "\u00c5ngermanland"
                    },
                    {
                        text: "Blekinge",
                        value: "Blekinge"
                    },
                    {
                        text: "Bohusl\u00e4n",
                        value: "Bohusl\u00e4n"
                    },
                    {
                        text: "Dalarna",
                        value: "Dalarna"
                    },
                    {
                        text: "Dalsland",
                        value: "Dalsland"
                    },
                    {
                        text: "Gotland",
                        value: "Gotland"
                    },
                    {
                        text: "G\u00e4strikland",
                        value: "G\u00e4strikland"
                    },
                    {
                        text: "Halland",
                        value: "Halland"
                    },
                    {
                        text: "H\u00e4lsingland",
                        value: "H\u00e4lsingland"
                    },
                    {
                        text: "H\u00e4rjedalen",
                        value: "H\u00e4rjedalen"
                    },
                    {
                        text: "J\u00e4mtland",
                        value: "J\u00e4mtland"
                    },
                    {
                        text: "Lappland",
                        value: "Lappland"
                    },
                    {
                        text: "Medelpad",
                        value: "Medelpad"
                    },
                    {
                        text: "Norrbotten",
                        value: "Norrbotten"
                    },
                    {
                        text: "N\u00e4rke",
                        value: "N\u00e4rke"
                    },
                    {
                        text: "\u00d6land",
                        value: "\u00d6land"
                    },
                    {
                        text: "\u00d6sterg\u00f6tland",
                        value: "\u00d6sterg\u00f6tland"
                    },
                    {
                        text: "Sk\u00e5ne",
                        value: "Sk\u00e5ne"
                    },
                    {
                        text: "Sm\u00e5land",
                        value: "Sm\u00e5land"
                    },
                    {
                        text: "S\u00f6dermanland ",
                        value: "S\u00f6dermanland"
                    },
                    {
                        text: "Uppland",
                        value: "Uppland"
                    },
                    {
                        text: "V\u00e4rmland",
                        value: "V\u00e4rmland"
                    },
                    {
                        text: "V\u00e4stmanland",
                        value: "V\u00e4stmanland"
                    },
                    {
                        text: "V\u00e4sterbotten",
                        value: "V\u00e4sterbotten"
                    },
                    {
                        text: "V\u00e4sterg\u00f6tland",
                        value: "V\u00e4sterg\u00f6tland"
                    }
                ]
            },
            {
                text: "United States of America",
                value: "US",
                regions: [{
                        text: "AL",
                        value: "AL"
                    },
                    {
                        text: "AK",
                        value: "AK"
                    },
                    {
                        text: "AZ",
                        value: "AZ"
                    },
                    {
                        text: "AR",
                        value: "AR"
                    },
                    {
                        text: "CA",
                        value: "CA"
                    },
                    {
                        text: "CO",
                        value: "CO"
                    },
                    {
                        text: "CT",
                        value: "CT"
                    },
                    {
                        text: "DC",
                        value: "DC"
                    },
                    {
                        text: "DE",
                        value: "DE"
                    },
                    {
                        text: "FL",
                        value: "FL"
                    },
                    {
                        text: "GA",
                        value: "GA"
                    },
                    {
                        text: "HI",
                        value: "HI"
                    },
                    {
                        text: "ID",
                        value: "ID"
                    },
                    {
                        text: "IL",
                        value: "IL"
                    },
                    {
                        text: "IN",
                        value: "IN"
                    },
                    {
                        text: "IA",
                        value: "IA"
                    },
                    {
                        text: "KS",
                        value: "KS"
                    },
                    {
                        text: "KY",
                        value: "KY"
                    },
                    {
                        text: "LA",
                        value: "LA"
                    },
                    {
                        text: "ME",
                        value: "ME"
                    },
                    {
                        text: "MD",
                        value: "MD"
                    },
                    {
                        text: "MA",
                        value: "MA"
                    },
                    {
                        text: "MI",
                        value: "MI"
                    },
                    {
                        text: "MN",
                        value: "MN"
                    },
                    {
                        text: "MS",
                        value: "MS"
                    },
                    {
                        text: "MO",
                        value: "MO"
                    },
                    {
                        text: "MT",
                        value: "MT"
                    },
                    {
                        text: "NE",
                        value: "NE"
                    },
                    {
                        text: "NV",
                        value: "NV"
                    },
                    {
                        text: "NH",
                        value: "NH"
                    },
                    {
                        text: "NJ",
                        value: "NJ"
                    },
                    {
                        text: "NM",
                        value: "NM"
                    },
                    {
                        text: "NY",
                        value: "NY"
                    },
                    {
                        text: "NC",
                        value: "NC"
                    },
                    {
                        text: "ND",
                        value: "ND"
                    },
                    {
                        text: "OH",
                        value: "OH"
                    },
                    {
                        text: "OK",
                        value: "OK"
                    },
                    {
                        text: "OR",
                        value: "OR"
                    },
                    {
                        text: "PA",
                        value: "PA"
                    },
                    {
                        text: "RI",
                        value: "RI"
                    },
                    {
                        text: "SC",
                        value: "SC"
                    },
                    {
                        text: "SD",
                        value: "SD"
                    },
                    {
                        text: "TN",
                        value: "TN"
                    },
                    {
                        text: "TX",
                        value: "TX"
                    },
                    {
                        text: "UT",
                        value: "UT"
                    },
                    {
                        text: "VT",
                        value: "VT"
                    },
                    {
                        text: "VA",
                        value: "VA"
                    },
                    {
                        text: "WA",
                        value: "WA"
                    },
                    {
                        text: "WV",
                        value: "WV"
                    },
                    {
                        text: "WI",
                        value: "WI"
                    },
                    {
                        text: "WY",
                        value: "WY"
                    }
                ]
            },
            {
                text: "Spain",
                value: "ES",
                regions: [
                    {
                        "text": "Albacete",
                        "value": "Albacete"
                    },
                    {
                        "text": "Alicante",
                        "value": "Alicante"
                    },
                    {
                        "text": "Almer\u00EDa",
                        "value": "Almer\u00EDa"
                    },
                    {
                        "text": "Asturias",
                        "value": "Asturias"
                    },
                    {
                        "text": "Badajoz",
                        "value": "Badajoz"
                    },
                    {
                        "text": "Balearic Islands",
                        "value": "Balearic-Islands"
                    },
                    {
                        "text": "Barcelona",
                        "value": "Barcelona"
                    },
                    {
                        "text": "Burgos",
                        "value": "Burgos"
                    },
                    {
                        "text": "C\u00E1ceres",
                        "value": "C\u00E1ceres"
                    },
                    {
                        "text": "C\u00E1diz",
                        "value": "C\u00E1diz"
                    },
                    {
                        "text": "Cantabria",
                        "value": "Cantabria"
                    },
                    {
                        "text": "Castell\u00F3n",
                        "value": "Castell\u00F3n"
                    },
                    {
                        "text": "Ceuta",
                        "value": "Ceuta"
                    },
                    {
                        "text": "Ciudad Real",
                        "value": "Ciudad-Real"
                    },
                    {
                        "text": "C\u00F3rdoba",
                        "value": "C\u00F3rdoba"
                    },
                    {
                        "text": "Cuenca",
                        "value": "Cuenca"
                    },
                    {
                        "text": "Gerona",
                        "value": "Gerona"
                    },
                    {
                        "text": "Granada\t",
                        "value": "Granada\t"
                    },
                    {
                        "text": "Guadalajara",
                        "value": "Guadalajara"
                    },
                    {
                        "text": "Guip\u00FAzcoa",
                        "value": "Guip\u00FAzcoa"
                    },
                    {
                        "text": "Huelva",
                        "value": "Huelva"
                    },
                    {
                        "text": "Huesca",
                        "value": "Huesca"
                    },
                    {
                        "text": "Ja\u00E9n",
                        "value": "Ja\u00E9n"
                    },
                    {
                        "text": "La Coru\u00F1a",
                        "value": "La-Coru\u00F1a"
                    },
                    {
                        "text": "La Rioja",
                        "value": "La-Rioja"
                    },
                    {
                        "text": "Las Palmas",
                        "value": "Las-Palmas"
                    },
                    {
                        "text": "Le\u00F3n",
                        "value": "Le\u00F3n"
                    },
                    {
                        "text": "L\u00E9rida",
                        "value": "L\u00E9rida"
                    },
                    {
                        "text": "Lugo",
                        "value": "Lugo"
                    },
                    {
                        "text": "Madrid",
                        "value": "Madrid"
                    },
                    {
                        "text": "M\u00E1laga",
                        "value": "M\u00E1laga"
                    },
                    {
                        "text": "Melilla",
                        "value": "Melilla"
                    },
                    {
                        "text": "Murcia",
                        "value": "Murcia"
                    },
                    {
                        "text": "Navarra",
                        "value": "Navarra"
                    },
                    {
                        "text": "Ourense",
                        "value": "Ourense"
                    },
                    {
                        "text": "Palencia",
                        "value": "Palencia"
                    },
                    {
                        "text": "Pontevedra",
                        "value": "Pontevedra"
                    },
                    {
                        "text": "Salamanca",
                        "value": "Salamanca"
                    },
                    {
                        "text": "Santa Cruz",
                        "value": "Santa-Cruz"
                    },
                    {
                        "text": "Segovia",
                        "value": "Segovia"
                    },
                    {
                        "text": "Sevilla",
                        "value": "Sevilla"
                    },
                    {
                        "text": "Soria",
                        "value": "Soria"
                    },
                    {
                        "text": "Tarragona",
                        "value": "Tarragona"
                    },
                    {
                        "text": "Teruel",
                        "value": "Teruel"
                    },
                    {
                        "text": "Toledo",
                        "value": "Toledo"
                    },
                    {
                        "text": "Valencia",
                        "value": "Valencia"
                    },
                    {
                        "text": "Valladolid",
                        "value": "Valladolid"
                    },
                    {
                        "text": "Vizcaya",
                        "value": "Vizcaya"
                    },
                    {
                        "text": "Zamora",
                        "value": "Zamora"
                    },
                    {
                        "text": "Zaragoza",
                        "value": "Zaragoza"
                    },
                    {
                        "text": "\u00C1lava",
                        "value": "\u00C1lava"
                    },
                    {
                        "text": "\u00C1vila",
                        "value": "\u00C1vila"
                    }
                ]
            },
        ],
        regionOptions: [],
        valid: false,
        errors: [],
        cybersourceCallbackErrors: [],
        hasUnknownError: false,
        fieldMapping: {
            'streetAddress': 'street-address',
            'postalCode': 'postal-code',
            'locality': 'locality',
            'country': 'country-name',
            'region': 'region',
        },
        isLoading: false,
        isLoadingFreeOrder: false,
        processingPayment: false,
    }),
    watch: {
        terms(val) {
            // clear any terms errors.
            this.clearError('terms');

            if (!val) {
                this.addError('terms', 'checkout.payment.terms.not-checked')
                return;
            }
        },
        streetAddress(val) {
            this.clearError('streetaddress');

            if (!val) {
                this.addError('streetaddress', 'checkout.billing.streetaddress.empty');
                return;
            }
        },
        locality(val) {
            this.clearError('locality');

            if (!val) {
                this.addError('locality', 'checkout.billing.locality.empty');
                return;
            }
        },
        country(val) {
            this.clearError('country');

            if (val === "null" || !val) {
                this.addError('country', 'checkout.billing.country.empty');
                return;
            }
        },
        region(val) {
            this.clearError('region');

            if (val === "null" || !val) {
                this.addError('region', 'checkout.billing.region.empty');
                return;
            }
        },
        postalCode(val) {
            this.clearError('postalcode');

            if (!val) {
                this.addError('postalcode', 'checkout.billing.postalcode.empty')
                return;
            }

            this.postalCode = val.toLocaleUpperCase();
        },
    },
    mounted() {
        if (this.order && this.order.userAddress) {
            this.region = this.order.userAddress['region'].toUpperCase();
            this.country = this.order.userAddress['country-name'];
            this.locality = this.order.userAddress['locality'];
            this.postalCode = this.order.userAddress['postal-code'];
            this.streetAddress = this.order.userAddress['street-address'];

            this.valid = true;
        }

        // Create hash to detect changes.
        this.dataHash = this.createDataHash;

        if (window.invalidAddressFields !== undefined && Array.isArray(window.invalidAddressFields)) {
            for (const error of window.invalidAddressFields) {
                let fieldName = error;
                switch(fieldName) {
                    case 'bill_to_address_city':
                        fieldName = 'locality';
                        break;
                    case 'bill_to_address_postal_code':
                        fieldName = 'postalcode';
                        break;
                    case 'bill_to_address_country':
                        fieldName = 'country';
                        break;
                    case 'bill_to_address_state':
                        fieldName = 'region';
                        break;
                    case 'bill_to_address_line1':
                        fieldName = 'streetaddress';
                        break;
                }

                let translationString = `checkout.billing.validation.unknown-error`;

                if (translationString) {
                    this.addCybersourceCallbackError(fieldName, translationString);
                }
            }

            // Clear any errors passed using the global window object, just in case
            delete window.invalidAddressFields;
        }
    },
    computed: {
        hasDataHashChanged() {
            const newDataHash = this.createDataHash;
            return newDataHash !== this.dataHash;
        },
        createDataHash() {
            return this.hashCode(this.region + this.country + this.locality + this.postalCode + this.streetAddress);
        },
        computedRegionOptions() {

            if (!this.country) {
                return [];
            }
            this.regionOptions = this.getRegionOptionsByCountryName(this.country);
            return this.regionOptions;
        },
        isValid() {
            if (this.streetAddress && this.locality && this.country && this.region && this.postalCode && this.terms) {
                if (!this.errors.length && !this.cybersourceCallbackErrors.length) {
                    return true;
                }
            }
            return false;
        },
        isFreeOrder() {
            return this.order.orderCost[0].amount === 0;
        },
        ...mapGetters([
            'order',
            'preferredFormat',
            'designFormat',
            'preferredCraftingFormat',
            'craftingFormat',
        ]),
    },
    methods: {
        isValidUSZip(sZip) {
            return /^\d{5}(-\d{4})?$/.test(sZip);
        },
        hashCode(str) {
            var hash = 0,
                i, chr;
            for (i = 0; i < str.length; i++) {
                chr = str.charCodeAt(i);
                hash = ((hash << 5) - hash) + chr;
                hash |= 0; // Convert to 32bit integer
            }
            return hash;
        },
        validateTerms() {
            return this.hasError('terms') === false;
        },
        validateStreetAddress() {
            return (this.hasError('streetaddress') === false) && (this.hasCybersourceCallbackError('streetaddress') === false);
        },
        validateLocality() {
            return (this.hasError('locality') === false) && (this.hasCybersourceCallbackError('locality') === false);
        },
        validateCountry() {
            return (this.hasError('country') === false) && (this.hasCybersourceCallbackError('country') === false);
        },
        validateRegion() {
            return (this.hasError('region') === false) && (this.hasCybersourceCallbackError('region') === false);
        },
        validatePostalCode() {
            return (this.hasError('postalcode') === false) && (this.hasCybersourceCallbackError('postalcode') === false);
        },
        hasError(field) {
            if (this.errors) {
                const foundError = this.errors.filter((error) => error.field === field);

                if (foundError.length) {
                    return foundError[0];
                }
            }

            return false;
        },
        hasCybersourceCallbackError(field) {
            if (this.cybersourceCallbackErrors) {
                const foundError = this.cybersourceCallbackErrors.filter((error) => error.field === field);

                if (foundError.length) {
                    return foundError[0];
                }
            }

            return false;
        },
        translateError(field) {
            const error = this.hasError(field);

            if (error) {
                return this.t(error.msg)
            }

            return null;
        },
        addError(field, msg) {
            this.errors.push({
                field: field,
                msg: msg
            });
        },
        addCybersourceCallbackError(field, msg) {
            this.cybersourceCallbackErrors.push({ field: field, msg: msg});
        },
        clearError(field) {
            if (this.errors) {
                this.errors.forEach((error, index) => {
                    if (error.field === field) {
                        this.errors.splice(index, 1);
                        return false;
                    }
                })
            }
        },
        clearCybersourceCallbackError(field) {
            if (this.cybersourceCallbackErrors) {
                this.cybersourceCallbackErrors.forEach((error, index) => {
                    if (error.field === field) {
                        this.cybersourceCallbackErrors.splice(index, 1);
                        return false;
                    }
                })
            }
        },
        hasErrors(fieldName) {
            // If valid is set, everything is OK
            if (this.valid) {
                // return false;
            }

            // Check if the field has any value set at all.
            const value = this[fieldName];

            if (!value) {
                this.valid = false;
                return true;
            }

            const epFieldName = this.fieldMapping[fieldName];

            if (this.errors) {
                if (this.errors[epFieldName]) {
                    return true; // Has errors
                } else {
                    return false; // Does not have any errors.
                }
            }

            return null; // Null means not validated yet.
        },
        getRegionOptionsByCountryName(countryName) {
            if (!countryName) {
                return [];
            }

            const country = this.countryOptions.filter(country => country.value === countryName);

            // populate region options with regions for country
            return country[0] ? country[0].regions.map(region => {
                region.value = region.value.toUpperCase();
                return region;
            }) : [];
        },
        changeStep(step, event) {
            if (event !== null) {
                event.preventDefault();
            }
            this.$emit("handleChange", step);
        },
        onSubmit(event) {
            this.hasUnknownError = false;
            const postNewBillingInformation = this.hasDataHashChanged;

            // If no data has change was detected, do not send new billing information,
            // just continue to next Payment step.
            if (!postNewBillingInformation) {
                this.changeStep('Payment', event);
                SmoothScrollToTop();
                return;
            }

            this.submitBillingInformation(() => this.changeStep('Payment', event));
            SmoothScrollToTop();
        },
        onConfirmFree(event) {
            event.preventDefault();
            if (!this.isFreeOrder) {
                return;
            }

            this.hasUnknownError = false;
            const postNewBillingInformation = this.hasDataHashChanged;

            // If no data change was detected, do not send new billing information,
            // just place the free order and continue to the confirmation step.
            if (!postNewBillingInformation) {
                this.placeFreeOrder(event);
                return;
            }

            this.submitBillingInformation(() => this.placeFreeOrder(event));
            SmoothScrollToTop();
        },
        submitBillingInformation(successCallback) {
            this.isLoading = true;

            const url = window.location.origin + '/api/order/set-billing-information';

            const postData = {
                'address': {
                    'street-address': this.streetAddress,
                    'locality': this.locality,
                    'country-name': this.country,
                    'postal-code': this.postalCode,
                    'region': this.region.toUpperCase(),
                }
            };

            const ajaxOptions = {
                url: url,
                method: "POST",
                dataType: "json",
                data: postData,
                beforeSend: xhr => {
                    xhr.setRequestHeader(
                        "Cache-Control",
                        "no-store, no-cache, must-revalidate, max-age=0"
                    );
                },
                success: response => {

                    // Check for billing address message.
                    if (response.messages && response.messages["need.billing.address"]) {
                        console.error("Could not set billing address");
                        this.valid = false;
                    } else {
                        // Check if currencyCode has changed from previous order state.
                        if (response.currencyCode !== this.order.currencyCode) {
                            // Present the modal on next step
                            response.showCurrencyChangedModal = true;
                        }

                        // Update the order state.
                        store.commit("updateOrder", {
                            order: response,
                        });

                        this.valid = true;

                        successCallback();
                    }
                },
                error: (errorResponse, textStatus, error) => {
                    if (typeof Rollbar !== 'undefined') {
                        var eventObject = {
                            errorResponseObject: errorResponse,
                            textStatus: textStatus,
                            error: error,
                            eventType: 'setBillingInformationError'
                        }
                        Rollbar.captureEvent(eventObject, 'error');
                        Rollbar.error('API call order/set-billing-information error');
                    }

                    this.valid = false;
                    const json = errorResponse.responseJSON;

                    if (json !== undefined) {
                        const errors = json.errors;
                        if (errors !== undefined) {
                            for (const [epFieldName, errors] of Object.entries(errors)) {
                                let fieldName = epFieldName;

                                // We only validate the first error.
                                const error = errors[0];

                                const isEmptyError = error.endsWith('null');

                                switch(epFieldName) {
                                    case 'street-address':
                                        fieldName = 'streetaddress';
                                        break;
                                    case 'locality':
                                        fieldName = 'locality';
                                        break;
                                    case 'postal-code':
                                        fieldName = 'postalcode';
                                        break;
                                    case 'country-name':
                                        fieldName = 'country';
                                        break;
                                    case 'region':
                                        fieldName = 'region';
                                        break;
                                }

                                let translationString = `checkout.billing.validation.unknown-error`;

                                if (isEmptyError) {
                                    translationString = `checkout.billing.${fieldName}.empty`;
                                }

                                if (translationString) {
                                    this.addError(fieldName, translationString);
                                }
                            }
                        } else {
                            this.hasUnknownError = true;
                        }
                    } else {
                        this.hasUnknownError = true;
                    }
                },
                complete: (event) => {
                    this.isLoading = false;
                }
            }
            $.ajax(ajaxOptions);
        },
        placeFreeOrder(event) {
            this.isLoading = true;
            this.isLoadingFreeOrder = true;
            this.processingPayment = true;

            apiCall("order/set-payment-method-free", "POST", {},
                (setPaymentMethodResponse) => {
                    const placeOrderData = {
                        format: this.designFormat ? this.designFormat : this.preferredFormat,
                    }

                    apiCall("order/place-order", "POST", placeOrderData,
                        (placeOrderResponse) => {
                            this.isLoading = false;
                            this.valid = true;
                            this.processingPayment = false;

                            // Remove token information from store
                            store.commit("updatePayment", { payload: null});
                            store.commit("updatePurchase", {
                                purchase: placeOrderResponse,
                            })
                            this.changeStep('ConfirmationFree', event);
                        },
                        (errorResponse, textStatus, error) => {
                            if (typeof Rollbar !== 'undefined') {
                                var eventObject = {
                                    errorResponseObject: errorResponse,
                                    textStatus: textStatus,
                                    error: error,
                                    eventType: 'placeOrderError'
                                }
                                Rollbar.captureEvent(eventObject, 'error');
                                Rollbar.error('API call order/place-order error');
                            }
                            this.isLoading = false;
                            this.isLoadingFreeOrder = false
                            this.valid = false;
                            this.processingPayment = false;
                            this.changeStep('FailedFree', event);
                        }
                    );
                },
                (errorResponse, textStatus, error) => {
                    if (typeof Rollbar !== 'undefined') {
                        var eventObject = {
                            errorResponseObject: errorResponse,
                            textStatus: textStatus,
                            error: error,
                            eventType: 'setPaymentError'
                        }
                        Rollbar.captureEvent(eventObject, 'error');
                        Rollbar.error('API call order/set-payment-method-free error');
                    }
                    this.changeStep('FailedFree', event);
                }
            );
        },
        changeCountry(event) {
            // Validation

            this.changeValidation(event, 'country');
            this.clearCybersourceCallbackError('country');

            // Reset region because dynamic options
            this.region = null;
            this.postalCode = null;
        },
        changeValidation(event, fieldName) {
            const value = event.target.value.trim();
            const epFieldName = this.fieldMapping[fieldName];
            if (!value || value === "null") {
                // Add to errors
                this.addError(fieldName, 'checkout.billing.country.empty');
            }
        },
        t(key, variables) {
            return translate(key, variables);
        }
    },
}
</script>
