var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "horizontal-list-container" },
    [
      _c(
        "tiny-slider",
        {
          staticClass: "design-slide-container",
          attrs: {
            items: 2,
            slideBy: 2,
            loop: false,
            responsive: _vm.slideOptions.responsive,
            controlsContainer: _vm.controlsContainerSelector,
            nav: true,
            navContainer: _vm.navContainerSelector,
            controls: false,
            preventScrollOnTouch: _vm.slideOptions.preventScrollOnTouch,
          },
        },
        _vm._l(_vm.computedDesigns, function (design) {
          return _c(
            "div",
            { key: design.sku, staticClass: "design-slide-item mt-3" },
            [
              _vm.useSimpleListEntity
                ? _c(
                    "div",
                    [_c("SimpleListEntity", { attrs: { entity: design } })],
                    1
                  )
                : _c(
                    "div",
                    [
                      _c("ListEntity", {
                        attrs: { entity: design, iscol: design.isCollection },
                      }),
                    ],
                    1
                  ),
            ]
          )
        }),
        0
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "controls-container",
          class: _vm.controlsContainerClass,
        },
        [_vm._m(0), _vm._v(" "), _vm._m(1)]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "nav-container", class: _vm.navContainerClass },
        _vm._l(_vm.computedDesigns.length, function (i) {
          return _c("button", { key: "key" + i, staticClass: "navigation-dot" })
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "left" }, [
      _c("i", { staticClass: "fas fa-chevron-left left" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "right" }, [
      _c("i", { staticClass: "fas fa-chevron-right right" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }