var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "container mb-4" }, [
      _c("div", { staticClass: "search-result-wrapper" }, [
        _vm.isLoading
          ? _c(
              "div",
              {
                staticClass:
                  "loading-container d-flex align-items-center justify-content-center",
              },
              [
                _c(
                  "div",
                  { staticClass: "spinner-grow", attrs: { role: "status" } },
                  [
                    _c("span", { staticClass: "sr-only" }, [
                      _vm._v(_vm._s(_vm.t("search.loading"))),
                    ]),
                  ]
                ),
              ]
            )
          : _vm.currentList.length
          ? _c("div", [
              _c("div", { staticClass: "mt-4" }, [
                _c("div", { staticClass: "page-margin w-100" }, [
                  _c("div", { staticClass: "col-md-12 col-lg-12 p-0" }, [
                    _c(
                      "div",
                      { staticClass: "row" },
                      _vm._l(_vm.currentList, function (result) {
                        return _c(
                          "div",
                          {
                            key: result.sku,
                            staticClass: "col-lg-4 col-md-4 col-6 mt-4",
                          },
                          [
                            _c("ListEntity", {
                              attrs: { entity: result },
                              on: {
                                favoriteClickEvent:
                                  _vm.handleFavoriteClickEvent,
                              },
                            }),
                          ],
                          1
                        )
                      }),
                      0
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _vm.isMoreResultsLoading
                ? _c(
                    "div",
                    {
                      staticClass:
                        "loading-container d-flex align-items-center justify-content-center",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "spinner-grow",
                          attrs: { role: "status" },
                        },
                        [
                          _c("span", { staticClass: "sr-only" }, [
                            _vm._v(_vm._s(_vm.t("search.loading"))),
                          ]),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "search-count-result-wrapper" }, [
                _c(
                  "p",
                  {
                    staticClass: "result-length",
                    staticStyle: { padding: "10px" },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.t("search.results.showing", {
                          current: _vm.dataList.length,
                          total: _vm.total,
                        })
                      )
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm._m(0),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        !_vm.isLoading && _vm.currentList.length === 0
          ? _c("div", [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.t("search.empty_result")) +
                  "\n            "
              ),
            ])
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "storage-bar" }, [
      _c("span", {
        staticClass: "storage-bar-fill",
        attrs: { id: "storage-bar-fill" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }