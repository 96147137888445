var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.order
    ? _c("div", [
        _c("p", { staticClass: "order-total" }, [
          _vm._v(
            _vm._s(_vm.t("checkout.details.order-total")) +
              " (" +
              _vm._s(_vm.order.totalQuantity) +
              " " +
              _vm._s(_vm.t("checkout.details.items")) +
              ")"
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "price m-t-10" }, [
          _c("p", [
            _vm._v(
              "\n                " + _vm._s(_vm.t("checkout.details.price"))
            ),
            !_vm.taxNotIncluded()
              ? _c("span", [
                  _vm._v(
                    " (" + _vm._s(_vm.t("checkout.details.incl-tax")) + ")"
                  ),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "val" }, [
            _vm._v(
              "\n                " +
                _vm._s(_vm.order.cost[0].display) +
                "\n            "
            ),
          ]),
        ]),
        _vm._v(" "),
        _vm.order.discounts[0].amount > 0
          ? _c("div", { staticClass: "price" }, [
              _c("p", [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.t("checkout.details.discount")) +
                    "\n            "
                ),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "val" }, [
                _vm._v(
                  "\n                - " +
                    _vm._s(_vm.order.discounts[0].display) +
                    "\n            "
                ),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.taxNotIncluded()
          ? _c("div", { staticClass: "price" }, [
              _c("p", [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.t("checkout.tax.added")) +
                    "\n            "
                ),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "val" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.order.taxTotal) +
                    "\n            "
                ),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        !_vm.taxNotIncluded() && _vm.isPaymentStep()
          ? _c("div", { staticClass: "price" }, [
              _c("p", [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.t("checkout.tax.included")) +
                    "\n            "
                ),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "val" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.order.taxTotal) +
                    "\n            "
                ),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "total" }, [
          _c("p", [
            _vm._v(
              "\n                " +
                _vm._s(_vm.t("checkout.details.total")) +
                "\n            "
            ),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "val" }, [
            _vm._v(
              "\n               " +
                _vm._s(_vm.order.orderCost[0].display) +
                " \n            "
            ),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }