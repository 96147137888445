var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "thankyouPage-wrapper" }, [
    _c("h2", [_vm._v(_vm._s(_vm.t("checkout.failed.top-headline")))]),
    _vm._v(" "),
    _c("img", { attrs: { src: "/static/icons/share.svg", alt: "" } }),
    _vm._v(" "),
    _c("p", [_vm._v(_vm._s(_vm.t("checkout.failed.second-headline")) + " ")]),
    _vm._v(" "),
    _c("p", {
      staticClass: "recipent",
      domProps: { innerHTML: _vm._s(_vm.t("checkout.failed.text")) },
    }),
    _vm._v(" "),
    _c("div", { staticClass: "btn-wrapper" }, [
      _c(
        "a",
        {
          staticClass: "pink-btn",
          attrs: { "@click": _vm.changeStep("Payment") },
        },
        [_vm._v(_vm._s(_vm.t("checkout.failed.return-button-label")))]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }