<script>
import { translate } from "../../utils/utils";
import LButton from "../buttons/LButton.vue";
export default {
  name: "SubscriptionTooltip",
  components: { LButton },
  methods: {
    t(key) {
      return translate(key);
    },
    goToSubscribe() {
      window.location.href = 'https://mysewnet.com/products-and-plans/embroidery';
    },
  }
}
</script>

<template>
  <div class="subscription-wrap">
    <h5>{{ t("account.subscription_required_title") }}</h5>
    <p>
      {{ t("account.subscription_required") }} <br/>
      <a href="https://mysewnet.com/en/join-mysewnet/mysewnet-enabled-machines/">{{ t('mySewnet.Enabled-Machines') }}</a>
    </p>
    <div class="subscription-wrap__actions">
      <l-button color="pink" size="tiny" @click.native="goToSubscribe">{{ t("account.subscription_cta") }}</l-button>
    </div>
  </div>
</template>

<style scoped lang="scss">
.subscription-wrap {
  width: 100%;
  text-align: left;
  & h5 {
    font-family: soleil-semibold, Helvetica, Arial, sans-serif;
    font-size: 16px;
  }
  & p {
    font-size: 14px;
  }
  &__actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 5px;
  }
}
</style>
