var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "l-accordion" }, [
    _c(
      "div",
      {
        staticClass: "l-accordion-item",
        on: {
          click: function ($event) {
            _vm.accordionIsOpen = !_vm.accordionIsOpen
          },
        },
      },
      [
        _c("p", { staticClass: "l-accordion-item__label" }, [
          _vm._v(_vm._s(_vm.title)),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            class: [
              "l-accordion-item__arrow",
              _vm.accordionIsOpen ? "collapsed" : null,
            ],
          },
          [
            _c(
              "IconsBase",
              {
                attrs: { iconColor: _vm.accordionIsOpen ? "blue" : "#000000" },
              },
              [_c("arrow-down-icon")],
              1
            ),
          ],
          1
        ),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.accordionIsOpen,
            expression: "accordionIsOpen",
          },
        ],
        staticClass: "l-accordion-item__content",
      },
      [_vm._t("default")],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }