<template>
<div class="cart-wrapper">
    <a href="/checkout">
        <img src="/static/icons/cart-black.svg" alt="">
        <span v-if="this.cartItems.totalQuantity>0" class="count">{{cartItems.totalQuantity}}</span>
    </a>
</div>
</template>

<script>
import {
    mapGetters
} from 'vuex'
import store from "../store";

export default {
    name: "MiniCart",
    computed: {
        ...mapGetters([
            'cartItems',
        ])
    },
    created: function () {
        store.dispatch("fetchAndUpdateCart");
    },
}
</script>

<style lang="scss">
.mini-cart {
    @media(max-width: 767px) {
        margin-top: 10px;
    }

    .mini-cart-icon {
        display: block;
        width: 3.21rem;
        height: 3.21rem;
        background: url(../icons/btn_cart.svg) no-repeat center center;
    }

    .badge {
        position: absolute;
        top: 4px;
        right: 0;
        border-radius: 10px;
        background-color: #c61a5a;
        color: #fff;
        font-size: 12px;
        height: 20px;
        letter-spacing: 0;
        min-width: 20px;
        padding: 4px 6px;
        text-align: center;
    }

    .wrapper {
        position: relative;
    }
}
</style>
