var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "mt-4 search-page-banner" }, [
    _c("div", { staticClass: "text-container" }, [
      _c("h2", [
        _c("span", {
          domProps: { innerHTML: _vm._s(_vm.t("search.banner.title")) },
        }),
      ]),
      _vm._v(" "),
      _c("p", [_vm._v(_vm._s(_vm.t("search.banner.text")))]),
    ]),
    _vm._v(" "),
    _vm._m(0),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "images-container" }, [
      _c("div", { staticClass: "image-container" }, [
        _c("img", {
          attrs: {
            src: require("../img/design-listing-header-1.jpg"),
            alt: "",
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "image-container" }, [
        _c("img", {
          attrs: {
            src: require("../img/design-listing-header-2.jpg"),
            alt: "",
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "image-container" }, [
        _c("img", {
          attrs: {
            src: require("../img/design-listing-header-3.jpg"),
            alt: "",
          },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }