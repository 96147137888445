var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card-item" }, [
    _c("div", { staticClass: "card-item-header" }, [
      _c("a", { attrs: { href: _vm.generatedURL } }, [
        _c("img", { attrs: { src: _vm.entity.thumbnail, lazy: "loaded" } }),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "card-item-header__icon left" },
        [
          _c(
            "IconsBase",
            { attrs: { iconName: _vm.type, "icon-color": "#fff" } },
            [
              _vm.type === "collection" ? _c("collection-icon") : _vm._e(),
              _vm._v(" "),
              _vm.type === "embroidery" ? _c("embroidery-icon") : _vm._e(),
              _vm._v(" "),
              _vm.type === "fonts" ? _c("fonts-icon") : _vm._e(),
              _vm._v(" "),
              _vm.type === "crafting" ? _c("crafting-icon") : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "card-item-header__icon right",
          on: { click: _vm.onFavoriteClick },
        },
        [
          !_vm.isFavorite
            ? _c(
                "IconsBase",
                { attrs: { iconName: "Favorite" } },
                [_c("favorite-icon")],
                1
              )
            : _c(
                "IconsBase",
                { attrs: { iconName: "Favorite", iconColor: "#C61A5A" } },
                [_c("favorite-active-icon")],
                1
              ),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "card-item-body" }, [
      _vm._v("\n        " + _vm._s(_vm.entity.name) + "\n    "),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "card-item-footer" },
      [
        !_vm.entity.isCollection && _vm.entity.designtype != "font"
          ? [
              _c("SendToMachineButton", {
                attrs: {
                  sku: _vm.entity.sku,
                  "is-purchased": _vm.entity.isPurchased,
                  designname: _vm.entity.label,
                  designimage: _vm.entity.thumbnail,
                  designtype: _vm.entity.designtype,
                },
              }),
            ]
          : _vm._e(),
        _vm._v(" "),
        _vm.entity.designtype != "font"
          ? _c("SaveDesignVaultButton", {
              attrs: {
                designtype: _vm.entity.designtype,
                iscollection: _vm.entity.isCollection ? "true" : "false",
                designimage: _vm.entity.thumbnail || _vm.noImageUrl,
                designname: _vm.entity.label,
                sku: _vm.entity.priceGroup,
                "is-purchased": _vm.entity.isPurchased,
                designs: _vm.computedDesigns,
                "price-display": _vm.entity.price
                  ? _vm.entity.price.display
                  : "",
                allowedCountry: _vm.showPrice,
                designCode: _vm.entity.sku,
                nameOfDesign: _vm.entity.name,
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.canDownload && _vm.entity.designtype != "font"
          ? _c("DownloadButton", {
              attrs: {
                image: _vm.entity.thumbnail || _vm.noImageUrl,
                sku: _vm.entity.sku,
                name: _vm.entity.label,
                designtype: _vm.entity.designtype,
                isCollection: _vm.entity.isCollection,
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.entity.isPurchased ? _c("PurchasedTag") : _vm._e(),
        _vm._v(" "),
        _vm.entity.isAvailableAsSinglePurchase && !_vm.entity.isPurchased
          ? _c("AddToCart", {
              attrs: {
                skuCode: _vm.entity.priceGroup,
                designCode: _vm.entity.sku,
                price: _vm.entity.price,
                nameOfDesign: _vm.entity.name,
                allowedCountry: _vm.entity.allowedCountry,
                free: _vm.free,
                iscol: _vm.entity.isCollection,
                itemtype: _vm.entity.designtype,
              },
            })
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }