<template>
<div class="thankyouPage-wrapper">
    <h2>{{ t("checkout.failed.top-headline") }}</h2>
    <img src="/static/icons/share.svg" alt="">
    <p>{{ t("checkout.failed.second-headline") }} </p>
    <p class="recipent" v-html='t("checkout.failed.text")'></p>
    <div class="btn-wrapper">
        <a :@click="changeStep('Payment')" class="pink-btn">{{ t("checkout.failed.return-button-label") }}</a>
    </div>

</div>
</template>

<style lang="scss">
@import "../../globals";

.failed {
    &.purchase {

        .align-center {
            text-align: center;
        }

        .text {
            margin-bottom: 3rem;
        }

        .fa-times-circle {
            font-size: 80px;
            margin-bottom: 3rem;
            color: $primary-color;
        }

        h3 {
            margin-bottom: 3rem;
        }

        .wrapper {
            padding-top: 20px;
            padding-bottom: 20px;
            max-width: 500px;
            margin: 0 auto;
        }

        .btn {
            text-transform: uppercase;
            font-weight: bold;
            padding: 14px;

            &.black {
                background: black;
                color: white;
            }
        }
    }
}
</style>

<script>
import {
    translate
} from "../../utils/utils";

export default {
    name: "Failed",
    props: ["handleChange"],
    methods: {
        changeStep(step) {
            this.$emit("handleChange", step);
        },
        t(key) {
            return translate(key)
        },
    },
}
</script>
