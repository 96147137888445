var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", { staticClass: "text" }, [
    _vm._v(
      _vm._s(
        this.displayDimension + " " + this.t("display-unit." + this.displayUnit)
      )
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }