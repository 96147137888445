<template>
<div class="checkout-details payment">
    <div class="modal fade modal-library" id="checkoutDetailsLoginModal" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div v-if="this.order.allowedCountry && !this.loggedIn" class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ t("checkout.details.login-headline") }}</h5>
                                    <button style="color:white" type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <div class="modal-body">
                    <p v-html="t('checkout.details.login-info')"></p>
                </div>
                <div class="modal-footer">
                    <a :href="loginUrl" class="btn btn-primary">{{ t("checkout.details.sign-in") }}</a>
                    <a :href="registerUrl" class="btn btn-primary">{{ t("checkout.details.register") }}</a>
                </div>
            </div>
            <div v-else class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ t("checkout.details.login-headline.notAllowed") }}</h5>
                </div>
                <div class="modal-body">
                    <p>{{ t("checkout.details.login-info.notAllowed") }}</p>
                </div>
                <div class="modal-footer">
                    <a :href="selfUrl" class="btn btn-primary">{{ t("checkout.details.ok.notAllowed") }}</a>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="shopping-cart">
            <div v-if="isLoading" class="spinner-wrapper">
                <div class="spinner-grow" role="status"></div>
            </div>
            <div v-if="!isLoading && order" class="checkout-wrapper">
                <div v-if="!order.lineitems.length">
                    <div class="row">
                        <div class="col-12">
                            <h3 v-html="t('checkout.empty.details.headline')"></h3>
                            <p class="order-details-information" v-html="t('checkout.empty.details.information')"></p>
                            <p class="empty">{{ t("checkout.details.empty") }}</p>
                        </div>
                    </div>
                </div>

                <div v-else style="position: relative">

                    <div v-if="orderLoading" class="cart-loading">
                        <div class="spinner-grow"></div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <h3 v-html="t('checkout.details.headline')"></h3>
                            <p class="order-details-information" v-html="t('checkout.details.information')"></p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-7 product-wrapper">
                            <div class="product-card-container" v-for="(values, key) in groupedItems">
                                <div v-if="values.length > 0">
                                    <div class="cart-section">
                                        <div class="cart-section-header">{{ t("checkout.cart_header."+key) }}</div>
                                        <div v-for="item in values">
                                            <div v-if="item.extendedData" class="product-card">
                                                <div class="product-card-header">
                                                    <p class="design-name">{{ item.nameofdesign }}</p>
                                                    <RemoveFromCart :designCode="item.designcode">
                                                        <img src="/static/icons/cart-remove.png" alt="" class="remove-from-cart-icon"/>
                                                    </RemoveFromCart>
                                                </div>
                                                <img class="product-img" :src="item.extendedData.thumbnail" alt="">
                                                <div>
                                                    <div  v-if="item.type == 'collection' " style="position:relative; text-align:left;display:flex">
                                                        <img style="width:20px" src="/static/icons/collection-1.svg" alt="" />

                                                        <div v-if="item.extendedData.totalDesigns">
                                                            <p style="padding-top:13px;padding-left:10px">{{item.extendedData.totalDesigns}} {{ t("Designs in this collection")}}</p>
                                                        </div>
                                                        <div v-else>
                                                            <p style="padding-top:13px;padding-left:10px">{{ t("Collection")}}</p>
                                                        </div>

                                                    </div>

                                                    <div class="product-description">
                                                        <div v-if="item.extendedData.colorBlockCount" class="design-color-count">
                                                            <img src="/static/icons/colors.svg" :alt="t('design-page.color-block-count')">
                                                            <span>{{ item.extendedData.colorBlockCount }}</span>
                                                        </div>
                                                        <div v-if="item.extendedData.stitchCount" class="design-stitch-count">
                                                            <img src="/static/icons/stitch.svg" :alt="t('design-page.stitch-count')">
                                                            <span>{{ item.extendedData.stitchCount }}</span>
                                                        </div>
                                                        <div v-if="item.extendedData.craftingHeight" class="design-stitch-count">
                                                            <img src="/static/icons/stitch.svg" :alt="t('design-page.stitch-count')">
                                                            <CraftingDesignDimensions :heightinmm="item.extendedData.craftingHeight" :widthinmm=" item.extendedData.craftingWidth" :lengthtinmm="item.extendedData.craftingLength"></CraftingDesignDimensions>
                                                        </div>

                                                        <div v-if="item.extendedData.height" class="design-height">
                                                            <img src="/static/icons/height-arrow.png" style="width:20px !important; height:20px !important;" :alt="t('design-page.design-height')">
                                                            <DesignDimensions :dimensioninmm="item.extendedData.height | dimensionToString" />
                                                        </div>
                                                        <div v-if="item.extendedData.width" class="design-width">
                                                            <img src="/static/icons/width-arrow.png" style="width:20px !important; height:20px !important;" :alt="t('design-page.design-width')">
                                                            <DesignDimensions :dimensioninmm="item.extendedData.width | dimensionToString" />
                                                        </div>
                                                        <div v-if="item.extendedData.designsCount" style="left:15px;position:relative" class="design-count">
                                                            <div v-for="(value, key) in item.extendedData.designsCount" class="design-tag">
                                                                 <span class="design-type">
                                                                     <img v-if="key === 'embroidery' " style="width:15px" src="/static/icons/emb-1.svg" alt="" />
                                                                     <img v-if="key === 'crafting' " style="width:15px" src="/static/icons/crafting-1.svg" alt="" />
                                                                     <IconsBase  v-if="key === 'font'" icon-color="black">
                                                                            <fonts-icon />
                                                                        </IconsBase>
                                                                        <i class="design-tag-count">({{ value }})</i>
                                                                 </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div v-if="item.type == 'embroidery' " style="position:relative; text-align:left;display:flex">
                                                        <img style="width:20px" src="/static/icons/emb-1.svg" alt="" />
                                                        <p style="padding-top:13px;padding-left:10px">{{t("Embroidery Designs")}}</p>
                                                    </div>

                                                    <div  v-if=" item.type == 'crafting' " style="position:relative ; left: 10px;text-align:left;display:flex">
                                                        <img style="width:20px" src="/static/icons/crafting-1.svg" alt="" />
                                                        <p style="padding-top:13px;padding-left:10px">{{t("crafting")}}</p>
                                                    </div>

                                                    <div  v-if=" item.type == 'font' " style="position:relative ; left: 10px;text-align:left;display:flex">
                                                        <IconsBase icon-color="black" style="top: 2px; position: relative;"  width="20px">
                                                                <fonts-icon />
                                                        </IconsBase>
                                                        <p style="padding-left:10px">{{t("Fonts")}}</p>
                                                    </div>

                                                    <div v-if="item.extendedData.categories && item.extendedData.categories.length > 0 && item.type !== 'collection' " class="design-categories">
                                                        <div v-for="category in item.extendedData.categories">
                                                            <span class="design-category">{{ category }}</span>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div class="product-price">
                                                    <p>{{ item.listPrice.display }}</p>
                                                </div>
                                            </div>
                                            <div v-else>
                                                <div class="product-card">
                                                    <div class="product-card-header">
                                                        <p class="design-name">{{ item.nameofdesign }}</p>
                                                        <RemoveFromCart :designCode="item.designcode">
                                                            <img src="/static/icons/cart-remove.png" alt="" class="remove-from-cart-icon">
                                                        </RemoveFromCart>
                                                    </div>

                                                    <img class="product-img" :src="item.thumbnail" alt="">

                                                    <div class="product-description">

                                                    </div>
                                                    <div class="product-price">
                                                        <p>{{ item.listPrice.display }}</p>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-5 order-details-wrapper">
                            <div class="price-wrapper">
                                <Summary data-vue step="details"></Summary>
                                <checkoutaccordion :title="t('checkout.details.coupons')" :is-open="true">
                                    <Coupons :with-title="false"></Coupons>
                                </checkoutaccordion>

                                <div class="design-format-wrapper">
                                    <div v-if="!loggedIn" class="disabled-preferred-format"></div>
                                    <div class="format-info">
                                        <div class="format-info-header">
                                            <img src="/static/icons/emb-1.svg" alt="">
                                            <h4>{{ t('checkout.details.format-header-embroidery') }}</h4>
                                        </div>
                                        <p>{{ t("checkout.details.format-info") }}</p>
                                    </div>
                                    <div class="mb-3 form-check design-select">
                                        <PreferredFormat :isCheckout="true"></PreferredFormat>
                                    </div>

                                    <div class="mb-3 form-check design-check">
                                        <input @change="formatSaveHandler" type="checkbox" class="form-check-input" id="save-format" :checked="saveFormat" />
                                        <label class="form-check-label term" for="save-format">{{ t("checkout.details.save-format") }}</label>
                                    </div>
                                </div>
                            
                                <div class="crafting-format-wrapper">
                                    <div v-if="!loggedIn" class="disabled-preferred-format"></div>
                                    <div class="format-info">
                                        <div class="format-info-header">
                                            <img src="/static/icons/crafting-1.svg" alt="">
                                            
                                            <h4>{{ t('checkout.details.format-header-craftingcutting') }}</h4>
                                        </div>
                                        <p v-html="t('checkout.details.format-crafting-info')"></p>
                                    </div>
                                    <div class="mb-3 form-check design-select">
                                        <PreferredCraftingFormat :isCheckout="true"></PreferredCraftingFormat>
                                    </div>


                                </div>
                                <p class="cart-instruction">
                                    <a href="https://mysewnet.com/en/join-mysewnet/mysewnet-enabled-machines/" class="cart-instruction-link">{{ t("checkout.details.download-info") }}</a>
                                </p>
                                <div class="row">
                                    <div class="btn-wrapper-profile">
                                        <button :disabled="!canProceed" class="card-detals-btn pink-btn" @click="changeStep('Billing')">
                                            {{ t(isFreeOrder ? "checkout.billing.headline" : "checkout.details.proceed-to-payment") }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import store from "../../store";
import { translate, apiCall, isLoggedIn } from "../../utils/utils";
import Summary from "./Summary";
import Coupons from "./Coupons";
import IconsBase from "../icons/IconsBase.vue";

import PreferredFormat from "../PreferredFormat";
import {
    mapGetters,
    mapMutations,
    mapActions
} from "vuex";
import CraftingDesignDimensions from "../CraftingDesignDimensions";
import DesignDimensions from "../DesignDimensions";
import RemoveFromCart from "../RemoveFromCart";
import Modal from "../Modal";
import { translatedAlertModal } from "../../components/alert-modal";
import { SmoothScrollToTop } from "../../utils/utils";
import AccordionComponent from "../AccordionComponent.vue";
import PreferredCraftingFormat from "../PreferredCraftingFormat.vue";
import FontsIcon from "../icons/common/FontsIcon.vue";

export default {
    name: "Details",

    components: {
    PreferredCraftingFormat,
    AccordionComponent,
    CraftingDesignDimensions,
    PreferredFormat,
    DesignDimensions,
    RemoveFromCart,
    Summary,
    Modal,
    IconsBase,
    Coupons,
    FontsIcon
},
    props: ["handleChange"],
    data() {
        return {
            currency: "",
            GData: [],
            terms: false,
            url: "/api/order",
            disableOrderList: false,
            loading: true,
            data: null,
            loginUrl: window.global_login_url,
            registerUrl: window.global_register_url,
            selfUrl: "/checkout",
            loggedIn: isLoggedIn(),
        };
    },
    filters: {
        dimensionToString: function (value) {
            if(value){
            return value.toString();
            }
            else {return}
        },
    },
    computed: {
        validate() {
            // Check that we have values on each field.
            if (this.terms) {
                if (this.hasErrors) {
                    return false;
                }

                this.valid = true;
                return true;
            }

            this.valid = false;
            return false;
        },
        canProceed() {

            return this.order && this.order.lineitems.length > 0;

        },
        isAllowed() {
            if (this.order) {
                return (
                    this.order.allowedCountry || this.order.orderCost[0].amount === 0
                );
            }
            return true;
        },
        isFreeOrder() {
            return this.order.orderCost[0].amount === 0;
        },
        ...mapGetters([
            "saveFormat",
            "designFormat",
            "craftingFormat",
            "order",
            "orderLoading",
            "preferredFormat",
            "preferredCraftingFormat"
        ]),
        ...mapMutations([
            "updateSaveFormat",
            "updatePreferredFormat",
            "updatePreferredCraftingFormat"
        ]),
        isLoading() {
            return this.loading;
        },
        groupedItems() {
            return this.getGroupItemsByType();
        },
    },
    methods: {
        /**
         * A function to handle a click on a checkout button. This function uses the eventCallback
         * data layer variable to handle navigation after the ecommerce data has been sent to Google Analytics.
         */
        getGroupItemsByType() {
            let groupedItems = {
                "design": [],
                "collection": [],
                "embroidery": [],
                "crafting": [],
                "quilting": [],
                "font": []
            };

            for (let i = 0; i < this.order.lineitems.length; i++) {
                let item = this.order.lineitems[i];
                groupedItems[item.type].push(item);
            }

            return groupedItems;
        },
        pushData(item) {
            try {
                this.currency = item.purchasePrice.currency;
                if (item.extendedData.categories) {
                    this.GData.push({
                        item_id: item.designcode,
                        item_name: item.nameofdesign.replace(/['"]+/g, ''),
                        currency:  this.currency.toString(),
                        price: item.purchasePrice.amount.toString(),
                        item_category: item.extendedData.categories[0],
                        item_category1: item.extendedData.categories[1],
                        item_category2: item.extendedData.categories[2],
                        item_category3: item.extendedData.categories[3],
                        item_category4: item.extendedData.categories[4],
                        item_category5: item.extendedData.categories[5],
                        quantity: 1,
                    });
                }
            } catch (error) {
                console.warn("Could not send event for item " + item.name);
                console.warn(error);
            }
        },
        onCheckout() {
            this.GData = [];
            this.order.lineitems.forEach(this.pushData);
            dataLayer.push({
                ecommerce: null
            }); // Clear the previous ecommerce object.
            dataLayer.push({
                event: "view_cart",
                ecommerce: {
                    currency: this.currency.toString(),
                    value: this.order.cost[0].display,
                    tax:this.order.taxTotal,
                    items: this.GData,
                },
            });
        },
        // onCheckoutGtagBackub() {
        //   this.order.lineitems.forEach(this.pushData);
        //   gtag("event", "view_cart", {
        //     currency: this.currency.toString(),
        //     value: this.order.orderCost[0].display,
        //     items: this.GData,
        //   });
        // },
        hasError(field) {
            if (this.errors) {
                const foundError = this.errors.filter((error) => error.field === field);

                if (foundError.length) {
                    return foundError[0];
                }
            }

            return false;
        },
        translateError(field) {
            const error = this.hasError(field);

            if (error) {
                return this.t(error.msg);
            }

            return null;
        },
        validateTerms() {
            return this.hasError("terms") === false;
        },
        getOrder() {
            $.ajax({
                url: this.url,
                method: "GET",
                success: (data) => {
                    if (window.mySewnetUser && data.messages["need.email"]) {
                        apiCall("order/add-email", "POST", {
                                email: window.mySewnetUser.email,
                            },
                            (addEmailResponseData) => {
                                this.loading = false;
                                store.commit("updateOrder", {
                                    order: addEmailResponseData,
                                });
                            });
                    } else {
                        store.commit("updateOrder", {
                            order: data,
                        });
                        this.loading = false;
                        if (this.order.lineitems.length > 0) {
                           this.onCheckout();
                        }
                    }
                },
                error: (event) => {
                    translatedAlertModal("checkout.order.fetchandupdate.failed.headline", "checkout.order.fetchandupdate.failed.text");
                },
            });
        },
        formatSaveHandler(event) {
            const value = event.target.checked;
            store.commit("updateSaveFormat", {
                value,
            });
        },
        changeStep(step) {
            if (isLoggedIn() && this.isAllowed) {
                if (this.saveFormat) {
                    const value = this.designFormat ? this.designFormat : this.preferredFormat;
                    store.dispatch("storePreferredFormat", {
                        value,
                    });
                    store.commit("updatePreferredFormat", {
                        value,
                    });
                    const ccmValue = this.craftingFormat ? this.craftingFormat : this.preferredCraftingFormat;
                    store.dispatch("storePreferredCraftingFormat", {
                        ccmValue
                    });
                    store.commit("updatePreferredCraftingFormat", {
                        ccmValue
                    });
                }
                this.$emit("handleChange", step);
                SmoothScrollToTop();
            } else {
                if (!isLoggedIn()) {
                    this.openModal();
                }
            }
        },
        t(key) {
            return translate(key);
        },
        openModal() {
            $(this.$el).find('#checkoutDetailsLoginModal').modal();
        }
    },
    created() {
        store.commit("updateOrderLoading", {
            status: false,
        });
        this.getOrder();
    },
};
</script>

<style lang="scss">
@import "../../globals";

@keyframes pulse {
    0%,
    100% {
        background-color: rgba(255, 255, 255, 0.7);
    }

    50% {
        background-color: rgba(255, 255, 255, 0.9);
    }
}

.checkout-details {
    p.cart-instruction {
        margin: 40px 0 38px 0;
        text-align: right;
        a.cart-instruction-link {
            color: black !important;
        }
    }
    .empty {
        text-align: center;
        font-size: large;
        border: 1px solid rgb(0, 0, 0);
        padding: 7px;
    }

    .order-details-information {
        margin: 1.2rem 0;
    }

    .order-list {
        .order-list-inner {
            position: relative;
        }
    }

    .product-card-container {
        .cart-section {
            .cart-section-header {
                background: #f5f5f5;
                border-radius: 8px;
                padding: 14px 16px 13px 16px;
                font-family: soleil-semibold, Helvetica, Arial, sans-serif;
                font-size: 16px;
                margin-bottom: 16px;
            }

        }
    }

    img[src*="emb-1.svg"] {
        filter: brightness(0);
    }

    .crafting-format-wrapper {
        margin-top: 18px;
    }
    
    .design-format-wrapper,
    .crafting-format-wrapper {
        border-bottom: 1px solid #D8D8D8;
        .format-info {
            .format-info-header {
                font-family: soleil-semibold, Helvetica, Arial, sans-serif;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                img {
                    height: 18px;
                    width: 18px;
                    margin-right: 9px;
                }
                h4 {
                    margin-bottom: 0;
                    font-size: 18px;
                }
            }
            p {
                margin-top: 18px;
                color: #161614;
                font-weight: 400;
                font-size: 16px !important;
            }
        }
    }

    span.design-type {
        width: 54px;
        height:27px;
        border-radius:18px;
        border: 1px solid #d8d8d8;
        padding: 6px 10px;
        display: inline-flex;
        flex-wrap: nowrap;
        flex-direction: row;
        align-content: center;
        align-items: center;
        justify-content: center;
        margin: 0 6px;
        .design-tag-count {
            font-family: soleil-semibold, Helvetica, Arial, sans-serif;
        }
    }

    .design-categories {
        span.design-category {
            display: block;
            border-radius: 18px;
            border: 1px solid #D8D8D8;
            padding: 6px 10px;
            margin: 0 6px;
            float: left;
            margin-bottom: 10px;
            font-size: 12px;
        }
    }

    .cart-loading {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 9;
        animation: pulse 2s infinite;
    }

    .spinner-wrapper {
        min-height: 400px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .price-wrapper {
        position: sticky;
        top: 200px;
    }

    .preferred-format {
        display: inline-block;

        select {
            font-weight: bold;
            margin: 0;
        }
    }

    .order-summary {
        .summary-bottom {
            background-color: #f9f9f9;
            padding: 0 18px 18px;
        }

        .btn-primary {
            width: 100%;
            padding: 0.8rem;
            text-transform: uppercase;
            font-size: 1.2rem;
            margin-bottom: 2rem;
        }

        .format {
            position: relative;

            .disabled-preferred-format {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 1;
                background: rgba(249, 249, 249, 0.7);
            }
        }

        .text {
            font-size: 1rem;
        }
    }

    .format-select {
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
            font-weight: bold;
            margin: 0;
        }
    }

    .save-format {
        margin-top: 1rem;

        .text {
            font-size: 1rem;
        }
    }

    .order-item {
        border: 1px solid #ececec;
        margin-bottom: 0.4rem;

        .order-item-inner {
            padding: 7px;
        }

        .item-name {
            font-weight: bold;
            margin-bottom: 0.5rem;
            font-size: 1.3rem;
        }
    }

    .item-summary {
        display: flex;
        flex-direction: column;
        padding-left: 0;
        padding-right: 13px;
        position: relative;

        .remove-from-cart {
            position: absolute;
            top: 0;
            right: 13px;
            cursor: pointer;
        }
    }

    img {
        width: 100%;
        height: auto;
    }

    .item-price {
        text-align: right;
        font-size: 1.2rem;
        font-weight: bold;
        color: $primary-color;
    }
}
</style>


