var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "scroll-arrow-navigator", on: { click: _vm.onClick } },
    [
      _vm.right
        ? _c("i", { staticClass: "fas fa-chevron-right right" })
        : _vm._e(),
      _vm._v(" "),
      _vm.left
        ? _c("i", { staticClass: "fas fa-chevron-left left" })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }