var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "main-banner-outer" }, [
    _c(
      "div",
      {
        staticClass: "main-banner",
        style: { backgroundImage: "url(" + _vm.imageUrl + ")" },
      },
      [
        _c(
          "div",
          { staticClass: "main-banner__filter-buttons" },
          [
            _c("LFilterButtons", {
              attrs: {
                allnum: _vm.totals.all,
                embroiderynum: _vm.totals.embroidery,
                craftingnum: _vm.totals.crafting,
                fontsnum: _vm.totals.font,
                collectionsnum: _vm.totals.collection,
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "main-banner__body container" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12 main-banner__body__content" }, [
              _c("h1", { staticClass: "main-banner__h1" }, [
                _vm._v(_vm._s(_vm.title)),
              ]),
              _vm._v(" "),
              _c("h3", { staticClass: "main-banner__h3" }, [
                _vm._v(_vm._s(_vm.description)),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "main-banner__search" },
                [
                  _c("l-search-input", {
                    attrs: {
                      placeholder: "Search Design…",
                      shouldRedirectToSearchPage: "",
                    },
                    model: {
                      value: _vm.searchTxt,
                      callback: function ($$v) {
                        _vm.searchTxt = $$v
                      },
                      expression: "searchTxt",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm._m(0),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "main-banner__link" }, [
      _c("a", { attrs: { href: "/search" } }, [_vm._v("View all designs")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }