var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "collection-help", on: { click: _vm.openModal } },
    [
      _c("img", {
        staticClass: "help-icon",
        attrs: { src: "/static/icons/quick_help.svg", alt: "help" },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }