<template>
    <div class="mt-4 search-page-banner">
        <div class="text-container">
            <h2><span v-html="t('search.banner.title')"></span></h2>
            <p>{{ t("search.banner.text") }}</p>
        </div>
        <div class="images-container">
            <div class="image-container">
                <img src="../img/design-listing-header-1.jpg" alt="">
            </div>
            <div class="image-container">
                <img src="../img/design-listing-header-2.jpg" alt="">
            </div>
            <div class="image-container">
                <img src="../img/design-listing-header-3.jpg" alt="">
            </div>
        </div>
    </div>
</template>

<script>
import {
    translate
} from "../utils/utils";

export default {
    name: "SearchResultBanner",
    methods: {
        t(key, variables) {
            return translate(key, variables)
        }
    }
}
</script>

<style lang="scss">
@import "../globals";

.search-page-banner {
    background-color: $primary;
    padding: 20px 15px;
    overflow: hidden;
    border-radius: 10px;

    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    flex-direction: column;
    align-items: flex-start;

    @include media-breakpoint-up(md) {
        flex-direction: row;
        padding: 10px 15px;
        align-items: center;
    }

    .text-container {
        flex: 1 1 0;

        @include media-breakpoint-up(md) {
            flex: 60 1 0;
        }

        p,
        h2 {
            color: $white;
        }

        h2 {
            @media(max-width: 500px) {
                font-size: 6vw;
            }
        }

        p {
            margin-bottom: 15px;

            @include media-breakpoint-up(md) {
                margin-bottom: 0;
            }
        }
    }

    .images-container {
        flex: 1 1 0;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 -4px;

        @include media-breakpoint-up(md) {
            flex: 40 1 0;
            padding-left: 20px;
        }

        .image-container {
            flex: 1 1 0;
            margin: 4px;
            text-align: center;
            border-radius: 5px;
            overflow: hidden;

            img {
                max-width: 100%;
            }
        }
    }
}
</style>
