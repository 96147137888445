<template>
    <div>
<div v-if="allowedCountry && !isPurchased &&  price && !isLoading">
    <l-button type="small" color="pink" @click.native="addToCart">
        <IconsBase :iconName="inCart ? t('button.InCart') : price.display">
            <shopping-cart-icon/>
        </IconsBase>
        {{ inCart ? t('button.InCart') : price.display }}
    </l-button>
</div>
<l-button v-if="isLoading" type="small" style="height: 37px;" color="pink" >
   
            <div style="position:relative  ;height: 37px;  bottom: 40px;" class="loading-container d-flex align-items-center justify-content-center">
                    <div style=" background-color: #fff;" class="spinner-grow" role="status">
                        <span class="sr-only">{{ t("search.loading") }}</span>
                    </div>
                </div>
        
    </l-button>

</div>
</template>

<style scoped lang="scss">
    .loading-cover {

        background-color: #fff;
    }
</style>

<script>
import {
    mapGetters,
    mapMutations
} from 'vuex';
import {
    translate,
    userHasSubscription
} from "../utils/utils";
import store from "../store";
import {
    translatedAlertModal
} from "../components/alert-modal";
import IconsBase from "./icons/IconsBase.vue";
import ShoppingCartIcon from "./icons/common/ShoppingCartIcon.vue";
import LButton from "./buttons/LButton.vue";

export default {
    name: "AddToCart",
    components: { IconsBase, ShoppingCartIcon, LButton },
    props: {
        colprice:String,
        price: Object,
        isPurchased: Boolean,
        skuCode: String,
        designCode: String,
        nameOfDesign: String,
        allowedCountry: Boolean,
        free: Boolean,
        iscol: {
            type: Boolean,
            default: false
        },
        itemtype:String,
    },
    data() {
        return {
            loading: false,
            hasSubscription: userHasSubscription(),
            currency: window.global_currency_code || '',
        }
    },
    computed: {
        ...mapGetters([
            'designCodes',
        ]),
        ...mapMutations([
            'updateDesignCodes'
        ]),
        isLoading() {
            return this.loading;
        },
        inCart: function () {
            return this.designCodes.indexOf(this.designCode) !== -1;
        }
    },
    methods: {
        reportAnalytics() {
          //  if(!this.colprice){
                dataLayer.push({
                    ecommerce: null
                }); // Clear the previous ecommerce object.
                dataLayer.push({
                    event: "add_to_cart",
                    ecommerce: {
                        items: [{
                            item_id: this.skuCode,
                            item_name: this.nameOfDesign.replace(/['"]+/g, ''),
                            currency: this.currency,
                            price: this.price.price,
                            quantity: 1
                        }]
                    }
                });
                console.log(dataLayer);
           // }   
        },
        // reportAnalyticsbackub() {
        //     gtag("event", "add_to_cart", {
        //         currency: this.currency,
        //         value: this.price.price.toString(),
        //         items: [{
        //             item_id: this.skuCode,
        //             item_name: this.nameOfDesign,
        //             currency: this.currency,
        //             price: this.price.price.toString(),
        //             quantity: 1
        //         }]
        //     });
        // },
        addToCart() {
            if (!this.inCart && !this.isLoading && !this.isPurchased) {
                this.loading = true;
                const url = "/api/cart/add-to-cart?";
                xhr => {
                    xhr.setRequestHeader(
                        "Cache-Control",
                        "no-store, no-cache, must-revalidate, max-age=0"
                    );
                },
                $.ajax({
                    url: url,
                    method: "POST",
                    data: {
                        skucode: this.skuCode,
                        designcode: this.designCode,
                        nameofdesign: this.nameOfDesign,
                        type: this.iscol ? 'collection' : this.itemtype
                    },

                    success: data => {
                        let designCodes = [];
                        data.lineitems.forEach((e) => {
                            designCodes.push(e["designcode"])
                        })
                        store.commit('updateDesignCodes', {
                            designCodes
                        });
                        store.commit('updateCartItems', {
                            data
                        })
                    },
                    error: event => {
                        translatedAlertModal("design-page.add-to-cart.failed.headline", "design-page.add-to-cart.failed.text");
                    },
                    complete: () => {
                        this.reportAnalytics();
                        this.loading = false;
                    }
                });
            }
        },
        t(key) {
            return translate(key)
        },
    },
}
</script>
