var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "32",
        height: "32",
        viewBox: "0 0 32 32",
      },
    },
    [
      _c(
        "g",
        { attrs: { id: "length_height", transform: "translate(-6 -6)" } },
        [
          _c("rect", {
            attrs: {
              id: "Rectangle_5621",
              "data-name": "Rectangle 5621",
              width: "32",
              height: "32",
              transform: "translate(6 6)",
              fill: "none",
            },
          }),
          _vm._v(" "),
          _c("g", { attrs: { id: "Group_5637", "data-name": "Group 5637" } }, [
            _c("path", {
              attrs: {
                id: "Vector",
                d: "M28,14,22,8l-6,6",
                fill: "none",
                stroke: "#555",
                "stroke-linecap": "round",
                "stroke-linejoin": "round",
                "stroke-width": "2.333",
              },
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                id: "Vector-2",
                d: "M22,36V8",
                fill: "none",
                stroke: "#555",
                "stroke-linecap": "round",
                "stroke-linejoin": "round",
                "stroke-width": "2.333",
              },
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                id: "Vector-3",
                d: "M28,30l-6,6-6-6",
                fill: "none",
                stroke: "#555",
                "stroke-linecap": "round",
                "stroke-linejoin": "round",
                "stroke-width": "2.333",
              },
            }),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }