var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "price" }, [
    _vm.isPurchased
      ? _c("div", [
          _vm._v(_vm._s(_vm.t("design-page.in.cloud.design.price") + " ")),
        ])
      : _vm.inCart
      ? _c("div", [
          _vm._v(_vm._s(_vm.t("design-page.in.cart.design.price") + " ")),
        ])
      : _c("div", [_vm._v(_vm._s(_vm.priceDisplay))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }