<template>
    <div class="subscription-wrap">
        <div class="modal-header">
            <img style="width: 40px; height: 40px; padding: 3px;" src="/static/icons/btn_download.svg" alt>
            <p style="font-weight: bold; top:8px; position: relative;">{{ t("design.download-design") }}</p>
        </div>
        <div class="modal-body">

            <p v-if="isCollection || designtype=='embroidery'">
                {{ t("design.download-design-info") }} {{name}}<br />
                <PreferredFormat style="max-width: -webkit-fill-available;" v-if="designtype === 'embroidery' || isCollection"
                    @getSelectedFormat="handleSelectedFormat" :isStandAlone="true"></PreferredFormat>

            </p>
            <div class="subscription-wrap__actions">
                <LButton v-if="isCollection" color="pink" size="tiny" @click.native="downloadCollection()">{{
                    t(downloadText) }}
                </LButton>

                <LButton v-else color="pink" size="tiny" @click.native="download()">{{ t(downloadText) }}</LButton>
            </div>
        </div>
    </div>
</template>
<style scoped lang="scss">
.subscription-wrap {
    width: 100%;
    text-align: left;

    & h5 {
        font-family: soleil-semibold, Helvetica, Arial, sans-serif;
        font-size: 16px;
    }

    & p {
        font-size: 14px;
    }

    &__actions {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 5px;
    }

    .modal-body {
        & p {
            padding-left: 5px;
            font-size: 12px;
        }
    }

    .modal-header {
        display: inline-flex;
        border: none;
    }
}
</style>

<script>
import { translate, userHasSubscription } from "../utils/utils";
import PreferredFormat from "./PreferredFormat";
import { mapGetters } from "vuex";
import { EventBus } from "../utils/eventBus";
import { subscriptionModal } from "../components/subscription-modal";
import LButton from "./buttons/LButton.vue";

export default {
    name: "DownloadDesign",
    components: {
        PreferredFormat,
        LButton
    },
    props: {
        sku: String,
        name: String,
        isPurchased: Boolean,
        isCollection: Boolean,
        designtype: String,
    },
    data() {
        return {
            uploading: false,
            errorText: null,
            uploadSuccessful: false,
            filename: this.name,
            apiUrl: "/api/designs",
            clients: [],
            modalOpen: false,
            loading: false,
            errorState: false,
            targetClient: null,
            format: 'vp3',
            downloading: false,
        }
    },

    computed: {
        ...mapGetters([
            'preferredFormat',

        ]),
        downloadText() {
            return this.downloading ? "design.downloading" : "design.download"
        }
    },
    created() {
        this.format = this.preferredFormat;
    },
    methods: {

        handleSelectedFormat(format) {
            this.format = format;
        },
        download() {

            if (this.isCollection) {
                this.downloadCollection()
            } else {
                this.downloadDesign();
            }
        },

        downloadDesign() {

            if (!this.downloading) {
                this.downloading = true;
                if (this.designtype === "quilt") {
                    this.format = "hqv"
                }
                if (this.designtype === "crafting") {
                    this.format = "ccm"
                }
                $.ajax({
                    url: `/api/designs/${this.sku}/convert/${this.format}`,
                    method: "POST",
                    xhrFields: {
                        responseType: 'blob'
                    },
                    success: data => {
                        let a = document.createElement('a');
                        let url = window.URL.createObjectURL(data);
                        a.href = url;
                        a.download = `${this.sku}.${this.format}`;
                        document.body.append(a);
                        a.click();
                        a.remove();
                        window.URL.revokeObjectURL(url);
                        this.downloading = false;
                        this.$emit("closeModal");
                    },
                    error: event => {
                        console.log(event);
                        alert("Something went wrong");
                        this.downloading = false;
                    },
                });
            }
        },
        downloadCollection() {
            this.downloading = true;
            console.log(`/api/collections/${this.sku}/convert/${this.format}`);

            $.ajax({
                url: `/api/collections/${this.sku}/convert/${this.format}`,
                method: "POST",
                xhrFields: {
                    responseType: 'blob'
                },
                success: data => {
                    let a = document.createElement('a');
                    let url = window.URL.createObjectURL(data);
                    a.href = url;
                    a.download = `${this.sku}.zip`;
                    document.body.append(a);
                    a.click();
                    a.remove();
                    window.URL.revokeObjectURL(url);
                    this.downloading = false;
                    this.$emit("closeModal");
                },
                error: event => {
                    console.log(event);
                    alert("Something went wrong");
                    this.downloading = false;

                },
            });
        },
        t(key) {
            return translate(key)
        }
    }
}
</script>
