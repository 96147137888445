var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        id: "load_font",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        width: "32",
        height: "32",
        viewBox: "0 0 32 32",
      },
    },
    [
      _c("g", { attrs: { id: "Group_183978", "data-name": "Group 183978" } }, [
        _c(
          "g",
          {
            attrs: {
              id: "Group_183977",
              "data-name": "Group 183977",
              "clip-path": "url(#clip-path)",
            },
          },
          [
            _c("line", {
              attrs: {
                id: "Line_3468",
                "data-name": "Line 3468",
                x2: "14",
                transform: "translate(3 6)",
                fill: "none",
                "stroke-linecap": "round",
                "stroke-linejoin": "round",
                "stroke-width": "2",
              },
            }),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("line", {
        attrs: {
          id: "Line_3469",
          "data-name": "Line 3469",
          y1: "21",
          transform: "translate(10 6)",
          fill: "none",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
          "stroke-width": "2",
        },
      }),
      _vm._v(" "),
      _c("g", { attrs: { id: "Group_183980", "data-name": "Group 183980" } }, [
        _c(
          "g",
          {
            attrs: {
              id: "Group_183979",
              "data-name": "Group 183979",
              "clip-path": "url(#clip-path)",
            },
          },
          [
            _c("path", {
              attrs: {
                id: "Path_129119",
                "data-name": "Path 129119",
                d: "M24,27h-.389A2.611,2.611,0,0,1,21,24.389V11",
                fill: "none",
                "stroke-linecap": "round",
                "stroke-linejoin": "round",
                "stroke-width": "2",
              },
            }),
            _vm._v(" "),
            _c("line", {
              attrs: {
                id: "Line_3470",
                "data-name": "Line 3470",
                x2: "7",
                transform: "translate(18 15)",
                fill: "none",
                "stroke-linecap": "round",
                "stroke-linejoin": "round",
                "stroke-width": "2",
              },
            }),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }