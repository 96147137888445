var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.hasValidSubscription
    ? _c(
        "div",
        { staticClass: "subscription-info" },
        [
          _c("p", [_vm._v(_vm._s(_vm.t("subscription-info.pdp.text")))]),
          _vm._v(" "),
          _c(
            "l-button",
            {
              attrs: { type: "secondary", color: "pink", block: "true" },
              nativeOn: {
                click: function ($event) {
                  return _vm.openLibrarySubscriptionsLink.apply(null, arguments)
                },
              },
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.t("action-banner.subscribe-now")) +
                  "\n    "
              ),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }