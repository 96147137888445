var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "subscription-wrap" }, [
    _c("h5", [_vm._v(_vm._s(_vm.t("account.subscription_required_title")))]),
    _vm._v(" "),
    _c("p", [
      _vm._v("\n    " + _vm._s(_vm.t("account.subscription_required")) + " "),
      _c("br"),
      _vm._v(" "),
      _c(
        "a",
        {
          attrs: {
            href: "https://mysewnet.com/en/join-mysewnet/mysewnet-enabled-machines/",
          },
        },
        [_vm._v(_vm._s(_vm.t("mySewnet.Enabled-Machines")))]
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "subscription-wrap__actions" },
      [
        _c(
          "l-button",
          {
            attrs: { color: "pink", size: "tiny" },
            nativeOn: {
              click: function ($event) {
                return _vm.goToSubscribe.apply(null, arguments)
              },
            },
          },
          [_vm._v(_vm._s(_vm.t("account.subscription_cta")))]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }