var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "search-page" }, [
      _c("div", { staticClass: "search-page__box" }, [
        _c("div", { staticClass: "place-holder" }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "search-header" },
          [
            _c(
              "div",
              { staticClass: "search-header__input" },
              [
                _c("LSearchInput", {
                  attrs: { isOnSearchPage: _vm.isOnSearchPage },
                  on: { search: _vm.searchTextChanged },
                }),
                _vm._v(" "),
                _c("MobileFilterModal", {
                  on: {
                    searchWithFilter: _vm.searchWithFilter,
                    resetFilter: _vm.resetFilter,
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "search-header__info" }, [
              _c(
                "div",
                {
                  staticClass: "search-header__info-result",
                  style: { opacity: _vm.searchParams.q ? 1 : 0 },
                },
                [
                  _vm._v("\n                        There are "),
                  _c("span", [_vm._v(_vm._s(_vm.totalResultCount))]),
                  _vm._v(" results found for\n                        “"),
                  _c("span", [_vm._v(_vm._s(_vm.searchParams.q))]),
                  _vm._v("”\n                    "),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "search-header__info-sort" },
                [
                  _c("SortBy", {
                    attrs: {
                      sortParams: {
                        orderKey: this.searchParams.orderKey,
                        order: this.searchParams.order,
                      },
                    },
                    on: { sortChanged: _vm.sortChanged },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("Tags", {
              attrs: {
                categoryList: _vm.selectedCategories,
                techniqueList: _vm.selectedTechniques,
                fontList: _vm.selectedFontType,
                collectionList: _vm.selectedCollections,
              },
              on: {
                clearFilter: _vm.clearAllFilter,
                removeSpecificFilter: _vm.removeSpecificFilter,
              },
            }),
          ],
          1
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "search-body" }, [
      _c(
        "div",
        { staticClass: "search-body-filter" },
        [
          _c("FilterForSearch", {
            attrs: { isReactive: "" },
            on: { searchWithFilter: _vm.searchWithFilter },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "search-body-result" },
        [
          _c(
            "div",
            { staticClass: "category-list" },
            [
              _c("MainFilters", {
                attrs: {
                  total: _vm.totalResultCount,
                  activeFilter: _vm.searchParams.type
                    ? _vm.searchParams.type
                    : "all",
                },
                on: { filterChanged: _vm.setType },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("SearchResult", {
            attrs: {
              dataList: _vm.searchResults,
              isLoading: _vm.resultsLoading,
              isMoreResultsLoading: _vm.isMoreResultsLoading,
              total: _vm.totalResultCount,
              isGettingMore: _vm.isGettingMore,
            },
          }),
          _vm._v(" "),
          _vm.totalResultCount > _vm.searchResults.length
            ? _c(
                "div",
                { staticClass: "search-view-more" },
                [
                  _c(
                    "l-button",
                    {
                      attrs: { color: "pink" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.getResults(true)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.t("viewmore")))]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }