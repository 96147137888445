var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "latest-designs-container",
      class: { "is-loading": _vm.loading },
    },
    [
      _vm.loading
        ? _c(
            "div",
            {
              staticClass:
                "loading-container d-flex align-items-center justify-content-center",
            },
            [
              _c(
                "div",
                { staticClass: "spinner-grow", attrs: { role: "status" } },
                [
                  _c("span", { staticClass: "sr-only" }, [
                    _vm._v(_vm._s(_vm.t("search.loading"))),
                  ]),
                ]
              ),
            ]
          )
        : _c("div", [
            _c(
              "div",
              { staticClass: "horizontal-list-container" },
              [
                _c(
                  "tiny-slider",
                  {
                    staticClass: "design-slide-container",
                    attrs: {
                      items: 2,
                      slideBy: 2,
                      loop: false,
                      responsive: _vm.slideOptions.responsive,
                      controlsContainer: _vm.controlsContainerSelector,
                      nav: true,
                      navContainer: _vm.navContainerSelector,
                      controls: false,
                      preventScrollOnTouch:
                        _vm.slideOptions.preventScrollOnTouch,
                    },
                  },
                  _vm._l(_vm.computedCollections, function (design) {
                    return _c(
                      "div",
                      {
                        key: design.sku,
                        staticClass: "design-slide-item mt-3",
                      },
                      [_c("ListEntity", { attrs: { entity: design } })],
                      1
                    )
                  }),
                  0
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "controls-container",
                    class: _vm.controlsContainerClass,
                  },
                  [_vm._m(0), _vm._v(" "), _vm._m(1)]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "nav-container",
                    class: _vm.navContainerClass,
                  },
                  _vm._l(_vm.computedCollections.length, function (i) {
                    return _c("button", {
                      key: "key" + i,
                      staticClass: "navigation-dot",
                    })
                  }),
                  0
                ),
              ],
              1
            ),
          ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "left" }, [
      _c("i", { staticClass: "fas fa-chevron-left left" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "right" }, [
      _c("i", { staticClass: "fas fa-chevron-right right" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }