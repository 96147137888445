<template>
    <div class="studio-link-card">
        <p><a :href="link"><img :src="image" :alt="title" /></a></p>
        <div class="studio-link-card-text-content">
            <p v-if="categories"><small>{{ categories }}</small></p>
            <h3><a :href="link">{{ title }}</a></h3>
            <small class="published-date" v-if="publishDate">{{ publishDate }}</small>
            <p v-if="author" class="by-author"><small>{{t('design-page.studio.by')}} {{ author }}</small></p>
        </div>
    </div>
</template>
<script>
import {
    translate
} from "../utils/utils";
import Swiper from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';

export default {
    name: "StudioLinkCard",
    props: {
        title: String,
        description: String,
        categories: String,
        image: String,
        link: String,
        publishDate: String,
        author: String,
    },
    methods: {
        t(key) {
            return translate(key)
        }
    },
    mounted: function() {
        new Swiper("#studioLinksSwiper", {
            slidesPerView: 'auto',
            spaceBetween: 16,
            mousewheel: true,
            // Navigation arrows
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            }
        })
    }
}
</script>

<style lang="scss">
    .design-studiolinks {
        h2 {
            display: inline-block;
            width: 50%;
        }

        .pull-left {
            float: left;
        }

        .pull-right {
            float: right;
            text-align: right;
        }
    }

    .studio-link-card {
        border-radius: 10px;
        border: 1px solid #D8D8D8;
        overflow: hidden;
        padding: 0;

        min-width: 224px;
        max-width: 398px;
        width: 100%;

        a, p {
            color: #161614;
        }

        a {
            text-decoration: none;
        }

        a:hover {
            color: inherit;
        }

        p {
            margin-bottom: 0.5rem;
            font-size: 12px;
        }

        h3 {
            padding-top: 20px;
            font-size: 16px;
        }

        .studio-link-card-text-content {
            padding: 0 0.5rem 0 0.5rem;
        }

        .by-author {
            text-transform: capitalize;
        }
        .published-date{
            padding-top: 10px;
        }
    }

    .swiper {
        margin: 0;
    }

    .swiper-button-next,
    .swiper-button-prev {
        background-color: white;
        padding: 8px;
        border-radius: 100%;
        box-shadow: 0 10px 30px #0000003E;
        height: 44px;
        width: 44px;

        &::after {
            font-size: 16px;
            color: #161614;
        }

        &.swiper-button-disabled {
            display: none;
        }
    }

    .swiper-slide {
        width: 260px;
    }
</style>
