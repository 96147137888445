var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    { staticClass: "slider-thumb" },
    _vm._l(_vm.computedImages, function (image) {
      return _c(
        "li",
        { key: image.index },
        [
          _c("AdditionalImageThumbnail", {
            attrs: { "additional-image": image, "current-index": _vm.index },
            nativeOn: {
              click: function ($event) {
                return _vm.changeImage(image.index)
              },
            },
          }),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }