var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.entity.empty
    ? _c(
        "div",
        {
          staticClass: "list-entity",
          staticStyle: { border: "none !important" },
        },
        [
          _c("div", { staticClass: "list-entity-inner" }, [
            _c("div", { staticClass: "list-entity-body" }, [
              _c("div", { staticClass: "list-entity-image-wrapper itemimg" }, [
                _vm.entity.isPurchased
                  ? _c("span", { staticClass: "purchased-label" }, [
                      _vm._v(_vm._s(_vm.t("design.purchased-label"))),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "card-img-wrapper",
                    attrs: {
                      href: _vm.entity.isCollection
                        ? _vm.entity.collectionPageUrl
                        : _vm.entity.designPageUrl,
                    },
                  },
                  [
                    _c("img", {
                      directives: [
                        {
                          name: "lazy",
                          rawName: "v-lazy",
                          value: _vm.entity.thumbnail || _vm.noImageUrl,
                          expression: "entity.thumbnail || noImageUrl",
                        },
                      ],
                    }),
                  ]
                ),
                _vm._v(" "),
                _c("span", { on: { click: _vm.onFavoriteClick } }, [
                  !_vm.entity.isFavorite
                    ? _c("img", {
                        staticClass: "like-img",
                        attrs: { src: "/static/icons/like.svg", alt: "" },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.entity.isFavorite
                    ? _c("img", {
                        staticClass: "like-img",
                        attrs: {
                          src: "/static/icons/selected-heart.svg",
                          alt: "",
                        },
                      })
                    : _vm._e(),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "list-entity-buttons" },
                [
                  _c(
                    "div",
                    { staticClass: "list-entity-buttons-inner" },
                    [
                      !_vm.entity.isCollection
                        ? _c(
                            "div",
                            { staticClass: "card-design-share-btn" },
                            [
                              _c("SendToMachineButton", {
                                attrs: {
                                  sku: _vm.entity.sku,
                                  "is-purchased": _vm.entity.isPurchased,
                                  designname: _vm.entity.label,
                                  designimage: _vm.entity.thumbnail,
                                  designtype: _vm.entity.designtype,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("SaveDesignVaultButton", {
                        attrs: {
                          designtype: _vm.entity.designtype,
                          iscollection: _vm.entity.isCollection,
                          designimage: _vm.entity.thumbnail || _vm.noImageUrl,
                          designname: _vm.entity.label,
                          sku: _vm.entity.priceGroup,
                          "is-purchased": _vm.entity.isPurchased,
                          "price-display": _vm.entity.price
                            ? _vm.entity.price.display
                            : "",
                          allowedCountry: _vm.showPrice,
                          designCode: _vm.entity.sku,
                          nameOfDesign: _vm.entity.name,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.entity.isAvailableAsSinglePurchase && !_vm.canDownload
                    ? _c("AddToCart", {
                        attrs: {
                          skuCode: _vm.entity.priceGroup,
                          designCode: _vm.entity.sku,
                          price: _vm.entity.price,
                          nameOfDesign: _vm.entity.name,
                          allowedCountry: _vm.entity.allowedCountry,
                          free: _vm.free,
                          itemtype: _vm.entity.designtype,
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.canDownload
                    ? _c("DownloadButton", {
                        attrs: {
                          image: _vm.entity.thumbnail || _vm.noImageUrl,
                          sku: _vm.entity.sku,
                          name: _vm.entity.label,
                          designtype: _vm.entity.designtype,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "list-entity-footer" }, [
              _c("img", { attrs: { src: _vm.itemTypeImage, alt: "" } }),
              _vm._v(" "),
              _c("span", { staticClass: "design-name" }, [
                _vm._v(_vm._s(_vm.entity.label)),
              ]),
            ]),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }