<template>
    <div class="related-designs-wrap" >
        <l-accordion :isOpen="true" :title="t(this.actualTitle)">
            <products-swiper v-if="relatedDesigns.length>0" :products="relatedDesignsSkus()" />
        </l-accordion>
    </div>
</template>
<script>
import {
    translate,
    generalErrorCallback,
    handleFetchErrors
} from "../utils/utils";
import { alertModal } from "../components/alert-modal";
import ListEntity from "./card/ListEntity.vue";
import HorizontalList from "./HorizontalList";
import ProductsSwiper from "./slider/ProductsSwiper.vue";
import LAccordion from "./LAccordion.vue";

export default {
    name: "RelatedDesigns",
    data: () => ({
        apiUrl: "/api/designs",
        errorState: false,
        loading: true,
        relatedDesigns: [],
        actualType: null,
        actualTitle : null,
    }),
    props: {
        sku: String,
        type: String,
        title: String,
        iscollection: String,
    },
    components: {
        LAccordion,
        ListEntity,
        HorizontalList,
        ProductsSwiper,
    },
    created: function () {
        if (typeof this.type === "undefined") {
            this.actualType = 'designs';
        }

        if (this.type) {
            this.actualType = this.type;
        }

        if (typeof this.title === "undefined") {
            this.actualTitle = 'design-page.related-designs';
        }

        if (this.title) {
            this.actualTitle = this.title;
        }
        if(this.iscollection){
            this.actualTitle = 'collection-page.related-collections';
            this.actualType = 'collections';

        }
        this.fetchResult();
    },
    methods: {
        handleFavoriteClickEvent(favoritePromise, sku) {
            favoritePromise.then(handleFetchErrors)
                .then(function (result) {
                    if (result.success === false) {
                        // Aborts and runs catch function
                        throw Error(result.error);
                    }
                }).catch(function (error) {
                    // TODO show custom error message
                    alertModal();
                });
        },
        relatedDesignsSkus(){
            var relatedDesignsSkus = [];
            this.relatedDesigns.forEach(design => {
                relatedDesignsSkus.push(design.sku);
            });
            return JSON.stringify(relatedDesignsSkus);
        },
        fetchResult: function () {
            if(this.iscollection){
                this.apiUrl = "/api/collections"
            }
            var listType = this.actualType;
            if (this.type === 'font') {
                this.apiUrl = '/api/v2/fonts';
                listType='fonts';
            }
            const url = window.location.origin + this.apiUrl + "/" + this.sku + "/related";
            this.errorState = false;
            this.loading = true;
            $.getJSON(
                url,
                (data) => {
                    if (!data[listType]) {
                        return;
                    }
                    console.log(listType);
                    this.relatedDesigns = data[listType].map(hit => {          
                        return {
                            url: listType === 'designs' ? hit.designPageUrl : hit.fontPageUrl,
                            ...hit
                        }
                    });
                    this.loading = false;
                },
            ).fail(function (event) {
                this.errorState = true;
                generalErrorCallback(event, this.url);
            });
        },
        t(key) {
            return translate(key)
        }
    },
}
</script>

<style lang="scss" scoped>
.related-designs-wrap {
    margin-bottom: 20px;
}
</style>
