<template>
<div class="search-wrapper">
    <form v-on:submit="onSubmit" :action="searchUrl" ref="form" method="GET" v-bind:class="{'open':openSuggestion, 'has-filter-button':hasFilter}" class="header-search-form d-flex align-items-center">
        <div v-bind:class="{'top-suggestion-selected': topSuggestionActive}" class="input-wrapper flex-grow-1">
            <input type="text" class="search-input" :placeholder="t('search.inputplaceholder')" id="txtSearch" name="q" autocomplete="off" :value="value" @input="updateValue($event.target.value)" @keydown.enter='enter' @keydown.down='down' @keydown.up='up' @focus='focus' v-on-clickaway='focusout'>
            <div class="separator"></div>
            <div class="input-group-btn">
                <button class="btn" type="submit">
                    <i class="fas fa-search"></i>
                </button>
            </div>
            <ul class="dropdown-menu">
                <li v-for="(suggestion, index) in suggestions" v-bind:key="index" v-bind:class="{'active': isActive(index)}" @click="suggestionClick(index)" @mouseover="suggestionHover(index)" @mouseleave="suggestionHover(-1)">
                    <div class="suggestion" v-html="renderSuggestion(suggestion)"></div>
                </li>
            </ul>
        </div>
    </form>
</div>
</template>

<script>
import {
    getQueryParameterValue,
    composeUrlFromQueryVariables,
    generalErrorCallback,
    translate
} from "../utils/utils";
import {
    mixin as clickaway
} from 'vue-clickaway';

export default {
    name: "SearchInput",
    mixins: [clickaway],
    data: () => ({
        value: "",
        suggestions: [],
        open: false,
        current: -1,
        originalValue: "",
        hoverCurrent: -1,
    }),
    components: {
    },
    props: {
        searchUrl: String,
        isOnSearchPage: Boolean,
        hasFilter: Boolean
    },
    created: function () {
        this.value = getQueryParameterValue("q") || "";
        this.originalValue = this.value;
    },
    mounted() {
        this.$on('input', (value) => {
            this.value = value;
            this.originalValue = this.value;
        });
    },
    methods: {
        onSubmit: function (event = null) {
            this.value = this.value.trim().replace(/\s+/g, ' ');
            this.originalValue = this.value;
            this.open = false;

            if (this.isOnSearchPage) {
                if (event) {
                    event.preventDefault();
                }

                window.history.replaceState({}, document.title, composeUrlFromQueryVariables({
                    q: this.value
                }));
                this.$store.commit("updateQueryData", {
                    q: this.value
                });
            } else {
                this.$nextTick(() => {
                    this.$refs.form.submit();
                    this.$store.commit("clearSearchResult");
                });
            }
        //reloading the page to make sure the value change in the search input if we are in the search page with nothing found 
          location.reload();
        },

        fetchSuggestions: function () {
            const self = this;

            if (!self.value.length) return;

            const url = `${ window.location.origin }/api/v2/autocomplete?q=${ self.value }`

            $.getJSON(
                url,
                (data) => {
                    if (!data.length) return;
                    self.suggestions = data.map((suggestion) => {
                        return {
                            label: suggestion
                        };
                    });
                },
            ).fail(function (event) {
                self.errorState = true;
                generalErrorCallback(event, this.url);
            });
        },

        renderSuggestion: function (suggestion) {
            if (!suggestion.label.toLowerCase().includes(this.value.toLowerCase())) {
                return suggestion.label;
            }

            const startIndex = suggestion.label.toLowerCase().indexOf(this.value.toLowerCase());
            return `${ suggestion.label.slice(0, startIndex) }<strong>${ suggestion.label.slice(startIndex, startIndex + this.value.length) }</strong>${ suggestion.label.slice(startIndex + this.value.length) }`;
        },

        // Triggered the input event to cascade the updates to 
        // parent component
        updateValue(value) {
            if (this.open === false) {
                this.open = true
            }

            // Always reset current suggestion index when typing additional text
            this.current = -1

            this.$emit('input', value)
            this.suggestions = [];
            this.fetchSuggestions();
        },

        // Reopen suggestions dropdown when focus returns
        focus(event) {
            this.updateValue(event.target.value);
        },

        // While clicking away from search input, hide suggestions.
        focusout(event) {
            if (this.open === true) {
                this.open = false
            }
        },

        // When enter key pressed on the input
        enter(event) {
            if (this.suggestions.length >= this.current + 1 && this.current >= 0) {
                let suggestion = this.suggestions[this.current].label;
                this.$emit('input', suggestion)
                this.sendAutocompleteUsedEvent(suggestion);
            }

            this.open = false
            // Close mobile keyboard
            event.target.blur();
            this.onSubmit();
        },

        // When up arrow pressed while suggestions are open
        up(event) {
            event.preventDefault();

            if (this.suggestions.length > 0) {
                // If we are at index 0 (meaning the top suggestion), when we press up
                // again, we want to move "focus" from the selections list, meaning
                // this.current will become -1, which is the "unselected" status.
                if (this.current >= 0) {
                    this.current--;
                } else {
                    this.current = this.suggestions.length - 1;
                }

                if (typeof this.suggestions[this.current] != 'undefined') {
                    this.value = this.suggestions[this.current].label;
                } else {
                    // Reset to the value that was originally typed in the search field
                    this.value = this.originalValue;
                }
            }
        },

        // When down arrow pressed while suggestions are open
        down(event) {
            event.preventDefault();

            if (this.suggestions.length > 0) {
                if (this.current < this.suggestions.length - 1) {
                    this.current++;
                } else {
                    this.current = 0;
                }

                this.value = this.suggestions[this.current].label;
            }
        },

        // For highlighting element
        isActive(index) {
            return index === this.current
        },

        // When one of the suggestion is clicked
        suggestionClick(index) {
            let suggestion = this.suggestions[index].label;
            this.$emit('input', suggestion);
            this.sendAutocompleteUsedEvent(suggestion);
            this.open = false
            this.onSubmit();
        },

        suggestionHover(index) {
            this.hoverCurrent = index;
        },

        sendAutocompleteUsedEvent(suggestion) {
            const url = `${ window.location.origin }/api/mysewnet/event/library-autocomplete-used`;
            $.post(url, {
                suggestion: suggestion
            });
        },

        t(key) {
            return translate(key)
        }
    },
    computed: {
        openSuggestion() {
            return this.value !== '' &&
                this.suggestions.length !== 0 &&
                this.open === true
        },

        topSuggestionActive() {
            return this.current === 0 || this.hoverCurrent === 0;
        }
    },
}
</script>

<style lang="scss">
@import "../globals";

.header-search-form {
    position: relative;

    .input-wrapper {
        display: flex;
        flex-direction: row;
        align-items: stretch;

        border: solid;
        border-width: 0px;
        background-color: white;

        .separator {
            width: 2px;
            margin-top: 3px;
            margin-bottom: 3px;
            background: $secondary-bg-color;
            flex-basis: auto;
            flex-grow: 0;
            flex-shrink: 0;
        }

        >input {
            margin-left: 15px;
            flex-basis: 1px;
            flex-grow: 1;
            flex-shrink: 1;
            border: none;
            background-color: rgba(0, 0, 0, 0);
            outline: 0;
            font-family: "soleil-light", sans-serif;
            height: 45px;
            border: 1px solid #ADADAD;
            padding: 16px;
            border-radius: 8px;
        }

        .input-group-btn {
            flex-basis: auto;
            flex-grow: 0;
            flex-shrink: 0;
        }

        .dropdown-menu {
            position: absolute;
            width: calc(100% - 88px);
            // Adjust for border
            left: 15px;
            border-radius: 0 0 23px 23px;
            font-size: 14px;
            z-index: 900;
            box-shadow: none;
            border: 2px solid;
            border-color: $secondary-bg-color;
            margin: 0;
            padding-bottom: 0;
            padding-top: 0;
            overflow: hidden;
            background-color: $secondary-bg-color;
            background-clip: border-box;
            border-top: none;

            &:before {
                content: "";
                position: absolute;
                width: 100%;
                height: 2px;
                top: 0px;
                left: 0px;
                background: linear-gradient(to right, #ffffff 5%, $secondary-bg-color 5%, $secondary-bg-color 95%, #ffffff 95%, #ffffff 100%);
            }

            li {
                background-color: #ffffff;
                padding: 10px 17px;
                cursor: pointer;

                &.active {
                    background: $secondary-bg-color;
                }

                &:hover {
                    background: $secondary-bg-color;
                }

                .suggestion {
                    color: $body-text-color;
                    font-family: "soleil-light", sans-serif;

                    strong {
                        font-family: "soleil-semibold", sans-serif;
                    }
                }
            }
        }

        &.top-suggestion-selected .dropdown-menu {
            border-color: $secondary-bg-color;

            &:before {
                background: $secondary-bg-color;
            }
        }
    }

    &.open .dropdown-menu {
        display: block;
    }
}

.header-search-form {
    .input-wrapper {
        width: 100%;
        border-radius: 20px;

        &:before {
            height: 30px;
            top: 6px;
            right: 100px;
        }
    }

    &.open {
        .input-wrapper {
            border-radius: 20px 20px 20px 0;
        }
    }
}

@media(min-width: 768px) {

    // Desktop search
    .header-search-form {
        .input-wrapper {
            width: 270px;
            border-radius: 23px;

            &:before {
                height: 24px;
                top: 4px;
                right: 60px;
            }

            .dropdown-menu {
                width: calc(100% - 78px) !important;
            }
        }

        @media (max-width: 1069px) {
            .input-wrapper {
                width: 160px;
                transition: width 0.5s;

                &:focus-within {
                    width: 250px;
                }

                input {
                    text-overflow: ellipsis;
                }
            }
        }

        &.open {
            .input-wrapper {
                border-radius: 23px 23px 23px 0;
            }
        }
    }
}
</style>
