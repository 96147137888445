<template>
<span class="text">{{ this.displayDimension + " " + this.t("display-unit." + this.displayUnit)}}</span>
</template>

<script>
import {
    generalErrorCallback,
    translate
} from "../utils/utils";

export default {
    name: "DesignDimensions",
    created: function () {},
    methods: {
        mmToInch(dimension) {
            return parseFloat((dimension) / 25.4).toFixed(2).toString();
        },
        t(key) {
            return translate(key);
        }
    },
    computed: {
        displayUnit() {
                return this.$store.state.displayUnit;
        },
        displayDimension() {
            this.dimensioninmm = this.dimensioninmm.replace(",", ".");
            return (this.displayUnit === "inch") ? this.mmToInch(this.dimensioninmm) : this.dimensioninmm;
        },
    },
    props: {
        dimensioninmm: {
            type: String
        }
    },
}
</script>
