var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "32",
        height: "32",
        viewBox: "0 0 32 32",
      },
    },
    [
      _c("g", { attrs: { id: "cancel", transform: "translate(-860 -8)" } }, [
        _c(
          "g",
          {
            attrs: {
              id: "Group_14145",
              "data-name": "Group 14145",
              transform: "translate(758 2)",
            },
          },
          [
            _c("line", {
              attrs: {
                id: "Line_2137",
                "data-name": "Line 2137",
                x2: "16",
                y2: "16",
                transform: "translate(110 14)",
                fill: "none",
                "stroke-linecap": "round",
                "stroke-width": "2",
              },
            }),
            _vm._v(" "),
            _c("line", {
              attrs: {
                id: "Line_2138",
                "data-name": "Line 2138",
                x1: "16",
                y2: "16",
                transform: "translate(110 14)",
                fill: "none",
                "stroke-linecap": "round",
                "stroke-width": "2",
              },
            }),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }