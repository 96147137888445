const designSearch = {
    state: {
        queryData: {
            q: "",
            order: "",
            orderKey: ""
        }
    },
    getters: {
        designQueryData(state) {
            return state.queryData
        },
    },
    mutations: {
        updateDesignQueryData(state, payload) {
            state.queryData = {
                ...state.queryData,
                ...payload
            }
        },
    }
}

export default designSearch;
