var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "search-wrapper" }, [
    _c("div", { staticClass: "input-wrapper" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.value,
            expression: "value",
          },
        ],
        staticClass: "search-input",
        attrs: {
          type: "text",
          placeholder: _vm.searchPlaceholder,
          id: "txtSearch",
          name: "q",
          autocomplete: "off",
        },
        domProps: { value: _vm.value },
        on: {
          keydown: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            )
              return null
            return _vm.enter.apply(null, arguments)
          },
          input: function ($event) {
            if ($event.target.composing) return
            _vm.value = $event.target.value
          },
        },
      }),
      _vm._v(" "),
      _vm._m(0),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-btn" }, [
      _c("button", { staticClass: "btn", attrs: { type: "submit" } }, [
        _c("i", { staticClass: "fas fa-search" }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }