<template>
<div class="failed purchase">
    <div class="row">
        <div class="col-md-12 wrapper">
            <div class="align-center">
                <h3>{{ t("checkout.failed.top-headline") }}</h3>
                <i class="far fa-times-circle align-center" />
            </div>
            <div class="text" v-html='t("checkoutFree.failed.text")'></div>

            <button class="btn btn-block btn-lg black" @click="changeStep('Billing')" >{{ t("checkoutFree.failed.return-button-label") }}</button>
        </div>
    </div>
</div>
</template>

<style lang="scss">
@import "../../globals";

.failed {
    &.purchase {

        .align-center {
            text-align: center;
        }

        .text {
            margin-bottom: 3rem;
        }

        .fa-times-circle {
            font-size: 80px;
            margin-bottom: 3rem;
            color: $primary-color;
        }

        h3 {
            margin-bottom: 3rem;
        }

        .wrapper {
            padding-top: 20px;
            padding-bottom: 20px;
            max-width: 500px;
            margin: 0 auto;
        }

        .btn {
            text-transform: uppercase;
            font-weight: bold;
            padding: 14px;

            &.black {
                background: black;
                color: white;
            }
        }
    }
}
</style>

<script>
import {
    translate
} from "../../utils/utils";

export default {
    name: "FailedFree",
    props: ["handleChange"],
    methods: {
        changeStep(step) {
            this.$emit("handleChange", step);
        },
        t(key) {
            return translate(key)
        },
    },
}
</script>
