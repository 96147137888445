<template>
<div>
    <h1>{{computedcollection.sku}}</h1>
    <a class="card-img-wrapper" :href="computedcollection.url"><img v-lazy="category" /></a>
    <ListEntity :entity="computedcollection" @favoriteClickEvent="handleFavoriteClickEvent" />

</div>
</template>

<script>
import ListEntity from "./card/ListEntity.vue";
import {
    translate,
    handleFetchErrors
} from "../utils/utils";
import HorizontalList from './HorizontalList.vue';

export default {
    name: "CollectionBanner",
    created: function () {
        console.log("hello world");
    },
    components: {
        ListEntity,
        HorizontalList,
    },
    mounted: function () {
        console.log(this.buttonurl);
    },
    computed: {
        computedcollection: function () {
            if (typeof this.collection === 'string') {
                return JSON.parse(this.collection);
            }

            return this.collection;
        }
    },
    props: {
        collection: String,
    },
    methods: {
        handleFavoriteClickEvent(favoritePromise, sku) {
            favoritePromise.then(handleFetchErrors)
                .then(function (result) {
                    if (result.success === false) {
                        // Aborts and runs catch function
                        throw Error(result.error);
                    }
                }).catch(function (error) {
                    // TODO show custom error message
                    alertModal();
                });
        },
        t(key, variables) {
            return translate(key, variables)
        }
    }
}
</script>

<style lang="scss">
@import "../globals";

.collection-page-banner {
    background-color: $primary;
    padding: 20px 15px;
    overflow: hidden;
    border-radius: 10px;

    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    flex-direction: column;
    align-items: flex-start;

    @include media-breakpoint-up(md) {
        flex-direction: row;
        padding: 10px 15px;
        align-items: center;
    }

    .text-container {
        flex: 1 1 0;

        @include media-breakpoint-down(sm) {
            width: 100%;
        }

        @include media-breakpoint-up(md) {
            flex: 60 1 0;
        }

        p,
        h2 {
            color: $white;
        }

        h2 {
            @media(max-width: 500px) {
                font-size: 6vw;
            }
        }

        p {
            margin-bottom: 15px;

            @include media-breakpoint-up(md) {
                margin-bottom: 0;
            }
        }
    }

    .images-container {
        flex: 1 1 0;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 -4px;

        @include media-breakpoint-up(md) {
            flex: 40 1 0;
            padding-left: 20px;
        }

        .image-container {
            flex: 1 1 0;
            margin: 4px;
            text-align: center;
            border-radius: 5px;
            overflow: hidden;

            img {
                max-width: 100%;
            }
        }
    }

    .btn-primary-alt,
    .btn-primary {
        /* background-color: $secondary-bg-color;
            color: $primary-color; */
        border: 2px solid $secondary-bg-color;
        text-transform: uppercase;
        font-weight: 600;
        margin-top: 10px;
        margin-bottom: 5px;

        @include media-breakpoint-down(sm) {
            display: block !important;
        }

        @include media-breakpoint-up(md) {
            /* padding-left: 40px;
                padding-right: 40px; */
            width: 75%;
        }

        @include media-breakpoint-up(lg) {
            /* padding-left: 40px;
                padding-right: 40px; */
            width: 50%;
        }
    }
}
</style>
