<template>
    <div class="like-box" @click="onFavoriteClick">
        <IconsBase v-if="!isFavorite" iconColor="#000" iconName="Favorite">
            <favorite-icon />
        </IconsBase>
        <IconsBase v-else iconColor="#000" iconName="Favorite">
            <favorite-active-icon />
        </IconsBase>
    </div>
</template>

<script>
import {
    switchFavorite,
    generalErrorCallback,
    handleFetchErrors,
    isLoggedIn
} from "../utils/utils";
import {
    alertModal
} from "../components/alert-modal";
import {
    loginModal
} from "../components/login-modal";
import FavoriteActiveIcon from "./icons/common/FavoriteActiveIcon.vue";
import IconsBase from "./icons/IconsBase.vue";
import FavoriteIcon from "./icons/common/FavoriteIcon.vue";

export default {
    name: "FavoriteButton",
    components: {FavoriteIcon, IconsBase, FavoriteActiveIcon},
    data: () => ({
        apiUrl: "/api/designs/",
        isFavorite: false,
        isUpdating: false,
        loggedIn: isLoggedIn(),
    }),
    created: function () {
        if (typeof this.iscol !== 'undefined') {
            this.apiUrl = "/api/collections/"  ;
        }
        if(this.objecttype == "font"){
            this.apiUrl = "/api/v2/fonts/"  ;
        }

        this.checkIsFavorite();
    },
    methods: {
        onFavoriteClick: function () {
     
            if (this.isUpdating) {
                return;
            }

            if (isLoggedIn() === false) {
                loginModal();
                return;
            }

            this.isUpdating = true;

            const that = this;
            const favoritePromise = switchFavorite(this.sku, this.isFavorite,this.objecttype)
                .then(handleFetchErrors)
                .then(function (result) {
                    if (result.success === false) {
                        // Aborts and runs catch function
                        throw Error(result.error);
                    }
                }).catch(function (error) {
                    // TODO show custom error message
                    alertModal();
                }).finally(function () {
                    that.isUpdating = false;
                });

            this.isFavorite = !this.isFavorite;
        },
        
        checkIsFavorite: function () {
            const url = window.location.origin + this.apiUrl + this.sku;

            console.log( url);
            $.getJSON(
                url,
                (data) => {
                    this.isFavorite = data.isFavorite
                },
            ).fail((event) => {
                alertModal();
                generalErrorCallback(event, url);
            });
        },
    },
    props: {
        objecttype:String, 
        iscol: String,
        sku: {
            type: String,
            required: true
        },
    },
}
</script>
<style scoped>
.like-box {
    cursor: pointer;
    top: 6px;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    padding: 6px;
    width: 37px;
    height: 37px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}
</style>
