<script>
import LFilterButtons from "../buttons/LFilterButtons.vue";
import LSearchInput from "../input/LSearchInput.vue";
import LTag from "../LTag.vue";
import {generalErrorCallback, translate} from "../../utils/utils";
import $ from 'jquery';

export default {
    name: "MainPageBanner",
    components: { LFilterButtons, LTag, LSearchInput },
    data() {
        return {
            searchTxt: "",
            totals: {
                all: 0,
                embroidery: 0,
                crafting: 0,
                font: 0,
                collection: 0
            }
        }
    },
    props: {
        title: String,
        description: String,
        image: String,

    },
    computed: {
        imageUrl() {
            // Extract the src attribute from the HTML string
            const matches = this.image.match(/src="([^"]*)"/);
            return matches ? matches[1] : ''; // Return the extracted URL
        },
    },
    mounted() {
        this.fetchTotals();
    },
    methods: {
        t(key) {
            return translate(key)
        },
        fetchTotals() {
            const apiUrl = '/api/v2/totals';
            $.ajax({
                url: apiUrl,
                method: "GET",
                dataType: "json",
                beforeSend: xhr => {
                    xhr.setRequestHeader(
                        "Cache-Control",
                        "no-store, no-cache, must-revalidate, max-age=0"
                    );
                },
                success: data => {
                    this.totals = { ...data };
                },
                error: event => {
                    generalErrorCallback(event, apiUrl);
                }
            });
        }
    }
}
</script>

<template>
    <div class="main-banner-outer">
        <div class="main-banner" :style="{ backgroundImage: 'url(' + imageUrl + ')'}">
            <div class="main-banner__filter-buttons">
                <LFilterButtons :allnum="totals.all" :embroiderynum="totals.embroidery" :craftingnum="totals.crafting" :fontsnum="totals.font"
                                        :collectionsnum="totals.collection" />
            </div>
            <div class="main-banner__body container">
                <div class="row">
                    <div class="col-12 main-banner__body__content">
                        
                        <h1 class="main-banner__h1">{{ title }}</h1>
                        <h3 class="main-banner__h3">{{ description }}</h3>
                        <div class="main-banner__search">
                            <l-search-input v-model="searchTxt" placeholder="Search Design…" shouldRedirectToSearchPage></l-search-input>
                        </div>
                        <div class="main-banner__link">
                            <a href="/search">View all designs</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.main-banner-outer {
    padding-top: 149px; // Mobile header + pentachrome border
    margin-bottom: 40px;
}

.main-banner {
    color: var(--white);
    position: relative;
    display: block;
    text-align: center;
    margin: 0 auto;
    width: 100%;
    max-width: 1600px;
    background-size: cover;
    background-position: center;
    padding-top: 50px;
    padding-bottom: 50px;

    &__body {
        text-align: center;
        color: white;
        box-sizing: border-box;
        
        & .row {
            background-size: cover;
            background-position: center;
        }

        &__content {
            padding-right: 20px;
            padding-left: 20px;
        }
    }

    &__h1 {
        color: var(--white);
        font-size: 36px;
        margin-top: 25px;
    }

    &__h3 {
        color: var(--white);
        font-size: 16px;
        margin-top: 16px;
    }

    &__search {
        margin: 40px auto 0 auto;
        max-width: 790px;
        width: 100%;
    }

    &__frequent {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
    }

    &__link {
        margin-top: 40px;
        & a {
            color: var(--white);
            text-decoration: underline;
        }
    }


}

/* Tablet devices */
@media screen and (max-width: 1023px) {
    .main-banner-outer {
        padding-top: 103px; // Mobile header + pentachrome border
    }

    .main-banner {
        width: -webkit-fill-available;

        &__h1 {
            font-size: 22px;
        }

        &__h3 {
            font-size: 13px;
        }

        &__search {
            width: 80%;
        }

        &__link {
            margin-top: 20px;
        }
    }
}

/* Mobile devices */
@media screen and (max-width: 767px) {
    .main-banner-outer {
        padding-top: 93px; // Mobile header + pentachrome border
    }
    
    .main-banner {
        width: -webkit-fill-available;

        & img {
            min-height: 390px;
        }
        &__h1 {
            font-size: 20px;
        }

        &__h3 {
            font-size: 12px;
        }

        &__search {
            width: 98%;
        }

        &__frequent {
            & span {
                display: none;
            }
        }

        &__link {
            display: none;
        }
    }
}</style>
