var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "32",
        height: "32",
        viewBox: "0 0 32 32",
      },
    },
    [
      _c("g", { attrs: { id: "color", transform: "translate(46 -6)" } }, [
        _c("rect", {
          attrs: {
            id: "Rectangle_6643",
            "data-name": "Rectangle 6643",
            width: "32",
            height: "32",
            transform: "translate(-46 6)",
            fill: "none",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            id: "Path_7201",
            "data-name": "Path 7201",
            d: "M-16,21.973c0,1.953-.483,5.611-3.63,6.257s-3.219-2.406-5.9-1.9a3,3,0,0,0-2.685,3.28,3,3,0,0,0,.55,1.453,3.259,3.259,0,0,1-1.018,4.5,3.231,3.231,0,0,1-.882.387c-6.945,1.077-16.174-6.548-14.15-16.767a14,14,0,0,1,16.5-10.932A14,14,0,0,1-16,21.973Z",
            fill: "none",
            stroke: "#555",
            "stroke-width": "2",
          },
        }),
        _vm._v(" "),
        _c("circle", {
          attrs: {
            id: "Ellipse_107",
            "data-name": "Ellipse 107",
            cx: "1",
            cy: "1",
            r: "1",
            transform: "translate(-28 13)",
            fill: "#555",
            stroke: "#555",
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
            "stroke-width": "1",
          },
        }),
        _vm._v(" "),
        _c("circle", {
          attrs: {
            id: "Ellipse_107-2",
            "data-name": "Ellipse 107",
            cx: "1",
            cy: "1",
            r: "1",
            transform: "translate(-34 13)",
            fill: "#555",
            stroke: "#555",
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
            "stroke-width": "1",
          },
        }),
        _vm._v(" "),
        _c("circle", {
          attrs: {
            id: "Ellipse_107-3",
            "data-name": "Ellipse 107",
            cx: "1",
            cy: "1",
            r: "1",
            transform: "translate(-39 17)",
            fill: "#555",
            stroke: "#555",
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
            "stroke-width": "1",
          },
        }),
        _vm._v(" "),
        _c("circle", {
          attrs: {
            id: "Ellipse_107-4",
            "data-name": "Ellipse 107",
            cx: "1",
            cy: "1",
            r: "1",
            transform: "translate(-39 23)",
            fill: "#555",
            stroke: "#555",
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
            "stroke-width": "1",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }