// General app styling
import "./app.scss";

// JS libs
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import Vue from "vue";
import 'bootstrap';
import VueLazyload from 'vue-lazyload';
// State
import store from "./store";

// Global capture and reporting of errors to Rollbar
// Check for undefined, since we include Rollbar in 
// the layout.html.twig file, instead of importing in JS
Vue.config.errorHandler = (err, vm, info) => {
    if (typeof Rollbar !== 'undefined') {
        Rollbar.error(err);
    }

    throw err; // rethrow
};
import MainPageBanner from './vue-components/banners/MainPageBanner.vue';
Vue.component('mainpagebanner', MainPageBanner);
import ActionCallBanner from './vue-components/banners/ActionCallBanner';
Vue.component('actioncallbanner', ActionCallBanner);
import ProductSwiper from './vue-components/slider/ProductsSwiper.vue';
import StudioLinkCard from './vue-components/StudioLinkCard.vue';
Vue.component('studiolinkcard', StudioLinkCard);

Vue.component('productswiper', ProductSwiper);
import COMPONENTS from './vue-components';
for (const item in COMPONENTS) {
    Vue.component(item, COMPONENTS[item])
}
import { register } from 'swiper/element/bundle';
// register Swiper custom elements
register();

function startup() {
    Vue.use(VueLazyload, {
            preLoad: 1.3,
            attempt: 1
        })
        // Vue component loader
        // Starts a Vue instance on every element that has the data-vue attribute
    for (let element of document.querySelectorAll("[data-vue]")) {
        new Vue({ el: element, store });
    }

    $('[data-toggle="tooltip"]').tooltip()
}

function GetCurrentUrl(data) {
    return window.location.href + data;
}

window.addEventListener("load", startup);


//the new design 
import "./demo";
