var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "subscription-wrap" }, [
    _c("div", { staticClass: "modal-header" }, [
      _c("img", {
        staticStyle: { width: "40px", height: "40px", padding: "3px" },
        attrs: { src: "/static/icons/btn_download.svg", alt: "" },
      }),
      _vm._v(" "),
      _c(
        "p",
        {
          staticStyle: {
            "font-weight": "bold",
            top: "8px",
            position: "relative",
          },
        },
        [_vm._v(_vm._s(_vm.t("design.download-design")))]
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "modal-body" }, [
      _vm.isCollection || _vm.designtype == "embroidery"
        ? _c(
            "p",
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.t("design.download-design-info")) +
                  " " +
                  _vm._s(_vm.name)
              ),
              _c("br"),
              _vm._v(" "),
              _vm.designtype === "embroidery" || _vm.isCollection
                ? _c("PreferredFormat", {
                    staticStyle: { "max-width": "-webkit-fill-available" },
                    attrs: { isStandAlone: true },
                    on: { getSelectedFormat: _vm.handleSelectedFormat },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "subscription-wrap__actions" },
        [
          _vm.isCollection
            ? _c(
                "LButton",
                {
                  attrs: { color: "pink", size: "tiny" },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.downloadCollection()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.t(_vm.downloadText)) + "\n            ")]
              )
            : _c(
                "LButton",
                {
                  attrs: { color: "pink", size: "tiny" },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.download()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.t(_vm.downloadText)))]
              ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }