<template>
<div class="modal fade modal-library" id="sendToModal" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="sendDesignModalTitle" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="sendDesignModalTitle">{{ t("design-page.send-to") }}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <img src="/static/icons/close.png" alt>
                </button>
            </div>
            <div class="modal-body">
                <div class="container">
                    <div v-if="!isPurchased && !hasSub" class="row">
                        <div class="left-container col-12">
                            <div class="image-offset">
                                <img src="/static/icons/black-44.svg" alt>
                                <p class="bold">
                                    {{ t('send-to.design-sent-error-msg') }}
                                </p>
                            </div>
                            <a :href="loginUrl">{{ t('account.login_suggestion') }}</a>
                        </div>
                    </div>
                    <div v-else>
                        <div v-if="loading" class="col-12">
                            <div class="loading-container d-flex align-items-center justify-content-center">
                                <div class="spinner-grow" role="status"></div>
                            </div>
                        </div>
                        <div v-else class="row">
                            <!-- This should be shown after the design has finished sending to a client -->
                            <div v-if="designSent" class="left-container col-12">
                                <div class="image-offset">
                                    <img src="/static/icons/black-44.svg" alt>
                                    <p v-html="t('send-to.design-sent-success', { design: designname, machine: targetClient.name })">
                                    </p>
                                </div>
                                <div class="image-container">
                                    <img class="card-img" alt="" :src="thumbnail">
                                </div>
                            </div>

                            <!-- This should be shown after loading has finished, and some clients were found -->
                            <div v-else-if="compatible.length || notcompatible.length" class="left-container col-sm-8">
                                <div class="image-offset">
                                    <img src="/static/icons/black-44.svg" alt>
                                    <p>
                                        {{ t("send-to.heading-send-to") }} <span class="design-name">{{designname}}</span>
                                    </p>
                                </div>
                                <div class="client-list-wrapper">
                                    <div v-for="client in compatible" :key="client.clientId">
                                        <button type="button" @click="onSendDesignClick($event, client)" class="btn btn-block btn-primary client-button">{{ client.name }}</button>
                                    </div>
                                    <div v-if="notcompatible.length">
                                        <div v-for="client in notcompatible" :key="client.clientId">
                                            <button disabled role="button" class="btn btn-block btn-primary client-button">{{ client.name }}</button>
                                        </div>
                                    </div>
                                </div>
                                <a @click="refreshClientList" class="client-list-refresh"><img src="/static/icons/refresh.png" alt="">{{t('send_to.refresh')}}</a>
                                <a href="https://mysewnet.com/en/join-mysewnet/mysewnet-enabled-machines/">{{t('send-to.isenabled')}}</a>
                            </div>

                            <!-- This should be shown if no machines were found -->
                            <div v-else-if="!compatible.length && !notcompatible.length" class="left-container col-sm-8">
                                <div class="image-offset">
                                    <img src="/static/icons/black-44.svg" alt>
                                    <p class="bold">{{ t("send-to.no-clients") }}</p>
                                </div>
                                <p>
                                    {{t("sendDesign.not-loggedin",{designname:designname})}}
                                    
                                </p>
                                <a @click="refreshClientList" class="client-list-refresh"><img src="/static/icons/refresh.png" alt="">{{t('send_to.refresh')}}</a>

                                <a href="https://mysewnet.com/en/join-mysewnet/mysewnet-enabled-machines/">{{t('mySewnet.Enabled-Machines')}}</a>
                                <a href="https://mysewnet.com/knowledge-center/">{{t('send_to.how_to_sign_dvice')}}</a>
                            </div>
                            <div v-if="!designSent && !loading" class="right-container col-sm-4">
                                <div class="image-container">
                                    <img class="card-img" alt="" :src="thumbnail">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="!isPurchased && !hasSub" class="modal-footer">
                <button type="button" @click="goToItemPage" class="btn btn-secondary d-flex" data-bs-dismiss="modal">
                    <img src="/static/icons/cart-pink.svg" alt="cart">{{t('button.AddToCart')}}
                </button>
                <button type="button" @click="UpgradeSubscription" class="btn btn-primary">{{t('upgrade_subscription')}}</button>
            </div>
            <div v-else class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">{{t('Cancel')}}</button>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    generalErrorCallback,
    translate,
    userHasSubscription
} from "../utils/utils";
import {
    EventBus
} from "../utils/eventBus";
import AddToCart from './AddToCart.vue';
import {
    subscriptionModal
} from "../components/subscription-modal";

export default {
    name: "SendDesign",
    data: () => ({
        apiUrl: "/api/designs",
        clients: [],
        modalOpen: false,
        loading: true,
        errorState: false,
        designSent: null,
        targetClient: null,
        sku: '',
        isPurchased: false,
        designname: '',
        thumbnail: '',
        designtype: '',
        hasSub: !!userHasSubscription(),
        loginUrl: window.global_login_url,
    }),
    computed: {
        heading() {
            return this.errorState ? this.t("send-to.heading-error") :
                this.designSent ? this.t("send-to.heading-design-sent") :
                ""
        },
        icon() {
            return this.errorState ? "icon-send-to-failed" : "icon-send-to";
        },
        infotext() {
            return this.errorState ? this.t("send-to.clients.error") :
                this.designSent === true ? this.t('send-to.design-sent-success', {
                    design: this.designname,
                    machine: this.targetClient.name
                }) :
                this.designSent === false ? this.t("send-to.design-sent-failure") : ""
        },
        compatible() {
            return this.clients.filter(function (client) {
                return client.compatible;
            });
        },
        notcompatible() {
            return this.clients.filter(function (client) {
                return !client.compatible;
            });
        }
    },
    created: function () {
        // Listen for Bootstrap modal events and call Vue methods
        $("body").on("shown.bs.modal", this.onOpen);
        $("body").on("hidden.bs.modal", this.onClose);
    },
    mounted: function () {
        EventBus.$on('sendToMachineEvent', this.handleSendToMachineEvent);
    },
    props: {

    },
    methods: {
        UpgradeSubscription: function () {
            window.location.href = 'https://mysewnet.com/en/join-mysewnet/subscription-plans-pricing/';
        },
        goToItemPage: function () {
            window.location.href = "/search?q=" + this.designname;
        },
        refreshClientList: function () {
            this.modalOpen = true;

            let url = window.location.origin + "/api/mysewnet/clients?designtype=" + this.designtype;

            if (this.isPurchased) {
                url += '&supportsUnencrypted=1';
            }

            this.errorState = false;
            this.loading = true;
            $.getJSON(
                url,
                (data) => {
                    this.clients = data;
                },
            ).fail(event => {
                this.errorState = true;
                generalErrorCallback(event, url);
            }).always(event => {
                this.loading = false;
            });
        },
        clientIcon: function (type) {
            return type.includes("machine") ? "icon-machine" : "icon-premier";
        },
        handleSendToMachineEvent: function (sku, designname, designimage, isPurchased = false, designtype) {
            if (userHasSubscription() === false && !isPurchased) {
                subscriptionModal();
                return;
            }

            this.sku = sku;
            this.isPurchased = isPurchased;
            this.designname = designname;
            this.thumbnail = designimage;
            this.designtype = designtype;
            $(this.$el).modal('show');
        },
        onSendDesignClick: function (e, client) {
            e.preventDefault();
            const url = window.location.origin + "/api/mysewnet/send/" + this.sku;

            this.loading = true;
            this.designSent = null;
            this.targetClient = null;
            this.targetClientId = client.clientId;

            $.ajax({
                type: "POST",
                url: url,
                context: this,
                data: client,
                success: (data) => {
                    if (!data || data.success == false) {
                        this.designSent = false;
                    } else {
                        this.designSent = true;
                        this.targetClient = client;
                    }
                },
            }).fail(event => {
                this.designSent = false;
                generalErrorCallback(event, url);
            }).always(event => {
                this.loading = false;

                // Send analytics event.
                var parameters = {
                    sku: this.sku,
                    success: this.designSent,
                    targetClientId: this.targetClientId,
                };
                const url = window.location.origin + "/api/mysewnet/event/library-send-design";
                $.post(url, parameters);
            });
        },
        onClose: function (e) {
            if (e.target.id === "sendToModal") {
                this.modalOpen = false;
                this.designSent = null;
                this.targetClient = null;
            }
        },

        onOpen: function (e) {
            if (e.target.id === "sendToModal") {
                this.modalOpen = true;

                let url = window.location.origin + "/api/mysewnet/clients?designtype=" + this.designtype;

                if (this.isPurchased) {
                    url += '&supportsUnencrypted=1';
                }

                this.errorState = false;
                this.loading = true;
                $.getJSON(
                    url,
                    (data) => {
                        this.clients = data;
                    },
                ).fail(event => {
                    this.errorState = true;
                    generalErrorCallback(event, url);
                }).always(event => {
                    this.loading = false;
                });
            }
        },
        t(key, variables) {
            return translate(key, variables)
        }
    }
}
</script>
