var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page-back", class: { "has-bg": _vm.bg } }, [
    _c("div", { staticClass: "container p-0" }, [
      _c("i", {
        staticClass: "fas fa-arrow-left purple-action-icon",
        on: { click: _vm.onClick },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }