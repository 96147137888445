<template>
    <ul class="slider-thumb">
        <li v-for="image in computedImages" :key="image.index">
            <AdditionalImageThumbnail :additional-image="image" @click.native="changeImage(image.index)" :current-index="index" />
            <!-- Possibly uncomment the below line, to enable preloading the main image for each additional image -->
            <!-- <link rel="preload" as="image" :href="image.image"> -->
        </li>
    </ul>

 <!-- Alternate version, old / WIP -->
 <!-- <div class="product-main">
        <tiny-slider
            class="design-slide-container"
            
            v-bind="slideOptions"
        >
            <div 
                v-for="(image, index) in computedImages" 
                :key="image.index" 
                class="col module-gallery"
            >
                <AdditionalImageThumbnail :additional-image="image" @click.native="changeImage(index)" :current-index="currentIndex"/>
            </div>
        </tiny-slider>
        <div class="controls-container" :class="controlsContainerClass">
            <span class="left">
                <i class="fas fa-chevron-left left"></i>
            </span>
            <span class="right">
                <i class="fas fa-chevron-right right"></i>
            </span>
        </div>
    </div> -->
</template>

<script>
import VueTinySlider from 'vue-tiny-slider';
import AdditionalImageThumbnail from "./AdditionalImageThumbnail";

export default {
    name: "AdditionalImages",
    data: () => ({
        nextButton: `<i class="fas fa-chevron-right right"></i>`,
        prevButton: `<i class="fas fa-chevron-left left"></i>`,
        slideOptions: {
            /* nav: false,
            navContainer: '.tiny-slider-nav-container-additional-images', */
            controls: false,
            controlsContainer: '.tiny-slider-controls-container-additional-images',
            preventScrollOnTouch: 'force',
            items: 3,
            slideBy: 3,
            loop: false,
            axis: 'vertical',
            responsive: {
                768: {
                    items: 4,
                    slideBy: 4,
                    controls: true
                },
                1070: {
                    items: 2,
                    slideBy: 2,
                    controls: true
                }
            }
        },
        currentIndex: 1
    }),
    methods: {
        changeImage: function (index) {

            this.currentIndex = index;
            this.$emit('changeImageEvent', index);
            if (this.index > this.computedImages.length - 4) {
                this.index = this.computedImages.length - 5
            }

            if (this.currentIndex > this.computedImages.length - 4) {
                this.currentIndex = this.computedImages.length - 5
            }

        }
    },
    components: {
        'tiny-slider': VueTinySlider,
        AdditionalImageThumbnail
    },
    computed: {
        controlsContainerClass: function () {
            return this.slideOptions.controlsContainer.substring(1);
        },
        navContainerClass: function () {
            return this.slideOptions.navContainer.substring(1);
        },
        computedImages: function () {
            if (typeof this.images === 'string') {
                return JSON.parse(this.images);
            }

            return this.images;
        }
    },
    props: {
        images: [String, Array]
    },
}
</script>
