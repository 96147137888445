var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "l-tag", class: [_vm.color, { filled: _vm.filled }] },
    [
      _vm._t("default"),
      _vm._v(" "),
      _vm.closable
        ? _c(
            "button",
            { staticClass: "tag-close", on: { click: _vm.closeClicked } },
            [
              _c(
                "IconsBase",
                {
                  attrs: {
                    iconColor: _vm.filled ? "#fff" : "#000",
                    iconName: "Cancel",
                  },
                },
                [_c("cancel-icon")],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }