var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal fade modal-library",
      attrs: {
        id: "sendToModal",
        "data-backdrop": "static",
        "data-keyboard": "false",
        tabindex: "-1",
        "aria-labelledby": "sendDesignModalTitle",
        "aria-hidden": "true",
      },
    },
    [
      _c("div", { staticClass: "modal-dialog" }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "modal-header" }, [
            _c(
              "h5",
              {
                staticClass: "modal-title",
                attrs: { id: "sendDesignModalTitle" },
              },
              [_vm._v(_vm._s(_vm.t("design-page.send-to")))]
            ),
            _vm._v(" "),
            _vm._m(0),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "modal-body" }, [
            _c("div", { staticClass: "container" }, [
              !_vm.isPurchased && !_vm.hasSub
                ? _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "left-container col-12" }, [
                      _c("div", { staticClass: "image-offset" }, [
                        _c("img", {
                          attrs: { src: "/static/icons/black-44.svg", alt: "" },
                        }),
                        _vm._v(" "),
                        _c("p", { staticClass: "bold" }, [
                          _vm._v(
                            "\n                                    " +
                              _vm._s(_vm.t("send-to.design-sent-error-msg")) +
                              "\n                                "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("a", { attrs: { href: _vm.loginUrl } }, [
                        _vm._v(_vm._s(_vm.t("account.login_suggestion"))),
                      ]),
                    ]),
                  ])
                : _c("div", [
                    _vm.loading
                      ? _c("div", { staticClass: "col-12" }, [_vm._m(1)])
                      : _c("div", { staticClass: "row" }, [
                          _vm.designSent
                            ? _c(
                                "div",
                                { staticClass: "left-container col-12" },
                                [
                                  _c("div", { staticClass: "image-offset" }, [
                                    _c("img", {
                                      attrs: {
                                        src: "/static/icons/black-44.svg",
                                        alt: "",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("p", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.t("send-to.design-sent-success", {
                                            design: _vm.designname,
                                            machine: _vm.targetClient.name,
                                          })
                                        ),
                                      },
                                    }),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "image-container" },
                                    [
                                      _c("img", {
                                        staticClass: "card-img",
                                        attrs: { alt: "", src: _vm.thumbnail },
                                      }),
                                    ]
                                  ),
                                ]
                              )
                            : _vm.compatible.length || _vm.notcompatible.length
                            ? _c(
                                "div",
                                { staticClass: "left-container col-sm-8" },
                                [
                                  _c("div", { staticClass: "image-offset" }, [
                                    _c("img", {
                                      attrs: {
                                        src: "/static/icons/black-44.svg",
                                        alt: "",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("p", [
                                      _vm._v(
                                        "\n                                        " +
                                          _vm._s(
                                            _vm.t("send-to.heading-send-to")
                                          ) +
                                          " "
                                      ),
                                      _c(
                                        "span",
                                        { staticClass: "design-name" },
                                        [_vm._v(_vm._s(_vm.designname))]
                                      ),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "client-list-wrapper" },
                                    [
                                      _vm._l(_vm.compatible, function (client) {
                                        return _c(
                                          "div",
                                          { key: client.clientId },
                                          [
                                            _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn btn-block btn-primary client-button",
                                                attrs: { type: "button" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.onSendDesignClick(
                                                      $event,
                                                      client
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v(_vm._s(client.name))]
                                            ),
                                          ]
                                        )
                                      }),
                                      _vm._v(" "),
                                      _vm.notcompatible.length
                                        ? _c(
                                            "div",
                                            _vm._l(
                                              _vm.notcompatible,
                                              function (client) {
                                                return _c(
                                                  "div",
                                                  { key: client.clientId },
                                                  [
                                                    _c(
                                                      "button",
                                                      {
                                                        staticClass:
                                                          "btn btn-block btn-primary client-button",
                                                        attrs: {
                                                          disabled: "",
                                                          role: "button",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(client.name)
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          )
                                        : _vm._e(),
                                    ],
                                    2
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "a",
                                    {
                                      staticClass: "client-list-refresh",
                                      on: { click: _vm.refreshClientList },
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: "/static/icons/refresh.png",
                                          alt: "",
                                        },
                                      }),
                                      _vm._v(_vm._s(_vm.t("send_to.refresh"))),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: "https://mysewnet.com/en/join-mysewnet/mysewnet-enabled-machines/",
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.t("send-to.isenabled")))]
                                  ),
                                ]
                              )
                            : !_vm.compatible.length &&
                              !_vm.notcompatible.length
                            ? _c(
                                "div",
                                { staticClass: "left-container col-sm-8" },
                                [
                                  _c("div", { staticClass: "image-offset" }, [
                                    _c("img", {
                                      attrs: {
                                        src: "/static/icons/black-44.svg",
                                        alt: "",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("p", { staticClass: "bold" }, [
                                      _vm._v(
                                        _vm._s(_vm.t("send-to.no-clients"))
                                      ),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("p", [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(
                                          _vm.t("sendDesign.not-loggedin", {
                                            designname: _vm.designname,
                                          })
                                        ) +
                                        "\n                                    \n                                "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "a",
                                    {
                                      staticClass: "client-list-refresh",
                                      on: { click: _vm.refreshClientList },
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: "/static/icons/refresh.png",
                                          alt: "",
                                        },
                                      }),
                                      _vm._v(_vm._s(_vm.t("send_to.refresh"))),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: "https://mysewnet.com/en/join-mysewnet/mysewnet-enabled-machines/",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.t("mySewnet.Enabled-Machines")
                                        )
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: "https://mysewnet.com/knowledge-center/",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.t("send_to.how_to_sign_dvice")
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.designSent && !_vm.loading
                            ? _c(
                                "div",
                                { staticClass: "right-container col-sm-4" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "image-container" },
                                    [
                                      _c("img", {
                                        staticClass: "card-img",
                                        attrs: { alt: "", src: _vm.thumbnail },
                                      }),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]),
                  ]),
            ]),
          ]),
          _vm._v(" "),
          !_vm.isPurchased && !_vm.hasSub
            ? _c("div", { staticClass: "modal-footer" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary d-flex",
                    attrs: { type: "button", "data-bs-dismiss": "modal" },
                    on: { click: _vm.goToItemPage },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: "/static/icons/cart-pink.svg",
                        alt: "cart",
                      },
                    }),
                    _vm._v(
                      _vm._s(_vm.t("button.AddToCart")) + "\n                "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { type: "button" },
                    on: { click: _vm.UpgradeSubscription },
                  },
                  [_vm._v(_vm._s(_vm.t("upgrade_subscription")))]
                ),
              ])
            : _c("div", { staticClass: "modal-footer" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary",
                    attrs: { type: "button", "data-dismiss": "modal" },
                  },
                  [_vm._v(_vm._s(_vm.t("Cancel")))]
                ),
              ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          type: "button",
          "data-dismiss": "modal",
          "aria-label": "Close",
        },
      },
      [_c("img", { attrs: { src: "/static/icons/close.png", alt: "" } })]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "loading-container d-flex align-items-center justify-content-center",
      },
      [_c("div", { staticClass: "spinner-grow", attrs: { role: "status" } })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }