<template>
    <div>
        <l-button v-if="allowedCountry && !isPurchased && !isLoading"
                  color="pink"
                  block="true"
                  
                  @click.native="addToCart()">
            <template v-slot:left_icon>
                <IconsBase>
                    <shopping-cart-icon :iconName=" inCart ? t('button.InCart') : price"/>
                </IconsBase>
            </template>
            {{ inCart ? t('button.InCart') : (price !== 0 ? t('button.AddToCart') + price : price) }}
        </l-button>
        <l-button v-if="isLoading"  block="true" color="pink" >
   
   <div style="" class="loading-container d-flex align-items-center justify-content-center">
           <div style=" background-color: #fff;" class="spinner-grow" role="status">
               <span class="sr-only">{{ t("search.loading") }}</span>
           </div>
       </div>

</l-button>
    </div>

</template>

<script>
import {
    translate
} from "../utils/utils";
import {
    mapGetters,
    mapMutations
} from 'vuex';
import IconsBase from "./icons/IconsBase.vue";
import ShoppingCartIcon from "./icons/common/ShoppingCartIcon.vue";
import store from "../store";
import {
    translatedAlertModal
} from "../components/alert-modal";

export default {
    name: "AddToCartCollectionDetails",
    components: {ShoppingCartIcon, IconsBase},
    props: {
        currency: String,
        priceValue: String,
        catnames: String,
        price: String,
        isPurchased: Boolean,
        skuCode: String,
        collectionCode: String,
        nameOfCollection: String,
        catnames: String,
        allowedCountry: Boolean,
        free: Boolean
    },
    data() {
        return {
            loading: false,
        }
    },
    computed: {
        ...mapGetters([
            'designCodes',
        ]),
        ...mapMutations([
            'updateDesignCodes'
        ]),
        isLoading() {
            return this.loading;
        },
        inCart: function () {
            return this.designCodes.indexOf(this.collectionCode) !== -1;
        },
        compiledCatNames(){
            if (typeof this.catnames === 'string') {
                
                return JSON.parse(this.catnames);
            }

            return this.catnames;
        }
    },
    mounted:function(){
        
            console.log(this.catnames);
        
    },
    methods: {
        t(key) {
            return translate(key)
        },
        reportAnalytics() {
            var CatNames = this.compiledCatNames;
            dataLayer.push({
                ecommerce: null
            }); // Clear the previous ecommerce object.
            dataLayer.push({
                event: "add_to_cart",
                ecommerce: {
                    items: [{
                        item_id: this.skuCode,
                        item_name: this.nameOfCollection.replace(/['"]+/g, ''),
                        currency: this.currency,
                        item_category: CatNames[0],
                        item_category2: CatNames[1],
                        item_category3: CatNames[2],
                        item_category4: CatNames[3],
                        item_category5: CatNames[4],
                        price: this.priceValue,
                        quantity: 1
                    }]
                }
            });
            console.log(dataLayer);
        },
        addToCart() {
            if (!this.inCart && !this.isLoading && !this.isPurchased) {
                this.loading = true;
                const url = "/api/cart/add-to-cart?";
                xhr => {
                        xhr.setRequestHeader(
                            "Cache-Control",
                            "no-store, no-cache, must-revalidate, max-age=0"
                        );
                    },
                    $.ajax({
                        url: url,
                        method: "POST",
                        data: {
                            skucode: this.skuCode,
                            designcode: this.collectionCode,
                            nameofdesign: this.nameOfCollection,
                            type: 'collection'
                        },

                        success: data => {
                            let designCodes = [];
                            data.lineitems.forEach((e) => {
                                designCodes.push(e["designcode"])
                            })
                            store.commit('updateDesignCodes', {
                                designCodes
                            });
                            store.commit('updateCartItems', {
                                data
                            })
                        },
                        error: event => {
                            translatedAlertModal("design-page.add-to-cart.failed.headline", "design-page.add-to-cart.failed.text");
                        },
                        complete: () => {
                            this.reportAnalytics();
                            this.loading = false;
                        }
                    });
            }
        }
    },
}
</script>

<style lang="scss">
.add-to-cart-wrapper {
    .cart-loading {
        opacity: 0;
    }
}
</style>
