var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        width: "25",
        height: "25",
        viewBox: "0 0 25 25",
      },
    },
    [
      _c("defs", [
        _c("clipPath", { attrs: { id: "clip-path-purchased" } }, [
          _c("rect", {
            attrs: {
              id: "Rectangle_85981",
              "data-name": "Rectangle 85981",
              width: "25",
              height: "25",
              fill: "none",
              stroke: "#74a765",
              "stroke-width": "1.5",
            },
          }),
        ]),
      ]),
      _vm._v(" "),
      _c("g", { attrs: { id: "purchased", transform: "translate(0)" } }, [
        _c(
          "g",
          {
            attrs: {
              id: "Group_190744",
              "data-name": "Group 190744",
              transform: "translate(0)",
              "clip-path": "url(#clip-path)",
            },
          },
          [
            _c("circle", {
              attrs: {
                id: "Ellipse_1191",
                "data-name": "Ellipse 1191",
                cx: "2.5",
                cy: "2.5",
                r: "2.5",
                transform: "translate(17 19)",
                fill: "none",
                stroke: "#74a765",
                "stroke-linecap": "round",
                "stroke-linejoin": "round",
                "stroke-width": "1.5",
              },
            }),
            _vm._v(" "),
            _c("circle", {
              attrs: {
                id: "Ellipse_1192",
                "data-name": "Ellipse 1192",
                cx: "2.5",
                cy: "2.5",
                r: "2.5",
                transform: "translate(8 19)",
                fill: "none",
                stroke: "#74a765",
                "stroke-linecap": "round",
                "stroke-linejoin": "round",
                "stroke-width": "1.5",
              },
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                id: "Path_138995",
                "data-name": "Path 138995",
                d: "M7,6H25.1L23.526,17.017H8.574",
                transform: "translate(-1.492 -1.278)",
                fill: "none",
                stroke: "#74a765",
                "stroke-linecap": "round",
                "stroke-linejoin": "round",
                "stroke-width": "1.5",
              },
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                id: "Path_138996",
                "data-name": "Path 138996",
                d: "M2,2l1.387.015A2.19,2.19,0,0,1,5.55,3.91L7.267,17.417a2.191,2.191,0,0,0,2.187,1.9H21.831",
                transform: "translate(-0.427 -0.426)",
                fill: "none",
                stroke: "#74a765",
                "stroke-linecap": "round",
                "stroke-linejoin": "round",
                "stroke-width": "1.5",
              },
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                id: "Path_138997",
                "data-name": "Path 138997",
                d: "M14,12.361l2.623,2.361L21.87,10",
                transform: "translate(-2.983 -2.13)",
                fill: "none",
                stroke: "#74a765",
                "stroke-linecap": "round",
                "stroke-linejoin": "round",
                "stroke-width": "1.5",
              },
            }),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }