var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.allowedCountry && !_vm.isPurchased && _vm.price && !_vm.isLoading
        ? _c(
            "div",
            [
              _c(
                "l-button",
                {
                  attrs: { type: "small", color: "pink" },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.addToCart.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "IconsBase",
                    {
                      attrs: {
                        iconName: _vm.inCart
                          ? _vm.t("button.InCart")
                          : _vm.price.display,
                      },
                    },
                    [_c("shopping-cart-icon")],
                    1
                  ),
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.inCart ? _vm.t("button.InCart") : _vm.price.display
                      ) +
                      "\n    "
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isLoading
        ? _c(
            "l-button",
            {
              staticStyle: { height: "37px" },
              attrs: { type: "small", color: "pink" },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "loading-container d-flex align-items-center justify-content-center",
                  staticStyle: {
                    position: "relative",
                    height: "37px",
                    bottom: "40px",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "spinner-grow",
                      staticStyle: { "background-color": "#fff" },
                      attrs: { role: "status" },
                    },
                    [
                      _c("span", { staticClass: "sr-only" }, [
                        _vm._v(_vm._s(_vm.t("search.loading"))),
                      ]),
                    ]
                  ),
                ]
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }