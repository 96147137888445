<script>
import LAccordion from "../../LAccordion.vue";
import { composeUrlFromQueryVariables, translate } from "../../../utils/utils";
import { EventBus } from "../../../utils/eventBus";
export default {
    name: "FilterForSearch",
    components: { LAccordion },
    props: {
        isReactive: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            searchParams: {},
            expandedCategories: []
        }
    },
    watch: {
        searchType() {
            this.getSearchParams();
        },
    },
    computed: {
        params() {
            return this.$store.state.searchParams
        },
        searchType() {
            return this.$store.state.searchType
        },
        displayUnit() {
            return this.$store.state.displayUnit;
        },
        categoryList() {
            return this.$store.state.categoryList;
        },
        techniqueList() {
            return this.$store.state.techniqueList;
        },
        fontTypesList() {
            return this.$store.state.fontTypesList;
        },
        collectionTypesList() {
            return this.$store.state.collectionTypesList;
        },
        operationTypesList() {
            return this.$store.state.operationTypesList;
        },
        categoriesByCraftingType() {
            let categoriesByCraftingType = {
                'crafting_image': [],
                'crafting_project': [],
                'crafting': []
            };
            this.categoryList.forEach(category => {
                if (category) {
                    if (categoriesByCraftingType[category?.type])
                        categoriesByCraftingType[category?.type].push(category);
                }

            });
            return categoriesByCraftingType;
        }
    },
    mounted() {
        this.getSearchParams();
        EventBus.$on('dropFilterMobile', this.handleDropMobileFilter);
    },
    methods: {
        shouldExpandCategory(i) {
            return (this.expandedCategories.includes(i));
        },
        toggleCategory(i) {
            if (this.expandedCategories.includes(i)) {
                this.expandedCategories = this.expandedCategories.filter(category => category !== i);

            } else {
                this.expandedCategories.push(i);

            }
        },
        handleDropMobileFilter() {
            // drop filter but dont update anything
            this.searchParams = {
                ...this.searchParams,
                categories: [], // design
                techniques: [], // design
                widthMin: "", // design
                widthMax: "", // design
                heightMin: "", // design
                heightMax: "", // design
                colorBlockCountMax: "", // design
                stitchCountMax: "", // design
                designtype: "", // design
                craftingType: "", // design
                categoryType: "", // design
                fontType: [], // fonts
                typeOfContent: [], // collection
                myFavorites:"",
                myPurchases:"",
                operationType:[],
            }
            window.history.replaceState({}, document.title, composeUrlFromQueryVariables(this.searchParams));
            this.filterChanged();
        },
        getSearchParams() {
            const params = this.getSearchParamsFromUrl()
            this.searchParams = {
                type: params?.type ?? "", // all
                q: params.q ?? "", // all
                orderKey: params.orderKey ?? "", // all
                order: params.order ?? "", // all
                categories: params.categories ? params.categories.split(',') : [], // design
                techniques: params.techniques ? params.techniques.split(',') : [], // design
                widthMin: params.widthMin ?? "", // design
                widthMax: params.widthMax ?? "", // design
                heightMin: params.heightMin ?? "", // design
                heightMax: params.heightMax ?? "", // design
                colorBlockCountMax: params.colorBlockCountMax ?? "", // design
                stitchCountMax: params.stitchCountMax ?? "", // design
                designtype: params.designtype ?? "", // design
                craftingType: params.craftingType ?? "", // design
                categoryType: params.categoryType ?? "", // design
                fontType: params.fontType ? params.fontType.split(',') : [], // fonts
                typeOfContent: params.typeOfContent ? params.typeOfContent.split(',') : [],// collection
                operationType: params.operationTypesList ? params.operationTypesList.split(',') : [],// collection
                myFavorites: params.myFavorites ? "true" : "",
                myPurchases: params.myPurchases ? "true" : "",
            }
        },
        filterChanged() {
            this.$store.commit('setSearchParams', this.searchParams);
            if (this.isReactive) {
                this.$emit('searchWithFilter');
            }
        },
        getSearchParamsFromUrl() {
            const url = new URL(window.location.href);
            const searchParams = url.searchParams;
            const paramsObject = {};
            for (const [key, value] of searchParams) {
                paramsObject[key] = value;
            }
            return paramsObject;
        },
        t(key, variables) {
            return translate(key, variables)
        },
        decrementColorCount() {
            if (this.searchParams.colorBlockCountMax > 0) {
                this.searchParams.colorBlockCountMax -= 1;
                this.filterChanged();
            }
        },
        incrementColorCount() {
            let colorCount = this.searchParams.colorBlockCountMax ? Number(this.searchParams.colorBlockCountMax) : 0
            if (colorCount < 100) {
                this.searchParams.colorBlockCountMax = colorCount + 1;
                this.filterChanged();
            }
        },
        selectAllCategories(selectAll = true) {
            if (selectAll) {
                this.searchParams.categories = this.categoryList
                    .filter(category => category && category.id)  // Filter out null/undefined values
                    .map(category => String(category.id));
            } else {
                this.searchParams.categories = []
            }
            this.filterChanged();
        },
        selectAllTechniques(selectAll = true) {
            if (selectAll) {
                this.searchParams.techniques = this.techniqueList
                    .filter(technique => technique && technique.id)
                    .map(technique => String(technique.id));
            } else {
                this.searchParams.techniques = []
            }
            this.filterChanged();
        },
        selectAllCollections(selectAll = true) {
            if (selectAll) {
                this.searchParams.typeOfContent = this.collectionTypesList
                    .filter(collection => collection && collection.id)
                    .map(collection => String(collection.id));
            } else {
                this.searchParams.typeOfContent = []
            }
            this.filterChanged();
        }
    }
}
</script>

<template>
    <div>
        <l-accordion :title="t('search.searchin')" isOpen>


            <ul class="ms-checkbox-list">
                <li>
                    <label class="ms-checkbox-pink m-0">
                        <input v-model="searchParams.myFavorites" @change="filterChanged()" type="checkbox"
                            :value="String(params.myFavorites)">
                        <span class="checkmark"></span>
                        <span class="text"> {{ t("header.library.myFavorites") }}</span>
                    </label>
                </li>
            </ul>
            <ul class="ms-checkbox-list">
                <li>
                    <label class="ms-checkbox-pink m-0">
                        <input v-model="searchParams.myPurchases" @change="filterChanged()" type="checkbox"
                            :value="String(params.myPurchases)">
                        <span class="checkmark"></span>
                        <span class="text"> {{ t("header.library.myPurchases") }}</span>
                    </label>
                </li>
            </ul>
        </l-accordion>
        <template v-if="searchParams?.type === 'embroidery' || searchParams?.type === 'crafting'">
            <l-accordion :title="t('search.filter.categories.header')" isOpen>
                <slot>
                    <div class="select-wrap">
                        <div class="select-wrap-all" @click="selectAllCategories()"
                            :class="{ 'opacity-for-select': searchParams.categories.length === categoryList.length }">
                            {{ t('search.filter.selectAll') }}
                        </div>
                        <div class="select-wrap-clear" @click="selectAllCategories(false)"
                            :class="{ 'opacity-for-select': !searchParams.categories.length }">
                            {{ t('search.filter.clearAll') }}
                        </div>
                    </div>
                    <div v-for="category in categoryList" class="categorie-filter-type-wrapper"
                        v-if="searchParams?.type === 'embroidery'">
                        <ul  v-if="category" class="ms-checkbox-list">
                            <div style="display: flex;">
                            <li>
                                <label class="ms-checkbox-pink m-0">
                                    <input v-model="searchParams.categories" @change="filterChanged()" type="checkbox"
                                        :value="String(category?.id)">
                                    <span class="checkmark"></span>
                                    <span class="text">{{ category?.name }}</span>
                                </label>
                            </li>
                            <a style="padding-left: 50px;" v-if="category.categories.length && !shouldExpandCategory(category.id)"
                               
                                @click="toggleCategory(category.id)" type="button">+</a>
                            <a style="padding-left: 50px;" v-if="category.categories.length && shouldExpandCategory(category.id)"
                             
                                @click="toggleCategory(category.id)" type="button">-
                            </a>
                            </div>
                            <div style="position:relative; display: grid; left:30px;" v-if="shouldExpandCategory(category.id)">
                                <label v-for="subCategory in category.categories" class="ms-checkbox-pink m-0">
                                    <input v-model="searchParams.categories" @change="filterChanged()" type="checkbox"
                                        :value="String(subCategory?.id)">
                                    <span class="checkmark"></span>
                                    <span class="text">{{ subCategory?.name }}
                                    </span>
                                </label>
                            </div>
                        </ul>

                    </div>
                    <div v-if="searchParams?.type === 'crafting'">
                        <div v-for="(value, key) in categoriesByCraftingType" class="category-filter-type-wrapper">
                            <h5 v-if="value.length > 0">{{ t('search-filters.' + key.replace('_', '-') + 's') }}</h5>
                            <ul v-for="category in value" class="ms-checkbox-list">
                                <div style="display: flex;">

                                <li>
                                    <label class="ms-checkbox-pink m-0">
                                        <input v-model="searchParams.categories" @change="filterChanged()" type="checkbox"
                                            :value="String(category?.id)">
                                        <span class="checkmark"></span>
                                        <span class="text">{{ category?.name }}</span>
                                    </label>
                                </li>
                                <a style="padding-left: 50px;" v-if="category.categories.length && !shouldExpandCategory(category.id)"
                               
                               @click="toggleCategory(category.id)" type="button">+</a>
                           <a style="padding-left: 50px;" v-if="category.categories.length && shouldExpandCategory(category.id)"
                            
                               @click="toggleCategory(category.id)" type="button">-
                           </a>
                           </div>
                           <div style="position:relative;display: grid; left:30px;" v-if="shouldExpandCategory(category.id)">
                               <label v-for="subCategory in category.categories" class="ms-checkbox-pink m-0">
                                   <input v-model="searchParams.categories" @change="filterChanged()" type="checkbox"
                                       :value="String(subCategory?.id)">
                                   <span class="checkmark"></span>
                                   <span class="text">{{ subCategory?.name }}
                                   </span>
                               </label>
                           </div>
                            </ul>
                        </div>
                    </div>

                </slot>
            </l-accordion>
            <l-accordion v-if="searchParams?.type === 'crafting'" :title="t('search.filter.operationType.header')" isOpen>
                <slot>
                    <div v-for="operationType in operationTypesList" class="operation-filter-type-wrapper">
                        <ul>
                            <li>
                                <label class="ms-checkbox-pink m-0">
                                    <input v-model="searchParams.operationType" @change="filterChanged()" type="checkbox"
                                        :value="String(operationType?.id)">
                                    <span class="checkmark"></span>
                                    <span class="text">{{ operationType?.name }}</span>
                                </label>
                            </li>
                        </ul>
                    </div>
                </slot>
            </l-accordion>
            <l-accordion v-if="searchParams?.type === 'embroidery'" :title="t('search.filter.techniques.header')" isOpen>
                <slot>
                    <div class="select-wrap">
                        <div class="select-wrap-all" @click="selectAllTechniques()"
                            :class="{ 'opacity-for-select': searchParams.techniques.length === techniqueList.length }">
                            {{ t('search.filter.selectAll') }}
                        </div>
                        <div class="select-wrap-clear" @click="selectAllTechniques(false)"
                            :class="{ 'opacity-for-select': !searchParams.techniques.length }">
                            {{ t('search.filter.clearAll') }}
                        </div>
                    </div>
                    <div class="scroll-content">
                        <div v-if="techniqueList.length > 0" class="inner-scroll-wrapper">
                            <ul class="ms-checkbox-list">
                                <li v-for="technique in techniqueList">
                                    <label style="padding: 3px 3px 3px 20px;" class="ms-checkbox-pink m-0">
                                        <input v-model="searchParams.techniques" @change="filterChanged()" type="checkbox"
                                            :value="String(technique?.id)">
                                        <span class="checkmark"></span>
                                        <span class="text">{{ technique?.name }}</span>
                                    </label>
                                </li>
                            </ul>
                        </div>
                    </div>
                </slot>
            </l-accordion>
            <l-accordion v-if="searchParams?.type === 'embroidery'" :title="t('search.filter.designSize.header')" isOpen>
                <slot>
                    <div class="header-container">
                        <h3>{{ t("search.filter.designSize.header") }} ({{ t("display-unit." + displayUnit) }})</h3>
                    </div>
                    <div class="section-content design-size">
                        <div class="row">
                            <div class="col-6 position-relative title">
                                <i class="fas fa-arrows-alt-v"></i><span class="heading"><strong>{{
                                    t("search.filter.designSize.height") }}</strong></span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="p-2 position-relative m-1 rounded bg-grey measurement">
                                <div class="form-group py-1">
                                    <label><span class="icon icon-arrow-left-right"></span>{{
                                        t("search.filter.designSize.min") }}</label>
                                    <input v-model="searchParams.heightMin" type="number" class="form-control input-sm"
                                        min="1" value="" max="99999999" data-limit="99999999" @change="filterChanged">
                                </div>
                                <div class="form-group py-1">
                                    <label><span class="icon icon-arrow-left-right"></span>{{
                                        t("search.filter.designSize.max") }}</label>
                                    <input v-model="searchParams.heightMax" type="number" class="form-control input-sm"
                                        min="1" value="" max="99999999" data-limit="99999999" @change="filterChanged">
                                </div>
                            </div>
                            <div class="col-6 position-relative title ">
                                <i class="fas fa-arrows-alt-h"></i><span class="heading"><strong>{{
                                    t("search.filter.designSize.width") }}</strong></span>
                            </div>
                            <div class="p-2 position-relative m-1 rounded bg-grey measurement">
                                <div class="form-group py-1">
                                    <label><span class="icon icon-arrow-left-right"></span>{{
                                        t("search.filter.designSize.min") }}</label>
                                    <input v-model="searchParams.widthMin" type="number" class="form-control input-sm"
                                        min="1" value="" max="99999999" data-limit="99999999" @change="filterChanged">
                                </div>
                                <div class="form-group py-1">
                                    <label><span class="icon icon-arrow-left-right"></span>{{
                                        t("search.filter.designSize.max") }}</label>
                                    <input v-model="searchParams.widthMax" type="number" class="form-control input-sm"
                                        min="1" value="" max="99999999" data-limit="99999999" @change="filterChanged">
                                </div>
                            </div>
                        </div>
                    </div>
                </slot>
            </l-accordion>
            <l-accordion v-if="searchParams?.type === 'embroidery'" :title="t('search.filter.colorBlockCount.header')" isOpen>
                <slot>
                    <div class="counter-wrapper">
                        <label><span class="icon icon-stitch-1"></span>{{ t("search.filter.colorBlockCount.subHeader")
                        }}</label>
                        <div class="max-count-wrapper">
                            <i @click="decrementColorCount" style="font-size: 23px" class="fas fa-minus-circle pointer">
                            </i>
                            <input v-model="searchParams.colorBlockCountMax" type="number" class="form-control input-sm"
                                min="1" value="" max="99" data-limit="99" @change="filterChanged" />
                            <i @click="incrementColorCount" style="font-size: 23px" class="fas fa-plus-circle pointer">
                            </i>
                        </div>
                    </div>
                </slot>
            </l-accordion>
        </template>
        <template v-else-if="searchParams?.type === 'font'">
            <l-accordion :title="t('search.filter.categories.header')" isOpen>
                <slot>
                    <div class="select-wrap">
                        <div class="select-wrap-all" @click="selectAllCategories()"
                            :class="{ 'opacity-for-select': searchParams.categories.length === categoryList.length }">
                            {{ t('search.filter.selectAll') }}
                        </div>
                        <div class="select-wrap-clear" @click="selectAllCategories(false)"
                            :class="{ 'opacity-for-select': !searchParams.categories.length }">
                            {{ t('search.filter.clearAll') }}
                        </div>
                    </div>
                    <div v-for="category in categoryList" class="categorie-filter-type-wrapper">
                        <ul v-if="category" class="ms-checkbox-list">
                            <div style="display: flex;">

                            <li>
                                <label class="ms-checkbox-pink m-0">
                                    <input v-model="searchParams.categories" @change="filterChanged()" type="checkbox"
                                        :value="String(category?.id)">
                                    <span class="checkmark"></span>
                                    <span class="text">{{ category?.name }}</span>
                                </label>
                            </li>
                            <a style="padding-left: 50px;" v-if="category.categories.length && !shouldExpandCategory(category.id)"
                               
                               @click="toggleCategory(category.id)" type="button">+</a>
                           <a style="padding-left: 50px;" v-if="category.categories.length && shouldExpandCategory(category.id)"
                            
                               @click="toggleCategory(category.id)" type="button">-
                           </a>
                           </div>
                           <div style="position:relative; display: grid; left:30px;" v-if="shouldExpandCategory(category.id)">
                               <label v-for="subCategory in category.categories" class="ms-checkbox-pink m-0">
                                   <input v-model="searchParams.categories" @change="filterChanged()" type="checkbox"
                                       :value="String(subCategory?.id)">
                                   <span class="checkmark"></span>
                                   <span class="text">{{ subCategory?.name }}
                                   </span>
                               </label>
                           </div>
                        </ul>
                    </div>
                </slot>
            </l-accordion>
            <l-accordion :title="t('design.type')" isOpen>
                <div class="categorie-filter-type-wrapper" v-for="font in fontTypesList">
                    <ul class="ms-checkbox-list">
                        <li>
                            <label class="ms-checkbox-pink m-0">
                                <input v-model="searchParams.fontType" @change="filterChanged()" type="checkbox"
                                    :value="font?.id">
                                <span class="checkmark"></span>
                                <span class="text"> {{ t("search-filters.operation-type."+font?.name.toLowerCase()) }} </span>
                            </label>
                        </li>
                    </ul>
                </div>
            </l-accordion>
        </template>
        <template v-else-if="searchParams?.type === 'collection'">
            <l-accordion :title="'search.filter.type-of-content'" isOpen>
                <div class="select-wrap">
                    <div class="select-wrap-all" @click="selectAllCollections()"
                        :class="{ 'opacity-for-select': searchParams.typeOfContent.length === collectionTypesList.length }">
                        {{ t('search.filter.selectAll') }}
                    </div>
                    <div class="select-wrap-clear" @click="selectAllCollections(false)"
                        :class="{ 'opacity-for-select': !searchParams.typeOfContent.length }">
                        {{ t('search.filter.clearAll') }}
                    </div>
                </div>
                <div class="categorie-filter-type-wrapper" v-for="collection in collectionTypesList">
                    <ul class="ms-checkbox-list">
                        <li>
                            <label class="ms-checkbox-pink m-0">
                                <input v-model="searchParams.typeOfContent" @change="filterChanged()" type="checkbox"
                                    :value="collection?.id">
                                <span class="checkmark"></span>
                                <span class="text"> {{ collection?.name }} </span>
                            </label>
                        </li>
                    </ul>
                </div>
            </l-accordion>
        </template>
    </div>
</template>

<style scoped lang="scss">
.select-wrap {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    margin-bottom: 14px;

    &-all {
        border-bottom: 2px solid var(--black);
        cursor: pointer;
    }

    &-clear {
        border-bottom: 2px solid var(--black);
        cursor: pointer;
    }
}

.opacity-for-select {
    opacity: 0.4;
    cursor: auto;
}
</style>
