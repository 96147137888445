<script>
import CollectionIcon from "../../icons/common/CollectionIcon.vue";
import EmbroideryIcon from "../../icons/common/EmbroideryIcon.vue";
import FontsIcon from "../../icons/common/FontsIcon.vue";
import CraftingIcon from "../../icons/common/CraftingIcon.vue";
import IconsBase from "../../icons/IconsBase.vue";

import Swiper from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import $ from 'jquery';
import {generalErrorCallback, translate} from '../../../utils/utils';

export default {
    name: "MainFilters",
    components: {
        IconsBase,
        EmbroideryIcon,
        FontsIcon,
        CraftingIcon,
        CollectionIcon,
    },
    props: {
        total: {
            type: Number,
            default: "all",
        },
    },
    data: () => ({
        activeType: '', // {string<all | emb | craft | fonts | collections >} - button style types
        totals: {
            all: 0,
            embroidery: 0,
            crafting: 0,
            font: 0,
            collection: 0
        }
    }),
    watch: {
        searchType(type) {
            this.activeType = type;
        }
    },
    computed: {
        searchType() {
            return this.$store.state.searchType
        }
    },
    mounted() {
        new Swiper(this.$refs.swiper, {
            slidesPerView: 'auto',
            spaceBetween: 16,
        })
        this.fetchTotals();
    },
    methods: {
        t(key) {
            return translate(key);
        },
        setFilter(filterType) {
            this.$store.commit('setSearchType', filterType);
            this.$emit('filterChanged', filterType);
        },
        fetchTotals() {
            const apiUrl = '/api/v2/totals';
            $.ajax({
                url: apiUrl,
                method: "GET",
                dataType: "json",
                beforeSend: xhr => {
                    xhr.setRequestHeader(
                        "Cache-Control",
                        "no-store, no-cache, must-revalidate, max-age=0"
                    );
                },
                success: data => {
                    this.totals = { ...data };
                },
                error: event => {
                    generalErrorCallback(event, apiUrl);
                }
            });
        }
    }
}
</script>

<template>
    <div class="l-filter">
        <div ref="swiper" class="swiper">
            <div class="swiper-wrapper">
                <div class="swiper-slide">
                    <div :class="['l-filter__item no-icon', { 'all-active': !activeType || activeType === 'all' }]"
                         @click="setFilter('')">
                        {{ t('search-results.type.all') }} <span>({{totals.all}})</span>
                    </div>
                </div>

                <div class="swiper-slide">
                    <div :class="['l-filter__item', { 'emb-active': activeType === 'embroidery' }]"
                                                @click="setFilter('embroidery')">
                    <IconsBase :iconColor="activeType === 'embroidery'? '#fff' : '#000'">
                        <embroidery-icon/>
                    </IconsBase>
                        {{ t('search-results.type.embroideries') }} <span>({{totals.embroidery}})</span>
                </div>
                </div>
                <div class="swiper-slide">
                    <div :class="['l-filter__item', { 'craft-active': activeType === 'crafting' }]"
                         @click="setFilter('crafting')">
                        <IconsBase :iconColor="activeType === 'crafting'? '#fff' : '#000'">
                            <crafting-icon />
                        </IconsBase>
                        {{ t('search-results.type.crafting') }} <span>({{ totals.crafting }})</span>
                    </div>
                </div>
                <div class="swiper-slide">
                    <div :class="['l-filter__item', { 'fonts-active': activeType === 'font' }]"
                         @click="setFilter('font')">
                        <IconsBase :iconColor="activeType === 'font'? '#fff' : '#000'">
                            <fonts-icon/>
                        </IconsBase>
                        {{ t('search-results.type.fonts') }} <span>({{ totals.font }})</span>
                    </div>
                </div>
                <div class="swiper-slide">
                    <div :class="['l-filter__item', { 'collections-active': activeType === 'collection' }]"
                         @click="setFilter('collection')">
                        <IconsBase :iconColor="activeType === 'collection'? '#fff' : '#000'">
                            <collection-icon/>
                        </IconsBase>
                        {{ t('search-results.type.collections') }} <span>({{ totals.collection }})</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.l-filter {
    font-family: 'soleil-regular', sans-serif;
    color: var(--black);
    font-size: 16px;
    line-height: 1;
    &__item {
        display: inline-block;
        cursor: pointer;
        padding: 11px 12px;
        border: 1px solid #D8D8D8;
        border-radius: 12px;
        height: 44px;
        background-color: var(--white);
        &.no-icon {
            display: inline-block;
            justify-content: center;
            align-items: center;
        }
        & span {
            font-family: 'soleil-semibold', sans-serif;
        }
        &.all-active {
            border: 1px solid #367498;
            background-color: var(--green);
            color: var(--white);
        }
        &.emb-active {
            border: 1px solid #4E2765;
            background-color: var(--violet);
            color: var(--white);
        }
        &.craft-active {
            border: 1px solid #9D272B;
            background-color: var(--singer-red);
            color: var(--white);
        }
        &.fonts-active {
            border: 1px solid #9D272B;
            background-color: var(--singer-red);
            color: var(--white);
        }
        &.collections-active {
            border: 1px solid #D8D8D8;
            background-color: var(--orange);
            color: var(--white);
        }
    }
}
.swiper-slide {
    width: auto;
}
/* Tablet devices */
@media screen and (max-width: 1023px) {
    .l-filter {
        font-size: 14px;
        &__item {
            height: 40px;
        }
    }
}
/* Mobile devices */
@media screen and (max-width: 767px) {
    .l-filter {
        font-size: 12px;
        &__item {
            height: 38px;
        }
    }
}
</style>
