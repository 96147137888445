var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.showList,
          expression: "showList",
        },
      ],
      ref: "swiper",
      staticClass: "swiper",
    },
    [
      _c(
        "div",
        { staticClass: "swiper-wrapper" },
        [
          _vm._l(_vm.toolsList, function (item) {
            return [
              _c(
                "div",
                { staticClass: "swiper-slide" },
                [_c("ToolsSwiperItem", { attrs: { item: item } })],
                1
              ),
            ]
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "swiper-pagination" }),
      _vm._v(" "),
      _c("div", { staticClass: "swiper-button-prev" }),
      _vm._v(" "),
      _c("div", { staticClass: "swiper-button-next" }),
      _vm._v(" "),
      _c("div", { staticClass: "swiper-scrollbar" }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }