// A $( document ).ready() block.

$(document).ready(function() {
    /* Accordion for Locator */

    $('.increaseQty').on('click', function() {
        var $parentElm = $(this).parents('.qtySelector');
        var maxVal = $parentElm.find('.qtyValue').attr('max');
        $(this).addClass('clicked');
        setTimeout(function() {
            $('.clicked').removeClass('clicked');
        }, 100);
        var value = $parentElm.find('.qtyValue').val();
        if (value < maxVal) {
            value++;
        }
        $parentElm.find('.qtyValue').val(value);
    });
    // Decrease product quantity on cart page
    $('.decreaseQty').on('click', function() {
        var $parentElm = $(this).parents('.qtySelector');
        $(this).addClass('clicked');
        setTimeout(function() {
            $('.clicked').removeClass('clicked');
        }, 100);
        var value = $parentElm.find('.qtyValue').val();
        if (value > 1) {
            value--;
        }
        $parentElm.find('.qtyValue').val(value);
    });

    $('.nav-link').hover(
        function() {
            $(this).next().addClass('show');
            $(this).addClass('expanded');
        },
        function() {
            $(this).next().removeClass('show');
            $(this).removeClass('expanded');
        }
    );
    $('.dropdown-menu').hover(
        function() {
            $(this).addClass('show');
            $(this).prev().addClass('expanded');
        },
        function() {
            $(this).removeClass('show');
            $(this).prev().removeClass('expanded');
        }
    );
    $('.dropdown').hover(
        function() {
            $('.homepage-header').addClass('focus');
        },
        function() {
            $('.homepage-header').removeClass('focus');
        }
    );

    $('#hamburger-menu').click(function() {
        $('.mobile-side-nav').removeClass('d-none');
        $('.mobile-side-nav').addClass('d-block');
    });

    $('.icon-close').click(function() {
        $('.mobile-side-nav').removeClass('d-block');
        $('.mobile-side-nav').addClass('d-none');
    });

    $('.image-container').click(function() {
        var url = $(this).find('img').attr('src');
        $('.enlarge-img').find('.enlarge-tag').attr('src', url);
        $('.enlarge-img').modal('show');
    });

    $('.enlarge-img-close').click(function() {
        $('.enlarge-img').modal('hide');
    });

    $('.filter-btn').click(function() {
        $('.filter-container').addClass('d-block');
    });

    $('.back-filter').click(function() {
        $('.filter-container').removeClass('d-block');
    });
});
