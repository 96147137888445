var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.getMergedList.length,
          expression: "getMergedList.length",
        },
      ],
      staticClass: "l-active-filter",
    },
    [
      _c("span", { on: { click: _vm.clearAllFilters } }, [_vm._v("Clear All")]),
      _vm._v(" "),
      _vm._l(_vm.getMergedList, function (tag) {
        return _c(
          "div",
          [
            _c(
              "l-tag",
              {
                attrs: { closable: "" },
                on: {
                  close: function ($event) {
                    return _vm.removeSpecificFilter(tag)
                  },
                },
              },
              [_vm._v(_vm._s(tag.name))]
            ),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }