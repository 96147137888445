<template>
<div>
    <l-popper
        trigger="clickToOpen"
        :options="{
      placement: 'auto',
      modifiers: { offset: { offset: '0,10px' } }
    }">
        <div class="popper">
            <SubscriptionTooltip   v-if="isOpenTooltip"/>
            <DownloadDesign v-if="isOpenDownloadTooltip" :sku="sku" :name="name" :isPurchased="isPurchased==true"  :isCollection="isCollection==true" :designtype="designtype"/>
        </div>
        <l-button style="background-color:rgba(0, 0, 0, 0.3);" type="small"
                  slot="reference"
                
                  @click.native="openDownloadModal()"
        >
            <IconsBase iconName="Download">
                <download-icon/>
            </IconsBase>
        </l-button>
    </l-popper>
</div>
</template>

<script>
import Modal from "./Modal";
import {
    EventBus
} from "../utils/eventBus";
import DownloadDesign from "./DownloadDesign";
import LButton from "./buttons/LButton.vue";
import IconsBase from "./icons/IconsBase.vue";
import DownloadIcon from "./icons/common/DownloadIcon.vue";
import { translate, userHasSubscription } from "../utils/utils";
import Popper from 'vue-popperjs';
import SubscriptionTooltip from "./tooltip/SubscriptionTooltip.vue";

export default {
    name: "DownloadButtonItemDetails",
    props: {
        sku: String,
        name: String,
        isPurchased: Boolean,
        designtype: {
            type: String,
            default: ""
        },
        image: {
            type: String,
            default: ""
        },
        isCollection: {
            type: Boolean,
            default: false
        }
    },
    components: {
        SubscriptionTooltip,
        Modal,
        DownloadDesign,
        LButton,
        IconsBase,
        DownloadIcon,
        'l-popper': Popper
    },
    data() {
        return {
            isOpenDownloadTooltip:false,
            showDownloadModal: false,
            isOpenTooltip: false,
            hasSub: !!userHasSubscription(),
        }
    },
    computed: {

        shouldOfferSubscription() {
            return userHasSubscription() === false && !this.isPurchased;
        }
    },
    methods: {
        t(key) {
            return translate(key)
        },
        
        openDownloadModal() {
            
            if (this.shouldOfferSubscription) {
                this.isOpenTooltip = true
            }else{
                this.isOpenDownloadTooltip=true;
            }

        },
        closeModal() {
            this.showDownloadModal = false;
        },
    }
}
</script>
