<template>
<div class="unit-switch">
    <input type="radio" id="unit-mm" value="millimeter" v-model="displayUnit" @change="setDisplayUnit">
    <label :class="{ active: mmIsActive() }" for="unit-mm">{{ t("display-unit.millimeter") }}</label>
    <input type="radio" id="unit-inch" value="inch" v-model="displayUnit" @change="setDisplayUnit">
    <label :class="{ active: !mmIsActive() }" for="unit-inch">{{ t("display-unit.inch") }}</label>
</div>
</template>

<style lang="scss">
.unit-switch {
    input {
        margin-right: .15em;
        cursor: pointer;
    }

    label {
        font-size: 13px;
        cursor: pointer;
        padding: 0 3px;

        &:hover {
            border-bottom: 1px solid rgba(198, 26, 90, 0.5);
        }

        &.active {
            border-bottom: 1px solid #c61a5a;
        }
    }
}
</style>

<script>

import $ from "jquery";
import {
    translate,
    isLoggedIn
} from "../utils/utils";

export default {
    name: "UnitSwitch",
    computed: {
        displayUnit: {
            get() {
                return this.$store.state.displayUnit;
            },
            set(value) {
                this.$store.commit("updateDisplayUnit", value);
            }
        },
    },
    created: function () {
        this.getDisplayUnit();
    },
    methods: {
        getDisplayUnit: function () {
            const url = window.location.origin + "/api/user/unit";

            // Get value from user if it's not in local storage
            if (!this.displayUnit) {
                if (isLoggedIn()) {
                    $.getJSON(
                        url,
                        (data) => {
                            if (data.unit) {
                                this.$store.commit("updateDisplayUnit", data.unit);
                            } else {
                                this.$store.commit("updateDisplayUnit", "millimeter");
                                this.setDisplayUnit();
                            }
                        },
                    ).fail(() => {
                        this.$store.commit("updateDisplayUnit", "millimeter");
                        this.setDisplayUnit();
                    });
                } else {
                    this.$store.commit("updateDisplayUnit", "millimeter");
                }
            }
        },
        mmIsActive(){
            if  (this.displayUnit ==="millimeter"){return true;}
            else {return false;}
        },

        setDisplayUnit: function () {
            const url = window.location.origin + "/api/user/unit";
            const displayUnit = this.displayUnit;
            if (isLoggedIn()) {
                $.post(
                    url, {
                        unit: this.displayUnit
                    }
                ).always(event => {
                    // Send analytics event.
                    var parameters = {
                        unit: displayUnit,
                    };
                    const url = window.location.origin + "/api/mysewnet/event/library-set-unit";
                    $.post(url, parameters);
                });

                // Send analytics event.

                $.post()
            }
            
        },
        t(key, variables) {
            return translate(key, variables);
        }
    },
}
</script>
