<template>
    <div class="price">
        <div v-if="isPurchased">{{ t("design-page.in.cloud.design.price") + " " }}</div>
        <div v-else-if="inCart">{{ t("design-page.in.cart.design.price") + " "}}</div>
        <div v-else>{{ priceDisplay }}</div>
    </div>
</template>

<script>
import {
    mapGetters
} from 'vuex'
import {
    userHasSubscription
} from "../utils/utils";
import {
    translate
} from "../utils/utils";

export default {
    name: "AddToCartText",
    data() {
        return {
            hasSubscription: userHasSubscription(),
        }
    },
    props: {
        isPurchased: Boolean,
        priceDisplay: String,
        designCode: String,
    },
    computed: {
        ...mapGetters([
            'designCodes',
        ]),
        inCart: function () {
            return this.designCodes.indexOf(this.designCode) !== -1;
        },
    },
    methods: {
        t(key) {
            return translate(key)
        },
    }
}
</script>
