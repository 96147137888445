<template>
<div class="preferred-format">
    <select @change="changeFormat" :value="selectedFormat">
     <option v-for="option in options" :value="option.value" :key="option.value">
            {{ option.text }}
        </option>
    </select>
    <i class="fa fa-caret-down" ></i>
</div>
</template>

<style lang="scss">
.preferred-format {
    position: relative;
    display: inline-block;
    margin: 10px 0;

    select {
        border: 1px solid ;
        border-color: gray;
        padding: .6rem 1rem .6rem .6rem;
        border-radius: 10px;
        width: 350px;
        max-width: 100%;
        background-color: white;
        height: 45px;
        -webkit-appearance: none;
        -moz-appearance: none;
        padding-right: 20px;
        text-overflow: ellipsis;
    }

    .fa-caret-down,
    .fa-caret-up  {
        position: absolute;
        right: 10px;
        top: 12px;
        font-size: 1.28rem;
        color: #505050;
        pointer-events: none;
    }


    .fa-caret-up {
        margin-top: -1rem;
    }
}

.preferred-format-icon {
    font-size: 16px;
}
</style>

<script>
import {
    mapGetters,
    mapMutations,
    mapActions
} from 'vuex'
import store from "../store";
import {
    isLoggedIn
} from "../utils/utils";

export default {
    name: 'PreferredFormat',
    props: {
        isCheckout: {
            type: Boolean,
            default: false,
        },
        isStandAlone: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            options: [{
                    text: '.VP3 (for Husqvarna® Viking® and Pfaff®)',
                    value: 'vp3'
                },
                {
                    text: '.VP4 (for Husqvarna® Viking® and Pfaff®)',
                    value: 'vp4'
                },
                {
                    text: '.PES (for Brother® Baby Lock® and Bernina®)',
                    value: 'pes'
                },
                {
                    text: '.XXX (for Compucon® and Singer®)',
                    value: 'xxx'
                },
                {
                    text: '.JEF (for Janome® and Singer®)',
                    value: 'jef'
                },
                {
                    text: '.EXP (for Melco Expanded®)',
                    value: 'exp'
                },
                {
                    text: '.DST (for Tajima®)',
                    value: 'dst'
                },

            ],
        }
    },
    computed: {
        selectedFormat() {
            return this.isCheckout && this.designFormat ? this.designFormat : this.preferredFormat;
        },
        ...mapGetters([
            'preferredFormat',
            'designFormat'
        ]),
        ...mapMutations([
            'updatePreferredFormat',
            'updateDesignFormat'
        ]),
        ...mapActions([
            'fetchPreferredFormat'
        ])
    },
    created: function () {
        // If no preferredFormat exists in state, load from server.
        if (!this.preferredFormat && isLoggedIn()) {
            store.dispatch('fetchPreferredFormat');
        }
    },
    methods: {
        changeFormat: function (event) {
            const value = event.target.value;
            if (this.isStandAlone) {
                this.$emit("getSelectedFormat", value);
                return;
            }
            if (!this.isCheckout) {
                store.dispatch('storePreferredFormat', {
                    value
                })
                store.commit('updatePreferredFormat', {
                    value
                });
            } else {
                store.commit('updateDesignFormat', {
                    value
                })
            }
        },
    },
};
</script>
