<template>
<div class="collection-designs">
    <div class="mt-4">
        <h2 v-show="this.hideTitle === false">{{ t(this.title) }}</h2>
        <p v-show="this.hideDescription === false"> {{ t("designsincollection", { count: this.designs.length }) }}</p>
    </div>
    <div class="mt-4">
        <div v-if="loading" class="loading-container d-flex align-items-center justify-content-center">
            <div class="spinner-grow" role="status">
                <span class="sr-only">{{ t("search.loading") }}</span>
            </div>
        </div>
        <div v-if="designs.length > 0" class="row">
            <div v-for="design in designs" :key="design.sku" class="col-lg-3 col-md-4 col-sm-6 col-6 mt-4">
                <ListEntity :entity="design" @favoriteClickEvent="handleFavoriteClickEvent" />
            </div>
        </div>
        <div v-if="errorState" class="vue-error-message">
            {{ t("search.fetcherror") }}
        </div>
        <div v-if="loadingMore" class="loading-container d-flex align-items-center justify-content-center">
            <div class="spinner-grow" role="status"></div>
        </div>
        <div class="mt-4">
            <LoadMore v-if="hits > pageSize + offset" :onClick="loadMoreClick" />
        </div>
    </div>
</div>
</template>

<script>
import {
    generalErrorCallback,
    translate,
    handleFetchErrors
} from "../utils/utils";
import {
    alertModal
} from "../components/alert-modal";
import SimpleListEntity from "./SimpleListEntity";
import ListEntity from "./card/ListEntity.vue";
import LoadMore from "./LoadMore";
import SendDesign from "./SendDesign";

export default {
    name: "CollectionDesigns",
    data: () => ({
        designs: [],
        pageSize: 24,
        hits:0,
        offset: 0,
        loading: true,
        loadingMore: false,
        errorState: false,
    }),
    components: {
        SimpleListEntity,
        LoadMore,
        SendDesign,
        ListEntity,
    },
    props: {
        hideTitle: {
            type: String,
            default: "false"
        },
        title: {
            type: String,
            default: "categories.designs"
        },
        hideDescription: {
            type: String,
            default: "true"
        },
        collectiondesigns: {
            type: String,
            required: true
        }
    },
    created: function () {
        this.fetchResult();
    },
    computed: {
        computedCollection: function () {
            return JSON.parse(this.collectiondesigns);
        }
    },
    methods: {
        t(key, variables) {
            return translate(key, variables)
        },
        fetchResult: function (loadmore) {
            this.errorState = false;
            this.loading = loadmore ? false : true;
            this.loadingMore = loadmore ? true : false;
            this.designs = this.computedCollection;
            this.designs.forEach(design => design.url=design.designPageUrl);
            this.hits = this.designs.length;
            this.loading = false;
        },
        loadMoreClick() {
            this.offset = this.offset > 0 ? this.offset + this.pageSize : this.pageSize;
            this.fetchResult(true);
        },
        handleFavoriteClickEvent(favoritePromise, sku) {
            favoritePromise.then(handleFetchErrors)
                .then(function (result) {
                    if (result.success === false) {
                        // Aborts and runs catch function
                        throw Error(result.error);
                    }
                }).catch(function (error) {
                    // TODO show custom error message
                    alertModal();
                });
        },

    },
}
</script>

<style lang="scss">
@import "../globals";

.collection-designs {
    a {
        color: $body-text-color;
    }

    .loading-container {
        min-height: 100px;
    }
}
</style>
