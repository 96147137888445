<script>
import { EventBus } from "../../utils/eventBus";
import {translate} from '../../utils/utils';
export default {
  name: "ActionCallBanner",
  props: {
    title: String,
    description: String,
    contenttype: String,
    image: String,
  },
  data() {
    return {
      shouldShow: true,
    }
  },
  mounted() { 
    EventBus.$on('filterChanged', this.filterChangedEvent);
  },
  methods: {

    t(key) {
      return translate(key)
    },
    filterChangedEvent(filterType) {
      this.shouldShow = filterType === this.contenttype
    },
  },
  computed: {
    imageUrl() {
      // Extract the src attribute from the HTML string
      const matches = this.image.match(/src="([^"]*)"/);
      console.log(this.image);
      return matches ? matches[1] : ''; // Return the extracted URL
    },
  },
}
</script>

<template>
  <div v-show="shouldShow" style="padding-bottom: 150px;">
    <div class="banner-wrapper">
      <div class="banner-wrapper__icon">
        <img :src="imageUrl" style=" max-height: 128px; max-width: 128px;" />
      </div>
      <div class="banner-wrapper__info">{{ title }}</div>
      <div class="banner-wrapper-action">
        <div class="banner-wrapper-action__price">
          <span v-html="description"></span>
        </div>
        <button  class="banner-wrapper-action__button">
          <a style="color: white; text-decoration: none;" href="https://mysewnet.com/products-and-plans/embroidery">
          <span >{{ t("action-banner.subscribe-now") }}</span>
          </a>
        </button>
      </div>
      <button  class="banner-wrapper-mobile-action">
        <a style="color: white; text-decoration=none" href="https://mysewnet.com/products-and-plans/embroidery">
        <span href="https://mysewnet.com/products-and-plans/embroidery" >{{ t("action-banner.subscribe-now") }}</span>
      </a>
      </button>
    </div>
  </div>
</template>

<style scoped lang="scss">
.banner-wrapper {
  display: flex;
  justify-content: space-between;
  gap: 31px;
  align-items: center;
  border: 1px solid #9090904D;
  border-radius: 12px;
  background-color: #F5F5F5;
  padding: 30px 42px;
  margin-bottom: 20px;

  &__icon {

    color: purple;
    border: 5px solid white;
    border-radius: 50%;
  }

  &__info {
    max-width: 1030px;
    font-size: 22px;
    text-align: left;

    & span {
      font-family: 'soleil-semibold', sans-serif;
      color: var(--violet);
    }
  }

  &-action {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__price {
      text-align: center;

      & span {
        font-family: 'soleil-semibold', sans-serif;
      }
    }

    &__button {
      display: flex;
      text-align: center;
      justify-content: center;
      align-items: center;
      border: none;
      width: 240px;
      cursor: pointer;
      outline: none;
      border-radius: 8px;
      text-transform: uppercase;
      transition: .3s;
      margin: 10px 0;
      box-sizing: border-box;
      font-family: 'soleil-semibold', sans-serif;
      font-size: 14px;
      padding: 19px 16px;
      background-color: var(--violet);
      color: var(--white);

      &:hover {
        background-color: var(--violet-light);
      }
    }
  }

  &-mobile-action {
    display: none;
    text-align: center;
    justify-content: center;
    align-items: center;
    border: none;
    width: 100%;
    cursor: pointer;
    outline: none;
    border-radius: 8px;
    text-transform: uppercase;
    transition: .3s;
    box-sizing: border-box;
    font-family: 'soleil-semibold', sans-serif;
    font-size: 14px;
    padding: 19px 16px;
    background-color: var(--violet);
    color: var(--white);

    &:hover {
      background-color: var(--violet-light);
    }
  }
}

.hidden {
  display: none;
}

/* Tablet devices */
@media screen and (max-width: 1023px) {
  .banner-wrapper {
    &__info {
      font-size: 14px;
    }
  }
}

/* Mobile devices */
@media screen and (max-width: 767px) {
  .banner-wrapper {
    flex-direction: column;
    border: 2px solid var(--tint-violet);
    position: relative;
    padding-bottom: 90px;

    &__info {
      font-size: 14px;
    }

    &-action {
      display: none;
    }

    &-mobile-action {
      display: flex;
      position: absolute;
      bottom: 0;
    }
  }
}
</style>
