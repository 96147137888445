var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "l-popper",
        {
          attrs: {
            trigger: "clickToOpen",
            options: {
              placement: "auto",
              modifiers: { offset: { offset: "0,10px" } },
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "popper" },
            [
              _c("SubscriptionTooltip", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isOpenTooltip,
                    expression: "isOpenTooltip",
                  },
                ],
              }),
              _vm._v(" "),
              _c("SaveDesignVault", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isOpenUploadTooltip,
                    expression: "isOpenUploadTooltip",
                  },
                ],
                attrs: {
                  sku: _vm.sku,
                  designCode: _vm.designCode,
                  designtype: _vm.designtype,
                  designs: null,
                  nameOfDesign: _vm.nameOfDesign,
                  designname: _vm.designname,
                  isPurchased: _vm.isPurchased == true,
                  priceDisplay: _vm.priceDisplay,
                  allowedCountry: _vm.allowedCountry,
                  iscollection: "true",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "l-button",
            {
              class: { "to-cloud-faded": !_vm.hasSub },
              staticStyle: { "background-color": "rgba(0, 0, 0, 0.3)" },
              attrs: { slot: "reference", type: "small" },
              nativeOn: {
                click: function ($event) {
                  return _vm.onClick()
                },
              },
              slot: "reference",
            },
            [
              _c(
                "IconsBase",
                { attrs: { iconName: _vm.t("upload.tocloud.modal.header") } },
                [_c("upload-icon")],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }