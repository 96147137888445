<template>
<div class="latest-designs-container" v-bind:class="{ 'is-loading': loading }">
    <div v-if="loading" class="loading-container d-flex align-items-center justify-content-center">
        <div class="spinner-grow" role="status">
            <span class="sr-only">{{ t("search.loading") }}</span>
        </div>
    </div>
    <div v-else>
        <HorizontalList v-bind:use-simple-list-entity="true" v-bind:designs="designs" />
    </div>
</div>
</template>

<script>
import {
    translate,
    generalErrorCallback,
    handleFetchErrors
} from "../utils/utils";
import {
    alertModal
} from '../components/alert-modal';
import HorizontalList from "./HorizontalList";

export default {
    name: "LatestDesigns",
    data: () => ({
        loading: true,
        designs: []
    }),
    components: {
        HorizontalList,
    },
    mounted: function () {
        this.fetchDesigns();
    },
    methods: {
        fetchDesigns: function () {
            let latestDesignsUrl = window.location.origin + '/api/designs/latest';

            fetch(latestDesignsUrl, {
                    method: 'GET',
                    credentials: 'same-origin',
                    headers: {
                        'Accept': 'application/json'
                    }
                }).then(handleFetchErrors)
                .then(result => {
                    this.loading = false;

                    if (result.success) {
                        this.designs = result.designs;
                    }
                })
                .catch(result => {
                    this.loading = false;
                    alertModal();
                });
        },

        t(key) {
            return translate(key)
        }
    }
}
</script>
