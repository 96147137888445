var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "latest-designs-container",
      class: { "is-loading": _vm.loading },
    },
    [
      _vm.loading
        ? _c(
            "div",
            {
              staticClass:
                "loading-container d-flex align-items-center justify-content-center",
            },
            [
              _c(
                "div",
                { staticClass: "spinner-grow", attrs: { role: "status" } },
                [
                  _c("span", { staticClass: "sr-only" }, [
                    _vm._v(_vm._s(_vm.t("search.loading"))),
                  ]),
                ]
              ),
            ]
          )
        : _c(
            "div",
            [
              _c("HorizontalList", {
                attrs: { "use-simple-list-entity": true, designs: _vm.designs },
              }),
            ],
            1
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }