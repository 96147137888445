<script>
import IconsBase from "./icons/IconsBase.vue"
import CancelIcon from "./icons/common/CancelIcon.vue";
export default {
  name: "LTag",
  components: {CancelIcon, IconsBase},
  props: {
    color: {
      type: String,
      default: 'violet',
    },
    filled: {
      type: Boolean,
      default: false,
    },
    closable: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    closeClicked() {
      this.$emit('close')
    },
  }
}
</script>

<template>
  <div  class="l-tag"
        :class="[color, {filled: filled}]"
  >
    <slot />
    <button v-if="closable" class="tag-close" @click="closeClicked">
      <IconsBase :iconColor="filled? '#fff' : '#000'" iconName="Cancel">
        <cancel-icon/>
      </IconsBase>
    </button>
  </div>
</template>

<style scoped lang="scss">
.l-tag {
  --color: var(--violet);

  white-space: nowrap;
  position: relative;
  box-sizing: border-box;
  cursor: default;
  display: inline-flex;
  align-items: center;
  flex-wrap: nowrap;
  border: 2px solid var(--violet);
  border-radius: 22px;
  padding: 8px 16px;
  font-size: 16px;
  color: var(--color);
  font-family: 'soleil-regular', sans-serif;
  /* Media query for tablet devices */
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    font-size: 14px;
  }
  /* Media query for mobile devices */
  @media screen and (max-width: 767px) {
    font-size: 12px;
  }
  &.filled {
    --color: var(--white);
    background-color: var(--violet);
    color: var(--color);
  }
  & .tag-close:hover {
    //background-color: var(--violet-light);
  }
  &.pink {
    --color: var(--pink);
    border: 2px solid var(--pink);
    color: var(--color);
    &.filled {
      --color: var(--white);
      background-color: var(--pink);
      color: var(--color);
    }
    & .tag-close:hover {
      //background-color: var(--pink-light);
    }
  }
}
.tag-close {
  color: var(--color);
  line-height: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
  background-color: transparent;
  margin-left: 8px;
  padding: 0;
  transition: .5s;
  border-radius: 50%;
  & svg g {
    fill: var(--color);
  }
}
</style>
