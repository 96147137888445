var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.allowedCountry && !_vm.isPurchased
        ? _c(
            "l-button",
            {
              attrs: { color: "pink", block: "true" },
              nativeOn: {
                click: function ($event) {
                  return _vm.addToCart()
                },
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "left_icon",
                    fn: function () {
                      return [
                        _c(
                          "IconsBase",
                          [
                            _c("shopping-cart-icon", {
                              attrs: {
                                iconName: _vm.inCart
                                  ? _vm.t("button.InCart")
                                  : _vm.price !== 0
                                  ? _vm.t("button.AddToCart") + _vm.price
                                  : _vm.price,
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                2487635667
              ),
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.inCart
                      ? _vm.t("button.InCart")
                      : _vm.price !== 0
                      ? _vm.t("button.AddToCart") + _vm.price
                      : _vm.price
                  ) +
                  "\n    "
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }