<template>
<div class="thankyouPage-wrapper container">
    <h1>{{t("checkout.confirmation.success")}}</h1>
    <img src="/static/icons/checkout-confirmation-success.png" alt=""/>
    <div class="confirmation-text" v-html='t("checkout.confirmation.text")'></div>
    <div v-if="this.profileData">
        <p class="recipent">{{ t("checkout.confirmation.receipt-sent-to") }}</p>
        <p>{{ this.profileData.email}}</p>

        <div class="btn-wrapper">
            <a style="max-width:60%" :href="myPurchaseLink" class="pink-btn">{{ t("checkout.confirmation.purchased-link-label") }}</a>
            <a style="max-width:60%" :href="myPurchasesLink" class="blank-btn">{{ t("checkout.confirmation.my-purchases-link-label") }}</a>
        </div>
    </div>
</div>
</template>

<style lang="scss">
@import "../../globals";

.confirmation {
    &.purchase {

        .align-center {
            text-align: center;
        }

        .fa-check-circle {
            font-size: 80px;
            margin-bottom: 3rem;
            color: #6ea760;
        }

        h3 {
            margin-bottom: 3rem;
        }

        .box {
            background: #f9f9f9;
            padding: 1rem;
            margin-bottom: 3rem;

        }

        .wrapper {
            padding-top: 20px;
            padding-bottom: 20px;
            max-width: 500px;
            margin: 0 auto;
        }

        .btn {
            text-transform: uppercase;
            font-weight: bold;
            padding: 14px;

            &.black {
                background: black;
                color: white;
            }
        }
    }
}
</style>

<script>
import {
    translate,
    getCurrentLanguageFromCookie
} from "../../utils/utils";
import store from "../../store";
import {
    mapGetters
} from "vuex";

export default {
    name: "Confirmation",
    props: ["handleChange"],
    data() {
        return {
            profileData: null,
            purchaseData: null,
            myPurchaseLink: null,
            myPurchasesLink: null,
        }
    },
    computed: {
        ...mapGetters([
            "purchase",
            "cartItems",
        ])
    },
    mounted() {
        this.myPurchasesLink = translate("checkout.confirmation.my-purchases-link-link");
        if(getCurrentLanguageFromCookie().toLowerCase() !=="en-us"){
            this.myPurchaseLink = "/" + getCurrentLanguageFromCookie() + "/my-purchases?myPurchases=true";

        }else{
            this.myPurchaseLink =  "/my-purchases?myPurchases=true";

        }
        if (this.purchase) {
      
            this.purchaseData = this.purchase;
            this.profileData = this.purchaseData.profile;

            try {
                dataLayer.push({
                    ecommerce: null
                }); // Clear the previous ecommerce object.
                var purchaseObject = {
                    event: "purchase",
                    ecommerce: {
                        transaction_id: this.purchaseData.purchaseNumber,
                        value: this.purchaseData.monetaryValue.amount,
                        tax: this.purchaseData.taxTotal.amount,
                        currency: this.purchaseData.monetaryValue.currency,
                        items: []
                    }
                };

                this.purchaseData.lineitems.forEach(item => {
                    purchaseObject.ecommerce.items.push({
                        item_name: item.nameofdesign.replace(/['"]+/g, ''),
                        item_id: item.designcode,
                        price: item.total.amount,
                        item_category: item.extendedData.categories[0],
                        item_category1: item.extendedData.categories[1],
                        item_category2: item.extendedData.categories[2],
                        item_category3: item.extendedData.categories[3],
                        item_category4: item.extendedData.categories[4],
                        item_category5: item.extendedData.categories[5],
                        quantity: 1
                    });
                });

                dataLayer.push(purchaseObject);
                console.log(dataLayer);
            } catch (error) {
                console.warn("Could not send purchase event");
                console.warn(error);
            }

            // Reset payment object in store.
            store.commit("updatePurchase", {
                purchase: null
            });

            // Reset cart
            store.commit('updateCartItems', {
                data: {
                    messages: [],
                    lineitems: [],
                    cost: [],
                    totalQuantity: 0,
                }
            })
        }
    },
    methods: {
        changeStep(step) {
            this.$emit("handleChange", step);
        },
        t(key) {
            return translate(key)
        },
    },
}
</script>
