import store from "./index";
import { translatedAlertModal } from "../components/alert-modal";

const checkout = {
    state: {
        craftingFormat: null,
        designFormat: null,
        saveFormat: false,
        order: null,
        orderLoading: false,
        payment: null,
        purchase: null,
    },
    getters: {
        craftingFormat(state) {
            return state.craftingFormat;
        },
        designFormat(state) {
            return state.designFormat;
        },
        saveFormat(state) {
            return state.saveFormat;
        },
        order(state) {
            return state.order;
        },
        orderLoading(state) {
          return state.orderLoading
        },
        payment(state) {
            return state.payment;
        },
        purchase(state) {
            return state.purchase;
        }
    },
    mutations: {
        updateCraftingFormat(state, payload) {
            if (payload.value) {
                state.craftingFormat = payload.value;
            }
        },
        updateDesignFormat(state, payload) {
            if (payload.value) {
                state.designFormat = payload.value;
            }
        },
        updateSaveFormat(state, payload) {
            state.saveFormat = payload.value;
        },
        updateOrder(state, payload) {
            state.order = payload.order;
        },
        updateOrderLoading(state, payload) {
            state.orderLoading = payload.status;
        },
        updatePayment(state, payload) {
            state.payment = payload.payment;
        },
        updatePurchase(state, payload) {
            state.purchase = payload.purchase;
        }
    },
    actions: {
        fetchAndUpdateOrder({ commit }) {
            $.ajax({
                url: "/api/order",
                method: "GET",
                success: data => {
                    this.data = data;
                    commit("updateOrder", {
                        order: data
                    });
                },
                error: event => {
                    translatedAlertModal("checkout.order.fetchandupdate.failed.headline","checkout.order.fetchandupdate.failed.text");
                },
                complete: () => {
                    commit("updateOrderLoading", {
                        status: false,
                    })
                }
            });
        },
    }
}

export default checkout;
