var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "lang-picker-current",
        on: {
          click: function ($event) {
            _vm.isListShown = !_vm.isListShown
          },
        },
      },
      [
        _c("img", {
          staticClass: "lang-icon",
          attrs: { src: _vm.getLanguageIcon(_vm.currentLanguage) },
        }),
        _vm._v("\n        " + _vm._s(_vm.currentLanguageName) + "\n        "),
        _c("i", {
          staticClass: "fa fa-angle-down",
          attrs: { "aria-hidden": "true" },
        }),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isListShown,
            expression: "isListShown",
          },
        ],
        staticClass: "lang-list",
      },
      [
        _c("p", [
          _vm._v(" " + _vm._s(_vm.t("languageDropdown.content.europe")) + " "),
        ]),
        _vm._v(" "),
        _vm._l(_vm.europeLanguageList, function (language) {
          return _c("div", { key: language.code }, [
            !_vm.northAmerica(language.name) && language.name
              ? _c(
                  "a",
                  {
                    staticClass: "header-language-name-selector font-msn-body",
                    on: {
                      click: function ($event) {
                        return _vm.onClick(language)
                      },
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        class:
                          _vm.currentLanguage === language.code
                            ? "active"
                            : null,
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(language.name) +
                            "\n                "
                        ),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
          ])
        }),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            " " + _vm._s(_vm.t("languageDropdown.content.northAmerica")) + " "
          ),
        ]),
        _vm._v(" "),
        _vm._l(_vm.northAmericaLanguageList, function (language) {
          return _c("div", { key: language.code }, [
            _vm.northAmerica(language.name) && language.name
              ? _c(
                  "a",
                  {
                    staticClass: "header-language-name-selector font-msn-body",
                    on: {
                      click: function ($event) {
                        return _vm.onClick(language)
                      },
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        class:
                          _vm.currentLanguage === language.code
                            ? "active"
                            : null,
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(language.name) +
                            "\n                "
                        ),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
          ])
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }