var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "studio-link-card" }, [
    _c("p", [
      _c("a", { attrs: { href: _vm.link } }, [
        _c("img", { attrs: { src: _vm.image, alt: _vm.title } }),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "studio-link-card-text-content" }, [
      _vm.categories
        ? _c("p", [_c("small", [_vm._v(_vm._s(_vm.categories))])])
        : _vm._e(),
      _vm._v(" "),
      _c("h3", [
        _c("a", { attrs: { href: _vm.link } }, [_vm._v(_vm._s(_vm.title))]),
      ]),
      _vm._v(" "),
      _vm.publishDate
        ? _c("small", { staticClass: "published-date" }, [
            _vm._v(_vm._s(_vm.publishDate)),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.author
        ? _c("p", { staticClass: "by-author" }, [
            _c("small", [
              _vm._v(
                _vm._s(_vm.t("design-page.studio.by")) +
                  " " +
                  _vm._s(_vm.author)
              ),
            ]),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }