<template>
    <div>
    <l-popper
        trigger="clickToOpen"
        :options="{
      placement: 'auto',
      modifiers: { offset: { offset: '0,10px' } }
    }">
      <div class="popper" >
        <SubscriptionTooltip v-if="isOpenTooltip"/>
            <SaveDesignVault v-if="isOpenUploadTooltip" :sku="sku" :designCode="designCode"  :designtype="designtype" :designs="null" :nameOfDesign="nameOfDesign" :designname="designname" :isPurchased="isPurchased==true" :priceDisplay="priceDisplay" :allowedCountry="allowedCountry" :isCollection="false" />

        </div>
      <l-button type="small"
                slot="reference"
                style="background-color:rgba(0, 0, 0, 0.3)"
                @click.native="onClick()"
                :class="{'to-cloud-faded': !hasSub }">
        <IconsBase :iconName='t("upload.tocloud.modal.header")'>
          <upload-icon/>
        </IconsBase>
      </l-button>
    </l-popper>
  </div>
</template>

<script>
import Modal from "./Modal";
import SaveDesignVault from './SaveDesignVault';
import SubscriptionTooltip from "./tooltip/SubscriptionTooltip.vue";
import Popper from 'vue-popperjs';

import {
    EventBus
} from "../utils/eventBus";
import { userHasSubscription, translate } from "../utils/utils";
import {
    subscriptionModal
} from "../components/subscription-modal";

import LButton from "./buttons/LButton.vue";
import IconsBase from "./icons/IconsBase.vue";
import UploadIcon from "./icons/common/UploadIcon.vue";
export default {
    name: "SaveDesignVaultButtonItemDetails",
    props: {
        sku: String,
        designtype:String,
        isPurchased: Boolean,
        designname: String,
        priceDisplay: String,
        allowedCountry: Boolean,
        designCode: String,
        nameOfDesign: String,
    },
    components: {
        Modal,
        SaveDesignVault,
        LButton,
        IconsBase,
        UploadIcon,
        SubscriptionTooltip,
        'l-popper': Popper,

    },

    computed: {

    },
    data() {
        return {
            showUploadModal: false,
            hasSub: !!userHasSubscription(),
            isOpenTooltip: false,
            isOpenUploadTooltip:false,

        }
    },
    methods: {
        t(key) {
            return translate(key)
        },
        onClick() {
            if (this.shouldOfferSubscription) {
                this.isOpenTooltip = true
            } else {
              this.isOpenUploadTooltip=true;            }
        },

        closeModal() {
            this.showUploadModal = false;
        },
    }
}
</script>
<style scoped lang="scss">
.subscription-wrap {
  width: 100%;
  text-align: left;
  & h5 {
    font-family: soleil-semibold, Helvetica, Arial, sans-serif;
    font-size: 16px;
  }
  & p {
    font-size: 14px;
  }
  &__actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 5px;
  }
}
</style>
