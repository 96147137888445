<script>
import { EventBus } from "../../utils/eventBus";

export default {
  name: "TitleWithLink",
  props: {
    title: String,
    link: String,
    linktext: String,
    contenttype:String,
  },
  data() {
    return {
      shouldShow: true,
    }
  },
  mounted() {
    EventBus.$on('filterChanged', this.filterChangedEvent);
  },
  methods: {
    filterChangedEvent(filterType) {
        this.shouldShow = filterType === this.contenttype
    },
    CompiledLink(){
        return  window.location.origin + this.link;
    }
  },

}
</script>

<template>
    <div v-if="shouldShow" class="title-with-link">
        <h3>{{ title }}</h3>
        <div>
            <a :href="CompiledLink()">
                <span class="link-text">{{ linktext }}</span>
                <span class="link-arrow">&#8250;</span>
            </a>
        </div>
    </div>
</template>
