<script>
export default {
    name: "CheckoutAccordion",
    props: {
        title: String,
        isOpen: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            accordionIsOpen: this.isOpen
        }
    }
}
</script>

<template>
    <div class="accordion-item">
        <div :class="['accordion-header-wrapper', accordionIsOpen ? 'active' : null]">
            <p v-if="title.length > 0" class="accordion-header">{{ title }}</p>
            <button @click="accordionIsOpen = !accordionIsOpen"
                    :class="['accordion-button', !accordionIsOpen ? 'collapsed' : null]"
                    type="button"
            />
        </div>
        <div class="accordion-body" v-show="accordionIsOpen">
            <slot/>
        </div>
    </div>
</template>

<style scoped lang="scss">

.accordion-item {
    background-color: unset;
    border: unset;
    margin-bottom: 18px;
    .accordion-header-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        &.active {
            .accordion-button {
                border-bottom: unset;
            }
            p.accordion-header {
                border-bottom: unset;
            }
        }
        p.accordion-header {
            text-transform: uppercase;
            font-family: soleil-semibold, Helvetica, Arial, sans-serif;
            font-size: 16px;
            padding-bottom: 16px;
            border-bottom: 1px solid #D8D8D8;
        }
        .accordion-button{
            background-color: unset;
            padding: 0 0 16px 0;
            border-bottom: 1px solid #D8D8D8;
            box-shadow: unset;
            &::after {
                margin-bottom: 6px;
            }
            &:not(.collapsed) {
                background-color: unset;
                color: unset;
            }
            &:focus {
                border-color: #D8D8D8;
            }
        }
    }
    .accordion-body {
        padding: 16px 0 0 0;
    }
}

</style>
