<script>
import LAccordion from "../../LAccordion.vue";
import LButton from "../../buttons/LButton.vue";
import IconsBase from "../../icons/IconsBase.vue";
import CancelIcon from "../../icons/common/CancelIcon.vue";
import ConfigIcon from "../../icons/common/ConfigIcon.vue";
import FilterForSearch from "./FilterForSearch.vue";
import { translate} from "../../../utils/utils";
import { EventBus } from "../../../utils/eventBus";
export default {
    name: "MobileFilterModal",
    components: { IconsBase, ConfigIcon, CancelIcon, LAccordion, LButton, FilterForSearch },
    data() {
        return {
            isModalShown: false,
            windowWidth: window.innerWidth,
        };
    },
    mounted() {
        window.addEventListener('resize', this.handleResize);
    },
    methods: {
        handleResize() {
            this.windowWidth = window.innerWidth;
            if (this.windowWidth > 1023) {
                document.body.style.overflow = 'initial';
            }
        },
        showFilterModal() {
          this.isModalShown = true;
          document.body.style.overflow = 'hidden';
        },
        closeFilterModal() {
          document.body.style.overflow = 'initial';
          this.isModalShown = false;
        },
        t(key, variables) {
          return translate(key, variables)
        },
        searchWithFilter() {
            this.$emit('searchWithFilter');
            this.closeFilterModal();
        },
        resetFilter() {
            EventBus.$emit('dropFilterMobile');
            // this.$emit('resetFilter');
            // this.closeFilterModal();
        }
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    },
};
</script>

<template>
  <div class="m-filter">
    <div class="m-filter-call" @click="showFilterModal">
      <icons-base iconColor="#000" width="24" height="24">
        <config-icon />
      </icons-base>
    </div>

      <transition name="slide-filter">
        <div v-if="isModalShown" class="m-filter-modal">
          <div class="m-filter-modal__close" @click="closeFilterModal">
            <IconsBase iconColor="#4E2F5D" width="24" height="24">
              <cancel-icon />
            </IconsBase>
          </div>

          <div class="search-filter">
            <p class="search-filter__header">Filter by</p>
            <div class="search-filter-wrap">
                <div class="search-filter-list">
                    <FilterForSearch @searchWithFilter="searchWithFilter"/>
                </div>
            </div>
          </div>

          <div class="m-filter-modal__action">
            <l-button type="secondary" color="pink" @click.native="resetFilter">{{t("search.reset")}}</l-button>
            <l-button color="pink" @click.native="searchWithFilter">{{ t("search.apply") }}</l-button>
          </div>
        </div>
      </transition>
  </div>
</template>

<style scoped lang="scss">
.m-filter {
  display: none;
  box-sizing: border-box;
  text-align: left;
  &-call {
    height: 45px;
    width: 45px;
    border-radius: 8px;
    background-color: #13131A1A;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.3s;
  }
  &-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #FFFFFF;
    padding: 5px;
    z-index: 999;
    &__close {
      height: 45px;
      width: 45px;
      border-radius: 8px;
      background-color: #13131A1A;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;
      transition: 0.3s;
      &:hover {
        background-color: var(--tint-pink)
      }
    }
    &__action {
      position: absolute;
      bottom: 10%;
      right: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
    }
  }
}
.search-filter {
  &__header {
    padding: 0 10px;
    font-family: 'soleil-semibold', sans-serif;
  }
  &-wrap {
    max-height: calc(100vh - 120px);
    overflow: auto;
  }
  &-list {
      padding: 0 20px 0 10px;
  }
}
.slide-filter-enter-active {
  transition: all .2s ease;
}
.slide-filter-leave-active {
  transition: all .2s ease;
}
.slide-filter-enter {
  transform: translateX(-1023px);
}

.slide-filter-leave-to {
  transform: translateX(-1023px);
}

/* Tablet devices */
@media screen and (max-width: 1023px) {
  .m-filter {
    display: block;
  }
}
/* Mobile devices */
@media screen and (max-width: 767px) {
}
</style>
