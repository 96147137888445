import store from "./index";
import { translatedAlertModal } from "../components/alert-modal";

const collectionSearch = {
    state: {
        queryData: {
            q: "",
            order: "",
            orderKey: ""
        }
    },
    getters: {
        collectionQueryData(state) {
            return state.queryData
        },
    },
    mutations: {
        updateCollectionQueryData(state, payload) {
            state.queryData = {
                ...state.queryData,
                ...payload
            }
        },
    },
    actions: {
        
    }
}

export default collectionSearch;
