import {translate} from "../utils/utils";

const modal = $("#alertModal");
const modalTitle = modal.find(".modal-title");
const modalText = modal.find(".modal-text");

modal.on("hidden.bs.modal", e => {
	modalTitle.text("");
	modalText.text("");
});

export function alertModal(title = translate("error-title"), text = translate("error-text")) {
	modalTitle.text(title);
	modalText.html(text);
	modal.modal("show");
}

export function translatedAlertModal(title, text) {
	alertModal(translate(title),translate(text));
}

