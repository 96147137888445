var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tool-item" },
    [
      _c("img", { attrs: { src: _vm.item.image, alt: _vm.item.name } }),
      _vm._v(" "),
      _c("p", [_vm._v(_vm._s(_vm.item.name))]),
      _vm._v(" "),
      _c(
        "l-button",
        {
          attrs: { type: "small", color: "", block: "block" },
          nativeOn: {
            click: function ($event) {
              return _vm.onClick(_vm.item.link)
            },
          },
        },
        [
          _vm._v(
            "\n        " +
              _vm._s(_vm.t("design-page.tools.visit-to-buy")) +
              "\n    "
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }