<template>
<div class="remove-from-cart" @click="removeFromCart">
    <div v-if="isLoading" class="spinner-grow spinner-grow-sm" role="status"></div>
    <slot v-else></slot>
</div>
</template>

<script>
import store from "../store";
import {
    translatedAlertModal
} from "../components/alert-modal";

export default {
    name: "RemoveFromCart",
    props: {
        designCode: {
            type: String,
            default: null,
        }
    },
    data() {
        return {
            isLoading: false,
            url: "/api/cart/remove-from-cart?",
        }
    },


    methods: {
        removeFromCart(e) {
            if (this.isLoading) {
                return;
            }

            this.isLoading = true;

            store.commit("updateOrderLoading", {
                status: true
            });
            
            $.ajax({
                url: this.url,
                method: "POST",
                data: {
                    designcode: this.designCode
                },

                success: data => {
                    store.dispatch("fetchAndUpdateOrder");
                    store.dispatch("fetchAndUpdateCart");
                    this.isLoading=false;
                },
                error: event => {
                    translatedAlertModal("checkout.details.remove-from-cart.failed.headline", "checkout.details.remove-from-cart.failed.text");
                    this.isLoading=false;
               },
            });
        }
    }
}
</script>

<style scoped>

</style>
