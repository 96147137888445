<template>
<div v-if="!loggedIn || !hasSub" class="design-user-info">
    <div class="sub-login">
        <p v-if="!loggedIn">
            <a :href="url" target="_blank">{{ t("design-page.login") }}</a> {{ t("design-page.login-info") }}
        </p>
        <p v-if="!hasSub">
            <a href="https://mysewnet.com/en/pricing">{{ t("design-page.subscribe") }}</a> {{ t("design-page.subscribe-info") }}
        </p>
    </div>
    <p class="design-user-info-reload">
        {{ t("design-page.reload-info") }}
    </p>
</div>
</template>

<script>
import {
    translate,
    userHasSubscription,
    isLoggedIn
} from "../utils/utils";

export default {
    name: "DesignUserInfo",
    data() {
        return {
            loggedIn: isLoggedIn(),
            hasSub: userHasSubscription(),
            url: window.global_login_url,
        }
    },
    methods: {
        t(key) {
            return translate(key)
        }
    },
}
</script>

<style lang="scss">
.design-user-info {
    font-weight: 600;

    .sub-login {
        margin-bottom: 1rem;

        p {
            margin: 0;
        }

        a {
            text-decoration: underline;
        }
    }

    .design-user-info-reload {
        margin-bottom: 1.6rem;
        font-size: .9rem;
    }
}
</style>
