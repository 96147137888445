<template>
<div class="purchases-listing-page item-listing-page">
    <div class="item-listing-page-header-container">
        <h1>{{ t("header.library.myPurchases") }}</h1>
        <div class="item-listing-page-header-extra">
            <div class="item-type-switch-container row">
                <div class="col-6" style="width:30%">
                    <button class="item-type-switch" :class="{ active: specifiedEmbroidery }" style="" value="embroidery" @click="specifyItemType('Embroidery')">
                        <img v-if="specifiedEmbroidery" src="/static/icons/check.png" />{{ t("categories.designs") }}
                    </button>
                </div>
                <div class="col-6" style="width:30%">
                    <button class="item-type-switch" :class="{ active: specifiedCollections }" value="collections" @click="specifyItemType('collections')">
                        <img v-if="specifiedCollections" src="/static/icons/check.png" />{{ t("Collections") }}
                    </button>
                </div>
                <div class="col-6" style="width:30%">
                    <button class="item-type-switch" :class="{ active:specifiedCrafting }" value="crafting" @click="specifyItemType('crafting')">
                        <img v-if="specifiedCrafting" src="/static/icons/check.png" />{{ t("Crafting") }}
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="page-margin mt-4">
        <KeepAlive>
            <EmbroideryPurchases style="padding-bottom:50px" v-if="specifiedEmbroidery || allTypesNotSelected()" />
        </KeepAlive>
        <KeepAlive>
            <CollectionPurchases v-if="specifiedCollections || allTypesNotSelected()" />
        </KeepAlive>
        <KeepAlive>
            <CraftingPurchases v-if="specifiedCrafting || allTypesNotSelected()" />
        </KeepAlive>
    </div>
</div>
</template>

<script>
import {

    generalErrorCallback,
    translate
} from "../utils/utils";
import EmbroideryPurchases from "./EmbroideryPurchases";
import CollectionPurchases from "./CollectionPurchases";
import CraftingPurchases from "./CraftingPurchases";

import {
    EventBus
} from "../utils/eventBus";
export default {
    name: "Purchases",
    data: () => ({
        specifiedEmbroidery: false,
        specifiedCollections:false,
        specifiedCrafting:false,
    }),
    components: {
        CraftingPurchases,
        EmbroideryPurchases,
        CollectionPurchases,

    },
    created: function () {

    },

    methods: {
        allTypesNotSelected(){
            if(!this.specifiedCollections && !this.specifiedEmbroidery && !this.specifiedCrafting)
            return true
        },
        specifyItemType(type) {          
            if (type === 'Embroidery' ){this.specifiedEmbroidery = !this.specifiedEmbroidery}
            if (type === 'collections' ){this.specifiedCollections = !this.specifiedCollections}
            if (type === 'crafting' ){this.specifiedCrafting = !this.specifiedCrafting}

        },
        t(key, variables) {
            return translate(key, variables)
        },
    },
}
</script>

<style lang="scss">
@import "../globals";

.purchases {
    .loading-container {
        min-height: 100px;
    }
}
</style>
