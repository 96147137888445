var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("svg", { attrs: { xmlns: "http://www.w3.org/2000/svg" } }, [
    _c("g", { attrs: { id: "load_vault", transform: "translate(-6 -6)" } }, [
      _c("path", {
        attrs: {
          id: "Vector-7",
          d: "M32,19.14H27.71",
          fill: "none",
          stroke: "#fff",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
          "stroke-width": "2",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          id: "Vector-8",
          d: "M32,24.86H27.71",
          fill: "none",
          stroke: "#fff",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
          "stroke-width": "2",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          id: "Vector-9",
          d: "M15.5,22H16",
          fill: "none",
          stroke: "#fff",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
          "stroke-width": "2",
        },
      }),
      _vm._v(" "),
      _c("rect", {
        attrs: {
          id: "Rectangle_20851",
          "data-name": "Rectangle 20851",
          width: "28",
          height: "28",
          rx: "2.8",
          transform: "translate(8 8)",
          fill: "none",
          stroke: "#fff",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
          "stroke-width": "2",
        },
      }),
      _vm._v(" "),
      _c("rect", {
        attrs: {
          id: "Rectangle_20852",
          "data-name": "Rectangle 20852",
          width: "20",
          height: "20",
          transform: "translate(12 12)",
          fill: "none",
          stroke: "#fff",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
          "stroke-width": "2",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          id: "Rectangle_20853",
          "data-name": "Rectangle 20853",
          d: "M12,18h4a4,4,0,0,1,4,4h0a4,4,0,0,1-4,4H12V18Z",
          fill: "none",
          stroke: "#fff",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
          "stroke-width": "2",
        },
      }),
      _vm._v(" "),
      _c("rect", {
        attrs: {
          id: "Rectangle_82110",
          "data-name": "Rectangle 82110",
          width: "32",
          height: "32",
          transform: "translate(6 6)",
          fill: "none",
        },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }