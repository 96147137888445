<template>
    <div>
        <div v-if="compiledCategories.length > 0">
            <div @click="load" style="display:flex">
                <div class="mt-4">
                    <h1 style="border-bottom: 1px solid #D7D7D4; padding-bottom: 28px;">{{ t("categories.subcategories") }}
                    </h1>
                </div>
            </div>
           
                
                    <div class="swiper-wrapper" >
                        
                        <div class="swiper-slide" style="width: 140px; ; font-weight: bold;">
                            <div :class="['l-filter__item', { 'emb-active': embisActive() }]"
                                @click="setCattype('embroidery')">
                                <IconsBase :iconName="t('search-results.type.embroideries')" :iconColor="embisActive() ? '#fff' : '#000'">
                                    <embroidery-icon />
                                </IconsBase>
                                {{ t('search-results.type.embroideries') }}
                            </div>
                        </div>
                        <div class="swiper-slide" style="width: 190px;  font-weight: bold;">
                            <div :class="['l-filter__item', { 'craft-active': craftingisActive() }]"
                                @click="setCattype('crafting')">
                                <IconsBase :iconName="t('search-results.type.crafting')" :iconColor="craftingisActive() ? '#fff' : '#000'">
                                    <crafting-icon />
                                </IconsBase>
                                {{ t('search-results.type.crafting') }}
                            </div>
                        </div>
                        <div class="swiper-slide" style="width: 205px; font-weight: bold;">
                            <div :class="['l-filter__item', { 'fonts-active': fontisActive() }]"
                                @click="setCattype('font')">
                                <IconsBase :iconName="t('search-results.type.fonts')" :iconColor="fontisActive() ? '#fff' : '#000'">
                                    <fonts-icon />
                                </IconsBase>
                                {{ t('search-results.type.fonts') }}
                            </div>
                        </div>
                    </div>
            
            


            <div class="page-margin mt-4n">
                <div class="row text-center text-lg-left grid-wrapper">
                    <div v-for="category in compiledCategories" :key="category.id"
                        style="outline-style: solid ;padding:10px;outline-color: white;outline-width: 10px;"
                        class="col-lg-3 col-md-4 col-sm-6 col-6 mt-4 pl-0">

                        <div style="border: solid 1px #c4c4c4;border-radius: 12px 12px 0px 0px; height: auto;">

                            <a :href="CompiledSearchURL(category.id, category.type)">
                                <div v-if="category.image" class="design-thumb" style="position: relative;">
                                    <div v-if="category.type == 'embroidery' || category.type == 'crafting' || category.type == 'font' || category.type == 'collection'"
                                        style="position: absolute; top: 5px; left: 5px; text-align: left; display: flex;">
                                        <div v-if="category.type == 'embroidery'"
                                            style="padding-top:25px;padding:5px;text-align:left;display:flex">
                                            <div class="CategoryIcon">
                                                <IconsBase :iconName="t('search-results.type.embroideries')" icon-color="#fff">
                                                    <embroidery-icon />
                                                </IconsBase>
                                            </div>
                                        </div>
                                        <div v-if="category.type == 'crafting'"
                                            style="padding-top:25px;padding:5px;text-align:left;display:flex">
                                            <div class="CategoryIcon">
                                                <IconsBase :iconName="t('search-results.type.crafting')" icon-color="#fff">
                                                    <crafting-icon />
                                                </IconsBase>
                                            </div>


                                        </div>
                                        <div v-if="category.type == 'font'"
                                            style="padding-top:25px;padding:5px;text-align:left;display:flex">
                                            <div class="CategoryIcon">
                                                <IconsBase :iconName="t('search-results.type.fonts')"  icon-color="#fff">
                                                    <fonts-icon />
                                                </IconsBase>
                                            </div>


                                        </div>
                                        <div v-if="category.type == 'collection'"
                                            style="padding-top:25px;padding:5px;text-align:left;display:flex">
                                            <div class="CategoryIcon">
                                                <IconsBase :iconName="t('search-results.type.collections')"  icon-color="#fff">
                                                    <collection-icon />
                                                </IconsBase>
                                            </div>

                                        </div>
                                    </div>
                                    <img :src="category.image" alt="" />
                                </div>

                            </a>


                        </div>
                        <div class="catname">
                            <div style="margin-top: 20px;"> {{ category.name }} </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<style lang="scss">
.catImage {
    height: 200px;
}

.catname {
    /* Layout Properties */
    top: 818px;
    left: 968px;
    font-weight: bold;
    height: 80px;

    /* UI Properties */
    background: var(---f7f7f7-bg-color-buttons) 0% 0% no-repeat padding-box;
    background: #F7F7F7 0% 0% no-repeat padding-box;
    border: 1px solid #D8D8D8;
    border-radius: 0px 0px 12px 12px;
    opacity: 1;
}

.l-filter {
    font-family: 'soleil-regular', sans-serif;
    color: var(--black);
    font-size: 16px;
    line-height: 1;
    
    &__item {
        display: inline-block;
        cursor: pointer;
        padding: 11px 12px;
        border: 1px solid #D8D8D8;
        border-radius: 12px;
        height: 44px;
        background-color: var(--white);
        
        &.no-icon {
            display: inline-block;
            justify-content: center;
            align-items: center;
        }

        & span {
            font-family: 'soleil-semibold', sans-serif;
        }

        &.all-active {
            border: 1px solid #367498;
            background-color: var(--green);
            color: var(--white);
        }

        &.emb-active {
            border: 1px solid #4E2765;
            background-color: var(--violet);
            color: var(--white);
        }

        &.craft-active {
            border: 1px solid #9D272B;
            background-color: var(--singer-red);
            color: var(--white);
        }

        &.fonts-active {
            border: 1px solid #9D272B;
            background-color: var(--singer-red);
            color: var(--white);
        }

        &.collections-active {
            border: 1px solid #D8D8D8;
            background-color: var(--orange);
            color: var(--white);
        }
    }
}

.type-switch {
    input {
        margin-right: .15em;
        cursor: pointer;
    }

    label {
        background: #c61a5a;
        ;
        font-size: 13px;
        cursor: pointer;
        padding: 0 3px;
        color: white;
        width: 100%;
        border-radius: 5px;
        height: 100%;
        top: 50%;
        font-size: 15px;
        font-weight: 500;
        text-align: center;
        padding: 10px;

        &.active {
            background: #ffe3ec;
            color: #c61a5a;
        }
    }

}

.CategoryIcon {

    cursor: pointer;
    top: 6px;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    padding: 6px;
}
</style>

<script>
import {
    EventBus
} from "../utils/eventBus";
import CategoryDesigns from "./CategoryDesigns";
import {
    generalErrorCallback,
    translate,
    handleFetchErrors
} from "../utils/utils";
import IconsBase from "./icons/IconsBase.vue";
import CollectionIcon from "./icons/common/CollectionIcon.vue";
import EmbroideryIcon from "./icons/common/EmbroideryIcon.vue";
import FontsIcon from "./icons/common/FontsIcon.vue";
import CraftingIcon from "./icons/common/CraftingIcon.vue";
export default {
    name: "Categories",
    data() {
        return {
            displaytype: "",
        }
    },
    components: {
        CategoryDesigns,
        IconsBase,
        CollectionIcon,
        EmbroideryIcon,
        FontsIcon,
        CraftingIcon,
    },
    created: function () {
        EventBus.$on('sort', this.updateOrderKey);
    },
    methods: {
        CompiledSearchURL(id, type) {

            return window.location.origin + "/search?type=" + type + "&categories=" + id;
        },
        t(key) {
            return translate(key)
        },
        setCattype(value) {
            this.displaytype = value
        },
        load() {

        },
        craftingisActive() {
            if (this.displaytype === "crafting") {
                return true;
            } else {
                return false;
            }
        },
        fontisActive() {
            if (this.displaytype === "font") {
                return true;
            } else {
                return false;
            }
        },
        embisActive() {
            if (this.displaytype === "embroidery") {
                return true;
            } else {
                return false;
            }
        },
    },
    computed: {
        compiledCategories() {
            var compiled = [];
            if (typeof this.subcategories === 'string') {
                JSON.parse(this.subcategories).forEach(cat => {
                    if (this.displaytype === cat.type || this.displaytype === '' || this.displaytype == 'both') {
                        console.log(cat.image);
                        compiled.push(cat);
                    }
                });

                return compiled;
            }
        }
    },

    props: {
        categoryid: String,
        subcategories: String,
        parent: String,
        type: String,
    },
}
</script>
