import Vue from "vue";
import Vuex from "vuex";
import VuexPersistedState from "vuex-persistedstate";
import { getQueryParameterValue } from "../utils/utils";
import user from "./user";
import cart from "./cart";
import checkout from "./checkout";
import collectionSearch from "./CollectionSearch";
import designSearch from "./designSearch";
import {translate} from "../utils/utils";

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        user,
        cart,
        checkout,
        collectionSearch,
        designSearch
    },
    plugins: [
        VuexPersistedState({
            paths: ["searchResult", "displayUnit", "persistentQueryData", "user", "cart", "checkout"]
        }),
    ],
    state: {
        queryData: {
            q: getQueryParameterValue("q"),
            order: getQueryParameterValue("order"),
            orderKey: getQueryParameterValue("orderKey"),
            limit: getQueryParameterValue("limit"),
            listView: getQueryParameterValue("listView"),
            heightMin: getQueryParameterValue("heightMin"),
            heightMax: getQueryParameterValue("heightMax"),
            widthMin: getQueryParameterValue("widthMin"),
            widthMax: getQueryParameterValue("widthMax"),
            colorBlockCountMax: getQueryParameterValue("colorBlockCountMax"),
            craftingType:getQueryParameterValue("craftingType"),
            craftingProjectLevel:getQueryParameterValue("craftingProjectLevel"),
            craftingProjectTime:getQueryParameterValue("craftingProjectTime"),
            maxcraftingProjectWidthInMm:getQueryParameterValue("maxcraftingProjectWidthInMm"),
            mincraftingProjectWidthInMm:getQueryParameterValue("mincraftingProjectWidthInMm"),

            maxcraftingProjectHeightInMm:getQueryParameterValue("maxcraftingProjectHeightInMm"),
            mincraftingProjectHeightInMm:getQueryParameterValue("mincraftingProjectHeightInMm"),

            maxcraftingProjectLengthInMm:getQueryParameterValue("maxcraftingProjectLengthInMm"),
            mincraftingProjectLengthInMm:getQueryParameterValue("mincraftingProjectLengthInMm"),
            stitchCountMax: getQueryParameterValue("stitchCountMax"),
            techniques: getQueryParameterValue("techniques"),
            categories: getQueryParameterValue("categories"),
            myFavorites: getQueryParameterValue("myFavorites"),
            myPurchases: getQueryParameterValue("myPurchases")
        },
        persistentQueryData: {},
        searchResult: {
            results: [],
            hits: 0,
            offset: 0,
        },
        filterDict: {
            techniques: [],
            categories: []
        },
        displayUnit: null,
        searchParams: {},
        searchType: 'all',
        categoryList: [],
        techniqueList: [],
        fontTypesList: [
            {
                id: 'cut',
                name: t("search-filters.cut")
            },
            {
                id: 'write',
                name: t("search-filters.write")
            },
        ],
        collectionTypesList: [
            {
                id: 'embroidery_designs',
                name: t("search-filters.embroidery-designs")
            },
            {
                id: 'crafting_images',
                name: t("search-filters.crafting-images")
            },
            {
                id: 'crafting_projects',
                name: t("search-filters.crafting-projects")
            },
            {
                id: 'fonts',
                name: t("search-filters.fonts")
            },
        ],
        operationTypesList: [
            {
                id: 'cut',
                name: t('search-filters.operation-type.cut')
            },
            {
                id: 'print_then_cut',
                name: t('search-filters.operation-type.print_then_cut')
            },
            {
                id: 'engrave',
                name: t('search-filters.operation-type.engrave')
            },
            {
                id: 'deboss',
                name: t('search-filters.operation-type.deboss')
            },
            {
                id: 'pen',
                name: t('search-filters.operation-type.pen')
            }
        ]
    },

    getters: {
        queryData(state) {
            return state.queryData
        },
        filterDict(state) {
            return state.filterDict
        },
        persistentQueryData(state) {
            return state.persistentQueryData
        },
    },
    mutations: {
        setSearchType(state, payload) {
            state.searchType = payload
        },
        setSearchParams(state, payload) {
            state.searchParams = payload
        },
        setCategoryList(state, payload) {
            state.categoryList = payload
        },
        setTechniqueList(state, payload) {
            state.techniqueList = payload
        },
        updateQueryData(state, payload) {
            state.queryData = {
                ...state.queryData,
                ...payload
            }
            state.persistentQueryData = state.queryData;
        },
        updatePersistentQueryData(state) {
            state.persistentQueryData = state.queryData;
        },
        updateFilterDict(state, payload) {
            state.filterDict = {
                ...state.filterDict,
                ...payload
            }
        },
        replaceSearchResult(state, payload) {
            state.searchResult.results = payload;
        },
        updateSearchResult(state, payload) {
            state.searchResult.results = state.searchResult.results.concat(payload.searchResult);
            state.searchResult.hits = payload.hits;
        },
        clearSearchResult(state) {
            state.searchResult.results = [];
            state.searchResult.hits = 0;
            state.searchResult.offset = 0;

        },
        updateOffset(state, payload) {
            state.searchResult.offset = payload;
        },
        updateDisplayUnit(state, payload) {
            state.displayUnit = payload;
        }
    },
});

function t(key, variables) {
    return translate(key, variables);
}

export default store;
