var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "pink-btn view-more m-auto c-pointer",
      on: { click: _vm.onClick },
    },
    [_c("i", [_vm._v(_vm._s(_vm.t("viewmore")))])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }