var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    {
      class: { "purchased-label": !_vm.pdp, "purchased-pdp-label": _vm.pdp },
      staticStyle: {
        display: "flex",
        "text-align": "center",
        "align-items": "center",
        "justify-content": "space-between",
      },
    },
    [
      _c("PurchasedIcon", { staticStyle: { padding: "2px" } }),
      _vm._v(" "),
      _c(
        "p",
        {
          class: { "purchased-text": !_vm.pdp },
          staticStyle: {
            position: "relative",
            color: "#74A765",
            "font-weight": "bold",
            top: "8px",
          },
        },
        [_vm._v("PURCHASED")]
      ),
      _vm._v(" "),
      _c("div"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }