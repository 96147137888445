var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "32",
        height: "32",
        viewBox: "0 0 32 32",
      },
    },
    [
      _c(
        "g",
        {
          attrs: { id: "arrow_light_down", transform: "translate(-214 -123)" },
        },
        [
          _c("path", {
            attrs: {
              id: "Vector",
              d: "M56,15,67.526,26.574a3.5,3.5,0,0,0,4.949,0L84,15",
              transform: "translate(160 117)",
              fill: "none",
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
              "stroke-width": "2",
            },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }