var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "g",
    { attrs: { id: "shopping_cart", transform: "translate(-58 -6)" } },
    [
      _c("path", {
        attrs: { id: "Vector-4", d: "M58,6H90V38H58Z", fill: "none" },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          id: "Vector-2-2",
          d: "M85,33a3,3,0,1,1-3-3A3,3,0,0,1,85,33Z",
          fill: "none",
          stroke: "#fff",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
          "stroke-width": "2",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          id: "Vector-3-2",
          d: "M74,33a3,3,0,1,1-3-3A3,3,0,0,1,74,33Z",
          fill: "none",
          stroke: "#fff",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
          "stroke-width": "2",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          id: "Path_42028",
          "data-name": "Path 42028",
          d: "M65,12H88L86.5,25H67",
          fill: "none",
          stroke: "#fff",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
          "stroke-width": "2",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          id: "Path_42029",
          "data-name": "Path 42029",
          d: "M60,8l1.763.019a2.782,2.782,0,0,1,2.748,2.408l2.182,17.164A2.785,2.785,0,0,0,69.472,30H85.2",
          fill: "none",
          stroke: "#fff",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
          "stroke-width": "2",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }