<template>
    <div class="coupons-wrapper" v-if="order">
        <h4>Coupons</h4>
        <div class="coupons-main">
            <!-- <div v-if="isLoading" class="coupons-loading">
                <div class="spinner-grow" role="status"></div>
            </div> -->
            <div class="applied-coupons">
                <div class="coupon-wrapper" v-for="coupon in order.coupons" v-bind:key="coupon.index">
                    <div class="coupon-info">
                        <div class="coupon-title">
                            <h5><b>{{ coupon.code }}:</b> {{ coupon.displayName }}</h5>
                        </div>
                        <div class="coupon-description">
                            <p>{{ coupon.displayDescription }}</p>
                        </div>
                    </div>
                    <RemoveCoupon :coupon="coupon" v-on:remove-coupon-error="onRemoveCouponError" v-on:remove-coupon-click="onRemoveCouponClick">
                        <i class="fas fa-times fa-md"></i>
                    </RemoveCoupon>
                </div>
            </div>
            <div class="coupon-form">
                <div class="form-row">
                    <div class="col-sm-8">
                        <input type="text" name="coupon" v-model="coupon" @keyup.enter="applyCoupon" :placeholder="t('add-your-coupon')" class="form-control" :class="{ 'is-invalid': hasError, 'is-valid': hasSuccess }">
                        <div class="invalid-feedback" :class="{ 'd-block': hasError }">{{ errorMessage }}</div>
                        <div class="valid-feedback">{{ successMessage }}</div>
                    </div>
                    <div class="col-sm-4">
                        <button type="submit" @click="applyCoupon" class="btn btn-primary btn-block apply-coupon">{{t("search.apply")}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { translate } from "../../utils/utils";
import { mapGetters } from "vuex";
import store from "../../store";
import RemoveCoupon from "../RemoveCoupon";

export default {
    name: "Coupons",

    components: {
        RemoveCoupon
    },

    computed: {
        ...mapGetters([
            'order',
        ]),
    },
    props: {
        step: String,
    },
    data: () => ({
        url: "/api/order/coupon",
        coupon: null,
        isLoading: false,
        hasSuccess: false,
        successMessage: "",
        hasError: false,
        errorMessage: ""
    }),
    watch: {
        coupon(newCoupon, oldCoupon) {
            if (oldCoupon !== newCoupon) {
                // Special case for when a coupon was successfully applied,
                // in which case we don't want to immediately clear the status
                if (newCoupon !== null && this.hasSuccess) {
                    this.hasSuccess = false;
                    this.successMessage = "";
                }

                this.hasError = false;
                this.errorMessage = "";
            }
        }
    },
    methods: {
        isPaymentStep() {
            return this.step == "payment";
        },
        applyCoupon() {
            if (this.isLoading) {
                return;
            }

            if (this.coupon === null || this.coupon.trim() === '') {
                return;
            }

            this.isLoading = true;

            store.commit("updateOrderLoading", {
                status: true
            });

            $.ajax({
                url: this.url,
                method: "POST",
                data: { coupon: this.coupon },
                success: data => {
                    this.hasSuccess = true;
                    this.successMessage = "Coupon \"" + this.coupon + "\" applied!";
                    this.coupon = null;

                    store.commit("updateOrder", {
                        order: data
                    });
                },
                error: (event, textStatus, error) => {
                    let response = event.responseJSON;
                    this.hasError = true;
                    this.errorMessage = response.message;

                    if (typeof Rollbar !== 'undefined') {
                        var eventObject = {
                            errorResponseObject: event,
                            textStatus: textStatus,
                            error: error,
                            eventType: 'placeOrderError'
                        }
                        Rollbar.captureEvent(eventObject, 'error');
                        Rollbar.error('API call POST order/coupon error');
                    }
                },
                complete: event => {
                    store.commit("updateOrderLoading", {
                        status: false
                    });

                    this.isLoading = false;
                }
            });
        },
        onRemoveCouponClick(couponId) {
            this.hasSuccess = false;
            this.successMessage = "";
            this.hasError = false;
            this.errorMessage = "";
        },
        onRemoveCouponError(message) {
            this.hasError = true;
            this.errorMessage = message;
        },
        t(key) {
            return translate(key)
        },
    }
}

</script>

<style lang="scss">
    .coupons-wrapper {
        position: relative;

        h4 {
            font-size: 16px;
            text-transform: uppercase;
            font-family: soleil-semibold, Helvetica, Arial, sans-serif;
            border-bottom: 1px solid #D8D8D8;
            margin-bottom: 1.2rem;
            padding-bottom: 16px;
        }

        .coupons-main {
            position: relative;

            .coupon-wrapper {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;

                .remove-coupon {
                    padding: 0 10px;
                    cursor: pointer;
                }
            }

            h5 {
                font-size: 1rem;
            }

            .coupons-loading {
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 9;
                background-color: #f9f9f9c9;
            }

            .coupon-form {
                border-bottom: 1px solid #cacaca;
                padding-bottom: 8px;

                .form-row > * {
                    margin-bottom: 10px;
                }

                .apply-coupon {
                    background-color: #c61a5a;
                    border-color: #c61a5a;
                }
            }
        }
    }
</style>
