var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "g",
    {
      attrs: {
        id: "Group_186106",
        "data-name": "Group 186106",
        "clip-path": "url(#clip-path)",
      },
    },
    [
      _c("path", {
        attrs: {
          id: "Path_135526",
          "data-name": "Path 135526",
          d: "M19,30H5.5A3.5,3.5,0,0,1,2,26.5V5.5A3.5,3.5,0,0,1,5.5,2h21A3.5,3.5,0,0,1,30,5.5V27a3,3,0,0,1-6,0",
          fill: "none",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
          "stroke-width": "2",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          id: "Path_135527",
          "data-name": "Path 135527",
          d: "M10,2V7H22V2",
          fill: "none",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
          "stroke-width": "2",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          id: "Path_135528",
          "data-name": "Path 135528",
          d: "M12,7V18.14L20,26V7",
          fill: "none",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
          "stroke-width": "2",
        },
      }),
      _vm._v(" "),
      _c("line", {
        attrs: {
          id: "Line_4010",
          "data-name": "Line 4010",
          x2: "8",
          y2: "7.86",
          transform: "translate(12 12.14)",
          fill: "none",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
          "stroke-width": "2",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }